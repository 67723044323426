import { MatrixSkill, MatrixSkillSet } from '@app/matrix'
import { SimplePerson } from '@app/person'
import { SkillDetails, SkillSetDetails } from '@app/template'
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large'
import classNames from 'classnames'
import DescriptionPopover from 'components/descriptionPopover/DescriptionPopover'
import React, { useState } from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'

interface Props extends WithTranslation {
    small: boolean
    showAdditionalContentOnlyOnHover: boolean
    data: MatrixSkill | MatrixSkillSet | SkillDetails | SkillSetDetails
    children?: React.ReactNode
    owners?: Array<SimplePerson>
    ownerUserIds?: Array<string>
    showTriggerButtonLabel?: boolean
    additionalTriggerButton?: React.ReactNode
    additionalContent?: React.ReactNode
    skillSetSubtitle?: React.ReactNode
}

interface State {
    showChildList: boolean
    rotate: boolean
}

const ContentBrowserListItem = (props: Props) => {
    const [state, setState] = useState<State>({
        showChildList: false,
        rotate: false
    })

    const toggleChildList = () =>
        setState({
            showChildList: !state.showChildList,
            rotate: !state.showChildList
        })

    return (
        <>
            <span
                className={classNames('contentBrowserListItem', {
                    small: props.small
                })}
                onClick={toggleChildList}
            >
                {!props.small && (
                    <div className="contentBrowserListItem__iconWrapper">
                        <div
                            className={classNames(
                                'contentBrowserListItem__arrow',
                                {
                                    rotate: state.rotate
                                }
                            )}
                        >
                            <ChevronRightLargeIcon label="" />
                        </div>
                    </div>
                )}

                <div className="contentBrowserListItem__text">
                    <div
                        className={classNames(
                            'contentBrowserListItem__titleWrapper',
                            'no-select',
                            { small: props.small }
                        )}
                        title={props.data.name}
                    >
                        <div
                            className={classNames(
                                'contentBrowserListItem__title',
                                'text-ellipsis'
                            )}
                        >
                            {props.data.name}
                        </div>

                        <DescriptionPopover
                            className="contentBrowserListItem__popover"
                            data={props.data}
                            owners={props.owners}
                            showTriggerButtonLabel={
                                props.showTriggerButtonLabel
                            }
                        />

                        {props.additionalTriggerButton && (
                            <div className="contentBrowserListItem__popover">
                                {props.additionalTriggerButton}
                            </div>
                        )}
                    </div>

                    <div className="contentBrowserListItem__subtitle text-ellipsis no-select">
                        {props.skillSetSubtitle}
                    </div>
                </div>

                <div
                    className={classNames(
                        'contentBrowserListItem__additionalContent',
                        {
                            showOnlyOnHover:
                                props.showAdditionalContentOnlyOnHover
                        }
                    )}
                >
                    {props.additionalContent}
                </div>
            </span>

            {state.showChildList && (
                <div className="contentBrowserListItem__childWrapper">
                    {props.children}
                </div>
            )}
        </>
    )
}

export default withTranslation('common')(ContentBrowserListItem)
