import {
    LoginDetails,
    LoginFormCredentialsData,
    SubmitNewPasswordFormData,
    SubmitPasswordFormData
} from '@app/common'
import { PersonDetailsWithMatrices, PersonPasswordUpdate } from '@app/person'
import { AxiosInstance } from 'axios'
import { useClient } from 'hooks/useClient'
import useSWR from 'swr'
import { determineComponentStatus, tryMutation } from './apiHelpers'

const ACCOUNT_BASE_URL = '/api/user'

function useFetchCurrentUser() {
    const { data, error, mutate } = useSWR<PersonDetailsWithMatrices>(
        `${ACCOUNT_BASE_URL}/logged-in`,
        {
            revalidateOnFocus: false,
            onErrorRetry: (
                retryError,
                _key,
                _config,
                retryRevalidate,
                { retryCount }
            ) => {
                if (retryError.status !== 401) {
                    setTimeout(() => retryRevalidate({ retryCount }), 5000)
                }
            }
        }
    )

    return {
        currentUser: data,
        error,
        revalidateCurrentUser: mutate,
        status: determineComponentStatus(data, error)
    }
}

function useFetchActivationStatus(activationKey: string) {
    const { data, error } = useSWR(
        `${ACCOUNT_BASE_URL}/${activationKey}/validate`
    )

    return {
        activationStatus: data,
        error,
        status: determineComponentStatus(data, error)
    }
}

function useCheckResetPasswordKey(resetPasswordKey: string) {
    const { error } = useSWR(
        `${ACCOUNT_BASE_URL}/reset-password/${resetPasswordKey}/validate`
    )

    return {
        data: null,
        error,
        status: determineComponentStatus(null, error)
    }
}

function useRequestCreateNewPasswordSubmit(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (credentials: SubmitNewPasswordFormData) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).patch(
                `${ACCOUNT_BASE_URL}/reset-password/`,
                credentials
            )

            return response.data
        })
}

function useRequestPasswordSubmit(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (credentials: SubmitPasswordFormData) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).patch(
                `${ACCOUNT_BASE_URL}/${credentials.activationKey}/complete`,
                credentials.user
            )
            return response.data
        })
}

function useRequestPasswordChange(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (passwordUpdate: PersonPasswordUpdate, personId: string) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).patch(
                `${ACCOUNT_BASE_URL}/${personId}/change-password`,
                passwordUpdate
            )
            return response.data
        })
}

function useRequestPasswordReset(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (emailAddress: string) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).patch(
                `${ACCOUNT_BASE_URL}/${emailAddress}/reset-password`
            )
            return response.data
        })
}

function useRequestLoginUser(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (loginFormData: LoginFormCredentialsData) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).post<LoginDetails>(
                '/api/auth',
                loginFormData.user
            )
            return response.data
        })
}

export {
    useFetchCurrentUser,
    useFetchActivationStatus,
    useRequestPasswordSubmit,
    useRequestPasswordChange,
    useRequestPasswordReset,
    useRequestLoginUser,
    useCheckResetPasswordKey,
    useRequestCreateNewPasswordSubmit
}
