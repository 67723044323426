import { RouteParams } from '@app/common'
import withTitle from 'hoc/withTitle'
import React from 'react'
import {
    match as Match,
    Route,
    RouteComponentProps,
    Switch,
    withRouter
} from 'react-router'
import { compose } from 'recompose'
import { useTranslation } from 'react-i18next'
import GroupsContentBrowser from './contentBrowser'
import { GroupsWorksheet } from './worksheet'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'

interface OwnProps {
    match?: Match<RouteParams>
}

type Props = OwnProps & RouteComponentProps

const Groups: React.FC<Props> = ({ match }) => {
    const { t } = useTranslation(['groups'])

    return (
        <>
            <Breadcrumb>{t('groups:title')}</Breadcrumb>
            <GroupsWorksheet match={match}>
                <Switch>
                    <Route
                        exact
                        path={`${match.path}/:id`}
                        render={(routerProps) => (
                            <GroupsContentBrowser
                                groupId={routerProps.match.params.id}
                                {...routerProps}
                            />
                        )}
                    />

                    <Route
                        exact
                        path={match.path}
                        render={(routerProps) => (
                            <GroupsContentBrowser
                                groupId={null}
                                {...routerProps}
                            />
                        )}
                    />
                </Switch>
            </GroupsWorksheet>
        </>
    )
}

export default compose<Props, OwnProps>(withRouter, withTitle('Groups'))(Groups)
