import { ListItemRender, SelectorListItem } from '@app/common'
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down'
import { List, ListItem } from '@material-ui/core'
import classNames from 'classnames'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Button from '../button/Button'
import {
    StyledMatrixPopover,
    useMatrixPopoverStyles
} from './MatrixPopover.styles'

export interface MatrixPopoverProps {
    listItems: Array<ListItemRender | SelectorListItem>
    selectAction: (id: string) => void
    activeListItemId?: string
}

export const MatrixPopover: React.FC<MatrixPopoverProps> = (props) => {
    const classes = useMatrixPopoverStyles()
    const { t } = useTranslation(['matrices'])
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const activeListItem = props.listItems.find(
        (item) => item.id === props.activeListItemId
    )

    const open = Boolean(anchorEl)
    const id = open ? 'matrix-popover' : undefined

    return (
        <div>
            <Button
                startIcon={
                    <HipchatChevronDownIcon
                        label=""
                        size="medium"
                        primaryColor="#000"
                    />
                }
                variant="secondary"
                className={classes.matrixPopoverButton}
                aria-describedby={id}
                onClick={handleClick}
                disabled={props.listItems.length === 0}
            >
                {activeListItem
                    ? activeListItem.title
                    : t('matrices:labels.notMatricesFound')}
            </Button>

            <StyledMatrixPopover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <List>
                    {props.listItems.map((item) => (
                        <ListItem
                            key={item.id}
                            className={classNames(classes.listItem, {
                                active: item.id === props.activeListItemId
                            })}
                            onClick={() => {
                                props.selectAction(item.id)
                                handleClose()
                            }}
                        >
                            <span
                                className={classNames(
                                    'text-ellipsis',
                                    classes.listItemText
                                )}
                            >
                                {item.title}
                            </span>
                        </ListItem>
                    ))}
                </List>
            </StyledMatrixPopover>
        </div>
    )
}

export default MatrixPopover
