const common = {
    labels: {
        emailAddress: 'Email address',
        phoneNumber: 'Phone number',
        member: 'Member',
        member_plural: 'Members',
        password: 'Password',
        close: 'Close',
        done: 'Done',
        leave: 'Leave',
        cancel: 'Cancel',
        change: 'Change',
        convert: 'Convert',
        delete: 'Delete',
        create: 'Create',
        assign: 'Assign',
        add: 'Add',
        revoke: 'Revoke',
        invite: 'Invite',
        remove: 'Remove',
        resend: 'Resend',
        edit: 'Edit',
        link: 'Link',
        copy: 'Copy',
        unlink: 'Unlink',
        unAssign: 'Unassign',
        update: 'Update',
        save: 'Save',
        share: 'Share',
        info: 'Info',
        changePassword: 'Change password',
        logout: 'Sign out',
        goBackToHome: 'Go back to home page',
        rememberMe: 'Remember me',
        readOnly: 'Read-only',
        success: 'Success',
        in: 'in'
    },

    roles: {
        ROLE_USER: 'User',
        ROLE_ADMIN: 'System Administrator',
        ROLE_SALES: 'Sales',
        ROLE_CONTENT_ADMIN: 'Content Admin'
    },

    searchBar: {
        placeholder: 'Search developers',
        title: 'Search Results'
    },

    worksheet: {
        search: {
            templates: 'Search templates',
            people: 'Search people',
            groups: 'Search groups',
            matrices: 'Search matrices',
            terms: 'Search terms'
        },
        error: {
            templates: 'An error occurred while loading templates.',
            people: 'An error occurred while loading people.',
            groups: 'An error occurred while loading groups.',
            matrices: 'An error occurred while loading matrices.'
        },
        emptyState: {
            templates: 'No templates were added yet.',
            people: 'No people were invited yet.',
            groups: 'No groups were created yet.',
            matrices: 'No matrices were created yet.',
            noResult: 'No search results found.',
            terms: 'No search terms were created yet.'
        }
    },

    popover: {
        description: {
            title: 'Description',
            emptyState: 'No description is available for this item.',
            seeMore: 'See more'
        },
        owner: {
            search: 'Filter people',
            emptyState: {
                people:
                    'Everyone is already assigned to this skill set as coach.',
                noResult: 'No search results found.'
            }
        },
        confirmation: {
            title: 'Confirm',
            content:
                'You have some changes that have not been saved yet. You are going to lose your changes if you proceed.',
            footerText: 'Are you sure you want to leave this page?'
        }
    },

    card: {
        welcome: {
            title: 'Welcome, {{username}}!',
            description:
                'Create a secure password containing at least 10 characters including 2 numbers and 1 special character to start using our application.',
            actionLabel: 'Create Password'
        },
        maintenance: {
            title: 'Service temporarily unavailable',
            description:
                'We are experiencing issues with our background services, make sure to check back in a few minutes or you can try to refresh this page manually.'
        },
        tokenError: {
            title: 'Invalid registration token',
            description:
                'We could not find available registrations with this token. Please contact the administrator who sent you this invite for further information.',
            actionLabel: 'Go back to sign in'
        },
        createNewPasswordError: {
            title: 'Invalid password reset token',
            description:
                'We could not find available password reset request with this token. Please try to reset your password again.',
            actionLabel: 'Go back to sign in'
        },
        createNewPasswordSuccess: {
            title: 'Password has been reset successfully',
            description: 'You can sign in with the new password.',
            actionLabel: 'Go back to sign in'
        },
        resetPassword: {
            title: 'Check your mailbox',
            description:
                'A password reset email was sent to your email address, follow the instructions to get your new password.',
            action: {
                primary: 'Reset Password',
                secondary: 'Go back to sign in'
            }
        },
        createNewPassword: {
            description:
                'Note: You can always change your password after you log in',
            action: 'Save new password'
        },
        createPassword: {
            passwordInfo:
                'Note: You can always change your password after you log in',
            passwordError:
                'Must contain at least 10 characters including 2 numbers and 1 special character',
            privacyPolicy:
                'I have read and agree to the <0>privacy policy</0>.',
            privacyPolicyNotAccepted:
                'You must agree to our privacy policy to activate your account.',
            actionLabel: 'Activate and sign in'
        }
    },

    accessForbidden: {
        title: '403',
        subtitle:
            'This is not the page you were looking for. We appreciate your efforts but this content is locked.'
    },

    notFound: {
        title: '404',
        subtitle:
            'We love exploration and encourage you too to do so, but you hit a wall this time.'
    },

    expectations: {
        title: 'Expectations',
        level1: 'Level 1',
        level2: 'Level 2',
        level3: 'Level 3',
        level4: 'Level 4',
        level5: 'Level 5'
    }
}

export default common
