import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'
import CircleIcon from '@atlaskit/icon/glyph/media-services/preselected'
import React from 'react'

interface SelectableListItemProps {
    title: string
    subtitle: string
    onClick: () => void
    selected?: boolean
    secondaryTitle?: string
    withSeparator?: boolean
    labels?: Array<string>
}

export const SelectableListItem: React.FC<SelectableListItemProps> = (
    props
) => (
    <div className="listItem__container">
        <div className="listItem" onClick={props.onClick}>
            <div className="listItem__text">
                <span className="listItem__title listItem__title--maxWidth text-ellipsis text--normal-wrap">
                    {props.title}
                </span>

                {props.secondaryTitle && (
                    <span className="listItem__title text-ellipsis">
                        {props.secondaryTitle}
                    </span>
                )}

                {props.subtitle && (
                    <span className="listItem__subtitle text-ellipsis">
                        {props.subtitle}
                    </span>
                )}

                {props.labels && (
                    <div className="listItem__labels">
                        {props.labels.map((label) => (
                            <span
                                key={label}
                                className="listItem__labels__label"
                            >
                                {label}
                            </span>
                        ))}
                    </div>
                )}
            </div>

            {!props.selected && (
                <CircleIcon label="" size="large" primaryColor="#eceff1" />
            )}

            {props.selected && (
                <CheckCircleIcon label="" size="large" primaryColor="#4ecca3" />
            )}
        </div>

        {props.withSeparator && <div className="listItem__separator" />}
    </div>
)

export default SelectableListItem
