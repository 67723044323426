import { UnassignDialogType } from '@app/searchTerm'
import { SkillDetails, SkillSetDetails } from '@app/template'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import {
    requestSingleSearchTermRevalidation,
    useRequestSkillSetUnAssign,
    useRequestSkillUnAssign
} from '../../../helpers/searchTermHelpers'
import { useGlobal } from '../../../hooks/useGlobal'
import Button from '../../button/base/Button'
import Dialog from '../base/Dialog'

export interface UnassignProps {
    searchTermId: string
    skillSet?: SkillSetDetails
    skill?: SkillDetails
    templateId: string
    type: UnassignDialogType
}

const UnassignDialog = (props: UnassignProps) => {
    const { t } = useTranslation(['common', 'searchTerms', 'error'])
    const isSkillSet = UnassignDialogType.SkillSet === props.type
    const {
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const initialValues = {}

    const requestSkillSetUnAssign = useRequestSkillSetUnAssign()
    const requestSkillUnAssign = useRequestSkillUnAssign()

    const onSubmit = async (_, formikActions: FormikActions<unknown>) => {
        try {
            if (isSkillSet) {
                await requestSkillSetUnAssign({
                    searchTermId: props.searchTermId,
                    skillSetId: props.skillSet.id,
                    skillId: '',
                    templateId: props.templateId
                })
            } else {
                await requestSkillUnAssign({
                    searchTermId: props.searchTermId,
                    skillSetId: props.skillSet.id,
                    skillId: props.skill.id,
                    templateId: props.templateId
                })
            }

            await requestSingleSearchTermRevalidation(props.searchTermId)

            closeActiveOverlay()
        } catch (payload) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            createNotification({
                type: t('error:labels.error'),
                description: isSkillSet
                    ? t('error:response.unAssignSkillSetUnknown')
                    : t('error:response.unAssignSkillUnknown')
            })
        }
    }

    return (
        <Dialog
            title={
                isSkillSet
                    ? t('searchTerms:unAssignSkillSet.title')
                    : t('searchTerms:unAssignSkill.title')
            }
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon={false}
        >
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(formikProps) => (
                    <Form className="dialog__form dialog__form--confirm">
                        <div className="dialog__info">
                            <Trans
                                i18nKey={
                                    isSkillSet
                                        ? 'searchTerms:unAssignSkillSet.question'
                                        : 'searchTerms:unAssignSkill.question'
                                }
                            >
                                <strong>
                                    {isSkillSet
                                        ? { skillSetName: props.skillSet.name }
                                        : { skillName: props.skill.name }}
                                </strong>
                            </Trans>
                        </div>
                        <div className="dialog__actions dialog__actions--noShadow">
                            <Button
                                title={t('common:labels.cancel')}
                                onClick={closeActiveOverlay}
                                formButton
                            />

                            <Button
                                title={t('common:labels.unAssign')}
                                type="submit"
                                loading={formikProps.isSubmitting}
                                destructive
                                primary
                                formButton
                                autoFocus
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default UnassignDialog
