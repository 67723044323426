import { LevelSelectorButtonSize } from '@app/common'
import classNames from 'classnames'
import LevelSelectorButton from 'components/input/levelSelectorButton/LevelSelectorButton'
import React from 'react'

interface LevelSelectorProps {
    onClick?: (value: number) => void
    value: number
    label?: string
    name?: string
    disabled?: boolean
    horizontal?: boolean
    isLoading?: boolean
}

const levelSelectorButtons: Array<{
    value: number
    size: LevelSelectorButtonSize
}> = [
    { value: 1, size: 'xs' },
    { value: 2, size: 'sm' },
    { value: 3, size: 'md' },
    { value: 4, size: 'lg' },
    { value: 5, size: 'xl' }
]

const LevelSelector = (props: LevelSelectorProps) => {
    const [oldRating, setOldRating] = React.useState<number>(null)
    const [newRating, setNewRating] = React.useState<number>(null)

    const onClick = (oldValue: number, newValue: number) => {
        setOldRating(oldValue)
        setNewRating(newValue)

        if (props.onClick) {
            props.onClick(newValue)
        }
    }

    return (
        <div
            className={classNames('radioButtonGroup', {
                'radioButtonGroup--horizontal': props.horizontal
            })}
        >
            {props.label && (
                <label className="radioButtonGroup__label" htmlFor={props.name}>
                    {props.label}
                </label>
            )}

            <div className="radioButtonGroup__container">
                {levelSelectorButtons.map((radioButton) => (
                    <LevelSelectorButton
                        onClick={() => onClick(props.value, radioButton.value)}
                        size={radioButton.size}
                        key={radioButton.value}
                        active={props.value >= radioButton.value}
                        disabled={props.disabled}
                        loading={
                            props.isLoading &&
                            ((radioButton.value > oldRating &&
                                radioButton.value <= newRating) ||
                                (radioButton.value > newRating &&
                                    radioButton.value <= oldRating))
                        }
                    />
                ))}
            </div>
        </div>
    )
}

export default LevelSelector
