import AddCircleIcon from '@atlaskit/icon/glyph/add-circle'
import classNames from 'classnames'
import React from 'react'

export interface LabeledButtonProps {
    onClick?: () => void
    icon?: React.ReactNode
    inactive?: boolean
    title: string
}

const LabeledButton = (props: LabeledButtonProps) => {
    const content = (
        <>
            <span className="labeledButton__icon">
                {props.icon || <AddCircleIcon label="" />}
            </span>

            <span className="labeledButton__title">{props.title}</span>
        </>
    )

    return props.inactive ? (
        <div className={classNames('labeledButton inactive')}>{content}</div>
    ) : (
        <button
            className={classNames('labeledButton')}
            disabled={props.inactive}
            onClick={props.onClick}
            type="button"
        >
            {content}
        </button>
    )
}

export default LabeledButton
