import { HistoryType } from '@app/common'
import Autocomplete from 'components/autocomplete/Autocomplete'
import { useRequestFilteredSearchBarList } from 'helpers/searchBarHelpers'
import React, { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { debounce } from 'throttle-debounce'

interface SearchBarProps {
    history: HistoryType
}

const SearchBar = (props: SearchBarProps) => {
    const requestFilteredSearchBarList = useRequestFilteredSearchBarList()

    const { history } = props

    // todo [COIN-597]: break down this state into multiple smaller states
    const [state, setState] = React.useState({
        loading: false,
        options: [],
        errorMessage: null,
        value: null
    })

    const debounceRef = useRef<any>()
    const { t } = useTranslation(['error', 'search'])

    const onInputChange = (event, newInputValue) => {
        setState({ ...state, errorMessage: null })
        if (newInputValue && newInputValue.length > 3) {
            if (debounceRef.current) {
                debounceRef.current(newInputValue)
            }
        } else {
            setState({ ...state, options: [] })
        }
    }

    useEffect(() => {
        const loadData = async (input: string) => {
            setState({ ...state, loading: true })
            const { data, error } = await requestFilteredSearchBarList(input)
            if (error) {
                setState({
                    ...state,
                    errorMessage: t('error:response.filteredSearchListUnknown')
                })
            } else {
                setState({ ...state, options: data, loading: false })
            }
        }

        debounceRef.current = debounce(500, loadData)

        return () => {
            debounceRef.current = undefined
        }
    }, [debounceRef, requestFilteredSearchBarList, state, t])

    return (
        <Autocomplete
            id="searchBar-input"
            options={state.options}
            loading={state.loading}
            error={state.errorMessage}
            onInputChange={onInputChange}
            value={state.value}
            onChange={(_event, newValue) => {
                if (newValue && newValue.id && newValue.type) {
                    history.push(
                        `/search/${encodeURIComponent(
                            newValue.id
                        )}?name=${encodeURIComponent(
                            newValue.name
                        )}&type=${encodeURIComponent(newValue.type)}`
                    )
                }
            }}
            aria-label={t('search:labels.searchBar')}
        />
    )
}

export default SearchBar
