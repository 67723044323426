import { RoleSelectInputValue } from '@app/common'
import { Roles } from '@app/person'
import { TFunction } from 'i18next'
import {
    ROLE_ADMIN,
    ROLE_CONTENT_ADMIN,
    ROLE_SALES,
    ROLE_SUPERVISOR,
    ROLE_USER
} from '../constants'

type RoleFunction = (roles: Array<Roles | RoleSelectInputValue>) => boolean

/**
 * Determines if the given roles contain the target role
 * @param targetRole the target role to match
 * @returns True if the provided role matches the target roles
 */
const isRole: (targetRole: Roles) => RoleFunction = (targetRole) => (roles) =>
    roles &&
    Boolean(
        roles.find((role) => {
            if (typeof role === 'string') {
                return role === targetRole
            }
            return role.value === targetRole
        })
    )

// helper functions for role checking
export const isUser = isRole(ROLE_USER)
export const isAdmin = isRole(ROLE_ADMIN)
export const isSupervisor = isRole(ROLE_SUPERVISOR)
export const isSales = isRole(ROLE_SALES)
export const isContentAdmin = isRole(ROLE_CONTENT_ADMIN)

/**
 * Determines whether provided target role matches any of the provided role checker functions.
 *
 * @param roleFns - Role checker functions
 * @param targetRole - Target role to match against provided role checker functions
 */
export const isMatchingAnyRole = (...roleFns: Array<RoleFunction>) => (
    roles: Array<Roles>
) =>
    roleFns.reduce(
        (matches, roleFn) => (!matches ? roleFn(roles) : true),
        false
    )

/**
 * Provides the available user role values to a select component.
 *
 * @param t - i18n TFunction
 */
export const getRoleOptions = (t: TFunction) =>
    <Array<RoleSelectInputValue>>[
        { label: t('common:roles.ROLE_USER'), value: ROLE_USER },
        { label: t('common:roles.ROLE_ADMIN'), value: ROLE_ADMIN },
        { label: t('common:roles.ROLE_SALES'), value: ROLE_SALES },
        {
            label: t('common:roles.ROLE_CONTENT_ADMIN'),
            value: ROLE_CONTENT_ADMIN
        }
    ]
