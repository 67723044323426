import { DeleteSearchTermDialogFormData } from '@app/form'
import { SearchTermDetails } from '@app/searchTerm'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import FormikHelpers from 'helpers/formikHelpers'
import {
    requestAllSearchTermRevalidation,
    useRequestSearchTermDelete
} from 'helpers/searchTermHelpers'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useGlobal } from 'hooks/useGlobal'
import { Button } from 'components/button/base/Button'
import LabeledInputField from 'components/input/labeledInputField/LabeledInputField'
import { Dialog } from 'components/dialog/base/Dialog'

export interface DeleteSearchTermProps {
    redirectOnMatch: (redirectUrl: string) => void
    searchTerm: SearchTermDetails
}

export const DeleteSearchTermDialog: React.FC<DeleteSearchTermProps> = ({
    redirectOnMatch,
    searchTerm
}) => {
    const { t } = useTranslation(['common', 'error', 'searchTerms'])

    const {
        formDirty,
        toggleFormToDirty,
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const requestSearchTermDelete = useRequestSearchTermDelete()

    const initialValues: DeleteSearchTermDialogFormData = {
        name: ''
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .oneOf(
                [searchTerm.name],
                t('error:validation.searchTermNameShouldMatch', {
                    searchTermName: searchTerm.name
                })
            )
            .required(t('error:validation.fieldRequired'))
    })

    const onSubmit = async (
        values: DeleteSearchTermDialogFormData,
        formikActions: FormikActions<DeleteSearchTermDialogFormData>
    ) => {
        const { status } = await requestSearchTermDelete(searchTerm.id)

        if (status === 'resolved') {
            await requestAllSearchTermRevalidation()
            redirectOnMatch(searchTerm.id)
            closeActiveOverlay()
        } else if (status === 'rejected') {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            createNotification({
                type: t('error:labels.error'),
                description: t('error:response.deleteSearchTermUnknown', {
                    searchTermName: searchTerm.name
                })
            })
        }
    }

    return (
        <Dialog
            title={t('searchTerms:deleteSearchTerm.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon={false}
        >
            <div className="dialog__info">
                <Trans i18nKey="searchTerms:deleteSearchTerm.question">
                    <strong>
                        {{
                            searchTermName: searchTerm.name
                        }}
                    </strong>
                </Trans>
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnChange
                render={(formikProps) => {
                    const nameError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'name'
                    )

                    return (
                        <Form className="dialog__form dialog__form--confirm margin-top-lg">
                            <LabeledInputField
                                name="name"
                                label={t('searchTerms:deleteSearchTerm.action')}
                                onChange={FormikHelpers.setDirtyForm(
                                    formikProps,
                                    'name',
                                    formDirty,
                                    toggleFormToDirty
                                )}
                                placeholder={searchTerm.name}
                                value={formikProps.values.name}
                                message={nameError}
                                hasError={!!nameError}
                                tabIndex={0}
                                autoFocus
                            />

                            <div className="dialog__actions dialog__actions--noShadow">
                                <Button
                                    title={t('common:labels.cancel')}
                                    onClick={closeActiveOverlay}
                                    formButton
                                />

                                <Button
                                    title={t('common:labels.delete')}
                                    type="submit"
                                    loading={formikProps.isSubmitting}
                                    destructive
                                    primary
                                    formButton
                                    autoFocus
                                />
                            </div>
                        </Form>
                    )
                }}
            />
        </Dialog>
    )
}

export default DeleteSearchTermDialog
