import { FormikProps } from 'formik'

class FormikHelpers {
    /**
     * Gets error message for specific field of specified type.
     *
     * @param props - Formik properties decorated with form configuration type
     * @param key - Some key of form configuration
     * @returns Error message if there is any
     */
    static getErrorMessage = <T>(props: FormikProps<T>, key: keyof T): string =>
        props.touched[key] && props.errors[key]
            ? props.errors[key].toString()
            : ''

    /**
     * Sets the dirty form flag according to the params and changes the specified field value.
     *
     * @param props - Formik properties decorated with form configuration type
     * @param key - Some key of form configuration
     * @param isFormDirty - Is the form already set to dirty
     * @param setFormDirty - A function which is setting the dirty form flag to true
     * @returns Changes the given field value
     */
    static setDirtyForm = <T>(
        props: FormikProps<T>,
        key: keyof T,
        isFormDirty: boolean,
        setFormDirty: () => void
    ) => (value: any) => {
        if (!value && !props.values[key]) {
            return
        }

        if (!props.dirty && !isFormDirty) {
            setFormDirty()
        }

        if (value && value.target) {
            props.handleChange(key)(value)
        } else {
            props.setFieldValue(key as string, value)
        }
    }
}

export default FormikHelpers
