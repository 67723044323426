import { RouteParams } from '@app/common'
import {
    useFetchActivationStatus,
    useRequestPasswordSubmit
} from 'helpers/accountHelpers'
import withCondition from 'hoc/withCondition'
import withMaybe from 'hoc/withMaybe'
import withTitle from 'hoc/withTitle'
import { useAuthentication } from 'hooks/useAuthentication'
import React, { useState } from 'react'
import { match, RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'
import PasswordCreatorCard, {
    PasswordCreatorCardProps
} from './PasswordCreatorCard'
import TokenErrorCard from './TokenErrorCard'
import WelcomeCard, { WelcomeCardProps } from './WelcomeCard'

export interface OwnProps {
    match: match<RouteParams>
}

export type Props = OwnProps & RouteComponentProps

const renderPasswordCreatorCard = (props: PasswordCreatorCardProps) => (
    <PasswordCreatorCard {...props} />
)

const renderTokenErrorCard = () => <TokenErrorCard />

const renderWelcomeCard = (props: WelcomeCardProps) => (
    <WelcomeCard {...props} />
)

const Activate = (props: Props) => {
    const { activationKey } = props.match.params

    const [creatingPassword, setCreatingPassword] = useState(false)
    const [switchingCard, setSwitchingCard] = useState(false)
    const [hasPasswordError, setHasPasswordError] = useState(false)

    const { activationStatus, error, status } = useFetchActivationStatus(
        activationKey
    )

    const tokenError = !!error || activationStatus?.status === 'COMPLETED'

    const { storeToken } = useAuthentication()

    const requestPasswordSubmit = useRequestPasswordSubmit()

    const handleSubmitPassword = async (
        password: string,
        privacyPolicyAccepted: boolean
    ) => {
        setHasPasswordError(false)

        const loginDetails = await requestPasswordSubmit({
            activationKey,
            user: {
                password,
                privacyPolicyAccepted
            }
        })

        if (loginDetails.data && loginDetails.data.token) {
            storeToken(loginDetails.data.token)
        }

        if (!loginDetails.data && loginDetails.error) {
            setHasPasswordError(true)
        }
    }

    const switchCard = () => {
        setSwitchingCard(true)

        setTimeout(() => {
            setCreatingPassword(true)
            setTimeout(() => setSwitchingCard(false), 500)
        }, 500)
    }

    const Card = withCondition(
        creatingPassword,
        renderPasswordCreatorCard,
        withMaybe(status !== 'pending')(
            withCondition(tokenError, renderTokenErrorCard, renderWelcomeCard)
        )
    )

    return (
        <div className="colorful-background">
            <Card
                className={switchingCard ? 'fade-out' : ''}
                shouldFadeIn={switchingCard}
                handleSubmitPassword={handleSubmitPassword}
                switchCard={switchCard}
                username={activationStatus?.name ?? ''}
                hasPasswordError={hasPasswordError}
            />
        </div>
    )
}

export default compose<Props, OwnProps>(
    withTitle('Activate'),
    withRouter
)(Activate)
