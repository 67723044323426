import { ListItemRender } from '@app/common'
import { WorksheetType } from '@app/worksheet'
import React, { ChangeEvent } from 'react'
import 'react-placeholder/lib/reactPlaceholder.css'
import { match } from 'react-router'
import WorksheetNavigation from './navigation/WorksheetNavigation'

export interface WorksheetProps {
    type: WorksheetType
    listError: boolean
    listItems: Array<ListItemRender>
    listLoading: boolean
    primaryActionButton: React.ReactNode
    match: match
    children?: React.ReactNode
}

const Worksheet: React.FC<WorksheetProps> = (props) => {
    const [searchKey, setSearchKey] = React.useState('')

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchKey(event.target.value)
    }

    return (
        <div className="worksheet slideFadeBottomToTop" role="main">
            <WorksheetNavigation
                {...props}
                listHasError={props.listError}
                searchKey={searchKey}
                handleSearchChange={handleSearchChange}
            />

            {props.children}
        </div>
    )
}

export default Worksheet
