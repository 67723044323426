import React from 'react'

interface Props {
    className?: string
    children?: React.ReactNode
}

const TooltipContent = React.forwardRef<HTMLDivElement, Props>((props, ref) => (
    <div {...props} ref={ref} />
))

export default TooltipContent
