import { ComponentStatus } from '@app/common'
import { SearchTermDetails } from '@app/searchTerm'
import ShareIcon from '@atlaskit/icon/glyph/share'
import LabeledButton from 'components/button/labeled/LabeledButton'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlaceholder from 'react-placeholder'
import 'react-placeholder/lib/reactPlaceholder.css'
import { isAdmin, isMatchingAnyRole } from '../../../helpers/roleHelpers'
import { useAuthentication } from '../../../hooks/useAuthentication'

export interface SearchHeaderProps {
    searchTerm?: SearchTermDetails
    status: ComponentStatus
    error?: Error
}

export const SearchTermsHeaderContent: React.FC<SearchHeaderProps> = (
    props: SearchHeaderProps
) => {
    const { openDialog } = useGlobal()
    const { searchTerm, status, error } = props

    const { t } = useTranslation(['common', 'searchTerms'])

    const { getCurrentUserRoles } = useAuthentication()
    const currentUserRoles = getCurrentUserRoles()

    const isCurrentUserAnAdmin = React.useMemo(
        () => isMatchingAnyRole(isAdmin)(currentUserRoles),
        [currentUserRoles]
    )

    if (status === 'idle') {
        return null
    }

    if (status === 'pending') {
        return (
            <ReactPlaceholder
                className="placeholder"
                showLoadingAnimation
                type="textRow"
                rows={1}
                ready={status !== 'pending'}
            >
                <></>
            </ReactPlaceholder>
        )
    }

    if (status === 'rejected') {
        throw error
    } else if (status === 'resolved') {
        return (
            <>
                <ReactPlaceholder
                    className="placeholder"
                    showLoadingAnimation
                    type="textRow"
                    rows={1}
                    ready={status === 'resolved'}
                >
                    <div className="worksheetContentBrowser__headerTitle text-ellipsis">
                        {searchTerm && <span>{searchTerm.name}</span>}
                    </div>
                </ReactPlaceholder>
                <ReactPlaceholder
                    className="placeholder"
                    showLoadingAnimation
                    type="text"
                    rows={1}
                    ready={status === 'resolved'}
                >
                    <div className="worksheetContentBrowser__actions">
                        {(isCurrentUserAnAdmin ||
                            searchTerm.type === 'PERSONALIZED') && (
                            <LabeledButton
                                title={t('searchTerms:addSkill.title')}
                                onClick={() =>
                                    openDialog({
                                        type: 'ASSIGN_SKILL',
                                        payload: searchTerm
                                    })
                                }
                            />
                        )}
                        {searchTerm.type === 'PERSONALIZED' && (
                            <LabeledButton
                                icon={<ShareIcon label="" />}
                                title={t('searchTerms:labels.share')}
                                onClick={() =>
                                    openDialog({
                                        type: 'SHARE_SEARCH_TERM',
                                        payload: {
                                            searchTermId: searchTerm.id,
                                            searchTermName: searchTerm.name
                                        }
                                    })
                                }
                            />
                        )}
                    </div>
                </ReactPlaceholder>
            </>
        )
    }

    return (
        <div className="worksheetContentBrowser__headerTitle text-ellipsis">
            {searchTerm && <span>{searchTerm.name}</span>}
        </div>
    )
}

export const SearchTermsHeader: React.FC<SearchHeaderProps> = (
    props: SearchHeaderProps
) => {
    const { searchTerm } = props
    return searchTerm ? (
        <div className="worksheetContentBrowser__header">
            <SearchTermsHeaderContent {...props} />
        </div>
    ) : null
}

export default SearchTermsHeader
