import { SelectableListItem as SelectableListItemConfig } from '@app/common'
import { SelectableFilterListProps } from '@app/dialog'
import { AssignTemplateDialogFormData } from '@app/form'
import classNames from 'classnames'
import SelectableListItem from 'components/listItem/selectable/SelectableListItem'
import { FormikProps } from 'formik'
import withFilterListItems from 'hoc/withFilterListItems'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface ListItemProps extends SelectableFilterListProps {
    handleTemplateListChange: (id: string) => Array<SelectableListItemConfig>
    formikProps: FormikProps<AssignTemplateDialogFormData>
    /**
     * Determines whether separator should be displayed for list items or not.
     *
     * @default true
     */
    withSeparator?: boolean
}

interface EmptyStateProps {
    error: boolean
}

export interface UnassignedTemplateListProps
    extends ListItemProps,
        EmptyStateProps {
    isEmpty: boolean
}

const ListItemContainer: React.FC<ListItemProps> = ({
    listItems,
    withSeparator,
    formikProps,
    handleTemplateListChange
}) => {
    const { t } = useTranslation()

    return listItems.length > 0 ? (
        <>
            {listItems.map((template, index) => (
                <div
                    className="worksheetNavigation__listItem"
                    key={template.id}
                >
                    <SelectableListItem
                        title={template.title}
                        subtitle={template.subtitle}
                        secondaryTitle={template.secondaryTitle}
                        labels={template.labels}
                        selected={template.selected}
                        withSeparator={
                            withSeparator
                                ? listItems.length - 1 !== index
                                : false
                        }
                        onClick={() =>
                            formikProps.setFieldValue(
                                'templates',
                                handleTemplateListChange(template.id)
                            )
                        }
                    />
                </div>
            ))}
        </>
    ) : (
        <div className="worksheetNavigation__emptyState">
            {t('common:worksheet.emptyState.noResult')}
        </div>
    )
}

const EmptyState: React.FC<EmptyStateProps> = ({ error }) => {
    const { t } = useTranslation()

    return (
        <div
            className={classNames('dialog__listContainer--emptyState', {
                error
            })}
        >
            {error
                ? t('error:response.unassignedTemplateLoadUnknown')
                : t('matrices:assignTemplate.labels.emptyState')}
        </div>
    )
}

export const UnassignedTemplateList: React.FC<UnassignedTemplateListProps> = ({
    isEmpty,
    error,
    ...props
}) =>
    !isEmpty ? (
        withFilterListItems<ListItemProps>(ListItemContainer)(props)
    ) : (
        <EmptyState error={error} {...props} />
    )

export default UnassignedTemplateList
