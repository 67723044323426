import { DialogConfig } from '@app/dialog'
import { SkillDetails, TemplateDetails } from '@app/template'
import ContentBrowserListItem from 'components/contentBrowserListItem/ContentBrowserListItem'
import { skillWithParentIdAndTemplate } from 'helpers/skillHelpers'
import React from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import ListItemActionMenu from './ListItemActionMenu'

interface Props {
    openDialog: (config: DialogConfig) => void
    skill: SkillDetails
    ownerUserIds: Array<string>
    disableAdditionalContent?: boolean
    template: TemplateDetails
}

const TemplateSkillListItem = ({
    openDialog,
    skill,
    ownerUserIds,
    disableAdditionalContent,
    template
}: Props) => {
    const modifyAction: (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void = (event) => {
        event.stopPropagation()
        openDialog({
            type: 'EDIT_SKILL',
            payload: skillWithParentIdAndTemplate(template, skill.id)
        })
    }

    const copyAction: (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void = (event) => {
        event.stopPropagation()
        openDialog({
            type: 'COPY_SKILL',
            payload: skillWithParentIdAndTemplate(template, skill.id)
        })
    }

    const convertAction: (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void = (event) => {
        event.stopPropagation()
        openDialog({
            type: 'CONVERT_SKILL',
            payload: {
                currentSkill: skill
            }
        })
    }

    const deleteAction: (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void = (event) => {
        event.stopPropagation()
        openDialog({
            type: 'DELETE_SKILL',
            payload: {
                currentSkill: skill
            }
        })
    }

    const DragHandle = SortableHandle(() => (
        <div className="grabIcon" title="Drag" />
    ))

    const listItemActionMenu = (
        <>
            <ListItemActionMenu
                deleteAction={deleteAction}
                modifyAction={modifyAction}
                convertAction={convertAction}
                copyAction={copyAction}
            />
            <DragHandle />
        </>
    )

    return (
        <ContentBrowserListItem
            data={skill}
            additionalContent={!disableAdditionalContent && listItemActionMenu}
            showAdditionalContentOnlyOnHover
            ownerUserIds={ownerUserIds}
            small
            showTriggerButtonLabel
        />
    )
}

export default TemplateSkillListItem
