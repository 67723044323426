import {
    createTheme,
    darken,
    hslToRgb,
    lighten,
    responsiveFontSizes
} from '@material-ui/core'

const whiteSmoke = hslToRgb('hsl(0, 0, 97)')
const denim = hslToRgb('hsl(214, 84, 50)')
const nero = hslToRgb('hsl(0, 0, 10)')

const theme = createTheme({
    palette: {
        primary: { main: denim, light: hslToRgb('hsl(214, 84, 50, 0.65)') },
        secondary: { main: '#00e677' },
        action: {
            hover: darken(whiteSmoke, 0.04),
            active: lighten(whiteSmoke, 0.02),
            disabled: darken(whiteSmoke, 0.1)
        },
        text: {
            primary: nero,
            secondary: lighten(nero, 0.12),
            hint: lighten(nero, 0.3),
            disabled: lighten(nero, 0.55)
        },
        common: { white: '#fff' },
        background: {
            default: hslToRgb('hsl(204, 15, 94)'),
            paper: 'white'
        },
        divider: '#eceff1'
    },
    typography: {
        fontFamily: 'Source Sans Pro, Helvetica, sans-serif'
    },
    overrides: {
        MuiTouchRipple: {
            child: {
                backgroundColor: 'rgba(0, 0, 0, 0.35)'
            }
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: '#1a1a1a',
                fontSize: 15,
                padding: 15,
                borderRadius: 8
            }
        },
        MuiBackdrop: {
            root: {
                backgroundColor: 'rgba(54, 65, 86, 0.65)'
            }
        },
        MuiPaper: {
            root: {
                color: '#1a1a1a'
            }
        },
        MuiFormLabel: {
            root: {
                color: '#666666',
                '&$focused': {
                    color: '#666666'
                }
            }
        }
    }
})

export const pxToRem = (value: number) => `${value / 16}rem`

export default responsiveFontSizes(theme)
