import { EditTemplateDialogFormData } from '@app/form'
import { TemplateUpdate } from '@app/template'
import Dialog from 'components/dialog/base/Dialog'
import TemplateForm from 'components/templateForm/TemplateForm'
import { FormikHelpers as FormikActions } from 'formik'
import {
    requestAllLabelsRevalidation,
    requestAllTemplatesRevalidation,
    requestSingleTemplateRevalidation,
    useFetchAllLabels,
    useRequestTemplateUpdate
} from 'helpers/templateHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    template: TemplateUpdate
}

const EditTemplateDialog = (props: Props) => {
    const { t } = useTranslation(['common', 'groups', 'error', 'templates'])

    const { template } = props

    const {
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const [labelOptions, setLabelOptions] = React.useState([])

    const { labelList } = useFetchAllLabels()

    const initialValues: EditTemplateDialogFormData = template
        ? {
              id: template.id,
              name: template.name,
              labels: template.labels
                  ? template.labels
                        .filter((label) => !label.linkedTemplateLabel)
                        .map((label) => ({
                            label: label.name,
                            value: label.id
                        }))
                  : []
          }
        : {
              id: '',
              name: '',
              labels: [labelOptions[0]]
          }

    useEffect(() => {
        setLabelOptions(
            (labelList ?? []).map((label) => ({
                label: label.name,
                value: label.id
            }))
        )
    }, [labelList])

    const getLinkedTemplateLabel = (labelId) => {
        const label = (labelList ?? []).find(
            (labelItem) => labelItem.id === labelId
        )

        return label ? label.linkedTemplateLabel : false
    }

    const requestTemplateUpdate = useRequestTemplateUpdate()
    const onSubmit = async (
        values: EditTemplateDialogFormData,
        formikActions: FormikActions<EditTemplateDialogFormData>
    ) => {
        try {
            const response = await requestTemplateUpdate({
                id: values.id,
                name: values.name,
                labels: values.labels.map((item) => ({
                    id: item.value === item.label ? null : item.value,
                    name: item.label,
                    linkedTemplateLabel: getLinkedTemplateLabel(item.value)
                }))
            })

            if (response.data) {
                await requestSingleTemplateRevalidation(response.data.id)
            }

            await requestAllTemplatesRevalidation()
            await requestAllLabelsRevalidation()
            closeActiveOverlay()
        } catch (payload) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            const { message } = payload.error.data

            switch (message) {
                case 'name.already.exists':
                    createNotification({
                        type: t('error:labels.error'),
                        description: t('error:response.duplicateTemplateName')
                    })
                    break
                case 'template.not.found':
                    createNotification({
                        type: t('error:labels.error'),
                        description: t('error:response.templateUnavailable')
                    })
                    break
                default:
                    createNotification({
                        type: t('error:labels.error'),
                        description: t('error:response.editTemplateUnknown', {
                            templateName: props.template.name
                        })
                    })
            }
        }
    }

    return (
        <Dialog
            title={t('templates:editTemplate.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
        >
            <TemplateForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                labelOptions={labelOptions}
                setLabelOptions={setLabelOptions}
            />
        </Dialog>
    )
}

export default EditTemplateDialog
