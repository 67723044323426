import classNames from 'classnames'
import React, { ChangeEvent } from 'react'

interface SearchBarProps extends React.HTMLProps<HTMLInputElement> {
    handleChange: (event: ChangeEvent<HTMLInputElement>) => void
    placeholder: string
    searchKey: string
    isDialog?: boolean
    isPopover?: boolean
    isEmpty?: boolean
    className?: string
    padding?: 'vertical' | 'horizontal' | 'both' | 'none'
}

const ListFilterBar = (props: SearchBarProps) => (
    <div
        className={classNames(
            'listFilter',
            props.className,
            {
                searchBar__dialogVariant: props.isDialog,
                searchBar__peopleFilter: props.isPopover,
                searchBar__isEmpty: props.isEmpty
            },
            props.padding ? `listFilter--padding-${props.padding}` : ''
        )}
    >
        <input
            type="text"
            className={classNames('listFilter__field', {
                empty: props.searchKey === ''
            })}
            placeholder={props.placeholder}
            onChange={props.handleChange}
            value={props.searchKey}
        />
    </div>
)

export default ListFilterBar
