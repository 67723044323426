import { CreateNewPasswordFormData } from '@app/form'
import { FormikHelpers as FormikActions } from 'formik/dist/types'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core'
import FormikHelpers from '../../helpers/formikHelpers'
import SecureInputField from '../../components/input/secureInputField/SecureInputField'
import Button from '../../components/button/base/Button'

export interface CreateNewPasswordCardProps {
    handleSubmitForm: (
        values: CreateNewPasswordFormData,
        formikActions: FormikActions<CreateNewPasswordFormData>
    ) => void
    switchingCard: boolean
}

const useCreateNewPasswordCardStyles = makeStyles({
    fadeOut: {}
})

export const CreateNewPasswordCard: React.FC<CreateNewPasswordCardProps> = ({
    handleSubmitForm,
    switchingCard
}) => {
    const { t } = useTranslation(['common', 'people'])

    const classes = useCreateNewPasswordCardStyles()

    const initialValues: CreateNewPasswordFormData = {
        newPassword: ''
    }

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string()
            .nullable()
            .required(t('error:validation.fieldRequired'))
            .min(10, t('error:response.invalidPassword'))
            .matches(
                /(?=(.*[0-9]){2,})(?=.*[ !"#$%&'()*+,-./:;<=>?@\\[\]\\^_`{|}~]).*$/,
                t('error:response.invalidPassword')
            )
    })

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmitForm}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationSchema}
        >
            {(formikProps) => {
                const newPasswordError = FormikHelpers.getErrorMessage(
                    formikProps,
                    'newPassword'
                )

                return (
                    <Form
                        className={clsx([
                            'resetPasswordCard__form',
                            'card small',
                            switchingCard && classes.fadeOut
                        ])}
                    >
                        <div className="card__logo" />

                        <div className="card__container">
                            <SecureInputField
                                label={t('people:labels.newPassword')}
                                name="newPassword"
                                type="password"
                                onChange={formikProps.handleChange(
                                    'newPassword'
                                )}
                                value={formikProps.values.newPassword}
                                message={
                                    newPasswordError ||
                                    t(
                                        'common:card.createNewPassword.description'
                                    )
                                }
                                hasError={!!newPasswordError}
                                key="newPasswordField"
                                autoFocus
                            />
                        </div>

                        <div className="card__footer">
                            <Button
                                className="normal"
                                loading={formikProps.isSubmitting}
                                primary
                                title={t(
                                    'common:card.createNewPassword.action'
                                )}
                                triggerEnter
                                type="submit"
                            />
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}
