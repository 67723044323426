import { ListItemRender } from '@app/common'
import { WorksheetNavigationBodyProps } from '@app/worksheet'
import ListItem from 'components/listItem/base/ListItem'
import withFilterListItems from 'hoc/withFilterListItems'
import React from 'react'
import { match, Redirect, Switch } from 'react-router'
import { NavLink } from 'react-router-dom'
import EmptyStateNavigationItem from './EmptyStateNavigationItem'

interface NavigationItemProps {
    match: match
    listItems: Array<ListItemRender>
}

const renderNavigationItems = (props: NavigationItemProps) =>
    props.listItems.length > 0 ? (
        <>
            {props.listItems.map((item, index) => {
                if (item.isHeader && item.key) {
                    return (
                        <div className="sectionHeader" key={item.key}>
                            <span className="listItem__title text-ellipsis">
                                {item.title}
                            </span>
                        </div>
                    )
                }

                return (
                    <NavLink
                        key={item.key ? item.key : item.id}
                        to={`${props.match.url}/${item.id}`}
                        className="worksheetNavigation__listItem"
                        role="listitem"
                    >
                        <ListItem
                            listItem={item}
                            withSeparator={props.listItems.length - 1 !== index}
                        />
                    </NavLink>
                )
            })}
            <Switch>
                <Redirect
                    exact
                    from="/matrices"
                    to={`${props.match.url}/${props.listItems[0].id}`}
                />
                <Redirect
                    exact
                    from="/templates"
                    to={`${props.match.url}/${props.listItems[0].id}`}
                />
                <Redirect
                    exact
                    from="/people"
                    to={`${props.match.url}/${
                        props.listItems[0].isHeader
                            ? props.listItems[1].id
                            : props.listItems[0].id
                    }`}
                />
                <Redirect
                    exact
                    from="/groups"
                    to={`${props.match.url}/${props.listItems[0].id}`}
                />
                <Redirect
                    exact
                    from="/search-terms"
                    to={`${props.match.url}/${props.listItems[0].id}`}
                />
            </Switch>
        </>
    ) : (
        <EmptyStateNavigationItem hasError={false} type="noResult" />
    )

const NavigationItems = withFilterListItems<WorksheetNavigationBodyProps>(
    renderNavigationItems
)

const WorksheetNavigationBody = (props: WorksheetNavigationBodyProps) => (
    <div className="worksheetNavigation__body">
        <div className="worksheetNavigation__scrollArea">
            <div className="worksheetNavigation__innerContent modern-scrollbar">
                <NavigationItems {...props} />
            </div>
        </div>
    </div>
)

export default WorksheetNavigationBody
