import {
    Notification as NotificationObject,
    NotificationType
} from '@app/common'
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import WarningIcon from '@atlaskit/icon/glyph/warning'
import React from 'react'
import FocusLock from 'react-focus-lock'
import { CSSTransitionGroup } from 'react-transition-group'

const typeClass: { [key in NotificationType]: React.ReactFragment } = {
    Error: <ErrorIcon label="" size="xlarge" primaryColor="#eb5757" />,
    Warning: <WarningIcon label="" size="large" primaryColor="#fcd835" />,
    Success: <CheckCircleIcon label="" size="large" primaryColor="#4ecca3" />
}

interface NotificationProps {
    notification: NotificationObject
    destroyNotification: (id: string) => void
}

const Notification = (props: NotificationProps) => {
    const { notification, destroyNotification } = props
    const { id, type, description, timeout } = notification
    if (timeout) setTimeout(() => destroyNotification(id), timeout)

    return (
        <FocusLock>
            <CSSTransitionGroup
                transitionName="ascend"
                transitionAppear
                transitionAppearTimeout={300}
                transitionEnter={false}
                transitionLeave={false}
                className="notification-transition-group"
            >
                <div className="notification" tabIndex={-1}>
                    <div className="notification__icon">{typeClass[type]}</div>

                    <div className="notification__description">
                        {description}
                    </div>

                    <div
                        className="notification__close"
                        onClick={() => destroyNotification(id)}
                    >
                        <CrossIcon label="" size="small" />
                    </div>
                </div>
            </CSSTransitionGroup>
        </FocusLock>
    )
}

export default Notification
