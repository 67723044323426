import { Roles } from '@app/person'
import { useGlobal } from 'hooks/useGlobal'
import React, { createContext, useEffect, useState } from 'react'
import { getParsedJWT } from 'utils/commonUtils'

const STORAGE_TOKEN_KEY = 'token'

export const retrieveToken: () => string = () =>
    window.localStorage.getItem(STORAGE_TOKEN_KEY) ||
    window.sessionStorage.getItem(STORAGE_TOKEN_KEY) ||
    ''

interface AuthenticationContextProps {
    rememberUser: boolean
    loggedIn: boolean
    setRememberUser: (rememberUser: boolean) => void
    storeToken: (token: string) => void
    getCurrentUserRoles: () => Array<Roles>
    isCurrentUserAdmin: () => boolean
    logOutCurrentUser: VoidFunction
}

export const AuthenticationContext = createContext<AuthenticationContextProps>(
    null
)

export const AuthenticationProvider: React.FC = ({ children }) => {
    const [rememberUser, setRememberUser] = useState(true)

    const [loggedIn, setLoggedIn] = useState(false)

    const { setRedirectUrl } = useGlobal()

    useEffect(() => {
        if (retrieveToken()) {
            setLoggedIn(true)
        }
    }, [setLoggedIn])

    function storeToken(token: string) {
        const storage = rememberUser
            ? window.localStorage
            : window.sessionStorage
        storage.setItem(STORAGE_TOKEN_KEY, token)

        setLoggedIn(!!token)
    }

    function getCurrentUserRoles(): Array<Roles> {
        const parsedJWT = getParsedJWT(retrieveToken())
        return parsedJWT && parsedJWT.roles
    }

    function isCurrentUserAdmin(): boolean {
        const roles = getCurrentUserRoles()
        return roles && roles.includes('ROLE_ADMIN')
    }

    function logOutCurrentUser() {
        window.localStorage.removeItem(STORAGE_TOKEN_KEY)
        window.sessionStorage.removeItem(STORAGE_TOKEN_KEY)

        setLoggedIn(false)
        setRedirectUrl(null)
    }

    return (
        <AuthenticationContext.Provider
            value={{
                rememberUser,
                loggedIn,
                setRememberUser,
                storeToken,
                getCurrentUserRoles,
                isCurrentUserAdmin,
                logOutCurrentUser
            }}
        >
            {children}
        </AuthenticationContext.Provider>
    )
}
