import classNames from 'classnames'
import MagicUrl from 'quill-magic-url'
import React from 'react'
import ReactQuill, { Quill } from 'react-quill'

// Registering auto-link detect module
Quill.register('modules/magicUrl', MagicUrl)

const Link = Quill.import('formats/link')

// Sanitizing links before save
Link.sanitize = (url: string) =>
    !url.startsWith('http://') &&
    !url.startsWith('https://') &&
    !url.startsWith('//')
        ? `https://${url}`
        : url

interface RichTextEditorProps {
    name: string
    label: string
    placeholder?: string
    value?: string
    hasError?: boolean
    message?: string
    tabIndex?: number
    onChange?: (content: string) => void
    bounds?: string | HTMLElement
}

const editorModules = {
    toolbar: [
        [{ header: [2, 3, false] }],
        [
            'bold',
            'italic',
            'underline',
            'link',
            { list: 'bullet' },
            { list: 'ordered' }
        ]
    ],
    clipboard: {
        matchVisual: false
    },
    magicUrl: true
}

const RichTextEditor = (props: RichTextEditorProps) => {
    const [content, setContent] = React.useState(props.value)

    const onContentChange = (newContent: string) => {
        setContent(newContent)
        props.onChange(newContent)
    }

    React.useEffect(() => {
        if (content !== props.value) {
            setContent(props.value)
        }
        // TODO: Check why adding content does remove focus from RichTextEditor
        // eslint-disable-next-line
    }, [props.value])

    return (
        <div className="richTextEditor">
            <label className="input__label" htmlFor={props.name}>
                {props.label}
            </label>

            <div className="richTextEditor__wrapper">
                <ReactQuill
                    id={props.name}
                    modules={editorModules}
                    value={content}
                    onChange={onContentChange}
                    placeholder={props.placeholder}
                    tabIndex={props.tabIndex}
                    bounds={props.bounds}
                />
            </div>

            <span
                className={classNames('input__information', {
                    error: props.hasError,
                    normal: !props.hasError
                })}
            >
                {props.message}
            </span>
        </div>
    )
}

export default RichTextEditor
