import React from 'react'
import {
    Button as MaterialButton,
    ButtonProps as MaterialButtonProps
} from '@material-ui/core'

export interface ButtonProps extends Omit<MaterialButtonProps, 'variant'> {
    variant: 'primary' | 'secondary'
}

export const Button: React.FC<ButtonProps> = ({ variant, ...props }) => {
    if (variant === 'secondary') {
        return <MaterialButton variant="outlined" {...props} />
    }

    return <MaterialButton variant="contained" color="primary" {...props} />
}

export default Button
