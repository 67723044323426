import React from 'react'

/**
 * Renders the provided component or null depending on the condition.
 *
 * @param condition - Render condition
 * @param Component - Component to render
 */
const withMaybe = <T extends any>(condition: boolean) => (
    Component: React.ComponentType<T>
) => (props: T) => (condition ? <Component {...props} /> : null)

export default withMaybe
