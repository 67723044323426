import { Nullable, Optional } from '@app/common'
import {
    EditSkillData,
    EditSkillSetData,
    EditSkillSetTuple,
    EditSkillTuple,
    SkillCreate,
    SkillSetCopy,
    SkillSetCreate,
    SkillSetDetails,
    SkillSetUpdate,
    SkillUpdate,
    TemplateDetails
} from '@app/template'
import { AxiosInstance } from 'axios'
import { useClient } from 'hooks/useClient'
import { merge } from 'utils/commonUtils'
import { tryMutation } from './apiHelpers'

/**
 * Creates skill set tuples containing the skill set, parent skill set identifier and template, from a template.
 *
 * @param template - A template with all its details
 */
const allSkillSetsWithParentIdAndTemplate: (
    template: TemplateDetails
) => Array<EditSkillSetTuple> = (template) => {
    const parentSkillSetsWithParentIdAndTemplate = template.childSkillSets.map(
        (skillSet) => [skillSet, template.id, template] as EditSkillSetTuple
    )

    const childSkillSetsWithParentIdAndTemplate: Array<EditSkillSetTuple> = parentSkillSetsWithParentIdAndTemplate
        .map((parentSkillSet) =>
            parentSkillSet[0].childSkillSets.map((skillSet) => [
                skillSet,
                parentSkillSet[0].id,
                parentSkillSet[2]
            ])
        )
        .reduce(merge, [])

    return parentSkillSetsWithParentIdAndTemplate.concat(
        childSkillSetsWithParentIdAndTemplate
    )
}

/**
 * Creates skill tuples containing the skill, parent skill set identifier and template, from the map of templates.
 *
 * @param templates - Map of templates
 */
const allSkillsWithParentIdAndTemplate: (
    template: TemplateDetails
) => Array<EditSkillTuple> = (template) => {
    const parentSkillSetsWithTemplates: Array<[
        SkillSetDetails,
        TemplateDetails
    ]> = template.childSkillSets.map((skillSet) => [skillSet, template])

    const childSkillSetsWithTemplates: Array<[
        SkillSetDetails,
        TemplateDetails
    ]> = parentSkillSetsWithTemplates
        .map((parentSkillSet) =>
            parentSkillSet[0].childSkillSets.map((skillSet) => [
                skillSet,
                parentSkillSet[1]
            ])
        )
        .reduce(merge, [])

    return parentSkillSetsWithTemplates
        .concat(childSkillSetsWithTemplates)
        .map((skillSet) =>
            skillSet[0].skills.map((skill) => [
                skill,
                skillSet[0].id,
                skillSet[1]
            ])
        )
        .reduce(merge, [])
}

export const skillSetWithParentIdAndTemplate: (
    currentTemplate: TemplateDetails,
    skillSetId: string
) => Nullable<EditSkillSetData> = (currentTemplate, skillSetId) => {
    const skillSetData: Optional<EditSkillSetTuple> = allSkillSetsWithParentIdAndTemplate(
        currentTemplate
    ).find((editSkillSetData) => editSkillSetData[0].id === skillSetId)

    if (skillSetData) {
        const [skillSet, parentId, template] = skillSetData

        return { skillSet, parentId, template }
    }

    return null
}

export const skillWithParentIdAndTemplate: (
    currentTemplate: TemplateDetails,
    skillId: string
) => Nullable<EditSkillData> = (currentTemplate, skillId) => {
    const skillData: Optional<EditSkillTuple> = allSkillsWithParentIdAndTemplate(
        currentTemplate
    ).find((editSkillData) => editSkillData[0].id === skillId)
    const [skill, parentSkillSetId, template] = skillData

    return skillData ? { skill, parentSkillSetId, template } : null
}

const SKILL_SET_BASE_URL = '/api/skill-set'
const SKILL_BASE_URL = '/api/skill'

function useRequestSkillSetCreate(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (skillSetCreate: SkillSetCreate) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).post<
                TemplateDetails
            >(SKILL_SET_BASE_URL, skillSetCreate)
            return response.data
        })
}

function useRequestSkillSetUpdate(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (skillSetUpdate: SkillSetUpdate) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).put<
                TemplateDetails
            >(`${SKILL_SET_BASE_URL}/${skillSetUpdate.id}`, {
                name: skillSetUpdate.name,
                description: skillSetUpdate.description,
                parentId: skillSetUpdate.parentId,
                newTemplateId: skillSetUpdate.templateId,
                ownerUserIds: skillSetUpdate.ownerUserIds
            })
            return response.data
        })
}

function useRequestSkillSetCopy(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (skillSetId: string, skillSetCopy: SkillSetCopy) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).post<
                TemplateDetails
            >(`${SKILL_SET_BASE_URL}/copy/${skillSetId}`, {
                newTemplateId: skillSetCopy.newTemplateId,
                parentId: skillSetCopy.parentId
            })
            return response.data
        })
}

function useRequestSkillSetDelete(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (skillSetId: string) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).delete<
                TemplateDetails
            >(`${SKILL_SET_BASE_URL}/${skillSetId}`)
            return response.data
        })
}

function useRequestSkillCreate(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (skillCreate: SkillCreate) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).post<
                TemplateDetails
            >(SKILL_BASE_URL, skillCreate)
            return response.data
        })
}

function useRequestSkillUpdate(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (skillUpdate: SkillUpdate) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).put<
                TemplateDetails
            >(`${SKILL_BASE_URL}/${skillUpdate.id}`, {
                name: skillUpdate.name,
                description: skillUpdate.description,
                parentSkillSetId: skillUpdate.parentSkillSetId,
                newTemplateId: skillUpdate.templateId,
                expectations: skillUpdate.expectations,
                resetRatings: skillUpdate.resetRatings
            })
            return response.data
        })
}

function useRequestSkillConvert(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (skillId: string) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).put<
                TemplateDetails
            >(`${SKILL_BASE_URL}/convert-to-skill-set/${skillId}`)
            return response.data
        })
}

function useRequestSkillDelete(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (skillId: string) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).delete(
                `${SKILL_BASE_URL}/${skillId}`
            )
            return response.data
        })
}

export {
    useRequestSkillSetCreate,
    useRequestSkillSetUpdate,
    useRequestSkillSetCopy,
    useRequestSkillSetDelete,
    useRequestSkillCreate,
    useRequestSkillUpdate,
    useRequestSkillConvert,
    useRequestSkillDelete
}
