import { ListItemRender, RouteParams } from '@app/common'
import { GroupDetails } from '@app/group'
import { DropdownItem } from '@atlaskit/dropdown-menu'
import LabeledButton from 'components/button/labeled/LabeledButton'
import Worksheet from 'components/worksheet/Worksheet'
import { useFetchAllGroups } from 'helpers/groupHelpers'
import { useAuthentication } from 'hooks/useAuthentication'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { match as Match } from 'react-router-dom'
import { openDialogWrapper } from 'utils/commonUtils'
import { useFetchAllPeople } from '../../../helpers/peopleHelpers'

export interface GroupsWorksheetProps {
    match: Match<RouteParams>
    children?: React.ReactNode
}

export const GroupsWorksheet: React.FC<GroupsWorksheetProps> = ({
    match,
    children
}) => {
    const { isCurrentUserAdmin } = useAuthentication()

    const { openDialog } = useGlobal()

    const { groupList, status } = useFetchAllGroups()

    const { peopleList } = useFetchAllPeople()

    const { createNotification } = useGlobal()

    const [t] = useTranslation(['groups', 'common'])

    const primaryActionButton = isCurrentUserAdmin() && (
        <LabeledButton
            title={t('labels.createGroup')}
            onClick={() =>
                openDialogWrapper(null, { type: 'CREATE_GROUP' }, openDialog)
            }
        />
    )

    const openDropdownDialog = (
        type: 'EDIT_GROUP' | 'DELETE_GROUP',
        data: GroupDetails
    ) => (event: MouseEvent | KeyboardEvent) => {
        if (
            type === 'DELETE_GROUP' &&
            data.membersCount === 1 &&
            data.temporary
        ) {
            if (
                peopleList.some(
                    (person) =>
                        person.groups.length === 1 &&
                        person.groups[0].id === data.id
                )
            ) {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.deleteTemporaryGroup')
                })
                return
            }
        }
        openDialogWrapper(
            event,
            {
                type,
                payload: data
            },
            openDialog
        )
    }

    const listItems: Array<ListItemRender> = groupList
        ? groupList.map((group) => ({
              id: group.id,
              title: group.name,
              subtitle: t(
                  group.membersCount === 0
                      ? 'groups:labels.noMembers'
                      : 'groups:labels.members',
                  {
                      count: group.membersCount
                  }
              ),
              dropdownMenuContent: (
                  <>
                      <DropdownItem
                          id="edit"
                          onClick={openDropdownDialog('EDIT_GROUP', group)}
                      >
                          {t('common:labels.edit')}
                      </DropdownItem>

                      <DropdownItem
                          id="delete"
                          onClick={openDropdownDialog('DELETE_GROUP', group)}
                      >
                          {t('common:labels.delete')}
                      </DropdownItem>
                  </>
              ),
              disableActionMenu: !isCurrentUserAdmin()
          }))
        : []

    return (
        <Worksheet
            type="groups"
            listError={status === 'rejected'}
            listLoading={status === 'pending'}
            listItems={listItems}
            primaryActionButton={primaryActionButton}
            match={match}
        >
            {children}
        </Worksheet>
    )
}

export default GroupsWorksheet
