import classNames from 'classnames'
import React from 'react'

export interface ToggleSwitchProps {
    label: string | React.ReactElement
    selected: boolean
    onChange: (value: boolean) => void
    small?: boolean
    hasError?: boolean
    message?: string
    textShouldNotBeClickable?: boolean
}

interface MessageLabelProps {
    message: string
    hasError: boolean
}

const renderMessageLabel = (props: MessageLabelProps) => (
    <div
        className={classNames('labeledInputField__message', {
            'labeledInputField__message--error': props.hasError,
            normal: !props.hasError
        })}
    >
        {props.message}
    </div>
)

const ToggleSwitch = (props: ToggleSwitchProps) => {
    const MessageLabel = props.message && renderMessageLabel

    const onClickHandler = (allowed) => {
        if (allowed) {
            props.onChange(!props.selected)
        }
    }

    return (
        <div
            className="toggleSwitch"
            onClick={() => onClickHandler(!props.textShouldNotBeClickable)}
        >
            <div
                onClick={() => onClickHandler(props.textShouldNotBeClickable)}
                className={classNames('toggleSwitch__background', {
                    selected: props.selected,
                    small: props.small
                })}
            >
                <div
                    className={classNames('toggleSwitch__slider', {
                        selected: props.selected,
                        small: props.small
                    })}
                />
            </div>

            <span
                className={classNames('toggleSwitch__label', {
                    selected: props.selected,
                    small: props.small
                })}
            >
                {props.label}
            </span>

            {MessageLabel && (
                <MessageLabel
                    hasError={props.hasError}
                    message={props.message}
                />
            )}
        </div>
    )
}

export default ToggleSwitch
