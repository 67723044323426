import { LeaveFeedbackDialogFormData } from '@app/form'
import { FeedbackTargetType } from '@app/matrix'
import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup
} from '@material-ui/core'
import classNames from 'classnames'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import TextArea from 'components/input/textArea/TextArea'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import FormikHelpers from 'helpers/formikHelpers'
import { useRequestLeaveFeedback } from 'helpers/matrixHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

interface Props {
    feedbackTargetId: string
    feedbackTargetType: FeedbackTargetType
    feedbackTargetName: string
}

const LeaveFeedbackDialog: React.FC<Props> = ({
    feedbackTargetId,
    feedbackTargetType,
    feedbackTargetName
}) => {
    const {
        formDirty,
        toggleFormToDirty,
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const { t } = useTranslation(['common', 'matrices', 'error'])

    const initialValues: LeaveFeedbackDialogFormData = {
        type: '',
        freeText: ''
    }

    const validationSchema = Yup.object().shape(
        {
            type: Yup.string()
                .nullable()
                .when('freeText', {
                    is: (freeText) => freeText,
                    then: Yup.string()
                        .nullable()
                        .required(t('error:validation.noFeedbackTypeSelected')),
                    otherwise: Yup.string().nullable()
                }),
            freeText: Yup.string()
                .nullable()
                .when('type', {
                    is: (type) => type === 'OTHER' || !type,
                    then: Yup.string()
                        .nullable()
                        .required(t('error:validation.fieldRequired')),
                    otherwise: Yup.string().nullable()
                })
        },
        [['freeText', 'type']]
    )

    const requestLeaveFeedback = useRequestLeaveFeedback()
    const onSubmit = async (
        values: LeaveFeedbackDialogFormData,
        formikActions: FormikActions<LeaveFeedbackDialogFormData>
    ) => {
        const { error } = await requestLeaveFeedback({
            feedbackTargetId,
            feedbackTargetType,
            feedbackType: values.type,
            text: values.freeText
        })

        if (error) {
            // TODO: Introduce logging
            let translatedMessage = t('error:response.leaveFeedbackUnknown')

            if (error.data.message === 'one.feedback.per.day') {
                translatedMessage = t('error:response.alreadyGaveFeedback')
            }

            formikActions.setSubmitting(false)
            createNotification({
                type: t('error:labels.error'),
                description: translatedMessage
            })
        } else {
            closeActiveOverlay()
            createNotification({
                type: t('common:labels.success'),
                description: t('matrices:leaveFeedback.success'),
                hideOverlay: true,
                timeout: 4000
            })
        }
    }

    return (
        <Dialog
            title={feedbackTargetName}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnChange
            >
                {(formikProps) => {
                    const freeTextError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'freeText'
                    )

                    const feedbackTypeError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'type'
                    )

                    return (
                        <Form className="dialog__form leaveFeedbackDialog">
                            <FormControl component="fieldset">
                                <FormLabel
                                    component="legend"
                                    className="leaveFeedbackDialog__feedbackType"
                                >
                                    {t('matrices:labels.selectFeedbackType')}
                                </FormLabel>
                                <RadioGroup
                                    aria-label="type"
                                    name="type"
                                    value={formikProps.values.type}
                                    onChange={FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'type',
                                        formDirty,
                                        toggleFormToDirty
                                    )}
                                >
                                    <FormControlLabel
                                        control={<Radio />}
                                        value="REPORT_AN_ISSUE"
                                        label={t(
                                            'matrices:feedbackType.reportAnIssue'
                                        )}
                                    />
                                    <FormControlLabel
                                        control={<Radio />}
                                        value="NOT_RELEVANT"
                                        label={t(
                                            'matrices:feedbackType.notRelevant'
                                        )}
                                    />
                                    <FormControlLabel
                                        control={<Radio />}
                                        value="INCOMPLETE_DESCRIPTION"
                                        label={t(
                                            'matrices:feedbackType.incompleteDescription'
                                        )}
                                    />
                                    <FormControlLabel
                                        control={<Radio />}
                                        value="OTHER"
                                        label={t('matrices:feedbackType.other')}
                                    />
                                </RadioGroup>
                            </FormControl>

                            {feedbackTypeError ? (
                                <span
                                    className={classNames(
                                        'input__information',
                                        'input__information--withMarginBottomMd',
                                        {
                                            error: feedbackTypeError,
                                            normal: !feedbackTypeError
                                        }
                                    )}
                                >
                                    {feedbackTypeError}
                                </span>
                            ) : null}

                            <TextArea
                                name="freeText"
                                className="leaveFeedbackDialog__freeText"
                                value={formikProps.values.freeText}
                                label={t(
                                    'matrices:labels.freeTextFeedbackOptional'
                                )}
                                placeholder={t(
                                    'matrices:labels.freeTextFeedbackPlaceholder'
                                )}
                                message={
                                    freeTextError ||
                                    t(
                                        'matrices:labels.freeTextFeedbackDescription'
                                    )
                                }
                                hasError={!!freeTextError}
                                onChange={FormikHelpers.setDirtyForm(
                                    formikProps,
                                    'freeText',
                                    formDirty,
                                    toggleFormToDirty
                                )}
                            />

                            <div className="dialog__actions">
                                <Button
                                    title={t('common:labels.cancel')}
                                    onClick={closeActiveOverlay}
                                    formButton
                                />

                                <Button
                                    title={t('common:labels.save')}
                                    type="submit"
                                    loading={formikProps.isSubmitting}
                                    primary
                                    formButton
                                />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export default LeaveFeedbackDialog
