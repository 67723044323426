import { DialogConfig } from '@app/dialog'
import { SearchTermDetails, UnassignDialogType } from '@app/searchTerm'
import { SkillDetails, SkillSetDetails, TemplateDetails } from '@app/template'
import ContentBrowserListItem from 'components/contentBrowserListItem/ContentBrowserListItem'
import React from 'react'
import ListItemActionMenu from '../../templates/ListItemActionMenu'

interface Props {
    openDialog: (config: DialogConfig) => void
    skill: SkillDetails
    ownerUserIds: Array<string>
    disableAdditionalContent?: boolean
    template: TemplateDetails
    searchTerm: SearchTermDetails
    skillSet: SkillSetDetails
}

const SearchTermSkillListItem = ({
    openDialog,
    skill,
    ownerUserIds,
    disableAdditionalContent,
    skillSet,
    template,
    searchTerm
}: Props) => {
    const unAssignSkill: (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void = (event) => {
        event.stopPropagation()
        openDialog({
            type: 'UN_ASSIGN_SKILL',
            payload: {
                searchTermId: searchTerm.id,
                skillSet,
                skill,
                templateId: template.id,
                type: UnassignDialogType.Skill
            }
        })
    }

    return (
        <ContentBrowserListItem
            data={skill}
            additionalContent={
                !disableAdditionalContent && (
                    <ListItemActionMenu unAssignAction={unAssignSkill} />
                )
            }
            showAdditionalContentOnlyOnHover
            ownerUserIds={ownerUserIds}
            small
        />
    )
}

export default SearchTermSkillListItem
