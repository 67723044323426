import { SelectableListItem } from '@app/common'
import { LinkTemplateDialogFormData } from '@app/form'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import ListFilterBar from 'components/listFilter/ListFilterBar'
import FormikHelpers from 'helpers/formikHelpers'
import {
    requestAllTemplatesRevalidation,
    requestSingleTemplateRevalidation,
    useFetchAllTemplates,
    useRequestTemplateLink
} from 'helpers/templateHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import * as Yup from 'yup'
import LinkableTemplateList from './LinkableTemplateList'

interface OwnProps {
    templateId: string
}

type Props = OwnProps & WithTranslation

const LinkTemplateDialog = (props: Props) => {
    const { t } = props

    const {
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const { templateList } = useFetchAllTemplates()

    const validationSchema = () => {
        return Yup.object().shape({
            templates: Yup.array().test(
                'Check for selected template',
                props.t('error:validation.noTemplatesSelected'),
                (templates: Array<SelectableListItem>) =>
                    templates.filter((template) => template.selected).length > 0
            )
        })
    }

    const initialValues = {
        templates: (templateList ?? [])
            .filter((template) => !template.linkedTemplateId)
            .map((template) => ({
                id: template.id,
                title: template.name,
                selected: false,
                labels: template.labels.map((label) => label.name)
            }))
            .filter((template) => template.id !== props.templateId),
        searchKey: ''
    }

    const requestTemplateLink = useRequestTemplateLink()
    const onSubmit = async (
        values: LinkTemplateDialogFormData,
        formikActions: FormikActions<LinkTemplateDialogFormData>
    ) => {
        const { templateId } = props

        const linkedTemplate = values.templates.find(
            (template) => template.selected
        )

        try {
            if (linkedTemplate) {
                await requestTemplateLink(templateId, linkedTemplate.id)
                await requestAllTemplatesRevalidation()
                await requestSingleTemplateRevalidation(templateId)
                await requestSingleTemplateRevalidation(linkedTemplate.id)
            }

            closeActiveOverlay()
        } catch (payload) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            createNotification({
                type: props.t('error:labels.error'),
                description: props.t('error:response.linkTemplateUnknown')
            })
        }
    }

    const handleTemplateListChange: (
        selectedId: string
    ) => Array<SelectableListItem> = (selectedId) =>
        initialValues.templates.map((template) => ({
            ...template,
            selected: template.id === selectedId
        }))

    return (
        <Dialog
            title={t('templates:linkTemplate.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
        >
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
            >
                {(formikProps) => {
                    const templateError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'templates'
                    )

                    return (
                        <Form className="dialog__form withoutMargin">
                            <div className="dialog__scrollArea">
                                <ListFilterBar
                                    className="dialog__search"
                                    handleChange={formikProps.handleChange(
                                        'searchKey'
                                    )}
                                    placeholder={t(
                                        'common:worksheet.search.templates'
                                    )}
                                    searchKey={formikProps.values.searchKey}
                                    isDialog
                                    autoFocus
                                />

                                <div className="dialog__listContainer modern-scrollbar">
                                    <LinkableTemplateList
                                        formikProps={formikProps}
                                        searchKey={formikProps.values.searchKey}
                                        listItems={formikProps.values.templates}
                                        handleTemplateListChange={
                                            handleTemplateListChange
                                        }
                                    />
                                </div>

                                <div className="dialog__informationWrapper">
                                    <span className="labeledInputField__message--error">
                                        {templateError}
                                    </span>
                                </div>
                            </div>

                            <div className="dialog__actions dialog__actions--noShadow">
                                <Button
                                    title={t('common:labels.cancel')}
                                    onClick={closeActiveOverlay}
                                    formButton
                                />

                                <Button
                                    title={t('common:labels.link')}
                                    type="submit"
                                    loading={formikProps.isSubmitting}
                                    primary
                                    formButton
                                />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export default compose<Props, OwnProps>(
    withTranslation(['common', 'templates', 'error'])
)(LinkTemplateDialog)
