import { makeStyles, Popover, withStyles } from '@material-ui/core'
import { pxToRem } from '../../material/theme'

export const useFilterPopoverStyles = makeStyles((theme) => ({
    filterPopoverButton: {},
    formActions: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
    filterPopoverHeader: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    filterPopoverHeaderText: {
        fontWeight: 600,
        fontSize: pxToRem(18)
    }
}))

export const StyledFilterPopover = withStyles((theme) => ({
    paper: {
        minWidth: 659,
        borderRadius: 15,
        padding: theme.spacing(3, 3)
    }
}))(Popover)
