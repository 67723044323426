import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchTermUpdate, TypeSelectInputValue } from '@app/searchTerm'
import { FormikHelpers as FormikActions } from 'formik/dist/types'
import { EditSearchTermDialogFormData } from '@app/form'
import {
    getTypeOptions,
    requestAllSearchTermRevalidation,
    useRequestSearchTermUpdate
} from '../../../helpers/searchTermHelpers'
import { useGlobal } from '../../../hooks/useGlobal'
import SearchTermForm from '../../searchTermForm/SearchTermForm'
import Dialog from '../base/Dialog'

interface OwnProps {
    searchTerm: SearchTermUpdate
}

const EditSearchTermDialog = (props: OwnProps) => {
    const { t } = useTranslation(['common', 'error'])
    const typeOptions: Array<TypeSelectInputValue> = getTypeOptions(t)
    const {
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const [initialValues, setInitialValues] = useState({
        id: props.searchTerm.id,
        name: props.searchTerm.name,
        type: typeOptions.find((type) => props.searchTerm.type === type.value)
    })

    const requestSearchTermUpdate = useRequestSearchTermUpdate()

    const onSubmit = async (
        values: EditSearchTermDialogFormData,
        formikActions: FormikActions<EditSearchTermDialogFormData>
    ) => {
        const { error, status } = await requestSearchTermUpdate({
            id: props.searchTerm.id,
            name: values.name,
            type: typeOptions.find((type) => values.type.value === type.value)
                .value
        })

        if (status === 'resolved') {
            await requestAllSearchTermRevalidation()
            closeActiveOverlay()
        } else if (status === 'rejected') {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            if (error.data === 'searchterm.already.exists') {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.duplicateSearchTermName')
                })
            } else {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.createSearchTermUnknown', {
                        groupName: values.name
                    })
                })
            }
        }
    }

    return (
        <Dialog
            title={t('searchTerms:editSearchTerm.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
        >
            <SearchTermForm initialValues={initialValues} onSubmit={onSubmit} />
        </Dialog>
    )
}

export default EditSearchTermDialog
