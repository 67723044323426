import { ListItemRender } from '@app/common'
import { WorksheetNavigationType } from '@app/worksheet'
import ListFilterBar from 'components/listFilter/ListFilterBar'
import React, { ChangeEvent } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import ReactPlaceholder from 'react-placeholder'
import { match } from 'react-router'
import EmptyStateNavigationItem from './EmptyStateNavigationItem'
import WorksheetNavigationBody from './WorksheetNavigationBody'

interface WorksheetNavigationProps extends WithTranslation {
    primaryActionButton: React.ReactNode
    listHasError: boolean
    listLoading: boolean
    listItems: Array<ListItemRender>
    type: WorksheetNavigationType
    match: match
    searchKey: string
    handleSearchChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const WorksheetNavigation = (props: WorksheetNavigationProps) => (
    <div className="worksheetNavigation">
        {props.primaryActionButton && (
            <div className="worksheetNavigation__header">
                {props.primaryActionButton}
            </div>
        )}

        <ReactPlaceholder
            className="worksheetNavigation__reactPlaceholder"
            showLoadingAnimation
            type="rect"
            rows={1}
            ready={!props.listLoading}
        >
            <div className="worksheetNavigation__search">
                <ListFilterBar
                    placeholder={props.t(`worksheet.search.${props.type}`)}
                    handleChange={props.handleSearchChange}
                    searchKey={props.searchKey}
                />
            </div>
        </ReactPlaceholder>

        <ReactPlaceholder
            className="worksheetNavigation__reactPlaceholder"
            showLoadingAnimation
            type="text"
            rows={12}
            ready={!props.listLoading}
        >
            {!props.listItems || props.listItems.length === 0 ? (
                <EmptyStateNavigationItem
                    hasError={props.listHasError}
                    type={props.type}
                />
            ) : (
                <WorksheetNavigationBody
                    listItems={props.listItems}
                    match={props.match}
                    searchKey={props.searchKey}
                    type={props.type}
                />
            )}
        </ReactPlaceholder>
    </div>
)

export default withTranslation('common')(WorksheetNavigation)
