import CrossIcon from '@atlaskit/icon/glyph/cross'
import classNames from 'classnames'
import React from 'react'

export interface ExitButtonProps {
    onClick: () => void
    size: 'small' | 'medium' | 'large' | 'xlarge'
    crossSize?: 'small' | 'medium' | 'large' | 'xlarge'
}

const ExitButton = (props: ExitButtonProps) => (
    <div
        className={classNames('exitButton', `exitButton--${props.size}`)}
        onClick={props.onClick}
    >
        <CrossIcon
            label=""
            size={props.crossSize ? props.crossSize : props.size}
        />
    </div>
)

export default ExitButton
