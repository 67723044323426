import { ErrorData } from '@app/api'
import { GroupCreate, GroupDetails, GroupUpdate } from '@app/group'
import { AxiosInstance } from 'axios'
import { useClient } from 'hooks/useClient'
import useSWR, { mutate } from 'swr'
import { determineComponentStatus, tryMutation } from './apiHelpers'

const GROUP_BASE_URL = '/api/group'

function useFetchAllGroups() {
    const { data, error } = useSWR<Array<GroupDetails>, ErrorData>(
        GROUP_BASE_URL
    )

    return {
        groupList: data ?? [],
        error,
        status: determineComponentStatus(data, error)
    }
}

function useFetchGroupById(groupId?: string) {
    const { data, error } = useSWR<GroupDetails, Error>(
        groupId ? `${GROUP_BASE_URL}/${groupId}` : null
    )

    return {
        group: data,
        error,
        status: data ? determineComponentStatus(data, error) : 'idle'
    }
}

function useRequestGroupCreate(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (groupCreate: GroupCreate) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).post<GroupCreate>(
                GROUP_BASE_URL,
                groupCreate
            )
            return response.data
        })
}

function useRequestGroupUpdate(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (groupUpdate: GroupUpdate) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).put<GroupUpdate>(
                `${GROUP_BASE_URL}/${groupUpdate.id}`,
                {
                    name: groupUpdate.name,
                    phoneNumber: groupUpdate.phoneNumber,
                    email: groupUpdate.email,
                    groupAdmin: groupUpdate.groupAdmin
                }
            )
            return response.data
        })
}

function useRequestGroupDelete(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (groupId: string) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).delete(
                `${GROUP_BASE_URL}/${groupId}`
            )
            return response.data
        })
}

async function requestAllGroupsRevalidation() {
    return mutate(GROUP_BASE_URL)
}

async function requestSingleGroupRevalidation(groupId: string) {
    return mutate(`${GROUP_BASE_URL}/${groupId}`)
}

export {
    useFetchAllGroups,
    useFetchGroupById,
    useRequestGroupCreate,
    useRequestGroupUpdate,
    useRequestGroupDelete,
    requestAllGroupsRevalidation,
    requestSingleGroupRevalidation
}
