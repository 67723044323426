import { ErrorData } from '@app/api'
import { PersonDetails, SimplePerson } from '@app/person'
import {
    AssignSkillToSearchTerm,
    SearchTermCreate,
    SearchTermDetails,
    SearchTermShare,
    SearchTermUpdate,
    TypeSelectInputValue,
    UnassignData
} from '@app/searchTerm'
import { AxiosInstance } from 'axios'
import { TFunction } from 'i18next'
import { useMemo } from 'react'
import useSWR, { mutate } from 'swr'
import { useClient } from '../hooks/useClient'
import { determineComponentStatus, tryMutation } from './apiHelpers'

const SEARCH_TERM_BASE_URL = '/api/search-term'

function useRequestSearchTermCreate(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (searchTermCreate: SearchTermCreate) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).post<
                SearchTermCreate
            >(SEARCH_TERM_BASE_URL, searchTermCreate)
            return response.data
        })
}

function useRequestSearchTermDelete(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (searchTermId: string) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).delete(
                `${SEARCH_TERM_BASE_URL}/${searchTermId}`
            )
            return response.data
        })
}

function useRequestSearchTermUpdate(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (searchTermUpdate: SearchTermUpdate) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).put<
                SearchTermUpdate
            >(`${SEARCH_TERM_BASE_URL}/${searchTermUpdate.id}`, {
                name: searchTermUpdate.name,
                type: searchTermUpdate.type
            })
            return response.data
        })
}

function useRequestSearchTermShare(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (searchTermShare: SearchTermShare) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).patch<
                SearchTermShare
            >(`${SEARCH_TERM_BASE_URL}/share/${searchTermShare.id}`, {
                sharedWithList: searchTermShare.sharedWithList
            })
            return response.data
        })
}

function useRequestSearchTermSeen(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (searchTermId: string) =>
        tryMutation(async () => {
            await (customClient ?? client).patch(
                `${SEARCH_TERM_BASE_URL}/seen/${searchTermId}`
            )
            return {}
        })
}

function useRequestAssignSkillToSearchTerm(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (assignSkillToSearchTerm: AssignSkillToSearchTerm) =>
        tryMutation(async () => {
            try {
                const response = await (customClient ?? client).post<
                    SearchTermUpdate
                >(
                    `${SEARCH_TERM_BASE_URL}/assign-skill/${assignSkillToSearchTerm.searchTermId}`,
                    {
                        skillId: assignSkillToSearchTerm.skillId,
                        templateId: assignSkillToSearchTerm.templateId
                    }
                )
                return response.data
            } catch (error) {
                return error
            }
        })
}

function useRequestSkillSetUnAssign(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (skillSetUnassign: UnassignData) =>
        tryMutation(async () => {
            try {
                const response = await (customClient ?? client).post<
                    SearchTermUpdate
                >(
                    `${SEARCH_TERM_BASE_URL}/un-assign-skill-set/${skillSetUnassign.searchTermId}`,
                    {
                        skillSetId: skillSetUnassign.skillSetId,
                        templateId: skillSetUnassign.templateId,
                        skillId: ''
                    }
                )
                return response.data
            } catch (error) {
                return error
            }
        })
}

function useRequestSkillUnAssign(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (skillUnAssign: UnassignData) =>
        tryMutation(async () => {
            try {
                const response = await (customClient ?? client).post<
                    SearchTermUpdate
                >(
                    `${SEARCH_TERM_BASE_URL}/un-assign-skill/${skillUnAssign.searchTermId}`,
                    {
                        skillId: skillUnAssign.skillId,
                        skillSetId: skillUnAssign.skillSetId,
                        templateId: skillUnAssign.templateId
                    }
                )
                return response.data
            } catch (error) {
                return error
            }
        })
}

function useFetchUserList(searchTermId?: string) {
    const { data, error } = useSWR<Array<SimplePerson>, ErrorData>(
        searchTermId
            ? `${SEARCH_TERM_BASE_URL}/share/user-list/${searchTermId}`
            : null
    )

    return {
        peopleList: useMemo(() => data, [data]),
        error,
        status: determineComponentStatus(data, error)
    }
}

function useFetchAllSearchTerms() {
    const { data, error } = useSWR<Array<SearchTermDetails>, ErrorData>(
        SEARCH_TERM_BASE_URL
    )

    return {
        searchTermList: data,
        error,
        status: determineComponentStatus(data, error)
    }
}

function useFetchSearchTermById(id?: string) {
    const { data, error } = useSWR<SearchTermDetails, Error>(
        id ? `${SEARCH_TERM_BASE_URL}/${id}` : null
    )

    return {
        searchTerm: data,
        error,
        status: data ? determineComponentStatus(data, error) : 'idle'
    }
}

const requestAllSearchTermRevalidation = () => {
    return mutate(SEARCH_TERM_BASE_URL)
}

const requestSingleSearchTermRevalidation = (searchTermId: string) => {
    return mutate(`${SEARCH_TERM_BASE_URL}/${searchTermId}`)
}

/**
 * Provides the available search term type values to a select component.
 *
 * @param t - i18n TFunction
 */
export const getTypeOptions = (t: TFunction) =>
    <Array<TypeSelectInputValue>>[
        {
            label: t('searchTerms:types.TYPE_PERSONALIZED'),
            value: 'PERSONALIZED'
        },
        { label: t('searchTerms:types.TYPE_GLOBAL'), value: 'GLOBAL' }
    ]

export const isSearchTermSeenByCurrentUser = (
    searchTerm: SearchTermDetails,
    currentUser: PersonDetails,
    fallbackValue: boolean
) =>
    !searchTerm || !currentUser || searchTerm.type !== 'SHARED'
        ? fallbackValue
        : searchTerm.seenByList.includes(currentUser.id)

export {
    useRequestSearchTermCreate,
    useRequestSearchTermDelete,
    requestAllSearchTermRevalidation,
    useFetchAllSearchTerms,
    useFetchSearchTermById,
    requestSingleSearchTermRevalidation,
    useRequestAssignSkillToSearchTerm,
    useRequestSkillSetUnAssign,
    useRequestSkillUnAssign,
    useRequestSearchTermUpdate,
    useRequestSearchTermShare,
    useRequestSearchTermSeen,
    useFetchUserList
}
