import { ClientContext } from 'contexts/ClientContext'
import { useContext } from 'react'

export function useClient() {
    const context = useContext(ClientContext)

    if (!context) {
        throw new Error(
            "'useClient' hook must be used within a 'ClientContext'"
        )
    }

    return context
}
