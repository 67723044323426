import { TemplateDetails } from '@app/template'
import classNames from 'classnames'
import { useGlobal } from 'hooks/useGlobal'
import move from 'lodash-move'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'

interface Props {
    list: Array<any>
    useDragHandle: boolean
    lockAxis: 'x' | 'y' | 'xy'
    onSortEnd?: (templateItems: Array<any>) => void
    template: TemplateDetails
}

const SortableItem = SortableElement(({ value, hovering }) => (
    <div
        className={classNames(
            'sortableItem',
            hovering && 'sortableItem--hover'
        )}
    >
        {value}
    </div>
))

const SortableSection = SortableContainer(({ items, hovering }) => (
    <div>
        {JSON.stringify(items[0].value)}
        {items.map((value: any, index: number) => (
            <SortableItem
                key={`item-${value}-${index.toString()}`}
                index={index}
                value={value}
                hovering={hovering}
            />
        ))}
    </div>
))

const SortableList = (props: Props) => {
    const [content, setContent] = React.useState(props.list)

    const [shouldHideHover, setShouldHideHover] = useState(false)

    const [t] = useTranslation('common')

    // TODO if redux gets removed from the code base, this needs to be refactored
    React.useEffect(() => {
        setContent(props.list)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.template])

    const { createNotification } = useGlobal()

    const onSortEnd = async ({ oldIndex, newIndex }) => {
        try {
            props.onSortEnd(move(content, oldIndex, newIndex))
            setContent(move(content, oldIndex, newIndex))
            setShouldHideHover(false)
        } catch (payload) {
            createNotification({
                type: t('error:labels.error'),
                description: t('error:response.reorderUnknown')
            })
        } finally {
            setShouldHideHover(false)
        }
    }

    const startHideHover = () => !shouldHideHover && setShouldHideHover(true)

    return (
        content.length > 0 && (
            <SortableSection
                useDragHandle={props.useDragHandle}
                onSortEnd={onSortEnd}
                onSortStart={() => startHideHover()}
                hideSortableGhost
                items={content}
                lockToContainerEdges
                helperClass="sortableItem--drag"
                lockAxis={props.lockAxis}
                hovering={shouldHideHover}
            />
        )
    )
}

export default SortableList
