import { SelectInputValue } from '@app/common'
import {
    CreateTemplateDialogFormData,
    EditTemplateDialogFormData
} from '@app/form'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import FormikHelpers from '../../helpers/formikHelpers'
import Button from '../button/base/Button'
import CreateableSelect from '../input/createableSelect/CreateableSelect'
import LabeledInputField from '../input/labeledInputField/LabeledInputField'

type TemplateFormData =
    | CreateTemplateDialogFormData
    | EditTemplateDialogFormData

export interface TemplateFormProps {
    initialValues: TemplateFormData
    onSubmit: (
        values: TemplateFormData,
        formikActions: FormikActions<TemplateFormData>
    ) => Promise<void>
    labelOptions: Array<SelectInputValue>
    setLabelOptions: (labelOptions: Array<SelectInputValue>) => void
}

const TemplateForm = (props: TemplateFormProps) => {
    const { t } = useTranslation(['common', 'groups', 'error', 'templates'])

    const { formDirty, toggleFormToDirty, closeActiveOverlay } = useGlobal()

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('error:validation.fieldRequired')),
        labels: Yup.array()
            .of(
                Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string()
                })
            )
            .max(3, t('error:validation.maxThreeLabels'))
            .nullable()
            .required(t('error:validation.fieldRequired'))
    })

    return (
        <Formik
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={props.onSubmit}
            validateOnChange
            enableReinitialize
        >
            {(formikProps) => {
                const nameError = FormikHelpers.getErrorMessage(
                    formikProps,
                    'name'
                )

                const labelError = FormikHelpers.getErrorMessage(
                    formikProps,
                    'labels'
                )

                return (
                    <Form className="dialog__form">
                        <div className="dialog__scrollArea">
                            <LabeledInputField
                                label={t('templates:labels.name')}
                                name="name"
                                onChange={FormikHelpers.setDirtyForm(
                                    formikProps,
                                    'name',
                                    formDirty,
                                    toggleFormToDirty
                                )}
                                value={formikProps.values.name}
                                message={nameError}
                                hasError={!!nameError}
                                tabIndex={0}
                                autoFocus
                            />

                            <CreateableSelect
                                label={t('templates:labels.labels')}
                                name="labels"
                                options={props.labelOptions}
                                value={formikProps.values.labels}
                                isClearable={false}
                                onChange={(values: Array<SelectInputValue>) =>
                                    FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'labels',
                                        formDirty,
                                        toggleFormToDirty
                                    )(values)
                                }
                                onCreate={(value: string) => {
                                    props.setLabelOptions([
                                        ...props.labelOptions,
                                        {
                                            label: value,
                                            value
                                        }
                                    ])
                                    FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'labels',
                                        formDirty,
                                        toggleFormToDirty
                                    )(
                                        formikProps.values.labels
                                            ? [
                                                  ...formikProps.values.labels,
                                                  {
                                                      label: value,
                                                      value
                                                  }
                                              ]
                                            : [{ label: value, value }]
                                    )
                                }}
                                hasError={!!labelError}
                                message={
                                    labelError ||
                                    t('templates:labels.labelsDescription')
                                }
                                multiSelect
                                placeholder={t('templates:labels.selectLabel')}
                                createLabel={(value) =>
                                    t('templates:labels.createLabel', {
                                        newLabel: value
                                    })
                                }
                                tabIndex={0}
                            />
                        </div>

                        <div className="dialog__actions">
                            <Button
                                title={t('common:labels.cancel')}
                                onClick={closeActiveOverlay}
                                formButton
                            />

                            <Button
                                title={t('common:labels.save')}
                                type="submit"
                                loading={formikProps.isSubmitting}
                                primary
                                formButton
                            />
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default TemplateForm
