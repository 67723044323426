import classNames from 'classnames'
import React from 'react'

interface OwnProps {
    label: string
    message?: string
    hasError?: boolean
    inputRef?: React.LegacyRef<HTMLTextAreaElement>
    icon?: React.ReactNode
    onIconClick?: () => void
}

type TextAreaProps = OwnProps & React.HTMLProps<HTMLTextAreaElement>

const TextArea = (props: TextAreaProps) => (
    <div className="input textArea">
        <label className="input__label" htmlFor={props.name}>
            {props.label}
        </label>

        <div className="input__wrapper">
            <textarea
                id={props.name}
                ref={props.inputRef}
                onChange={props.onChange}
                value={props.value}
                name={props.name}
                className={classNames('input__textArea', props.className)}
                tabIndex={props.tabIndex}
                placeholder={props.placeholder}
            />

            {props.icon && props.onIconClick && (
                <button
                    onClick={props.onIconClick}
                    className="input__imageView"
                    type="button"
                >
                    {props.icon}
                </button>
            )}
        </div>

        <span
            className={classNames('input__information', {
                error: props.hasError,
                normal: !props.hasError
            })}
        >
            {props.message}
        </span>
    </div>
)

export default TextArea
