import { RoleSelectInputValue, SelectInputValue } from '@app/common'
import { CreateGroupDialogFormData, EditGroupDialogFormData } from '@app/form'
import {
    Form,
    Formik,
    FormikHelpers as FormikActions,
    FormikProps
} from 'formik'
import FormikHelpers from 'helpers/formikHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { getRoleOptions, isUser } from '../../helpers/roleHelpers'
import { PHONE_NUMBER_REG_EXP } from '../../utils/commonUtils'
import Button from '../button/base/Button'
import LabeledInputField from '../input/labeledInputField/LabeledInputField'
import LabeledSelect from '../input/labeledSelect/LabeledSelect'
import LevelSelector from '../input/levelSelector/LevelSelector'

type GroupFormData = CreateGroupDialogFormData | EditGroupDialogFormData

export interface GroupFormProps {
    initialValues: GroupFormData
    getAdministratorDropdownOptions: Array<SelectInputValue>
    onSubmit: (
        values: GroupFormData,
        formikActions: FormikActions<GroupFormData>
    ) => Promise<void>
    inviteAdmin: string
    setInviteAdmin: (inviteAdmin: string) => void
}

const GroupForm = (props: GroupFormProps) => {
    const { t } = useTranslation(['common', 'groups', 'error'])

    const { formDirty, toggleFormToDirty, closeActiveOverlay } = useGlobal()

    const roleOptions = getRoleOptions(t)
    const [inviteAdminFieldsVisible, setInviteAdminFieldsVisible] = useState(
        false
    )

    const onAdministratorSelected = (
        formikProps: FormikProps<GroupFormData>,
        selectedAdministrator: SelectInputValue | Array<SelectInputValue>
    ) => {
        const selected = selectedAdministrator as SelectInputValue
        const inviteFieldsVisible =
            selected !== null && props.inviteAdmin === selected.value

        if (!inviteFieldsVisible) {
            props.setInviteAdmin('')
        }

        FormikHelpers.setDirtyForm(
            formikProps,
            'administrator',
            formDirty,
            toggleFormToDirty
        )(selectedAdministrator)

        setInviteAdminFieldsVisible(inviteFieldsVisible)
    }

    const emailValidation = Yup.string().email(
        t('error:validation.emailInvalid')
    )

    const validationSchema = Yup.object().shape({
        showInviteAdminFields: Yup.boolean(),
        name: Yup.string().required(t('error:validation.fieldRequired')),
        email: Yup.string()
            .nullable()
            .required(t('error:validation.fieldRequired'))
            .email(t('error:validation.emailInvalid')),
        phoneNumber: Yup.string()
            .nullable()
            .matches(
                PHONE_NUMBER_REG_EXP,
                t('error:validation.phoneNumberInvalid')
            ),
        administrator: Yup.object()
            .required(t('error:validation.fieldRequired'))
            .test(
                'administrator',
                t('error:validation.emailInvalid'),
                (value) => {
                    const schema = Yup.object().shape({
                        label: props.inviteAdmin
                            ? Yup.string()
                            : emailValidation,
                        value: inviteAdminFieldsVisible
                            ? emailValidation
                            : Yup.string()
                    })
                    return schema.isValid(value)
                }
            )
            .nullable(),
        roles: Yup.array().when('showInviteAdminFields', {
            is: (showInviteAdminFields) => showInviteAdminFields,
            then: Yup.array()
                .of(
                    Yup.object().shape({
                        label: Yup.string(),
                        value: Yup.string()
                    })
                )
                .nullable()
                .required(t('error:validation.fieldRequired'))
        }),
        fullName: Yup.string().when('showInviteAdminFields', {
            is: (showInviteAdminFields) => showInviteAdminFields,
            then: Yup.string().required(t('error:validation.fieldRequired'))
        }),
        level: Yup.number().when('showInviteAdminFields', {
            is: (showInviteAdminFields) => showInviteAdminFields,
            then: Yup.number
        })
    })

    return (
        <Formik
            initialValues={props.initialValues}
            validationSchema={validationSchema}
            onSubmit={props.onSubmit}
            validateOnChange
            enableReinitialize
        >
            {(formikProps) => {
                const nameError = FormikHelpers.getErrorMessage(
                    formikProps,
                    'name'
                )

                const emailError = FormikHelpers.getErrorMessage(
                    formikProps,
                    'email'
                )

                const phoneNumberError = FormikHelpers.getErrorMessage(
                    formikProps,
                    'phoneNumber'
                )

                const administratorError = FormikHelpers.getErrorMessage(
                    formikProps,
                    'administrator'
                )

                const fullNameError = FormikHelpers.getErrorMessage(
                    formikProps,
                    'fullName'
                )

                const rolesError = FormikHelpers.getErrorMessage(
                    formikProps,
                    'roles'
                )

                return (
                    <Form className="dialog__form">
                        <div className="dialog__scrollArea">
                            <LabeledInputField
                                label={t('groups:labels.name')}
                                name="name"
                                onChange={FormikHelpers.setDirtyForm(
                                    formikProps,
                                    'name',
                                    formDirty,
                                    toggleFormToDirty
                                )}
                                value={formikProps.values.name}
                                message={nameError}
                                hasError={!!nameError}
                                tabIndex={0}
                                autoFocus
                            />

                            <LabeledInputField
                                label={t('groups:labels.email')}
                                name="email"
                                onChange={FormikHelpers.setDirtyForm(
                                    formikProps,
                                    'email',
                                    formDirty,
                                    toggleFormToDirty
                                )}
                                value={formikProps.values.email}
                                message={emailError}
                                hasError={!!emailError}
                                tabIndex={0}
                            />

                            <LabeledInputField
                                label={t('groups:labels.phoneNumber')}
                                name="phoneNumber"
                                onChange={FormikHelpers.setDirtyForm(
                                    formikProps,
                                    'phoneNumber',
                                    formDirty,
                                    toggleFormToDirty
                                )}
                                value={formikProps.values.phoneNumber}
                                message={phoneNumberError}
                                hasError={!!phoneNumberError}
                                tabIndex={0}
                            />

                            <LabeledSelect
                                label={t('groups:labels.administrator')}
                                name="administrator"
                                message={
                                    administratorError.length > 0
                                        ? administratorError
                                        : t(
                                              'groups:labels.inviteAdminDescription'
                                          )
                                }
                                messageVariant="INFO"
                                options={props.getAdministratorDropdownOptions}
                                value={formikProps.values.administrator}
                                isClearable
                                hasError={!!administratorError}
                                placeholder={t(
                                    'groups:labels.selectAdministrator'
                                )}
                                onChange={(value) =>
                                    onAdministratorSelected(formikProps, value)
                                }
                                onInputChange={(newValue, meta) => {
                                    if (meta.action === 'input-change') {
                                        props.setInviteAdmin(newValue)
                                    }
                                }}
                                tabIndex={0}
                            />

                            {!inviteAdminFieldsVisible ? null : (
                                <>
                                    <LabeledInputField
                                        label={t('people:labels.fullName')}
                                        name="fullName"
                                        onChange={FormikHelpers.setDirtyForm(
                                            formikProps,
                                            'fullName',
                                            formDirty,
                                            toggleFormToDirty
                                        )}
                                        value={formikProps.values.fullName}
                                        message={fullNameError}
                                        hasError={!!fullNameError}
                                        tabIndex={0}
                                    />

                                    <LabeledSelect
                                        label={t('people:labels.roles')}
                                        name="roles"
                                        options={roleOptions}
                                        value={formikProps.values.roles}
                                        isClearable={false}
                                        onChange={(
                                            values: Array<RoleSelectInputValue>
                                        ) =>
                                            FormikHelpers.setDirtyForm(
                                                formikProps,
                                                'roles',
                                                formDirty,
                                                toggleFormToDirty
                                            )(values)
                                        }
                                        message={rolesError}
                                        hasError={!!rolesError}
                                        multiSelect
                                        tabIndex={0}
                                    />

                                    {isUser(formikProps.values.roles) && (
                                        <LevelSelector
                                            label={t('people:labels.level')}
                                            name="level"
                                            value={formikProps.values.level}
                                            onClick={FormikHelpers.setDirtyForm(
                                                formikProps,
                                                'level',
                                                formDirty,
                                                toggleFormToDirty
                                            )}
                                        />
                                    )}
                                </>
                            )}
                        </div>

                        <div className="dialog__actions">
                            <Button
                                title={t('common:labels.cancel')}
                                onClick={closeActiveOverlay}
                                formButton
                            />

                            <Button
                                title={t('common:labels.save')}
                                type="submit"
                                loading={formikProps.isSubmitting}
                                primary
                                formButton
                            />
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default GroupForm
