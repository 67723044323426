import { SelectInputValue } from '@app/common'
import { EditSkillSetDialogFormData } from '@app/form'
import { SkillSetDetails, TemplateDetails } from '@app/template'
import OwnersBadgeList from 'components/badge/ownerBadgeList/OwnersBadgeList'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import LabeledInputField from 'components/input/labeledInputField/LabeledInputField'
import LabeledSelect from 'components/input/labeledSelect/LabeledSelect'
import RichTextEditor from 'components/input/richTextEditor/RichTextEditor'
import {
    Form,
    FormikContext,
    FormikHelpers as FormikActions,
    FormikProps,
    useFormik
} from 'formik'
import FormikHelpers from 'helpers/formikHelpers'
import { useFetchAllActivePeople } from 'helpers/peopleHelpers'
import { useRequestSkillSetUpdate } from 'helpers/skillHelpers'
import {
    getSkillSetDropdownItems,
    getTemplateDropdownItems,
    requestSingleTemplateRevalidation,
    useFetchAllTemplates
} from 'helpers/templateHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React, { useCallback, useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import * as Yup from 'yup'

interface OwnProps {
    skillSet: SkillSetDetails
    parentId: string
    template: TemplateDetails
}

type Props = OwnProps & WithTranslation

const EditSkillSetDialog: React.FC<Props> = (props: Props) => {
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateDetails>(
        null
    )

    const { templateList } = useFetchAllTemplates()

    React.useEffect(() => {
        if (templateList) {
            setSelectedTemplate(
                templateList.find(
                    (template) => template.id === props.template.id
                )
            )
        }
    }, [props.template.id, templateList])

    const { t, skillSet, parentId } = props

    const {
        formDirty,
        toggleFormToDirty,
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const hasChild =
        skillSet.childSkillSets && skillSet.childSkillSets.length !== 0

    const {
        data: activePeopleList,
        status: loadingStatus
    } = useFetchAllActivePeople()

    const getSkillSetListBasedOnSelectedTemplate = useCallback(
        (template: TemplateDetails) => [
            {
                label: t('templates:labels.parentPlaceholderEdit', {
                    templateName: template?.name ?? ''
                }),
                value: template?.id ?? ''
            },
            ...getSkillSetDropdownItems(template).filter(
                (skillSetInput) => skillSetInput.value !== skillSet.id
            )
        ],
        [skillSet.id, t]
    )

    const initialValues: EditSkillSetDialogFormData = {
        name: skillSet.name,
        description: skillSet.description,
        parent: getSkillSetListBasedOnSelectedTemplate(selectedTemplate).find(
            (skillSetBasedOnSelectedTemplate) =>
                skillSetBasedOnSelectedTemplate.value === parentId
        ),
        template: getTemplateDropdownItems(templateList).find(
            (template) => template.value === selectedTemplate?.id
        ),
        ownerUserIds: skillSet.ownerUserIds
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('error:validation.fieldRequired'))
    })

    const requestSkillSetUpdate = useRequestSkillSetUpdate()
    const onSubmit = async (
        values: EditSkillSetDialogFormData,
        formikActions: FormikActions<EditSkillSetDialogFormData>
    ) => {
        try {
            const { data: template } = await requestSkillSetUpdate({
                id: skillSet.id,
                name: values.name,
                description: values.description
                    .replace(/^<p><br><\/p>/g, '')
                    .replace(/<p><br><\/p>$/g, ''),
                parentId: values.parent.value,
                templateId: values.template.value,
                ownerUserIds: values.ownerUserIds
            })
            await requestSingleTemplateRevalidation(template.id)

            closeActiveOverlay()
        } catch (payload) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            createNotification({
                type: t('error:labels.error'),
                description: t('error:response.editSkillSetUnknown', {
                    skillSetName: skillSet.name
                })
            })
        }
    }

    const formik: FormikProps<EditSkillSetDialogFormData> = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
        validateOnChange: true,
        enableReinitialize: true
    })

    const onTemplateSelect = (selectedTemplateItem: SelectInputValue) => {
        FormikHelpers.setDirtyForm(
            formik,
            'template',
            formDirty,
            toggleFormToDirty
        )(selectedTemplateItem)

        formik.setFieldValue(
            'parent',
            getSkillSetListBasedOnSelectedTemplate(
                templateList.find(
                    (template) => template.id === selectedTemplateItem.value
                )
            )[0]
        )
    }

    const nameError = FormikHelpers.getErrorMessage(formik, 'name')

    return (
        <FormikContext.Provider value={formik}>
            <Dialog
                title={t('templates:editSkillSet.title')}
                closeDialog={tryCloseDirtyOverlay}
                showCloseIcon
                animationWithMargin
            >
                <Form className="dialog__form">
                    <div className="dialog__scrollArea modern-scrollbar">
                        <LabeledInputField
                            label={t('templates:labels.name')}
                            name="name"
                            onChange={FormikHelpers.setDirtyForm(
                                formik,
                                'name',
                                formDirty,
                                toggleFormToDirty
                            )}
                            value={formik.values.name}
                            message={nameError}
                            hasError={!!nameError}
                            tabIndex={0}
                            autoFocus
                        />

                        <LabeledSelect
                            label={t('templates:labels.template')}
                            name="template"
                            options={getTemplateDropdownItems(templateList)}
                            value={formik.values.template}
                            isClearable={false}
                            placeholder={t('templates:labels.selectSkillSet', {
                                templateName: props.template.name
                            })}
                            onChange={onTemplateSelect}
                            tabIndex={0}
                        />

                        <LabeledSelect
                            label={t('templates:labels.parent')}
                            name="parent"
                            options={getSkillSetListBasedOnSelectedTemplate(
                                selectedTemplate
                            )}
                            value={formik.values.parent}
                            isClearable={false}
                            placeholder={t('templates:labels.selectSkillSet', {
                                templateName: selectedTemplate?.name ?? ''
                            })}
                            onChange={FormikHelpers.setDirtyForm(
                                formik,
                                'parent',
                                formDirty,
                                toggleFormToDirty
                            )}
                            disabled={hasChild}
                            message={
                                hasChild
                                    ? t('templates:editSkillSet.moveError')
                                    : ''
                            }
                            tabIndex={0}
                        />

                        <RichTextEditor
                            label={t('templates:labels.descriptionOptional')}
                            name="description"
                            onChange={FormikHelpers.setDirtyForm(
                                formik,
                                'description',
                                formDirty,
                                toggleFormToDirty
                            )}
                            value={formik.values.description}
                            placeholder={t(
                                'templates:labels.descriptionSkillSetPlaceholder'
                            )}
                            bounds=".richTextEditor"
                            tabIndex={0}
                        />

                        <OwnersBadgeList
                            label={t('templates:labels.coachesOptional')}
                            activePeopleList={(activePeopleList ?? []).map(
                                (people) => {
                                    return {
                                        id: people.id,
                                        title: people.name,
                                        subtitle: ''
                                    }
                                }
                            )}
                            formikProps={formik}
                            setFormDirty={toggleFormToDirty}
                            isFormDirty={formDirty}
                            hasError={loadingStatus === 'rejected'}
                        />
                    </div>

                    <div className="dialog__actions">
                        <Button
                            title={t('common:labels.cancel')}
                            onClick={tryCloseDirtyOverlay}
                            formButton
                        />

                        <Button
                            title={t('common:labels.save')}
                            type="submit"
                            loading={formik.isSubmitting}
                            primary
                            formButton
                        />
                    </div>
                </Form>
            </Dialog>
        </FormikContext.Provider>
    )
}

export default compose<Props, OwnProps>(
    withTranslation(['common', 'templates', 'error'])
)(EditSkillSetDialog)
