import React from 'react'

export type AppModeType = 'NORMAL' | 'FORBIDDEN' | 'MAINTENANCE'

export const AppModeContext = React.createContext<
    [AppModeType, React.Dispatch<React.SetStateAction<AppModeType>>]
>(null)

export const AppModeProvider: React.FC = ({ children }) => {
    const appModeState = React.useState<AppModeType>('NORMAL')

    return (
        <AppModeContext.Provider value={appModeState}>
            {children}
        </AppModeContext.Provider>
    )
}
