import { ListItem } from '@app/common'
import { CreateSkillSetDialogFormData } from '@app/form'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from 'components/badge/Badge'
import OwnerPopover from 'components/ownerPopover/OwnerPopover'
import TooltipContent from 'components/tooltipContent/TooltipContent'
import { FormikProps } from 'formik'
import FormikHelpers from 'helpers/formikHelpers'
import React from 'react'
import { getMonogram } from 'utils/commonUtils'

interface OwnProps extends React.HTMLProps<HTMLTextAreaElement> {
    activePeopleList?: Array<ListItem>
    label?: string
    hasError?: boolean
    formikProps?: FormikProps<CreateSkillSetDialogFormData>
    setFormDirty?: () => void
    isFormDirty?: boolean
}

interface BadgeTooltipProps extends OwnProps {
    listItem: ListItem
    showRemoveButton?: boolean
}

// REFACTOR withToolTip HOC
export const BadgeWithTooltip = (props: BadgeTooltipProps) => (
    <Tooltip
        title={props.listItem.title}
        enterDelay={100}
        leaveDelay={150}
        placement="bottom"
    >
        <TooltipContent>
            <Badge
                title={getMonogram(props.listItem.title)}
                size="md"
                className="badgeList__badge"
                disabled
                onDelete={() =>
                    FormikHelpers.setDirtyForm(
                        props.formikProps,
                        'ownerUserIds',
                        props.isFormDirty,
                        props.setFormDirty
                    )(
                        props.formikProps.values.ownerUserIds.filter(
                            (ownerUserId) => ownerUserId !== props.listItem.id
                        )
                    )
                }
                showRemoveButton={props.showRemoveButton}
            />
        </TooltipContent>
    </Tooltip>
)

const OwnersBadgeList = (props: OwnProps) => (
    <div className="badgeList">
        <label className="input__label" htmlFor={props.name}>
            {props.label}
        </label>

        <div className="badgeList__container">
            <OwnerPopover
                activePeopleList={props.activePeopleList.filter(
                    (people) =>
                        !props.formikProps.values.ownerUserIds.includes(
                            people.id
                        )
                )}
                formikProps={props.formikProps}
                setFormDirty={props.setFormDirty}
                isFormDirty={props.isFormDirty}
                hasError={props.hasError}
            />

            {props.activePeopleList
                .filter((people) =>
                    props.formikProps.values.ownerUserIds.includes(people.id)
                )
                .map((people) => (
                    <div key={people.id} onClick={null}>
                        <BadgeWithTooltip
                            listItem={people}
                            formikProps={props.formikProps}
                            setFormDirty={props.setFormDirty}
                            isFormDirty={props.isFormDirty}
                            showRemoveButton
                        />
                    </div>
                ))}
        </div>
    </div>
)

export default OwnersBadgeList
