import { MatrixSkill } from '@app/matrix'
import ContentBrowserListItem from 'components/contentBrowserListItem/ContentBrowserListItem'
import LevelSelector from 'components/input/levelSelector/LevelSelector'
import React from 'react'

interface Props {
    skill: MatrixSkill
    ownerUserIds: Array<string>
}

const PeopleSkillListItem = ({ skill, ownerUserIds }: Props) => {
    const listItemActionMenu = (
        <LevelSelector onClick={() => ({})} value={skill.rating} disabled />
    )

    return (
        <ContentBrowserListItem
            additionalContent={listItemActionMenu}
            showAdditionalContentOnlyOnHover={false}
            data={skill}
            ownerUserIds={ownerUserIds}
            small
            showTriggerButtonLabel
        />
    )
}

export default PeopleSkillListItem
