import { MenubarIconType } from '@app/common'
import DocumentsIcon from '@atlaskit/icon/glyph/documents'
import EditorImageBorderIcon from '@atlaskit/icon/glyph/editor/image-border'
import FileIcon from '@atlaskit/icon/glyph/file'
import MediaServicesGridIcon from '@atlaskit/icon/glyph/media-services/grid'
import PeopleIcon from '@atlaskit/icon/glyph/people'
import PersonIcon from '@atlaskit/icon/glyph/person'
import SearchIcon from '@atlaskit/icon/glyph/search'

import {
    isAdmin,
    isContentAdmin,
    isMatchingAnyRole,
    isSales,
    isSupervisor
} from 'helpers/roleHelpers'
import { useAuthentication } from 'hooks/useAuthentication'
import React from 'react'
import { NavLink } from 'react-router-dom'

const Logo = () => {
    return <div className="menuBar__logo" />
}

const getMenuItemIcon = (name: MenubarIconType) => {
    switch (name) {
        case 'overview':
            return <MediaServicesGridIcon label="" />
        case 'templates':
            return <DocumentsIcon label="" />
        case 'people':
            return <PersonIcon label="" />
        case 'groups':
            return <PeopleIcon label="" />
        case 'matrices':
            return <FileIcon label="" />
        case 'searchTerms':
            return <SearchIcon label="" />
        default:
            return <EditorImageBorderIcon label="" />
    }
}

interface NavigationItemProps {
    icon: MenubarIconType
    targetUrl: string
    title: string
}

const NavigationItem = (props: NavigationItemProps) => (
    <NavLink
        className="menuItem__link"
        to={props.targetUrl}
        activeClassName="selected"
    >
        <div className="menuItem__icon">{getMenuItemIcon(props.icon)}</div>
        <div className="menuItem__title">{props.title}</div>
    </NavLink>
)

const Navigation = () => {
    const { getCurrentUserRoles } = useAuthentication()
    const currentUserRoles = getCurrentUserRoles()

    return (
        <nav className="menuBar__navigation">
            <ul>
                <li className="menuItem">
                    <NavigationItem
                        icon="overview"
                        title="Overview"
                        targetUrl="/overview"
                    />
                </li>

                {isMatchingAnyRole(
                    isAdmin,
                    isSupervisor,
                    isContentAdmin
                )(currentUserRoles) && (
                    <li className="menuItem">
                        <NavigationItem
                            icon="templates"
                            title="Templates"
                            targetUrl="/templates"
                        />
                    </li>
                )}

                <li className="menuItem">
                    <NavigationItem
                        icon="people"
                        title="People"
                        targetUrl="/people"
                    />
                </li>

                {isMatchingAnyRole(isAdmin, isSales)(currentUserRoles) && (
                    <li className="menuItem">
                        <NavigationItem
                            icon="groups"
                            title="Groups"
                            targetUrl="/groups"
                        />
                    </li>
                )}

                {isMatchingAnyRole(isAdmin, isSales)(currentUserRoles) && (
                    <li className="menuItem">
                        <NavigationItem
                            icon="searchTerms"
                            title="Search"
                            targetUrl="/search-terms"
                        />
                    </li>
                )}

                <li className="menuItem">
                    <NavigationItem
                        icon="matrices"
                        title="Matrices"
                        targetUrl="/matrices"
                    />
                </li>
            </ul>
        </nav>
    )
}

const MenuBar = () => {
    return (
        <div className="menuBar">
            <NavLink to="/">
                <Logo />
            </NavLink>
            <Navigation />
        </div>
    )
}

export default MenuBar
