import Button from 'components/button/base/Button'
import InfoCard from 'components/card/InfoCard'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'

export interface WelcomeCardProps {
    className: string
    switchCard: () => void
    username: string
}

export type Props = WelcomeCardProps & WithTranslation

export const WelcomeCard = (props: Props) => {
    const cardAction = (
        <Button
            className="large"
            onClick={props.switchCard}
            title={props.t('card.welcome.actionLabel')}
            primary
            triggerEnter
        />
    )

    return (
        <InfoCard
            title={props.t('card.welcome.title', { username: props.username })}
            description={props.t('card.welcome.description')}
            className={props.className}
            descriptionClassName="welcomeCard"
            cardAction={cardAction}
        />
    )
}

export default withTranslation('common')(WelcomeCard)
