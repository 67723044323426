import classNames from 'classnames'
import ExitButton from 'components/button/exit/ExitButton'
import React from 'react'
import CrossCircleIcon from '@atlaskit/icon/glyph/cross-circle'

export interface BadgeProps {
    title: string
    className?: string
    size: 'sm' | 'md' | 'lg'
    disabled?: boolean
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
    icon?: React.ReactNode
    showRemoveButton?: boolean
    onDelete?: () => void
}

const Badge = (props: BadgeProps) => {
    return (
        <button
            className={classNames(
                'badge',
                `badge--${props.size}`,
                props.disabled && 'badge--disabled',
                props.className
            )}
            onClick={props.onClick}
            type="button"
        >
            <>
                <span className="badge__title">{props.title}</span>

                {props.icon}

                {props.showRemoveButton && (
                    <div
                        className="badge__removeButton"
                        onClick={props.onDelete}
                    >
                        <CrossCircleIcon label="" />
                    </div>
                )}
            </>
        </button>
    )
}

export default Badge
