import { TemplateData } from '@app/template'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import {
    requestAllTemplatesRevalidation,
    useRequestTemplateDelete
} from 'helpers/templateHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { Trans, WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'recompose'

interface OwnProps {
    redirectOnMatch: (redirectUrl: string) => void
    template: TemplateData
}

type Props = OwnProps & WithTranslation

const DeleteTemplateDialog = (props: Props) => {
    const initialValues = {}

    const { t, template } = props

    const {
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const requestTemplateDelete = useRequestTemplateDelete()
    const onSubmit = async (
        values: unknown,
        formikActions: FormikActions<unknown>
    ) => {
        try {
            await requestTemplateDelete(template.id)
            await requestAllTemplatesRevalidation()
            props.redirectOnMatch(template.id)
            closeActiveOverlay()
        } catch (payload) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            createNotification({
                type: props.t('error:labels.error'),
                description: props.t('error:response.deleteTemplateUnknown', {
                    templateName: template.name
                })
            })
        }
    }

    return (
        <Dialog
            title={t('templates:deleteTemplate.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon={false}
        >
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(formikProps) => (
                    <Form className="dialog__form dialog__form--confirm">
                        <div className="dialog__info">
                            <Trans i18nKey="templates:deleteTemplate.question">
                                <strong>
                                    {{
                                        templateName: props.template.name
                                    }}
                                </strong>
                            </Trans>
                        </div>

                        <div className="dialog__actions dialog__actions--noShadow">
                            <Button
                                title={t('common:labels.cancel')}
                                onClick={closeActiveOverlay}
                                formButton
                            />

                            <Button
                                title={t('common:labels.delete')}
                                type="submit"
                                loading={formikProps.isSubmitting}
                                destructive
                                primary
                                formButton
                                autoFocus
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default compose<Props, OwnProps>(
    withTranslation(['common', 'templates', 'error'])
)(DeleteTemplateDialog)
