import { DialogConfig } from '@app/dialog'
import { SkillSetDetails, TemplateDetails } from '@app/template'
import ContentBrowserListItem from 'components/contentBrowserListItem/ContentBrowserListItem'
import SortableList from 'components/listItem/sortable/SortableList'
import { TemplateSkillSetSubtitle } from 'components/subtitle/templateSkillSetSubtitle/TemplateSkillSetSubtitle'
import { skillSetWithParentIdAndTemplate } from 'helpers/skillHelpers'
import { orderArrayListByPosition } from 'helpers/templateHelpers'
import React from 'react'
import { SortableHandle } from 'react-sortable-hoc'
import { compose } from 'recompose'
import { merge } from 'utils/commonUtils'
import ListItemActionMenu from './ListItemActionMenu'
import TemplateSkillListItem from './TemplateSkillListItem'

export interface TemplateSkillSetListItemProps {
    openDialog: (config: DialogConfig) => void
    reorderTemplateItems?: (templateItems: Array<any>) => void
    skillSet: SkillSetDetails
    disableAdditionalContent?: boolean
    template: TemplateDetails
}

type Props = TemplateSkillSetListItemProps

const DragHandle = SortableHandle(() => (
    <div title="Drag">
        <div className="grabIcon" title="Drag" />
    </div>
))

const TemplateSkillSetListItem = ({
    skillSet,
    openDialog,
    disableAdditionalContent,
    reorderTemplateItems,
    template
}: Props) => {
    const modifyAction: (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void = (event) => {
        event.stopPropagation()
        openDialog({
            type: 'EDIT_SKILL_SET',
            payload: skillSetWithParentIdAndTemplate(template, skillSet.id)
        })
    }

    const copyAction: (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void = (event) => {
        event.stopPropagation()
        openDialog({
            type: 'COPY_SKILL_SET',
            payload: skillSetWithParentIdAndTemplate(template, skillSet.id)
        })
    }

    const deleteAction: (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void = (event) => {
        event.stopPropagation()
        openDialog({
            type: 'DELETE_SKILL_SET',
            payload: skillSet
        })
    }

    const listItemActionMenu = (
        <>
            <ListItemActionMenu
                deleteAction={deleteAction}
                modifyAction={modifyAction}
                copyAction={copyAction}
            />
            <DragHandle />
        </>
    )

    return (
        <ContentBrowserListItem
            data={skillSet}
            additionalContent={!disableAdditionalContent && listItemActionMenu}
            showAdditionalContentOnlyOnHover
            small={false}
            skillSetSubtitle={<TemplateSkillSetSubtitle skillSet={skillSet} />}
            showTriggerButtonLabel
        >
            <SortableList
                useDragHandle
                lockAxis="y"
                onSortEnd={reorderTemplateItems}
                template={template}
                list={
                    skillSet
                        ? orderArrayListByPosition(
                              merge(skillSet.childSkillSets, skillSet.skills)
                          ).map((skill) =>
                              skill.ownerUserIds ? (
                                  <TemplateSkillSetListItem
                                      key={skill.id}
                                      skillSet={skill}
                                      openDialog={openDialog}
                                      disableAdditionalContent={
                                          disableAdditionalContent
                                      }
                                      reorderTemplateItems={
                                          reorderTemplateItems
                                      }
                                      template={template}
                                  />
                              ) : skill.expectations ? (
                                  <TemplateSkillListItem
                                      key={skill.id}
                                      openDialog={openDialog}
                                      skill={skill}
                                      ownerUserIds={skillSet.ownerUserIds}
                                      disableAdditionalContent={
                                          disableAdditionalContent
                                      }
                                      template={template}
                                  />
                              ) : null
                          )
                        : null
                }
            />
        </ContentBrowserListItem>
    )
}

export default compose<Props, TemplateSkillSetListItemProps>()(
    TemplateSkillSetListItem
)
