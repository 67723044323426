import { CircularProgress, Grow, makeStyles } from '@material-ui/core'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import { TrainingMaterialsRecommendationMessage } from 'components/trainingMaterialsRecommendationMessage/TrainingMaterialsRecommendationMessage'
import {
    useFetchAllOverviewData,
    useFetchAllTrainingMaterialData
} from 'helpers/overviewHelpers'
import withTitle from 'hoc/withTitle'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import theme from '../../material/theme'
import RecommendationCard from './recommendationCard/RecommendationCard'
import StatisticsCard from './statisticsCard/StatisticsCard'

const useOverviewStyles = makeStyles(() => ({
    circleProgress: {
        marginLeft: theme.spacing(1)
    },
    cardWrapper: {
        width: '30%'
    }
}))

const Overview: React.FC = () => {
    const { overviewData, status: overviewStatus } = useFetchAllOverviewData()
    const classes = useOverviewStyles()
    const {
        trainingMaterialData,
        status: trainingMaterialStatus
    } = useFetchAllTrainingMaterialData()

    const { t } = useTranslation(['overview'])

    const {
        overallFillingRate,
        assignedMatrices,
        matricesMeetingExpectations
    } = overviewData ?? {}

    const overallFillingRateText = overallFillingRate?.toString()
        ? `${overallFillingRate}%`
        : '--'
    const assignedMatricesText = assignedMatrices?.toString() ?? '--'
    const matricesMeetingExpectationsText =
        matricesMeetingExpectations?.toString() ?? '--'

    const recommendationItems = (trainingMaterialData ?? []).map(
        (trainingMaterial, index) => (
            <Grow
                in
                timeout={800}
                style={{
                    transformOrigin: '0 0 0',
                    transitionDelay: `${index * 40}ms`
                }}
            >
                <div>
                    <RecommendationCard
                        key={trainingMaterial.id}
                        trainingMaterialId={trainingMaterial.id}
                        trainingMaterialTitle={trainingMaterial.name}
                        trainingMaterialUrl={trainingMaterial.url}
                        matrixName={trainingMaterial.matrixName}
                    />
                </div>
            </Grow>
        )
    )

    return (
        <>
            <Breadcrumb>
                {t('overview:title')}
                {overviewStatus === 'pending' && (
                    <CircularProgress
                        size={16}
                        className={classes.circleProgress}
                    />
                )}
            </Breadcrumb>
            {overviewStatus !== 'pending' && (
                <div className="statisticsContainer">
                    <Grow in timeout={800}>
                        <div className={classes.cardWrapper}>
                            <StatisticsCard
                                type="overallFillingRate"
                                description={overallFillingRateText}
                            />
                        </div>
                    </Grow>
                    <Grow in timeout={800} style={{ transitionDelay: '40ms' }}>
                        <div className={classes.cardWrapper}>
                            <StatisticsCard
                                type="assignedMatrices"
                                description={assignedMatricesText}
                            />
                        </div>
                    </Grow>
                    <Grow in timeout={800} style={{ transitionDelay: '60ms' }}>
                        <div className={classes.cardWrapper}>
                            <StatisticsCard
                                type="matricesMeetingExpectations"
                                description={matricesMeetingExpectationsText}
                            />
                        </div>
                    </Grow>
                </div>
            )}

            <Breadcrumb>
                {t('overview:recommendation.title')}
                {trainingMaterialStatus === 'pending' && (
                    <CircularProgress
                        role="progressbar"
                        size={16}
                        className={classes.circleProgress}
                    />
                )}
            </Breadcrumb>

            {trainingMaterialStatus ===
            'pending' ? null : recommendationItems.length > 0 ? (
                <div className="recommendationsContainer">
                    {recommendationItems}
                </div>
            ) : (
                //
                <div role="presentation">
                    <TrainingMaterialsRecommendationMessage
                        assignedMatrices={assignedMatrices}
                        matricesMeetingExpectations={
                            matricesMeetingExpectations
                        }
                        overallFillingRate={overallFillingRate}
                        link={
                            assignedMatrices > 0 &&
                            assignedMatrices !== matricesMeetingExpectations ? (
                                <NavLink
                                    className="placeholder__action"
                                    to="/matrices"
                                >
                                    {t(
                                        'overview:recommendation.noRecommendations.checkMatrices'
                                    )}
                                </NavLink>
                            ) : (
                                <NavLink
                                    className="placeholder__action"
                                    to="/matrices"
                                >
                                    {t(
                                        'overview:recommendation.noRecommendations.newMatrices'
                                    )}
                                </NavLink>
                            )
                        }
                    />
                </div>
            )}
        </>
    )
}

export default withTitle('Overview')(Overview)
