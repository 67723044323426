import { MatrixSkill, MatrixSkillSet } from '@app/matrix'
import { SimplePerson } from '@app/person'
import { SkillDetails, SkillSetDetails } from '@app/template'
import { BadgeWithTooltip } from 'components/badge/ownerBadgeList/OwnersBadgeList'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import LevelSelector from 'components/input/levelSelector/LevelSelector'
import { useFetchCurrentUser } from 'helpers/accountHelpers'
import {
    requestSingleMatrixRevalidation,
    useRequestRateSkill
} from 'helpers/matrixHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React, { useState } from 'react'
import stringToHtml from 'react-html-parser'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'recompose'

interface OwnProps {
    entity: MatrixSkill | MatrixSkillSet | SkillDetails | SkillSetDetails
    currentPage: string
    owners: Array<SimplePerson>
}

type Props = OwnProps & WithTranslation

const DescriptionDialog = (props: Props) => {
    const [rating, setRating] = useState<number | null>(
        'rating' in props.entity ? props.entity.rating : null
    )

    const [loading, setLoading] = useState<boolean>(false)

    const { currentUser } = useFetchCurrentUser()

    const {
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const owners =
        props.entity && 'owners' in props.entity
            ? props.entity.owners
            : props.owners

    const renderCoaches = () => {
        return (
            <>
                <div className="descriptionDialog__owners">
                    {(owners ?? [])
                        .map((people) => ({
                            id: people.id,
                            title: people.name,
                            subtitle: ''
                        }))
                        .map((people) => (
                            <div key={people.id} onClick={null}>
                                <BadgeWithTooltip
                                    listItem={people}
                                    showRemoveButton={false}
                                />
                            </div>
                        ))}
                </div>
            </>
        )
    }

    const requestRateSkill = useRequestRateSkill()
    const renderRadioButton = () => {
        if (
            props.entity &&
            props.currentPage !== 'people' &&
            'rating' in props.entity
        ) {
            const currentSkillId = props.entity.skillId

            const rateCurrentSkill = async (rate: number) => {
                setRating(rate)

                try {
                    setLoading(true)

                    const { data } = await requestRateSkill({
                        userId: currentUser.id,
                        skillId: currentSkillId,
                        rating: rate
                    })

                    requestSingleMatrixRevalidation(
                        currentUser.id,
                        data.templateId
                    )
                } catch (payload) {
                    // TODO: Introduce logging

                    createNotification({
                        type: props.t('error:labels.error'),
                        description: props.t(
                            'error:response.ratingUpdateUnknown'
                        )
                    })
                } finally {
                    setLoading(false)
                }
            }

            return (
                <LevelSelector
                    value={rating}
                    onClick={rateCurrentSkill}
                    isLoading={loading}
                />
            )
        }

        return null
    }

    return (
        <Dialog
            className="descriptionDialog"
            title={props.entity.name}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
            fullScreen
            footerClassName={
                props.entity &&
                props.currentPage !== 'people' &&
                'rating' in props.entity &&
                'descriptionDialog__footer'
            }
            footer={
                <>
                    {renderRadioButton()}

                    <Button
                        title={props.t('common:labels.close')}
                        onClick={closeActiveOverlay}
                        formButton
                        autoFocus
                    />
                </>
            }
        >
            <span className="descriptionDialog__content">
                <span className="descriptionDialog__label">
                    {props.t('templates:labels.description')}
                </span>

                <div className="descriptionDialog__description">
                    {stringToHtml(props.entity.description)}
                </div>
                {(owners ?? []).length > 0 ? (
                    <>
                        <span className="descriptionDialog__label">
                            {props.t('templates:labels.coaches')}
                        </span>
                        <div className="descriptionDialog__owners">
                            {renderCoaches()}
                        </div>
                    </>
                ) : null}
            </span>
        </Dialog>
    )
}

export default compose<Props, OwnProps>(
    withTranslation(['common', 'templates'])
)(DescriptionDialog)
