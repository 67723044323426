import { Button, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import React from 'react'

export interface StepIndicatorProps {
    step: number
    setStep: (index: number) => void
    numberOfSteps: number
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    stepContainer: {
        display: 'flex',
        flexDirection: 'row',
        margin: theme.spacing(0, 'auto')
    },
    step: {
        borderRadius: '50%',
        height: 10,
        width: 10,
        margin: theme.spacing(0.5),
        padding: 0,
        minWidth: 'auto',
        backgroundColor: theme.palette.action.disabled
    },
    activeButton: {
        backgroundColor: theme.palette.primary.main
    }
}))

export const StepIndicator: React.FC<StepIndicatorProps> = ({
    numberOfSteps,
    step,
    setStep
}) => {
    const classes = useStyles()

    return (
        <div className={classes.container}>
            <div className={classes.stepContainer}>
                {Array.from(Array(numberOfSteps), (_, i) => i).map((i) => (
                    <Button
                        type="button"
                        className={clsx(
                            classes.step,
                            step === i && classes.activeButton
                        )}
                        onClick={() => setStep(i)}
                        key={i}
                    />
                ))}
            </div>
        </div>
    )
}

export default StepIndicator
