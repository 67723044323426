import classNames from 'classnames'
import React, { LegacyRef } from 'react'

interface InputIconProps {
    onClick: () => void
    icon: React.ComponentType
}

const renderInputIcon = ({ icon: Icon, ...props }: InputIconProps) => (
    <button
        onClick={props.onClick}
        className="labeledInputField__icon imageButton"
        type="button"
    >
        <Icon />
    </button>
)

interface MessageLabelProps {
    message: string
    hasError: boolean
}

const renderMessageLabel = (props: MessageLabelProps) => (
    <span
        className={classNames('labeledInputField__message', {
            'labeledInputField__message--error': props.hasError,
            normal: !props.hasError
        })}
    >
        {props.message}
    </span>
)

interface OwnProps {
    label: string
    message?: string
    hasError?: boolean
    inputRef?: LegacyRef<HTMLInputElement>
    icon?: React.ComponentType
    onIconClick?: () => void
    placeholder?: string
}

export type LabeledInputFieldProps = OwnProps &
    React.HTMLProps<HTMLButtonElement>

const LabeledInputField = (props: LabeledInputFieldProps) => {
    const InputIcon = props.icon && renderInputIcon
    const MessageLabel = props.message && renderMessageLabel

    return (
        <div className="labeledInputField">
            <label className="labeledInputField__label" htmlFor={props.name}>
                {props.label}
            </label>

            <div className="labeledInputField__wrapper">
                <input
                    id={props.name}
                    name={props.name}
                    ref={props.inputRef}
                    onChange={props.onChange}
                    value={props.value}
                    type={props.type || 'text'}
                    className={classNames(
                        'labeledInputField__input',
                        { 'with-action-button': props.icon },
                        props.className
                    )}
                    tabIndex={props.tabIndex}
                    placeholder={props.placeholder}
                />

                {InputIcon && props.icon && (
                    <InputIcon onClick={props.onIconClick} icon={props.icon} />
                )}
            </div>

            {MessageLabel && (
                <MessageLabel
                    hasError={props.hasError}
                    message={props.message}
                />
            )}
        </div>
    )
}

export default LabeledInputField
