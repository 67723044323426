import { ChangePasswordDialogFormData } from '@app/form'
import { PersonDetails } from '@app/person'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import SecureInputField from 'components/input/secureInputField/SecureInputField'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import { useRequestPasswordChange } from 'helpers/accountHelpers'
import FormikHelpers from 'helpers/formikHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React, { createRef, useEffect, useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import * as Yup from 'yup'

interface OwnProps {
    person: PersonDetails
}

type Props = OwnProps & WithTranslation

const ChangePasswordDialog = (props: Props) => {
    const [passwordInfo, setPasswordInfo] = useState('')
    const [requestError, setRequestError] = useState(false)

    const inputRef: React.RefObject<HTMLInputElement> = createRef()

    const { t } = props

    const {
        formDirty,
        toggleFormToDirty,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [inputRef])

    const initialValues: ChangePasswordDialogFormData = {
        id: props.person.id,
        oldPassword: '',
        newPassword: ''
    }

    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required(
            props.t('error:validation.fieldRequired')
        ),
        newPassword: Yup.string().required(
            props.t('error:validation.fieldRequired')
        )
    })

    const { createNotification } = useGlobal()

    const requestPasswordChange = useRequestPasswordChange()
    const onSubmit = async (
        values: ChangePasswordDialogFormData,
        formikActions: FormikActions<ChangePasswordDialogFormData>
    ) => {
        const { error } = await requestPasswordChange(
            {
                oldPassword: values.oldPassword,
                newPassword: values.newPassword
            },
            props.person.id
        )

        if (error) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            const { message } = error.data
            const defaultMessage =
                error.data && error.data.length > 0
                    ? error.data[0].defaultMessage
                    : null

            if (message === 'user.passwords.does.not.match') {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.passwordMismatch')
                })
            } else if (
                defaultMessage &&
                defaultMessage === 'invalid.password'
            ) {
                setPasswordInfo(t('error:response.invalidPassword'))
                setRequestError(true)
            } else {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.changePasswordUnknown')
                })
            }
        } else {
            closeActiveOverlay()
        }
    }

    return (
        <Dialog
            title={t('people:changePassword.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnChange
            >
                {(formikProps) => {
                    const oldPasswordError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'oldPassword'
                    )

                    const newPasswordError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'newPassword'
                    )

                    return (
                        <Form className="dialog__form">
                            <div className="dialog__scrollArea">
                                <SecureInputField
                                    label={t('people:labels.oldPassword')}
                                    name="oldPassword"
                                    inputRef={inputRef}
                                    onChange={FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'oldPassword',
                                        formDirty,
                                        toggleFormToDirty
                                    )}
                                    value={formikProps.values.oldPassword}
                                    message={oldPasswordError}
                                    hasError={!!oldPasswordError}
                                    tabIndex={0}
                                />

                                <SecureInputField
                                    label={t('people:labels.newPassword')}
                                    name="newPassword"
                                    onChange={FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'newPassword',
                                        formDirty,
                                        toggleFormToDirty
                                    )}
                                    value={formikProps.values.newPassword}
                                    hasError={
                                        !!newPasswordError || requestError
                                    }
                                    message={newPasswordError || passwordInfo}
                                    tabIndex={0}
                                />
                            </div>

                            <div className="dialog__actions">
                                <Button
                                    title={t('common:labels.cancel')}
                                    onClick={closeActiveOverlay}
                                    formButton
                                />

                                <Button
                                    title={t('common:labels.change')}
                                    type="submit"
                                    loading={formikProps.isSubmitting}
                                    primary
                                    formButton
                                />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export default compose<Props, OwnProps>(
    withTranslation(['common', 'people', 'error'])
)(ChangePasswordDialog)
