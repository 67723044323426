import { ListItemRender, RouteParams } from '@app/common'
import { TemplateData } from '@app/template'
import { DropdownItem } from '@atlaskit/dropdown-menu'
import LabeledButton from 'components/button/labeled/LabeledButton'
import Worksheet from 'components/worksheet/Worksheet'
import { useFetchCurrentUser } from 'helpers/accountHelpers'
import { useFetchAllUserTemplates } from 'helpers/templateHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { match, Route, Switch } from 'react-router-dom'
import { openDialogWrapper } from 'utils/commonUtils'
import MatrixContentBrowser from './MatrixContentBrowser'

interface Props extends WithTranslation {
    match: match<RouteParams>
}

const MatrixWorksheet = (props: Props) => {
    const { currentUser } = useFetchCurrentUser()
    const userId = currentUser?.id

    const { userTemplateList, status } = useFetchAllUserTemplates(userId)
    const matrixList: Array<TemplateData> = userTemplateList ?? []

    const { createNotification, openDialog } = useGlobal()

    const openAssignDialog = () =>
        openDialogWrapper(
            null,
            {
                type: 'ASSIGN_TEMPLATE',
                payload: {
                    userId,
                    templates: []
                }
            },
            openDialog
        )

    const primaryActionButton = (
        <LabeledButton
            title={props.t('matrices:labels.createMatrix')}
            onClick={openAssignDialog}
        />
    )

    const listItems: Array<ListItemRender> = matrixList.map((matrix) => {
        const linkedMatrix =
            matrix.linkedTemplateId &&
            matrixList.find(
                (matrixListItem) =>
                    matrix && matrixListItem.id === matrix.linkedTemplateId
            )
        const isLinked =
            matrixList.filter(
                (matrixListItem) =>
                    matrixListItem.linkedTemplateId === matrix.id
            ).length > 0

        return {
            id: matrix.id,
            title: matrix.name,
            secondaryTitle: linkedMatrix
                ? props.t('matrices:contentBrowser.linkedMatrix', {
                      linkedMatrixName: linkedMatrix.name
                  })
                : '',
            subtitle: '',
            labels: matrix.labels.map((label) => label.name),
            dropdownMenuContent: (
                <DropdownItem
                    id="remove"
                    onClick={(event: MouseEvent) =>
                        isLinked
                            ? createNotification({
                                  type: 'Error',
                                  description: props.t(
                                      'matrices:removeMatrix.error'
                                  )
                              })
                            : openDialogWrapper(
                                  event,
                                  {
                                      type: 'REMOVE_MATRIX',
                                      payload: {
                                          templateId: matrix.id,
                                          templateName: matrix.name,
                                          userId
                                      }
                                  },
                                  openDialog
                              )
                    }
                >
                    Remove
                </DropdownItem>
            )
        }
    })

    return (
        <Worksheet
            match={props.match}
            listItems={listItems}
            listError={status === 'rejected'}
            listLoading={status === 'pending'}
            primaryActionButton={primaryActionButton}
            type="matrices"
        >
            <Switch>
                <Route
                    exact
                    path={`${props.match.path}/:id`}
                    render={(routerProps) => (
                        <MatrixContentBrowser
                            {...routerProps}
                            openAssignDialog={openAssignDialog}
                            key={routerProps.match.params.id}
                            openDialog={openDialog}
                            userId={userId}
                        />
                    )}
                />

                <Route
                    exact
                    path={props.match.path}
                    render={(routerProps) => (
                        <MatrixContentBrowser
                            {...routerProps}
                            openAssignDialog={openAssignDialog}
                            openDialog={openDialog}
                            userId={userId}
                        />
                    )}
                />
            </Switch>
        </Worksheet>
    )
}

export default withTranslation('matrices')(MatrixWorksheet)
