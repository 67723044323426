import { GlobalContext } from 'contexts/GlobalContext'
import { useContext } from 'react'

export function useGlobal() {
    const context = useContext(GlobalContext)

    if (!context) {
        throw new Error(
            "'useGlobal' hook must be used within a 'GlobalContext'"
        )
    }

    return context
}
