import React from 'react'
import DocumentTitle from 'react-document-title'

const withTitle = (title: string) => (
    WrappedComponent: React.ComponentType
) => (props) => {
    return (
        <DocumentTitle title={`${title} - Competency Inventory`}>
            <WrappedComponent {...props} />
        </DocumentTitle>
    )
}

export default withTitle
