import { DialogConfig } from '@app/dialog'
import { AccountStatus, PersonDetails } from '@app/person'
import { DropdownItem } from '@atlaskit/dropdown-menu'
import i18next from 'i18next'
import React from 'react'
import { openDialogWrapper } from 'utils/commonUtils'

const peopleDropdownMenuContent: (
    status: AccountStatus,
    openDialog: (config: DialogConfig) => void,
    person: PersonDetails
) => React.ReactNode = (status, openDialog, person) => {
    switch (status) {
        case 'ACTIVATED':
            return (
                <>
                    <DropdownItem
                        id="edit"
                        onClick={(event: MouseEvent) =>
                            openDialogWrapper(
                                event,
                                {
                                    type: 'EDIT_USER',
                                    payload: person
                                },
                                openDialog
                            )
                        }
                    >
                        {i18next.t('people:labels.edit')}
                    </DropdownItem>

                    <DropdownItem
                        id="delete"
                        onClick={(event: MouseEvent) =>
                            openDialogWrapper(
                                event,
                                {
                                    type: 'DELETE_USER',
                                    payload: person
                                },
                                openDialog
                            )
                        }
                    >
                        {i18next.t('people:labels.delete')}
                    </DropdownItem>
                </>
            )
        case 'PENDING':
            return (
                <>
                    <DropdownItem
                        id="resend"
                        onClick={(event: MouseEvent) =>
                            openDialogWrapper(
                                event,
                                {
                                    type: 'RESEND_INVITATION',
                                    payload: person
                                },
                                openDialog
                            )
                        }
                    >
                        {i18next.t('people:labels.resendInvitation')}
                    </DropdownItem>
                    <DropdownItem
                        id="cancel"
                        onClick={(event: MouseEvent) =>
                            openDialogWrapper(
                                event,
                                {
                                    type: 'CANCEL_INVITATION',
                                    payload: person
                                },
                                openDialog
                            )
                        }
                    >
                        {i18next.t('people:labels.cancelInvitation')}
                    </DropdownItem>
                    <DropdownItem
                        id="delete"
                        onClick={(event: MouseEvent) =>
                            openDialogWrapper(
                                event,
                                {
                                    type: 'DELETE_USER',
                                    payload: person
                                },
                                openDialog
                            )
                        }
                    >
                        {i18next.t('people:labels.delete')}
                    </DropdownItem>
                </>
            )
        case 'CANCELLED':
        case 'EXPIRED':
            return (
                <>
                    <DropdownItem
                        id="resend"
                        onClick={(event: MouseEvent) =>
                            openDialogWrapper(
                                event,
                                {
                                    type: 'RESEND_INVITATION',
                                    payload: person
                                },
                                openDialog
                            )
                        }
                    >
                        Resend invitation
                    </DropdownItem>
                    <DropdownItem
                        id="delete"
                        onClick={(event: MouseEvent) =>
                            openDialogWrapper(
                                event,
                                {
                                    type: 'DELETE_USER',
                                    payload: person
                                },
                                openDialog
                            )
                        }
                    >
                        {i18next.t('people:labels.delete')}
                    </DropdownItem>
                </>
            )

        default:
            return <div />
    }
}

export default peopleDropdownMenuContent
