import { FilterListProps } from '@app/common'
import React from 'react'
import { removeDiacritics } from 'utils/commonUtils'

const withFilterListItems = <T extends FilterListProps>(
    BaseComponent: React.ComponentType<T>
) => (props: T) => {
    const transformedList = props.listItems.filter((item) => {
        const normalizedTitle = item.title
            ? removeDiacritics(item.title.toLowerCase())
            : ''
        const normalizedSubtitle = item.subtitle
            ? removeDiacritics(item.subtitle.toLowerCase())
            : ''
        const normalizedSecondaryTitle =
            item.secondaryTitle &&
            removeDiacritics(item.secondaryTitle.toLowerCase())
        const normalizedSearchKey = removeDiacritics(
            props.searchKey.toLowerCase()
        )
        const normalizedLabels =
            item.labels &&
            item.labels.map((label) => removeDiacritics(label.toLowerCase()))

        return (
            normalizedTitle.indexOf(normalizedSearchKey) !== -1 ||
            normalizedSubtitle.indexOf(normalizedSearchKey) !== -1 ||
            (normalizedSecondaryTitle &&
                normalizedSecondaryTitle.indexOf(normalizedSearchKey) !== -1) ||
            (normalizedLabels &&
                normalizedLabels.find(
                    (label) => label.indexOf(normalizedSearchKey) !== -1
                )) ||
            props.searchKey === ''
        )
    })

    return <BaseComponent {...props} listItems={transformedList} />
}

export default withFilterListItems
