import Button from 'components/button/base/Button'
import InfoCard from 'components/card/InfoCard'
import withTitle from 'hoc/withTitle'
import React, { useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { compose } from 'recompose'
import ResetPasswordCard from './ResetPasswordCard'

export type ResetPasswordCardProps = WithTranslation & RouteComponentProps

interface State {
    reset: boolean
    switchingCard: boolean
}

const ResetPassword = (props: ResetPasswordCardProps) => {
    const [state, setState] = useState<State>({
        reset: false,
        switchingCard: false
    })

    const openLogin = () => props.history.push('/login')

    const switchCard = () => {
        setState({ ...state, switchingCard: true })

        setTimeout(() => setState({ ...state, reset: true }), 500)
    }

    const { reset, switchingCard } = state
    const { t } = props

    return (
        <div className="colorful-background">
            {reset ? (
                <InfoCard
                    title={t('card.resetPassword.title')}
                    description={t('card.resetPassword.description')}
                    cardAction={
                        <Button
                            className="large"
                            onClick={openLogin}
                            title={props.t(
                                'card.resetPassword.action.secondary'
                            )}
                            primary
                            triggerEnter
                        />
                    }
                    className="fade-in"
                />
            ) : (
                <ResetPasswordCard
                    postAction={switchCard}
                    className={switchingCard ? 'fade-out' : ''}
                />
            )}
        </div>
    )
}

export default compose<ResetPasswordCardProps, unknown>(
    withTitle('Reset password'),
    withTranslation('common')
)(ResetPassword)
