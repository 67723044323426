import { ComponentStatus } from '@app/common'
import Button from 'components/button/base/Button'
import { useCheckServices } from 'helpers/apiHelpers'
import withTitle from 'hoc/withTitle'
import React, { useEffect, useState } from 'react'
import MaintenanceCard from './MaintenanceCard'

const Maintenance: React.FC = () => {
    const [status, setStatus] = useState<ComponentStatus>()

    const { status: serviceStatus } = useCheckServices()

    useEffect(() => {
        if (status !== serviceStatus) {
            setStatus(serviceStatus)
        }
    }, [serviceStatus, status])

    const checkService = () => setStatus('pending')

    const cardAction = (
        <Button
            onClick={checkService}
            className="normal"
            primary
            title="Refresh"
            triggerEnter
            loading={status === 'pending'}
        />
    )

    return (
        <div className="colorful-background">
            <MaintenanceCard
                cardAction={cardAction}
                hasError={status === 'rejected'}
            />
        </div>
    )
}

export default withTitle('Maintenance')(Maintenance)
