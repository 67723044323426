import { useTranslation } from 'react-i18next'
import { CreateSearchTermDialogFormData } from '@app/form'
import { FormikHelpers as FormikActions } from 'formik/dist/types'
import React from 'react'
import {
    getTypeOptions,
    requestAllSearchTermRevalidation,
    useRequestSearchTermCreate
} from 'helpers/searchTermHelpers'
import { TypeSelectInputValue } from '@app/searchTerm'
import { Dialog } from 'components/dialog/base/Dialog'
import SearchTermForm from 'components/searchTermForm/SearchTermForm'
import { useGlobal } from 'hooks/useGlobal'

export const CreateSearchTermDialog: React.FC = () => {
    const { t } = useTranslation(['common', 'error'])
    const typeOptions: Array<TypeSelectInputValue> = getTypeOptions(t)
    const {
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const requestSearchTermCreate = useRequestSearchTermCreate()

    const initialValues: CreateSearchTermDialogFormData = {
        name: '',
        type: typeOptions[0]
    }

    const onSubmit = async (
        values: CreateSearchTermDialogFormData,
        formikActions: FormikActions<CreateSearchTermDialogFormData>
    ) => {
        const { error, status } = await requestSearchTermCreate({
            name: values.name.trim(),
            type: values.type.value
        })

        if (status === 'resolved') {
            await requestAllSearchTermRevalidation()
            closeActiveOverlay()
        } else if (status === 'rejected') {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            if (error.data.message === 'searchterm.already.exists') {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.duplicateSearchTermName')
                })
            } else {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.createSearchTermUnknown', {
                        searchTermName: values.name
                    })
                })
            }
        }
    }

    return (
        <Dialog
            title={t('searchTerms:createSearchTerm.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
        >
            <SearchTermForm initialValues={initialValues} onSubmit={onSubmit} />
        </Dialog>
    )
}

export default CreateSearchTermDialog
