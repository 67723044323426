import { Named, SearchBarOption } from '@app/common'
import {
    CircularProgress,
    InputAdornment,
    Paper,
    Popper,
    StandardTextFieldProps,
    TextField
} from '@material-ui/core'
import { useAutocomplete } from '@material-ui/lab'
import React from 'react'
import { useTranslation } from 'react-i18next'
import SearchIcon from '../../assets/images/search.svg'
import { useAutocompleteStyles } from './Autocomplete.styles'

type ChangeEventHandler = (
    event: React.ChangeEvent<unknown>,
    value: any
) => void

interface AutocompleteProps extends Omit<StandardTextFieldProps, 'onChange'> {
    id: string
    options: Array<SearchBarOption>
    error: boolean
    loading: boolean
    onChange: ChangeEventHandler
    onInputChange: ChangeEventHandler
}

const Autocomplete = ({
    options,
    onInputChange,
    onChange,
    id,
    error,
    loading,
    InputProps,
    InputLabelProps,
    inputProps,
    ...props
}: AutocompleteProps) => {
    const classes = useAutocompleteStyles()
    const { t } = useTranslation(['search'])

    const {
        anchorEl,
        setAnchorEl,
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        groupedOptions,
        popupOpen,
        getInputLabelProps,
        inputValue
    } = useAutocomplete<Named, boolean, boolean, boolean>({
        componentName: 'Autocomplete',
        id,
        freeSolo: true,
        options,
        onInputChange,
        onChange,
        getOptionLabel: (option: { name: string }) => option.name
    })

    const helperText = ''

    return (
        <div className={classes.root} {...getRootProps()}>
            <TextField
                error={error}
                className={classes.searchInput}
                helperText={helperText}
                fullWidth
                InputLabelProps={{
                    ...InputLabelProps,
                    ...getInputLabelProps()
                }}
                placeholder={t('search:labels.search')}
                variant="outlined"
                InputProps={{
                    ...InputProps,
                    className: classes.inputRoot,
                    ref: setAnchorEl,
                    startAdornment: (
                        <InputAdornment position="end">
                            <img src={SearchIcon} alt="Search Icon" />
                        </InputAdornment>
                    ),
                    endAdornment: loading ? (
                        <InputAdornment position="end">
                            <CircularProgress size={24} />
                        </InputAdornment>
                    ) : null,
                    inputProps: {
                        ...inputProps,
                        ...getInputProps(),
                        'aria-label': props['aria-label']
                    }
                }}
                {...props}
            />

            {popupOpen && anchorEl ? (
                <Popper
                    className={classes.popper}
                    style={{
                        width: anchorEl ? anchorEl.clientWidth : null
                    }}
                    role="presentation"
                    anchorEl={anchorEl}
                    open
                >
                    <Paper className={classes.paper}>
                        {loading && groupedOptions.length === 0 ? (
                            <ul
                                className={classes.listBox}
                                {...getListboxProps()}
                            >
                                <li className={classes.loading}>
                                    {t('search:labels.loading')}
                                </li>
                            </ul>
                        ) : null}

                        {!loading &&
                        groupedOptions.length === 0 &&
                        inputValue !== '' ? (
                            <ul
                                className={classes.listBox}
                                {...getListboxProps()}
                            >
                                <li className={classes.loading}>
                                    {t('search:labels.noOptions')}
                                </li>
                            </ul>
                        ) : null}

                        {error && (
                            <ul
                                className={classes.listBox}
                                {...getListboxProps()}
                            >
                                <li className={classes.option}>{error}</li>
                            </ul>
                        )}

                        {!error && groupedOptions.length > 0 ? (
                            <ul
                                className={classes.listBox}
                                {...getListboxProps()}
                            >
                                {groupedOptions.map((option, index) => (
                                    <li
                                        key={option.id}
                                        className={classes.option}
                                        {...getOptionProps({ option, index })}
                                    >
                                        {option.name}
                                    </li>
                                ))}
                            </ul>
                        ) : null}
                    </Paper>
                </Popper>
            ) : null}
        </div>
    )
}

export default Autocomplete
