import classNames from 'classnames'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { compose } from 'recompose'

export interface OwnProps {
    type:
        | 'overallFillingRate'
        | 'assignedMatrices'
        | 'matricesMeetingExpectations'
    description: string
}

type Props = OwnProps & WithTranslation

const StatisticsCard = (props: Props) => {
    const { t, type, description } = props
    const title = t(`overview:statistics.${type}`)

    return (
        <div className={classNames('statisticsCard', type)}>
            <div className={classNames('statisticsCard__art', type)}>
                <div className="statisticsCard__title">{title}</div>
                <div className="statisticsCard__description">{description}</div>
            </div>
        </div>
    )
}

export default compose<Props, OwnProps>(withTranslation('overview'))(
    StatisticsCard
)
