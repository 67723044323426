import { SearchResult } from '@app/common'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlaceholder from 'react-placeholder'
import Placeholder from '../../../components/placeholder/Placeholder'
import { SearchResultSection } from '../SearchResultSection'

export interface SearchResultContentProps {
    searchResults: Array<SearchResult>
    status: string
    error: Error
}

export const SearchResultContent: React.FC<SearchResultContentProps> = (
    props
) => {
    const { searchResults, status, error } = props
    const { t } = useTranslation(['search'])

    if (
        status === 'idle' ||
        (status === 'resolved' && searchResults && searchResults.length === 0)
    ) {
        return (
            <div className="worksheetContentBrowser__emptyState searchResultContent__emptyState">
                <Placeholder
                    icon={<CopyIcon label="" size="xlarge" />}
                    title={t('search:contentBrowser.emptyState.content.title')}
                    subtitle={t(
                        'search:contentBrowser.emptyState.content.subtitle'
                    )}
                />
            </div>
        )
    }

    if (status === 'pending') {
        return (
            <div role="progressbar">
                <ReactPlaceholder
                    className="contentBrowserListItem__reactPlaceholder"
                    showLoadingAnimation
                    type="text"
                    rows={18}
                    ready={false}
                >
                    <></>
                </ReactPlaceholder>
            </div>
        )
    }

    if (status === 'rejected' && error) {
        throw error
    }

    if (status === 'resolved' && searchResults && searchResults.length > 0) {
        return (
            <div className="worksheetContentBrowser__scrollArea">
                <div className="worksheetContentBrowser__innerContent searchResultContent__innerContent modern-scrollbar">
                    {props.searchResults.map((searchResult) => (
                        <SearchResultSection
                            searchResult={searchResult}
                            key={
                                searchResult.group.id || searchResult.group.name
                            }
                        />
                    ))}
                </div>
            </div>
        )
    }

    return null
}

export default SearchResultContent
