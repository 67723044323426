import { PlaceholderData, RouteParams, SelectorListItem } from '@app/common'
import { DialogConfig } from '@app/dialog'
import { MatrixDetails } from '@app/matrix'
import { PersonDetails } from '@app/person'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import ImageBorderIcon from '@atlaskit/icon/glyph/image-border'
import InviteTeamIcon from '@atlaskit/icon/glyph/invite-team'
import PersonIcon from '@atlaskit/icon/glyph/person'
import DropdownSelector from 'components/dropdownSelector/DropdownSelector'
import Placeholder from 'components/placeholder/Placeholder'
import { useFetchPersonById } from 'helpers/peopleHelpers'
import withCondition from 'hoc/withCondition'
import withMaybe from 'hoc/withMaybe'
import { useAuthentication } from 'hooks/useAuthentication'
import React, { useEffect, useMemo, useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import ReactPlaceholder from 'react-placeholder'
import 'react-placeholder/lib/reactPlaceholder.css'
import { match } from 'react-router'
import PeopleSkillSetListItem from './PeopleSkillSetListItem'

interface Props extends WithTranslation {
    openDialog: (dialogData: DialogConfig) => void
    match: match<RouteParams>
}

// TDOD REFACTOR WITHCONDITION HOC
const renderContentBrowserList = (currentPerson: any) =>
    currentPerson.matrix.childSkillSets
        .sort((a, b) => a.position - b.position)
        .map((skillSet) => (
            <PeopleSkillSetListItem
                key={skillSet.skillSetId}
                skillSet={skillSet}
            />
        ))

const PeopleContentBrowser: React.FC<Props> = (props: Props) => {
    const { isCurrentUserAdmin } = useAuthentication()

    const registrationData: PlaceholderData = {
        actionText: isCurrentUserAdmin()
            ? props.t('people:contentBrowser.registrationData.actionText')
            : '',
        icon: <PersonIcon label="" size="xlarge" />,
        subtitle: props.t('people:contentBrowser.registrationData.subtitle'),
        title: props.t('people:contentBrowser.registrationData.title')
    }

    const shouldDisplayEmptyState =
        !props.match.params || !props.match.params.id

    const { person: currentPerson, error, status } = useFetchPersonById(
        props.match.params?.id
    )

    const shouldDisplayRegistration =
        !!currentPerson && currentPerson.status !== 'ACTIVATED'

    const shouldDisplayIdError = !!error && error.status === 404

    const emptyPersonStateData: PlaceholderData = {
        icon: <ImageBorderIcon label="" size="xlarge" />,
        title: props.t('people:contentBrowser.emptyState.person.title'),
        subtitle: props.t('people:contentBrowser.emptyState.person.subtitle')
    }

    const emptyStateData: PlaceholderData = {
        action: () => props.openDialog({ type: 'INVITE_USER' }),
        actionText: props.t(
            'people:contentBrowser.emptyState.content.actionText'
        ),
        icon: <InviteTeamIcon label="" size="xlarge" />,
        title: props.t('people:contentBrowser.emptyState.content.title'),
        subtitle: props.t('people:contentBrowser.emptyState.content.subtitle')
    }

    const idErrorData: PlaceholderData = {
        icon: <CrossIcon label="" size="xlarge" />,
        title: props.t('people:contentBrowser.idErrorData.title'),
        subtitle: props.t('people:contentBrowser.idErrorData.subtitle')
    }

    const [selectedTemplateId, setSelectedTemplateId] = useState<string>()
    const [selectedMatrix, setSelectedMatrix] = useState<MatrixDetails>()
    const { t } = props

    const selectorListItems: Array<SelectorListItem> = useMemo(
        () =>
            currentPerson?.matrices.map((template) => {
                const linkedMatrix = currentPerson.matrices.find(
                    (matrixListItem) =>
                        matrixListItem.templateId === template.linkedTemplateId
                )
                return {
                    id: template.templateId,
                    title: `${template.name}${
                        linkedMatrix
                            ? t('matrices:contentBrowser.linkedMatrix', {
                                  linkedMatrixName: linkedMatrix.name
                              })
                            : ''
                    }`
                }
            }) || [],
        [currentPerson, t]
    )

    useEffect(() => {
        if (
            !selectedTemplateId &&
            currentPerson &&
            currentPerson.matrices &&
            currentPerson.matrices.length
        ) {
            setSelectedTemplateId(currentPerson.matrices[0].templateId)
        }

        if (currentPerson && selectedTemplateId) {
            const currentMatrix = currentPerson.matrices.find(
                (matrix) => matrix.templateId === selectedTemplateId
            )

            setSelectedMatrix(currentMatrix)
        }
    }, [currentPerson, selectedTemplateId])

    const renderContentBrowserListPlaceholder = () => (
        <div className="worksheetContentBrowser__emptyList topMargin">
            {props.t('people:contentBrowser.emptyState.template')}
        </div>
    )

    const openDialogWithPerson = (person: PersonDetails) => () =>
        props.openDialog({
            type: 'RESEND_INVITATION',
            payload: person
        })

    const selectMatrix: (id: string) => void = (id) => setSelectedTemplateId(id)

    const hasChildren = (matrix?: MatrixDetails) =>
        matrix && matrix.childSkillSets.length > 0

    const SkillList = withCondition(
        hasChildren(selectedMatrix),
        renderContentBrowserList,
        withMaybe(status === 'resolved')(renderContentBrowserListPlaceholder)
    )

    const linkedMatrix =
        selectedMatrix &&
        currentPerson &&
        currentPerson.matrices.find(
            (matrixListItem) =>
                matrixListItem.templateId === selectedMatrix.linkedTemplateId
        )

    const LinkedSkillList = withCondition(
        hasChildren(linkedMatrix),
        renderContentBrowserList,
        withMaybe(status === 'resolved')(renderContentBrowserListPlaceholder)
    )

    let content: React.ReactNode

    if (currentPerson && shouldDisplayRegistration) {
        content = (
            <div className="worksheetContentBrowser__emptyState">
                <Placeholder
                    action={openDialogWithPerson(currentPerson)}
                    actionText={registrationData.actionText}
                    icon={registrationData.icon}
                    title={registrationData.title}
                    subtitle={registrationData.subtitle}
                />
            </div>
        )
    } else if (shouldDisplayIdError) {
        content = (
            <div className="worksheetContentBrowser__emptyState">
                <Placeholder
                    icon={idErrorData.icon}
                    title={idErrorData.title}
                    subtitle={idErrorData.subtitle}
                />
            </div>
        )
    } else if (shouldDisplayEmptyState) {
        content = (
            <div className="worksheetContentBrowser__emptyState">
                <Placeholder
                    action={emptyStateData.action}
                    actionText={emptyStateData.actionText}
                    icon={emptyStateData.icon}
                    title={emptyStateData.title}
                    subtitle={emptyStateData.subtitle}
                />
            </div>
        )
    } else {
        content = (
            <>
                <div className="worksheetContentBrowser__header">
                    <ReactPlaceholder
                        className="placeholder"
                        showLoadingAnimation
                        type="textRow"
                        rows={1}
                        ready={status === 'resolved'}
                    >
                        <div className="worksheetContentBrowser__headerTitle text-ellipsis">
                            {currentPerson && currentPerson.name}
                        </div>
                    </ReactPlaceholder>
                    <ReactPlaceholder
                        className="placeholder"
                        showLoadingAnimation
                        type="textRow"
                        rows={1}
                        ready={status === 'resolved'}
                    >
                        <div className="worksheetContentBrowser__actions">
                            <DropdownSelector
                                activeListItemId={selectedTemplateId}
                                listItems={selectorListItems}
                                selectAction={selectMatrix}
                            />
                        </div>
                    </ReactPlaceholder>
                </div>

                {currentPerson && !currentPerson.matrices.length ? (
                    <div className="worksheetContentBrowser__emptyState">
                        <Placeholder
                            icon={emptyPersonStateData.icon}
                            title={emptyPersonStateData.title}
                            subtitle={emptyPersonStateData.subtitle}
                        />
                    </div>
                ) : (
                    <ReactPlaceholder
                        className="contentBrowserListItem__reactPlaceholder"
                        showLoadingAnimation
                        type="text"
                        rows={18}
                        ready={status === 'resolved'}
                    >
                        <div className="worksheetContentBrowser__scrollArea">
                            <div className="worksheetContentBrowser__innerContent modern-scrollbar">
                                <div className="worksheetContentBrowser__title">
                                    {selectedMatrix && selectedMatrix.name}
                                </div>
                                <div className="worksheetContentBrowser__section">
                                    <SkillList matrix={selectedMatrix} />
                                </div>
                                {linkedMatrix && (
                                    <>
                                        <div className="worksheetContentBrowser__title">
                                            {linkedMatrix.name}
                                        </div>
                                        <div className="worksheetContentBrowser__section">
                                            <LinkedSkillList
                                                matrix={linkedMatrix}
                                            />
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </ReactPlaceholder>
                )}
                <div />
            </>
        )
    }

    return <div className="worksheetContentBrowser__content">{content}</div>
}

export default withTranslation(['people', 'matrices'])(PeopleContentBrowser)
