import { PlaceholderConfig, RouteParams } from '@app/common'
import { DialogConfig } from '@app/dialog'
import { MatrixSkillSet } from '@app/matrix'
import AddItemIcon from '@atlaskit/icon/glyph/add-item'
import CommentIcon from '@atlaskit/icon/glyph/comment'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import classNames from 'classnames'
import LabeledButton from 'components/button/labeled/LabeledButton'
import Placeholder from 'components/placeholder/Placeholder'
import {
    filterSkillsByExistence,
    useFetchSingleMatrix
} from 'helpers/matrixHelpers'
import { orderArrayListByPosition } from 'helpers/templateHelpers'
import withMaybe from 'hoc/withMaybe'
import { useAuthentication } from 'hooks/useAuthentication'
import React, { useMemo } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import ReactPlaceholder from 'react-placeholder'
import 'react-placeholder/lib/reactPlaceholder.css'
import { match } from 'react-router'
import MatrixSkillSetListItem from './MatrixSkillSetListItem'

interface DispatchProps {
    openAssignDialog: () => void
}

interface OwnProps {
    match: match<RouteParams>
    userId: string
    openDialog: (dialogData: DialogConfig) => void
}

type Props = OwnProps & DispatchProps & WithTranslation

const renderContentBrowserTitle = (props: { title: string }) => (
    <div className="worksheetContentBrowser__title">{props.title}</div>
)

const MatrixContentBrowser = (props: Props) => {
    const { t } = props

    const { getCurrentUserRoles } = useAuthentication()

    const currentUserRoles = getCurrentUserRoles()

    const shouldDisplayEmptyState =
        !props.match.params || !props.match.params.id

    const { matrix, status } = useFetchSingleMatrix(
        props.userId,
        props.match.params.id
    )

    const { matrix: linkedMatrix } = useFetchSingleMatrix(
        props.userId,
        matrix?.linkedTemplateId
    )

    const emptyStateData: PlaceholderConfig = {
        icon: <AddItemIcon label="" size="xlarge" />,
        title: t('matrices:contentBrowser.emptyState.content.title'),
        subtitle: t('matrices:contentBrowser.emptyState.content.subtitle')
    }

    const idErrorData: PlaceholderConfig = {
        icon: <CrossIcon label="" size="xlarge" />,
        title: t('matrices:contentBrowser.idErrorData.title'),
        subtitle: t('matrices:contentBrowser.idErrorData.subtitle')
    }

    const skillSetList = useMemo(() => {
        if (!matrix) {
            return []
        }
        return orderArrayListByPosition<MatrixSkillSet>(
            filterSkillsByExistence(matrix.childSkillSets, currentUserRoles)
        )
    }, [matrix, currentUserRoles])

    const linkedSkillSetList = useMemo(() => {
        if (!matrix || !matrix.linkedTemplateId || !linkedMatrix) {
            return []
        }
        return orderArrayListByPosition<MatrixSkillSet>(
            filterSkillsByExistence(
                linkedMatrix.childSkillSets,
                currentUserRoles
            )
        )
    }, [linkedMatrix, matrix, currentUserRoles])

    const MatrixTitle = withMaybe<{ title: string }>(
        !!matrix && !!linkedMatrix
    )(renderContentBrowserTitle)

    const LinkedMatrixTitle = withMaybe<{ title: string }>(!!linkedMatrix)(
        renderContentBrowserTitle
    )

    let content: React.ReactFragment
    if (shouldDisplayEmptyState) {
        content = (
            <div className="worksheetContentBrowser__emptyState">
                <Placeholder
                    action={props.openAssignDialog}
                    actionText={t('matrices:assignTemplate.actionText')}
                    icon={emptyStateData.icon}
                    title={emptyStateData.title}
                    subtitle={emptyStateData.subtitle}
                />
            </div>
        )
    } else if (status === 'rejected') {
        content = (
            <div className="worksheetContentBrowser__emptyState">
                <Placeholder
                    icon={idErrorData.icon}
                    title={idErrorData.title}
                    subtitle={idErrorData.subtitle}
                />
            </div>
        )
    } else {
        const currentTemplateContent =
            skillSetList && skillSetList.length > 0 ? (
                <div className="worksheetContentBrowser__section">
                    {skillSetList.map((skillSet) => (
                        <MatrixSkillSetListItem
                            key={skillSet.skillSetId}
                            skillSet={skillSet}
                            userId={props.userId}
                        />
                    ))}
                </div>
            ) : (
                <div
                    className={classNames(
                        'worksheetContentBrowser__emptyList',
                        {
                            topMargin: !linkedMatrix
                        }
                    )}
                >
                    {t(
                        'matrices:contentBrowser.currentTemplateContent.emptyState'
                    )}
                </div>
            )
        const linkedTemplateContent =
            linkedSkillSetList && linkedSkillSetList.length > 0 ? (
                <div className="worksheetContentBrowser__section">
                    {linkedSkillSetList.map((skillSet) => (
                        <MatrixSkillSetListItem
                            key={skillSet.skillSetId}
                            skillSet={skillSet}
                            userId={props.userId}
                        />
                    ))}
                </div>
            ) : (
                <div className="worksheetContentBrowser__emptyList">
                    {t(
                        'matrices:contentBrowser.linkedTemplateContent.emptyState'
                    )}
                </div>
            )

        content = (
            <>
                <div className="worksheetContentBrowser__header">
                    <ReactPlaceholder
                        className="placeholder"
                        showLoadingAnimation
                        type="textRow"
                        rows={1}
                        ready={status === 'resolved'}
                    >
                        <div className="worksheetContentBrowser__headerTitle text-ellipsis">
                            {matrix !== null &&
                                matrix !== undefined &&
                                matrix.name}
                            {linkedMatrix !== null &&
                                linkedMatrix !== undefined &&
                                t('matrices:contentBrowser.linkedMatrix', {
                                    linkedMatrixName: linkedMatrix.name
                                })}
                        </div>
                    </ReactPlaceholder>

                    <div className="worksheetContentBrowser__actions">
                        <LabeledButton
                            icon={<CommentIcon label="" />}
                            title={t('matrices:labels.leaveFeedback')}
                            onClick={() =>
                                props.openDialog({
                                    type: 'LEAVE_FEEDBACK',
                                    payload: {
                                        feedbackTargetId: matrix.templateId,
                                        feedbackTargetType: 'TEMPLATE',
                                        feedbackTargetName: matrix.name
                                    }
                                })
                            }
                        />
                    </div>
                </div>

                <ReactPlaceholder
                    className="contentBrowserListItem__reactPlaceholder"
                    showLoadingAnimation
                    type="text"
                    rows={18}
                    ready={status === 'resolved'}
                >
                    <div className="worksheetContentBrowser__scrollArea">
                        <div className="worksheetContentBrowser__innerContent modern-scrollbar">
                            <MatrixTitle title={matrix && matrix.name} />
                            {currentTemplateContent}

                            {linkedMatrix && (
                                <>
                                    <LinkedMatrixTitle
                                        title={linkedMatrix.name}
                                    />
                                    {linkedTemplateContent}
                                </>
                            )}
                        </div>
                    </div>
                </ReactPlaceholder>
            </>
        )
    }

    return <div className="worksheetContentBrowser__content">{content}</div>
}

export default withTranslation('matrices')(MatrixContentBrowser)
