import { ListItem } from '@app/common'
import { CreateSkillSetDialogFormData } from '@app/form'
import EditorAddIcon from '@atlaskit/icon/glyph/editor/add'
import MaterialPopover from '@material-ui/core/Popover'
import classNames from 'classnames'
import Badge from 'components/badge/Badge'
import LabeledBadgeList from 'components/badge/labeledBadgeList/LabeledBadgeList'
import ListFilterBar from 'components/listFilter/ListFilterBar'
import { FormikProps } from 'formik'
import FormikHelpers from 'helpers/formikHelpers'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'

interface Props extends WithTranslation {
    activePeopleList?: Array<ListItem>
    hasError: boolean
    formikProps: FormikProps<CreateSkillSetDialogFormData>
    setFormDirty: () => void
    isFormDirty: boolean
}

type ReactMouseEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>

type ReactInputEvent = React.ChangeEvent<HTMLInputElement>

const OwnerPopover = ({
    activePeopleList,
    hasError,
    formikProps,
    isFormDirty,
    setFormDirty,
    t
}: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        undefined
    )

    const [searchKey, setSearchKey] = React.useState<string>('')

    const handleSearchChange = (event: ReactInputEvent) => {
        setSearchKey(event.currentTarget.value)
    }

    const onClick = (event: ReactMouseEvent) => {
        event.stopPropagation()

        setAnchorEl(event.currentTarget)
    }

    const crossIcon = (
        <EditorAddIcon label="" size="large" primaryColor="#1a1a1a" />
    )

    const onClose = (event: ReactMouseEvent) => {
        event.stopPropagation()

        setAnchorEl(undefined)
    }

    const onPeopleSave = (id: string, event: ReactMouseEvent) => {
        onClose(event)
        setTimeout(
            () =>
                FormikHelpers.setDirtyForm(
                    formikProps,
                    'ownerUserIds',
                    isFormDirty,
                    setFormDirty
                )(formikProps.values.ownerUserIds.concat(id)),
            200
        )

        setSearchKey('')
    }

    const open = Boolean(anchorEl)
    const id = open ? 'ownerPopover' : undefined

    return (
        <div className={classNames('badgeList__container', { open })}>
            <Badge
                title=""
                size="md"
                className="badgeList__defaultBadge"
                icon={crossIcon}
                onClick={onClick}
            />

            <MaterialPopover
                className={classNames('ownerPopover__body', {
                    'ownerPopover__body--error': activePeopleList.length === 0
                })}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                onClick={(event) => event.stopPropagation()}
            >
                {activePeopleList.length !== 0 && (
                    <div className="ownerPopover__header">
                        <ListFilterBar
                            isPopover
                            handleChange={handleSearchChange}
                            placeholder={t('common:popover.owner.search')}
                            searchKey={searchKey}
                            autoFocus
                        />
                    </div>
                )}

                <div
                    className={classNames(
                        'ownerPopover__menu modern-scrollbar',
                        {
                            'ownerPopover__menu--error':
                                activePeopleList.length === 0
                        }
                    )}
                >
                    <LabeledBadgeList
                        isEmpty={activePeopleList.length === 0}
                        listItems={activePeopleList}
                        withSeparator
                        searchKey={searchKey}
                        onClick={onPeopleSave}
                        error={hasError}
                    />
                </div>
            </MaterialPopover>
        </div>
    )
}

export default withTranslation(['common'])(OwnerPopover)
