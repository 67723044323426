const login = {
    action: {
        main: 'Sign in'
    },

    input: {
        email: 'Email address',
        password: 'Password'
    },

    options: {
        passwordReset: 'Forgot password?',
        rememberUser: 'Remember me'
    },

    validation: {
        email: {
            blank: 'This field is required',
            invalid: 'Must be a valid email address'
        },
        password: {
            blank: 'This field is required'
        }
    },

    request: {
        title: 'Authentication error',
        error:
            'Please check your email address or password because one of them was invalid.'
    }
}

export default login
