import { AsyncResponse } from '@app/api'
import { CreatePasswordFormData } from '@app/form'
import Button from 'components/button/base/Button'
import SecureInputField from 'components/input/secureInputField/SecureInputField'
import ToggleSwitch from 'components/input/toggleSwitch/ToggleSwitch'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import FormikHelpers from 'helpers/formikHelpers'
import { useAuthentication } from 'hooks/useAuthentication'
import React, { useState } from 'react'
import { Trans, WithTranslation, withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import * as Yup from 'yup'

export interface PasswordCreatorCardProps {
    shouldFadeIn: boolean
    handleSubmitPassword: (
        password: string,
        privacyPolicyAccepted: boolean,
        rememberUser: boolean
    ) => AsyncResponse
    hasPasswordError?: boolean
}

export type Props = PasswordCreatorCardProps & WithTranslation

const PasswordCreatorCard: React.FC<Props> = (props: Props) => {
    const { rememberUser, setRememberUser } = useAuthentication()

    const [state, setState] = useState({
        passwordInfo: props.hasPasswordError
            ? props.t('common:card.createPassword.passwordError')
            : props.t('common:card.createPassword.passwordInfo'),
        requestError: props.hasPasswordError
    })

    const inputRef = React.useRef<HTMLInputElement>(null)

    React.useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus()
        }
    }, [inputRef])

    const { passwordInfo, requestError } = state
    const { t, shouldFadeIn } = props

    const initialValues = (): CreatePasswordFormData => ({
        password: '',
        privacyPolicyAccepted: false,
        rememberUser: rememberUser ?? true
    })

    const validationSchema = () => {
        return Yup.object().shape({
            password: Yup.string().required(
                t('error:validation.fieldRequired')
            ),
            privacyPolicyAccepted: Yup.boolean().test(
                'privacyPolicyAccepted',
                t('common:card.createPassword.privacyPolicyNotAccepted'),
                (value) => value === true
            ),
            rememberUser: Yup.boolean()
        })
    }

    const onSubmit = async (
        values: CreatePasswordFormData,
        formikActions: FormikActions<CreatePasswordFormData>
    ) => {
        setRememberUser(values.rememberUser)

        try {
            await props.handleSubmitPassword(
                values.password,
                values.privacyPolicyAccepted,
                values.rememberUser
            )
        } catch (error) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            if (inputRef.current) {
                inputRef.current.focus()
            }

            setState({
                passwordInfo: t('common:card.createPassword.passwordError'),
                requestError: true
            })
        }
    }

    return (
        <Formik
            initialValues={initialValues()}
            onSubmit={onSubmit}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {(formikProps) => {
                const passwordError = FormikHelpers.getErrorMessage(
                    formikProps,
                    'password'
                )

                const privacyPolicyError = FormikHelpers.getErrorMessage(
                    formikProps,
                    'privacyPolicyAccepted'
                )

                return (
                    <Form
                        className={`card normal passwordCreatorForm ${
                            shouldFadeIn ? 'fade-in' : ''
                        }`}
                    >
                        <div className="card__logo" />

                        <SecureInputField
                            label={t('common:labels.password')}
                            name="password"
                            inputRef={inputRef}
                            onChange={formikProps.handleChange('password')}
                            hasError={!!passwordError || requestError}
                            message={passwordError || passwordInfo}
                            value={formikProps.values.password}
                        />

                        <div className="card__container--options-row">
                            <ToggleSwitch
                                textShouldNotBeClickable
                                selected={
                                    formikProps.values.privacyPolicyAccepted
                                }
                                label={
                                    <Trans i18nKey="common:card.createPassword.privacyPolicy">
                                        <Link
                                            className="colored"
                                            to="/privacy_policy.pdf"
                                            target="_blank"
                                        />
                                    </Trans>
                                }
                                onChange={(value) =>
                                    formikProps.setFieldValue(
                                        'privacyPolicyAccepted',
                                        value
                                    )
                                }
                                hasError={!!privacyPolicyError}
                                message={privacyPolicyError}
                                small
                            />
                        </div>

                        <div className="card__container--options-row">
                            <ToggleSwitch
                                selected={formikProps.values.rememberUser}
                                label={t('common:labels.rememberMe')}
                                onChange={(value) =>
                                    formikProps.setFieldValue(
                                        'rememberUser',
                                        value
                                    )
                                }
                                small
                            />
                        </div>

                        <div className="card__footer">
                            <Button
                                className="large"
                                title={t(
                                    'common:card.createPassword.actionLabel'
                                )}
                                type="submit"
                                loading={formikProps.isSubmitting}
                                primary
                                formButton
                            />
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default withTranslation(['common', 'error'])(PasswordCreatorCard)
