import React from 'react'
import classNames from 'classnames'

export interface InfoCardProps {
    title: string
    description: string
    className?: string
    descriptionClassName?: string
    cardAction?: React.ReactNode
}

const InfoCard = (props: InfoCardProps) => (
    <div className={classNames('card small', props.className)}>
        <div className="card__logo" />

        <div className="card__container">
            <span className="card__title">{props.title}</span>

            <span
                className={classNames(
                    'card__description',
                    props.descriptionClassName
                )}
            >
                {props.description}
            </span>
        </div>

        {props.cardAction && (
            <div className="card__footer">{props.cardAction}</div>
        )}
    </div>
)

export default InfoCard
