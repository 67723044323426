const people = {
    title: 'People',
    labels: {
        fullName: 'Full name',
        email: 'Email address',
        roles: 'Roles',
        level: 'Level',
        groups: 'Groups',
        oldPassword: 'Old password',
        newPassword: 'New password',
        edit: 'Edit',
        delete: 'Delete',
        resendInvitation: 'Resend invitation',
        cancelInvitation: 'Cancel invitation',
        filled: 'skill filled',
        filled_plural: 'skills filled',
        active: 'Active',
        pending: 'Pending',
        expired: 'Expired',
        rejected: 'Rejected',
        skillSet: '{{ count}} skill set',
        skillSet_plural: '{{ count}} skill sets',
        skillSet_and: '{{ count}} skill set and ',
        skillSet_and_plural: '{{ count}} skill sets and ',
        skillSet_empty: '',
        skill_notEmpty: '{{filled}} / {{count}} filled',
        skill_notEmpty_plural: '{{filled}} / {{count}} filled',
        skill_empty: '',
        completed: 'Completed',
        others: 'Others'
    },

    contentBrowser: {
        registrationData: {
            actionText: 'Resend invitation',
            subtitle:
                'This person has not accepted the invitation yet or got rejected.',
            title: 'Not registered yet'
        },

        emptyState: {
            person: {
                subtitle:
                    'This user has not created any matrices yet. Check back later.',
                title: 'Matrices not found'
            },

            template:
                "This template is empty, so we can't display anything at this time.",

            content: {
                actionText: 'Send an invite',
                subtitle:
                    'You can extend our database with new candidates or colleagues.',
                title: 'Invite people'
            }
        },

        idErrorData: {
            subtitle:
                "You've tried to open a person who is not available in our database.",
            title: 'Person not found'
        }
    },

    inviteUser: {
        title: 'Invite person',
        subtitle: 'Invited person will receive an activation email'
    },

    editUser: {
        title: 'Edit user information'
    },

    deleteUser: {
        title: 'Delete user',
        question:
            'Are you sure you want to remove <0>{{username}}</0>? This action will remove the application access and all data of the selected user.'
    },

    cancelInvitation: {
        title: 'Cancel invitation',
        question:
            "Are you sure you want to cancel the invitation of <0>{{username}}</0>? This action will revoke the user's ability to register into the application."
    },

    changePassword: {
        title: 'Change password'
    },

    resendInvitation: {
        title: 'Resend invitation',
        question:
            "Are you sure you want to resend the invitation of <0>{{username}}</0>? This action will grant permisson for rejected users to register or extend their invitation link's expiration date."
    }
}

export default people
