import React from 'react'
import { Route, Switch } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import { SearchTermsWorksheet } from './worksheet'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import withTitle from '../../hoc/withTitle'
import { SearchTermsContentBrowser } from './contentBrowser'

export const SearchTerms: React.FC = () => {
    const { t } = useTranslation(['searchTerms'])
    const match = useRouteMatch('/search-terms')

    return (
        <>
            <Breadcrumb>{t('searchTerms:title')}</Breadcrumb>
            <SearchTermsWorksheet>
                <Switch>
                    <Route
                        exact
                        path={`${match.path}/:id`}
                        render={(routerProps) => (
                            <SearchTermsContentBrowser
                                searchTermId={routerProps.match.params.id}
                                {...routerProps}
                            />
                        )}
                    />

                    <Route
                        exact
                        path={match.path}
                        render={(routerProps) => (
                            <SearchTermsContentBrowser
                                searchTermId={null}
                                {...routerProps}
                            />
                        )}
                    />
                </Switch>
            </SearchTermsWorksheet>
        </>
    )
}

export default withTitle('Terms')(SearchTerms)
