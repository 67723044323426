import { DialogConfig } from '@app/dialog'
import { AccountStatus, PeopleGroup, PersonDetails } from '@app/person'
import i18next from 'i18next'
import uuid from 'uuid'
import peopleDropdownMenuContent from '../pages/people/PeopleDropdownMenuContent'

const personStatus: (accountStatus: AccountStatus) => string = (status) => {
    switch (status) {
        case 'ACTIVATED':
            return i18next.t('people:labels.active')
        case 'PENDING':
            return i18next.t('people:labels.pending')
        case 'EXPIRED':
            return i18next.t('people:labels.expired')
        case 'CANCELLED':
            return i18next.t('people:labels.rejected')

        default:
            return ''
    }
}

const groupPeople = (peopleList: Array<PersonDetails>) => {
    const othersGroupId = uuid()
    const peopleMap: Map<string, PeopleGroup> = new Map()

    peopleList?.forEach((person) => {
        if (!person.groups.length) {
            peopleMap.set(othersGroupId, {
                title: i18next.t('people:labels.others'),
                people: peopleMap.get(othersGroupId)
                    ? [...peopleMap.get(othersGroupId).people, person]
                    : [person]
            })
        } else {
            person.groups.forEach((currentGroup) => {
                peopleMap.set(currentGroup.id, {
                    title: currentGroup.name,
                    people: peopleMap.get(currentGroup.id)
                        ? [...peopleMap.get(currentGroup.id).people, person]
                        : [person]
                })
            })
        }
    })

    return Array.from(peopleMap)
        .filter(([key]) => key !== othersGroupId)
        .concat(Array.from(peopleMap).filter(([key]) => key === othersGroupId))
        .map(([key, value]) => ({
            id: key,
            title: value.title,
            people: value.people
        }))
}

const groupedPeopleToListItems = (
    groupedPeople: Array<{
        id: string
        title: string
        people: Array<PersonDetails>
    }>,
    openDialog: (config: DialogConfig) => void,
    currentUser: PersonDetails,
    isCurrentUserAdmin: () => boolean
) =>
    groupedPeople.reduce(
        (listItems, group) =>
            [
                ...listItems,
                {
                    key: group.id,
                    title: group.title,
                    isHeader: true
                }
            ].concat(
                group.people
                    .sort((a, b) =>
                        a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1
                    )
                    .map((person) => ({
                        key: uuid(),
                        id: person.id,
                        title: person.name,
                        subtitle: personStatus(person.status),
                        dropdownMenuContent: peopleDropdownMenuContent(
                            person.status,
                            openDialog,
                            person
                        ),
                        disableActionMenu:
                            !isCurrentUserAdmin() ||
                            currentUser?.id === person.id
                    }))
            ),
        []
    )

export { groupPeople, groupedPeopleToListItems }
