const getUnknownError = (action: string) =>
    `An unknown error occurred while trying to ${action}, please try again later.`

const error = {
    labels: {
        error: 'Error'
    },
    validation: {
        fieldRequired: 'This field is required',
        emailInvalid: 'This is not a valid email address',
        phoneNumberInvalid: 'This is not a valid phone number',
        noTemplatesSelected:
            'You need to select a template from the list above.',
        noSkillSetSelected: 'Select a skill set from the list',
        noMatrixSelected:
            'You need to select at least one matrix from the list',
        groupNameShouldMatch:
            'Please fill the field with the group\'s name, "{{groupName}}".',
        maxThreeLabels: 'Maximum 3 labels can be added to a template',
        searchTermNameShouldMatch:
            'Please fill the field with the search term\'s name, "{{searchTermName}}".',
        maxThirtyCharacters: 'Should be maximum 30 characters long',
        noFeedbackTypeSelected: 'Please select a feedback category'
    },
    response: {
        alreadyGaveFeedback:
            'You have already sent a feedback for the particular skill, skill set or template today.',
        deleteTemporaryGroup:
            'This temporary group cannot be deleted right now. If you would like to delete the group, then first add the only user to another group or delete that user.',
        emailAlreadyRegistered:
            'A user with this email address was already registered.',
        passwordMismatch:
            'The password you provided does not match your current password.',
        invalidPassword:
            'Must contain at least 10 characters including 2 numbers and 1 special character',
        duplicateTemplateName:
            'The given name is already used by an other template.',
        duplicateGroupName: 'The given name is already used by an other group.',
        duplicateSearchTermName:
            'The given name is already used by an other search term.',
        templateUnavailable: 'The template you want to modify does not exist.',
        filteredSearchListUnknown: getUnknownError('load search terms'),
        ratingUpdateUnknown: getUnknownError('update the rating'),
        templateLoadUnknown: getUnknownError('load template'),
        reorderUnknown: getUnknownError('reorder items'),
        groupListLoadUnknown: getUnknownError('load the group list'),
        peopleListUpdateUnknown: getUnknownError('update people list'),
        peopleListLoadUnknown: getUnknownError('load the people list'),
        finishTrainingMaterialUnknown: getUnknownError(
            'finish training material'
        ),
        assignTemplateUnknown: getUnknownError('assign template'),
        unassignedTemplateLoadUnknown: getUnknownError(
            'load unassigned templates'
        ),
        filteredSearchTermListUnknown: getUnknownError('load search terms'),
        unassignedPeopleListLoadUnknown:
            'An unknown error occurred while trying to load available owners.',
        cancelInvitationUnknown: getUnknownError(
            'cancel the invitation of "{{username}}"'
        ),
        changePasswordUnknown: getUnknownError('change your password'),
        createSkillUnknown: getUnknownError(
            'create the skill named "{{skillName}}"'
        ),
        createSkillSetUnknown: getUnknownError(
            'create the skill set named "{{skillSetName}}"'
        ),
        createTemplateUnknown: getUnknownError(
            'create the template named "{{templateName}}"'
        ),
        createGroupUnknown: getUnknownError(
            'create the group named "{{groupName}}"'
        ),
        createSearchTermUnknown: getUnknownError(
            'create the search term named "{{searchTermName}}"'
        ),
        copySkillUnknown: getUnknownError(
            'copy the skill named "{{skillName}}"'
        ),
        copySkillSetUnknown: getUnknownError(
            'copy the skill set named "{{skillName}}"'
        ),
        editSkillUnknown: getUnknownError(
            'edit the skill named "{{skillName}}"'
        ),
        editSkillSetUnknown: getUnknownError(
            'edit the skill set named "{{skillSetName}}"'
        ),
        convertSkillUnknown: getUnknownError(
            'convert the skill named "{{skillName}}"'
        ),
        editTemplateUnknown: getUnknownError(
            'edit the template named "{{templateName}}"'
        ),
        deleteSkillUnknown: getUnknownError(
            'delete the skill named "{{skillName}}"'
        ),
        deleteSkillSetUnknown: getUnknownError(
            'delete the skill set named "{{skillSetName}}'
        ),
        deleteTemplateUnknown: getUnknownError(
            'delete the template named "{{templateName}}"'
        ),
        deleteGroupUnknown: getUnknownError(
            'delete the group named "{{groupName}}"'
        ),
        deleteSearchTermUnknown: getUnknownError(
            'delete the search term named "{{searchTermName}}"'
        ),
        shareSearchTermUnknown: getUnknownError(
            'share search term named "{{searchTermName}}"'
        ),
        editUserUnknown: getUnknownError(
            'invite or edit the user named "{{username}}"'
        ),
        deleteUserUnknown: getUnknownError(
            'delete the user named "{{username}}"'
        ),
        linkTemplateUnknown: getUnknownError('link templates'),
        unlinkTemplateUnknown: getUnknownError('unlink templates'),
        unAssignSkillSetUnknown: getUnknownError('unassign skill set'),
        unAssignSkillUnknown: getUnknownError('unassign skill'),
        assignSkillUnknown: getUnknownError('assign skill'),
        removeMatrixUnknown: getUnknownError(
            'remove "{{matrixName}}" from your profile'
        ),
        resendInvitationUnknown: getUnknownError('resend invitation'),
        resetPasswordUnknown: getUnknownError('reset your password'),
        createNewPasswordUnknown: getUnknownError('create new password'),
        createPasswordUnknown: getUnknownError('create your password'),
        leaveFeedbackUnknown: getUnknownError('leave feedback')
    }
}

export default error
