import { RouteParams } from '@app/common'
import withTitle from 'hoc/withTitle'
import React from 'react'
import { match, RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'
import { useTranslation } from 'react-i18next'
import TemplateWorksheet from './TemplateWorksheet'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'

interface OwnProps {
    match: match<RouteParams>
}

type Props = OwnProps & RouteComponentProps

const Templates: React.FC<Props> = (props: Props) => {
    const { t } = useTranslation(['templates'])
    return (
        <>
            <Breadcrumb>{t('templates:title')}</Breadcrumb>
            <TemplateWorksheet match={props.match} />
        </>
    )
}

export default compose<Props, OwnProps>(
    withRouter,
    withTitle('Templates')
)(Templates)
