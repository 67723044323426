import withTitle from 'hoc/withTitle'
import React, { useEffect, useMemo, useState } from 'react'
import { match, useHistory } from 'react-router'
import { compose } from 'recompose'
import { PlaceholderData, RouteParams, SelectorListItem } from '@app/common'
import PersonIcon from '@atlaskit/icon/glyph/person'
import ImageBorderIcon from '@atlaskit/icon/glyph/image-border'
import InviteTeamIcon from '@atlaskit/icon/glyph/invite-team'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import { MatrixDetails } from '@app/matrix'
import { PersonDetails } from '@app/person'
import ReactPlaceholder from 'react-placeholder'
import { useTranslation } from 'react-i18next'
import ArrowLeftIcon from '@atlaskit/icon/glyph/arrow-left'
import { IconButton } from '@material-ui/core'
import withCondition from '../../hoc/withCondition'
import withMaybe from '../../hoc/withMaybe'
import Placeholder from '../../components/placeholder/Placeholder'
import { useFetchAnonymousById } from '../../helpers/peopleHelpers'
import { useAuthentication } from '../../hooks/useAuthentication'
import PeopleSkillSetListItem from '../people/PeopleSkillSetListItem'
import { useGlobal } from '../../hooks/useGlobal'
import MatrixPopover from '../../components/matrixPopover/MatrixPopover'
import GroupInfoPopover from '../../components/groupInfoPopover/GroupInfoPopover'

interface OwnProps {
    match: match<RouteParams>
}

const renderContentBrowserList = (currentPerson: any) =>
    currentPerson.matrix.childSkillSets
        .sort((a, b) => a.position - b.position)
        .map((skillSet) => (
            <PeopleSkillSetListItem
                key={skillSet.skillSetId}
                skillSet={skillSet}
            />
        ))

export type PersonDetailsPageProps = OwnProps

export const PersonDetailsPage = (props: PersonDetailsPageProps) => {
    const { isCurrentUserAdmin } = useAuthentication()
    const history = useHistory()

    const { t } = useTranslation(['people'])

    const { openDialog } = useGlobal()

    const registrationData: PlaceholderData = {
        actionText: isCurrentUserAdmin()
            ? t('people:contentBrowser.registrationData.actionText')
            : '',
        icon: <PersonIcon label="" size="xlarge" />,
        subtitle: t('people:contentBrowser.registrationData.subtitle'),
        title: t('people:contentBrowser.registrationData.title')
    }

    const shouldDisplayEmptyState =
        !props.match.params || !props.match.params.id

    const { person: currentPerson, error, status } = useFetchAnonymousById(
        props.match.params?.id
    )

    const shouldDisplayRegistration =
        !!currentPerson && currentPerson.status !== 'ACTIVATED'

    const shouldDisplayIdError = !!error && error.status === 404

    const emptyPersonStateData: PlaceholderData = {
        icon: <ImageBorderIcon label="" size="xlarge" />,
        title: t('people:contentBrowser.emptyState.person.title'),
        subtitle: t('people:contentBrowser.emptyState.person.subtitle')
    }

    const emptyStateData: PlaceholderData = {
        action: () => openDialog({ type: 'INVITE_USER' }),
        actionText: t('people:contentBrowser.emptyState.content.actionText'),
        icon: <InviteTeamIcon label="" size="xlarge" />,
        title: t('people:contentBrowser.emptyState.content.title'),
        subtitle: t('people:contentBrowser.emptyState.content.subtitle')
    }

    const idErrorData: PlaceholderData = {
        icon: <CrossIcon label="" size="xlarge" />,
        title: t('people:contentBrowser.idErrorData.title'),
        subtitle: t('people:contentBrowser.idErrorData.subtitle')
    }

    const [selectedTemplateId, setSelectedTemplateId] = useState<string>()
    const [selectedMatrix, setSelectedMatrix] = useState<MatrixDetails>()

    const selectorListItems: Array<SelectorListItem> = useMemo(
        () =>
            currentPerson?.matrices.map((template) => {
                const linkedMatrix = currentPerson.matrices.find(
                    (matrixListItem) =>
                        matrixListItem.templateId === template.linkedTemplateId
                )
                return {
                    id: template.templateId,
                    title: `${template.name}${
                        linkedMatrix
                            ? t('matrices:contentBrowser.linkedMatrix', {
                                  linkedMatrixName: linkedMatrix.name
                              })
                            : ''
                    }`
                }
            }) || [],
        [currentPerson, t]
    )

    useEffect(() => {
        if (
            !selectedTemplateId &&
            currentPerson &&
            currentPerson.matrices &&
            currentPerson.matrices.length
        ) {
            setSelectedTemplateId(currentPerson.matrices[0].templateId)
        }

        if (currentPerson && selectedTemplateId) {
            const currentMatrix = currentPerson.matrices.find(
                (matrix) => matrix.templateId === selectedTemplateId
            )

            setSelectedMatrix(currentMatrix)
        }
    }, [currentPerson, selectedTemplateId])

    const renderContentBrowserListPlaceholder = () => (
        <div className="worksheetContentBrowser__emptyList topMargin">
            {t('people:contentBrowser.emptyState.template')}
        </div>
    )

    const openDialogWithPerson = (person: PersonDetails) => () =>
        openDialog({
            type: 'RESEND_INVITATION',
            payload: person
        })

    const selectMatrix: (id: string) => void = (id) => setSelectedTemplateId(id)

    const hasChildren = (matrix?: MatrixDetails) =>
        matrix && matrix.childSkillSets.length > 0

    const SkillList = withCondition(
        hasChildren(selectedMatrix),
        renderContentBrowserList,
        withMaybe(status === 'resolved')(renderContentBrowserListPlaceholder)
    )

    const linkedMatrix =
        selectedMatrix &&
        currentPerson &&
        currentPerson.matrices.find(
            (matrixListItem) =>
                matrixListItem.templateId === selectedMatrix.linkedTemplateId
        )

    const LinkedSkillList = withCondition(
        hasChildren(linkedMatrix),
        renderContentBrowserList,
        withMaybe(status === 'resolved')(renderContentBrowserListPlaceholder)
    )

    let content: React.ReactNode

    if (currentPerson && shouldDisplayRegistration) {
        content = (
            <div className="worksheetContentBrowser__emptyState">
                <Placeholder
                    action={openDialogWithPerson(currentPerson)}
                    actionText={registrationData.actionText}
                    icon={registrationData.icon}
                    title={registrationData.title}
                    subtitle={registrationData.subtitle}
                />
            </div>
        )
    } else if (shouldDisplayIdError) {
        content = (
            <div className="worksheetContentBrowser__emptyState">
                <Placeholder
                    icon={idErrorData.icon}
                    title={idErrorData.title}
                    subtitle={idErrorData.subtitle}
                />
            </div>
        )
    } else if (shouldDisplayEmptyState) {
        content = (
            <div className="worksheetContentBrowser__emptyState">
                <Placeholder
                    action={emptyStateData.action}
                    actionText={emptyStateData.actionText}
                    icon={emptyStateData.icon}
                    title={emptyStateData.title}
                    subtitle={emptyStateData.subtitle}
                />
            </div>
        )
    } else {
        content = (
            <>
                <div className="personDetails__header">
                    <ReactPlaceholder
                        className="placeholder"
                        showLoadingAnimation
                        type="textRow"
                        rows={1}
                        ready={status === 'resolved'}
                    >
                        {currentPerson && (
                            <>
                                <div className="personDetails__text">
                                    <div className="personDetails__headerTitle text-ellipsis">
                                        {currentPerson.name}
                                    </div>
                                    <div className="personDetails__subtitle">
                                        {currentPerson.groups.length > 0 &&
                                            currentPerson.groups[0].name}
                                    </div>
                                </div>
                                {currentPerson.groups &&
                                    currentPerson.groups.length > 0 && (
                                        <GroupInfoPopover
                                            groupId={currentPerson.groups[0].id}
                                        />
                                    )}
                            </>
                        )}
                    </ReactPlaceholder>
                </div>

                {currentPerson && !currentPerson.matrices.length ? (
                    <div className="worksheetContentBrowser__emptyState">
                        <Placeholder
                            icon={emptyPersonStateData.icon}
                            title={emptyPersonStateData.title}
                            subtitle={emptyPersonStateData.subtitle}
                        />
                    </div>
                ) : (
                    <ReactPlaceholder
                        className="contentBrowserListItem__reactPlaceholder"
                        showLoadingAnimation
                        type="text"
                        rows={18}
                        ready={status === 'resolved'}
                    >
                        <div className="worksheetContentBrowser__scrollArea">
                            <div className="worksheetContentBrowser__innerContent modern-scrollbar">
                                <div className="worksheetContentBrowser__section">
                                    <SkillList matrix={selectedMatrix} />
                                </div>
                                {linkedMatrix && (
                                    <div className="worksheetContentBrowser__section">
                                        <LinkedSkillList
                                            matrix={linkedMatrix}
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </ReactPlaceholder>
                )}
                <div />
            </>
        )
    }

    return (
        <>
            <div className="personDetails__navigation">
                <IconButton
                    aria-label="navBack"
                    onClick={() => history.goBack()}
                    className="personDetails__navBack"
                    size="medium"
                >
                    <ArrowLeftIcon label="" primaryColor="#000" />
                </IconButton>
                <ReactPlaceholder
                    className="placeholder"
                    showLoadingAnimation
                    type="textRow"
                    rows={1}
                    ready={status === 'resolved'}
                >
                    <MatrixPopover
                        listItems={selectorListItems}
                        activeListItemId={selectedTemplateId}
                        selectAction={selectMatrix}
                    />
                </ReactPlaceholder>
            </div>
            <div className="worksheet slideFadeBottomToTop" role="main">
                <div className="worksheetContentBrowser__content">
                    {content}
                </div>
            </div>
        </>
    )
}

export default compose<PersonDetailsPageProps, OwnProps>(
    withTitle('Person Details')
)(PersonDetailsPage)
