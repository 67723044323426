import { SelectableListItem as SelectableListItemConfig } from '@app/common'
import { SelectableFilterListProps } from '@app/dialog'
import { LinkTemplateDialogFormData } from '@app/form'
import SelectableListItem from 'components/listItem/selectable/SelectableListItem'
import { FormikProps } from 'formik'
import withFilterListItems from 'hoc/withFilterListItems'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'

interface ListItemProps extends SelectableFilterListProps, WithTranslation {
    handleTemplateListChange: (id: string) => Array<SelectableListItemConfig>
    formikProps: FormikProps<LinkTemplateDialogFormData>
}

type LinkableTemplateListProps = ListItemProps

const renderListItems = (props: ListItemProps) =>
    props.listItems.length > 0 ? (
        <>
            {props.listItems.map((template, index) => (
                <div
                    className="worksheetNavigation__listItem"
                    key={template.id}
                >
                    <SelectableListItem
                        title={template.title}
                        subtitle={template.subtitle}
                        selected={template.selected}
                        labels={template.labels}
                        withSeparator={props.listItems.length - 1 !== index}
                        onClick={() =>
                            props.formikProps.setFieldValue(
                                'templates',
                                props.handleTemplateListChange(template.id)
                            )
                        }
                    />
                </div>
            ))}
        </>
    ) : (
        <div className="worksheetNavigation__emptyState">
            {props.t('common:worksheet.emptyState.noResult')}
        </div>
    )

const LinkableTemplateList = (props: LinkableTemplateListProps) =>
    withFilterListItems<ListItemProps>(renderListItems)(props)

export default withTranslation('common')(LinkableTemplateList)
