import { LoginFormData } from '@app/form'
import Button from 'components/button/base/Button'
import LabeledInputField from 'components/input/labeledInputField/LabeledInputField'
import SecureInputField from 'components/input/secureInputField/SecureInputField'
import ToggleSwitch from 'components/input/toggleSwitch/ToggleSwitch'
import { Form, Formik } from 'formik'
import { useRequestLoginUser } from 'helpers/accountHelpers'
import FormikHelpers from 'helpers/formikHelpers'
import withTitle from 'hoc/withTitle'
import { useAuthentication } from 'hooks/useAuthentication'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { compose } from 'recompose'
import * as Yup from 'yup'

export type Props = WithTranslation

export const Login = ({ t }: Props) => {
    const { setRememberUser, storeToken } = useAuthentication()

    const initialValues: LoginFormData = {
        email: '',
        password: '',
        rememberUser: true
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email(t('validation.email.invalid'))
            .required(t('validation.email.blank')),
        password: Yup.string().required(t('validation.password.blank')),
        rememberUser: Yup.boolean()
    })

    const { createNotification } = useGlobal()

    const requestLoginUser = useRequestLoginUser()

    function handleLoginFailure({ setSubmitting }) {
        setSubmitting(false)

        createNotification({
            description: t('request.error'),
            type: 'Error'
        })
    }

    const handleSubmitForm = async (values, { setSubmitting }) => {
        try {
            setRememberUser(values.rememberUser)

            const loginDetails = await requestLoginUser({
                user: { email: values.email, password: values.password },
                rememberUser: values.rememberUser
            })

            if (loginDetails.data && loginDetails.data.token) {
                storeToken(loginDetails.data.token)
            } else {
                handleLoginFailure({ setSubmitting })
            }
        } catch (error) {
            handleLoginFailure({ setSubmitting })
        }
    }

    return (
        <div className="colorful-background">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmitForm}
                validateOnChange
            >
                {(formikProps) => {
                    const emailError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'email'
                    )

                    const passwordError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'password'
                    )

                    return (
                        <Form className="card normal loginForm" key="loginForm">
                            <div className="card__logo" />

                            <div className="card__container">
                                <LabeledInputField
                                    label={t('input.email')}
                                    name="email"
                                    type="email"
                                    onChange={formikProps.handleChange('email')}
                                    value={formikProps.values.email}
                                    message={emailError}
                                    hasError={!!emailError}
                                    key="loginEmailField"
                                    autoFocus
                                />

                                <SecureInputField
                                    label={t('input.password')}
                                    name="password"
                                    onChange={formikProps.handleChange(
                                        'password'
                                    )}
                                    value={formikProps.values.password}
                                    message={passwordError}
                                    hasError={!!passwordError}
                                    key="loginPasswordField"
                                />

                                <div className="card__container--options-row">
                                    <ToggleSwitch
                                        selected={
                                            formikProps.values.rememberUser
                                        }
                                        label={t('options.rememberUser')}
                                        onChange={(value) =>
                                            formikProps.setFieldValue(
                                                'rememberUser',
                                                value
                                            )
                                        }
                                        small
                                    />

                                    <NavLink
                                        to="/reset-password"
                                        className="card__link"
                                    >
                                        {t('options.passwordReset')}
                                    </NavLink>
                                </div>
                            </div>

                            <div className="card__footer">
                                <Button
                                    className="normal"
                                    title={t('action.main')}
                                    type="submit"
                                    loading={formikProps.isSubmitting}
                                    primary
                                    formButton
                                />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default compose<Props, unknown>(
    withTitle('Login'),
    withTranslation('login')
)(Login)
