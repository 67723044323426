import { LevelSelectorButtonSize } from '@app/common'
import classNames from 'classnames'
import React from 'react'

export interface LevelSelectorButtonProps {
    size: LevelSelectorButtonSize
    onClick: () => void
    active?: boolean
    disabled?: boolean
    loading?: boolean
    color?: 'light' | 'dark'
}

const LevelSelectorButton = (props: LevelSelectorButtonProps) => (
    <div
        className={classNames(
            'radioButton__container',
            `radioButton__container--${props.size}`,
            { 'radioButton__container--disabled': props.disabled }
        )}
        onClick={props.onClick}
    >
        <button
            className={classNames(
                'radioButton',
                `radioButton--${props.size}`,
                props.active && props.color,
                {
                    'radioButton--active': props.active,
                    'radioButton--disabled': props.disabled,
                    'radioButton--loading': props.loading
                }
            )}
            disabled={props.disabled}
            type="button"
            aria-label="Level selector"
        />
    </div>
)

export default LevelSelectorButton
