import { makeStyles } from '@material-ui/core'
import { textEllipsis } from 'styles/commonStyles'
import { pxToRem } from '../../material/theme'

export const useAutocompleteStyles = makeStyles((theme) => ({
    root: {
        flex: `0 0 ${pxToRem(362)}`,
        margin: `0 ${pxToRem(30)} 0 0`,
        position: 'relative'
    },
    searchInput: {
        background: 'transparent',
        boxShadow: 'none',
        padding: pxToRem(12)
    },
    inputRoot: {
        padding: 0,
        fontSize: pxToRem(16),
        borderStyle: 'none',
        borderRadius: pxToRem(5),
        appearance: 'none',
        transition: 'background-color 0.25s ease',
        border: '0 !important',
        boxShadow: 'none !important',
        backgroundColor: 'transparent !important',
        '& input#searchBar-input': {
            backgroundColor: 'transparent !important',
            boxShadow: 'none !important',
            padding: pxToRem(12),
            border: 0
        },
        '& fieldset.MuiOutlinedInput-notchedOutline': {
            border: '0 !important'
        }
    },
    listBox: {
        maxHeight: pxToRem(312),
        marginTop: 10,
        borderRadius: 15,
        background: '#fff',
        zIndex: 1,
        position: 'absolute',
        listStyle: 'none',
        border: 0,
        right: 0,
        padding: 0,
        width: '100%',
        overflow: 'auto',
        boxShadow:
            '0px 18px 80px rgba(0, 0, 0, 0.04), 0px 7.51997px 33.4221px rgba(0, 0, 0, 0.0240129), 0px 4.02054px 17.869px rgba(0, 0, 0, 0.0193547), 0px 2.25388px 10.0172px rgba(0, 0, 0, 0.0163117), 0px 1.19702px 5.32008px rgba(0, 0, 0, 0.013551), 0px 0.498106px 2.21381px rgba(0, 0, 0, 0.00991487)'
    },
    loading: {
        padding: '14px 16px'
    },
    option: {
        transition: 'background-color 0.35s ease !important',
        borderBottom: '1px solid #ECEFF1',
        cursor: 'pointer',
        padding: 16,
        ...textEllipsis,
        '&[data-focus="true"]': {
            backgroundColor: theme.palette.action.hover
        },
        '&:active': {
            backgroundColor: theme.palette.action.active
        },
        '&:last-of-type': {
            border: 0
        }
    },
    link: {
        display: 'block'
    },
    popper: {
        zIndex: 11
    },
    paper: {
        overflow: 'hidden',
        margin: '4px 0'
    }
}))
