import { PersonDetailsWithMatrices } from '@app/person'
import ProfilePopover from 'components/profilePopover/ProfilePopover'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { HistoryType } from '@app/common'
import { isAdmin, isMatchingAnyRole, isSales } from '../../helpers/roleHelpers'
import { useAuthentication } from '../../hooks/useAuthentication'
import SearchBar from '../searchBar/SearchBar'

export interface HeaderProps {
    currentUser: PersonDetailsWithMatrices
    onLogout: () => void
    history: HistoryType
}

export const Header: React.FC<HeaderProps> = ({
    currentUser,
    onLogout,
    history
}: HeaderProps) => {
    const { openDialog } = useGlobal()

    const { getCurrentUserRoles } = useAuthentication()
    const currentUserRoles = getCurrentUserRoles()

    function openChangePasswordDialog() {
        openDialog({
            type: 'CHANGE_PASSWORD',
            payload: currentUser
        })
    }

    return (
        <header className="header">
            {isMatchingAnyRole(isAdmin, isSales)(currentUserRoles) ? (
                <SearchBar history={history} />
            ) : (
                <span />
            )}

            <div className="header__dialog">
                <ProfilePopover
                    title={currentUser?.name ?? ''}
                    onChangePasswordClick={openChangePasswordDialog}
                    onLogoutClick={onLogout}
                />
            </div>
        </header>
    )
}

export default Header
