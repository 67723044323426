import { ErrorData } from '@app/api'
import axios, { AxiosInstance } from 'axios'
import { useAppMode } from 'hooks/useAppMode'
import { useAuthentication } from 'hooks/useAuthentication'
import React, { createContext } from 'react'
import { Cache, SWRConfig } from 'swr'
import { ProviderConfiguration, PublicConfiguration } from 'swr/dist/types'
import { retrieveToken } from './AuthenticationContext'

interface ClientContextProps {
    client: AxiosInstance
}

export const ClientContext = createContext<ClientContextProps>(null)

function useClient(): AxiosInstance {
    const [, setMode] = useAppMode()
    const { logOutCurrentUser } = useAuthentication()

    const client = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        responseType: 'json'
    })

    client.interceptors.request.use((request) => {
        const token = retrieveToken()

        if (token) {
            return {
                ...request,
                headers: {
                    ...request.headers,
                    Authorization: `Bearer ${token}`
                }
            }
        }

        return request
    })

    client.interceptors.response.use(
        (response) => {
            setMode('NORMAL')
            return response
        },
        (error) => {
            if (error.response) {
                switch (error.response.status) {
                    case 401:
                        logOutCurrentUser()
                        break
                    case 403:
                        setMode('FORBIDDEN')
                        break
                    case 503:
                        setMode('MAINTENANCE')
                        break
                    default:
                        break
                }
            } else {
                setMode('MAINTENANCE')
            }

            const customError: ErrorData = {
                data: error.response?.data,
                status: error.response?.status
            }

            return Promise.reject(customError)
        }
    )

    return client
}

export interface ClientProviderProps {
    SWRConfigProps?: Partial<PublicConfiguration> &
        Partial<ProviderConfiguration> & {
            provider?: (cache: Readonly<Cache<any>>) => Cache
        }
}

export const ClientProvider: React.FC<ClientProviderProps> = ({
    children,
    SWRConfigProps
}) => {
    const client = useClient()

    const fetcher = (url: string) =>
        client.get(url).then((response) => response.data)

    return (
        <ClientContext.Provider value={{ client }}>
            <SWRConfig
                value={{
                    fetcher,
                    ...SWRConfigProps
                }}
            >
                {children}
            </SWRConfig>
        </ClientContext.Provider>
    )
}
