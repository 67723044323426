import React from 'react'
import { CreateNewPasswordFormData } from '@app/form'
import { FormikHelpers as FormikActions } from 'formik/dist/types'
import { useRouteMatch } from 'react-router-dom'
import { RouteParams } from '@app/common'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import withCondition from '../../hoc/withCondition'
import {
    useCheckResetPasswordKey,
    useRequestCreateNewPasswordSubmit
} from '../../helpers/accountHelpers'
import {
    CreateNewPasswordCard,
    CreateNewPasswordCardProps
} from './CreateNewPasswordCard'
import { CreateNewPasswordErrorCard } from './CreateNewPasswordErrorCard'
import InfoCard from '../../components/card/InfoCard'
import Button from '../../components/button/base/Button'
import withTitle from '../../hoc/withTitle'
import { useGlobal } from '../../hooks/useGlobal'

export const CreateNewPassword = () => {
    const [created, setCreated] = React.useState(false)
    const [switchingCard, setSwitchingCard] = React.useState(false)

    const history = useHistory()

    const { t } = useTranslation(['common'])

    const { createNotification } = useGlobal()

    const requestCreateNewPasswordSubmit = useRequestCreateNewPasswordSubmit()

    const match = useRouteMatch<RouteParams>(
        '/reset-password/:resetPasswordKey'
    )

    const { error, status } = useCheckResetPasswordKey(
        match.params.resetPasswordKey
    )

    const switchCard = () => {
        setSwitchingCard(true)

        setTimeout(() => setCreated(true), 500)
    }

    const handleSubmitForm = async (
        values: CreateNewPasswordFormData,
        formikActions: FormikActions<CreateNewPasswordFormData>
    ) => {
        try {
            await requestCreateNewPasswordSubmit({
                newPassword: values.newPassword,
                resetPasswordKey: match.params.resetPasswordKey
            })
            switchCard()
        } catch (responseError) {
            formikActions.setSubmitting(false)

            createNotification({
                description: t('error:response.createNewPasswordUnknown'),
                type: 'Error'
            })
        }
    }

    const resetPasswordKeyError = !!error || status === 'rejected'

    const renderPasswordCreatorCard = (props: CreateNewPasswordCardProps) => (
        <CreateNewPasswordCard {...props} />
    )

    const renderCreateNewPasswordErrorCard = () => (
        <CreateNewPasswordErrorCard />
    )

    const Card = withCondition(
        resetPasswordKeyError,
        renderCreateNewPasswordErrorCard,
        renderPasswordCreatorCard
    )

    const openLogin = () => history.push('/login')

    const cardAction = (
        <Button
            className="large"
            onClick={openLogin}
            title={t('card.createNewPasswordSuccess.actionLabel')}
            primary
            triggerEnter
        />
    )

    return (
        <div className="colorful-background">
            {created ? (
                <InfoCard
                    title={t('card.createNewPasswordSuccess.title')}
                    description={t('card.createNewPasswordSuccess.description')}
                    cardAction={cardAction}
                    className="fade-in"
                />
            ) : (
                <Card
                    handleSubmitForm={handleSubmitForm}
                    className={switchingCard ? 'fade-out' : ''}
                />
            )}
        </div>
    )
}

export default withTitle('Reset password')(CreateNewPassword)
