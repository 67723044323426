import classNames from 'classnames'
import ExitButton from 'components/button/exit/ExitButton'
import React from 'react'
import FocusLock, { FreeFocusInside } from 'react-focus-lock'

export interface DialogProps {
    title: string
    className?: string
    showCloseIcon?: boolean
    animationWithMargin?: boolean
    fullScreen?: boolean
    subtitle?: string
    children?: React.ReactNode
    footer?: React.ReactNode
    footerClassName?: string
    closeDialog: () => void
}

export const Dialog = (props: DialogProps) => (
    <FocusLock
        className={classNames(
            props.className,
            'dialog',
            'slideFadeBottomToTop',
            { fullScreen: props.fullScreen }
        )}
        noFocusGuards
    >
        <div className="dialog__header">
            <div className="dialog__titleContainer">
                <span className="dialog__title">{props.title}</span>

                {props.subtitle && (
                    <span className="dialog__subtitle">{props.subtitle}</span>
                )}
            </div>

            {props.showCloseIcon && (
                <ExitButton
                    onClick={props.closeDialog}
                    crossSize="medium"
                    size="large"
                />
            )}
        </div>

        {props.children && <div className="dialog__body">{props.children}</div>}
        {props.footer && (
            <div
                className={classNames(props.footerClassName, 'dialog__footer')}
            >
                {props.footer}
            </div>
        )}
    </FocusLock>
)

export default Dialog
