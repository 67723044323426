import { AppContext, AppContextProps } from 'contexts/AppContext'
import React from 'react'

/**
 * Renders a component with the given context value
 *
 * @param context The value what you want to pass to a component
 * @param Component A component what you actually want to render
 */

const withAppContext = <P extends any>(context: AppContextProps) => (
    Component: React.ComponentType<P>
) => (props: P) => (
    <AppContext.Provider value={context}>
        <Component {...props} />
    </AppContext.Provider>
)

export default withAppContext
