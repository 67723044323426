import React from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    deleteAction?: (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void
    modifyAction?: (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void
    copyAction?: (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void
    unAssignAction?: (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void
    convertAction?: (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void
}

export const ListItemActionMenu = (props: Props) => {
    const [t] = useTranslation('common')

    return (
        <>
            {props.convertAction ? (
                <div
                    className="contentBrowserListItem__actionMenu"
                    onClick={(event) => props.convertAction(event)}
                >
                    {t('common:labels.convert')}
                </div>
            ) : null}
            {props.copyAction && (
                <div
                    className="contentBrowserListItem__actionMenu"
                    onClick={(event) => props.copyAction(event)}
                >
                    {t('common:labels.copy')}
                </div>
            )}
            {props.modifyAction && (
                <div
                    className="contentBrowserListItem__actionMenu"
                    onClick={(event) => props.modifyAction(event)}
                >
                    {t('common:labels.edit')}
                </div>
            )}

            {props.deleteAction && (
                <div
                    className="contentBrowserListItem__actionMenu"
                    onClick={(event) => props.deleteAction(event)}
                >
                    {t('common:labels.delete')}
                </div>
            )}

            {props.unAssignAction && (
                <div
                    className="contentBrowserListItem__actionMenu"
                    onClick={(event) => props.unAssignAction(event)}
                >
                    {t('common:labels.unAssign')}
                </div>
            )}
        </>
    )
}

export default ListItemActionMenu
