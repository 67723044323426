import useSWR from 'swr'
import { SearchResult } from '@app/common'
import { determineComponentStatus } from './apiHelpers'

const SEARCH_BASE_URL = '/api/search'

function useFetchSearchResultsById(id: string, type: string) {
    const { data, error } = useSWR<Array<SearchResult>, Error>(
        id ? `${SEARCH_BASE_URL}/${id}?type=${type}` : null
    )

    return {
        searchResults: data,
        error,
        status: data ? determineComponentStatus(data, error) : 'idle'
    }
}

export { useFetchSearchResultsById }
