import { ResetPasswordFormData } from '@app/form'
import classNames from 'classnames'
import Button from 'components/button/base/Button'
import LabeledInputField from 'components/input/labeledInputField/LabeledInputField'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import { useRequestPasswordReset } from 'helpers/accountHelpers'
import FormikHelpers from 'helpers/formikHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { NavLink } from 'react-router-dom'
import { compose } from 'recompose'
import * as Yup from 'yup'

interface OwnProps {
    className: string
    postAction: () => void
}

export type Props = OwnProps & WithTranslation

export const ResetPasswordCard = ({ className, postAction, t }: Props) => {
    const requestPasswordReset = useRequestPasswordReset()
    const { createNotification } = useGlobal()

    const handleSubmitForm = async (
        values: ResetPasswordFormData,
        formikActions: FormikActions<ResetPasswordFormData>
    ) => {
        const { error } = await requestPasswordReset(values.email)

        if (error) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            createNotification({
                description: t('error:response.resetPasswordUnknown'),
                type: 'Error'
            })
        } else {
            postAction()
        }
    }

    const initialValues: ResetPasswordFormData = {
        email: ''
    }

    const validationSchema = Yup.object().shape({
        email: Yup.string().required(t('error:validation.fieldRequired'))
    })

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleSubmitForm}
            validateOnBlur={false}
            validateOnChange={false}
            validationSchema={validationSchema}
        >
            {(formikProps) => {
                const emailError = FormikHelpers.getErrorMessage(
                    formikProps,
                    'email'
                )

                return (
                    <Form
                        className={classNames(
                            'resetPasswordCard__form',
                            'card small',
                            className
                        )}
                    >
                        <div className="card__logo" />

                        <div className="card__container">
                            <LabeledInputField
                                label={t('common:labels.emailAddress')}
                                name="email"
                                type="email"
                                onChange={formikProps.handleChange('email')}
                                value={formikProps.values.email}
                                message={emailError}
                                hasError={!!emailError}
                                key="loginEmailField"
                                autoFocus
                            />
                        </div>

                        <div className="card__footer">
                            <Button
                                className="normal"
                                loading={formikProps.isSubmitting}
                                primary
                                title={t(
                                    'common:card.resetPassword.action.primary'
                                )}
                                triggerEnter
                                type="submit"
                            />

                            <div className="resetPassword__link">
                                <NavLink to="/login">
                                    <span>
                                        {t(
                                            'common:card.resetPassword.action.secondary'
                                        )}
                                    </span>
                                </NavLink>
                            </div>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default compose<Props, OwnProps>(withTranslation(['common', 'error']))(
    ResetPasswordCard
)
