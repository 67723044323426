import { ListItemRender, RouteParams } from '@app/common'
import { SearchTermDetails } from '@app/searchTerm'
import { DropdownItem } from '@atlaskit/dropdown-menu'
import { Badge } from '@material-ui/core'
import LabeledButton from 'components/button/labeled/LabeledButton'
import Worksheet from 'components/worksheet/Worksheet'
import { useFetchCurrentUser } from 'helpers/accountHelpers'
import {
    isSearchTermSeenByCurrentUser,
    useFetchAllSearchTerms
} from 'helpers/searchTermHelpers'
import React, { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { useRouteMatch } from 'react-router-dom'
import { openDialogWrapper } from 'utils/commonUtils'
import { useGlobal } from '../../../hooks/useGlobal'
import {
    isAdmin,
    isMatchingAnyRole,
    isSales
} from '../../../helpers/roleHelpers'
import { useAuthentication } from '../../../hooks/useAuthentication'

export type SearchTermsWorksheetProps = PropsWithChildren<unknown>

export const SearchTermsWorksheet: React.FC<SearchTermsWorksheetProps> = ({
    children
}) => {
    const { t } = useTranslation(['searchTerms', 'common'])

    const { openDialog } = useGlobal()

    const { currentUser } = useFetchCurrentUser()

    const match = useRouteMatch<RouteParams>()

    const { searchTermList, status } = useFetchAllSearchTerms()

    const { getCurrentUserRoles } = useAuthentication()

    const currentUserRoles = getCurrentUserRoles()

    const primaryActionButton = (
        <LabeledButton
            title={t('labels.createSearch')}
            onClick={() => {
                openDialogWrapper(
                    null,
                    { type: 'CREATE_SEARCH_TERM' },
                    openDialog
                )
            }}
        />
    )

    const openDropdownDialog = (
        type: 'EDIT_SEARCH_TERM' | 'DELETE_SEARCH_TERM',
        data: SearchTermDetails
    ) => (event: MouseEvent | KeyboardEvent) =>
        openDialogWrapper(
            event,
            {
                type,
                payload: data
            },
            openDialog
        )

    const listItems: Array<ListItemRender> = searchTermList
        ? searchTermList.map((searchTerm) => ({
              id: searchTerm.id,
              title: searchTerm.name,
              subtitle:
                  searchTerm.type === 'GLOBAL'
                      ? t('searchTerms:types.TYPE_GLOBAL')
                      : searchTerm.type === 'PERSONALIZED'
                      ? t('searchTerms:types.TYPE_PERSONALIZED')
                      : t('searchTerms:types.TYPE_SHARED', {
                            ownerName: searchTerm.owner.name
                        }),
              badge: isSearchTermSeenByCurrentUser(
                  searchTerm,
                  currentUser,
                  true
              ) ? null : (
                  <Badge color="error" variant="dot">
                      <div className="listItem__badge" />
                  </Badge>
              ),
              disableActionMenu: !(
                  (isSales(currentUserRoles) &&
                      searchTerm.type === 'PERSONALIZED') ||
                  (isAdmin(currentUserRoles) && searchTerm.type !== 'SHARED')
              ),
              dropdownMenuContent: (
                  <>
                      <DropdownItem
                          id="edit"
                          onClick={openDropdownDialog(
                              'EDIT_SEARCH_TERM',
                              searchTerm
                          )}
                      >
                          {t('common:labels.edit')}
                      </DropdownItem>

                      <DropdownItem
                          id="delete"
                          onClick={openDropdownDialog(
                              'DELETE_SEARCH_TERM',
                              searchTerm
                          )}
                      >
                          {t('common:labels.delete')}
                      </DropdownItem>
                  </>
              )
          }))
        : []

    return (
        <Worksheet
            type="terms"
            listError={status === 'rejected'}
            listLoading={status === 'pending'}
            listItems={listItems}
            primaryActionButton={primaryActionButton}
            match={match}
        >
            {children}
        </Worksheet>
    )
}

export default SearchTermsWorksheet
