import { WorksheetNavigationType } from '@app/worksheet'
import classNames from 'classnames'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'

interface EmptyStateNavigationItemProps extends WithTranslation {
    hasError: boolean
    type: WorksheetNavigationType
}

const EmptyStateNavigationItem = (props: EmptyStateNavigationItemProps) => (
    <div
        className={classNames('worksheetNavigation__emptyState', {
            error: props.hasError
        })}
    >
        {props.hasError ? (
            <span>{props.t(`worksheet.error.${props.type}`)}</span>
        ) : (
            <span>{props.t(`worksheet.emptyState.${props.type}`)}</span>
        )}
    </div>
)

export default withTranslation('common')(EmptyStateNavigationItem)
