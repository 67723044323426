import classNames from 'classnames'
import withMaybe from 'hoc/withMaybe'
import React from 'react'

interface ActionButtonProps {
    action: () => void
    actionText: string
}

export interface Props {
    title: string
    subtitle: string
    className?: string
    icon?: React.ReactNode
    action?: () => void
    actionText?: string
}

const ActionButton = (props: ActionButtonProps) => (
    <span className="placeholder__action" onClick={props.action}>
        {props.actionText}
    </span>
)

const Placeholder = (props: Props) => {
    const OptionalActionButton = withMaybe<ActionButtonProps>(!!props.action)(
        ActionButton
    )

    return (
        <div className={classNames('placeholder', props.className)}>
            {props.icon && (
                <div className="placeholder__icon">{props.icon}</div>
            )}

            <span className="placeholder__title">{props.title}</span>
            <span className="placeholder__subtitle">{props.subtitle}</span>

            <OptionalActionButton
                action={props.action}
                actionText={props.actionText}
            />
        </div>
    )
}

export default Placeholder
