import { SelectInputValue } from '@app/common'
import { AssignSkillDialogFormData } from '@app/form'
import { SearchTermDetails } from '@app/searchTerm'
import { SkillDetails, SkillSetDetails, TemplateDetails } from '@app/template'
import { Form, FormikContext, FormikProps, useFormik } from 'formik'
import { FormikHelpers as FormikActions } from 'formik/dist/types'
import FormikHelpers from 'helpers/formikHelpers'
import {
    formatSkillSelector,
    getSkillDropdownItems,
    getTemplateDropdownItems,
    useFetchAllTemplates
} from 'helpers/templateHelpers'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {
    requestSingleSearchTermRevalidation,
    useRequestAssignSkillToSearchTerm
} from '../../../helpers/searchTermHelpers'
import { useGlobal } from '../../../hooks/useGlobal'
import Button from '../../button/base/Button'
import LabeledSelect from '../../input/labeledSelect/LabeledSelect'
import ToggleSwitch from '../../input/toggleSwitch/ToggleSwitch'
import Dialog from '../base/Dialog'

export interface AssignSkillDialogProps {
    searchTerm: SearchTermDetails
}

const AssignSkillDialog = (props: AssignSkillDialogProps) => {
    const { t } = useTranslation(['common', 'searchTerms'])
    const [selectedTemplateId, setSelectedTemplateId] = useState(null)
    const [selectedSkillSetId, setSelectedSkillSetId] = useState(null)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [skillSetList, setSkillSetList] = useState([])
    const [skillList, setSkillList] = useState([])
    const { templateList } = useFetchAllTemplates()
    const requestAssignSkillToSearchTerm = useRequestAssignSkillToSearchTerm()

    const {
        formDirty,
        toggleFormToDirty,
        closeActiveOverlay,
        tryCloseDirtyOverlay,
        createNotification,
        lastSkill,
        setLastSkill
    } = useGlobal()

    const onSubmit = async (
        values: AssignSkillDialogFormData,
        formikActions: FormikActions<AssignSkillDialogFormData>
    ) => {
        try {
            await requestAssignSkillToSearchTerm({
                searchTermId: props.searchTerm.id,
                skillId: values.skill.value,
                templateId: values.template.value
            })

            setLastSkill({
                node: null,
                addAnother: values.addAnother
            })

            if (values.addAnother) {
                setSelectedTemplate(null)
                setSelectedSkillSetId(null)
                setSelectedTemplateId(null)
                formikActions.resetForm()
                createNotification({
                    type: t('common:labels.success'),
                    description: t('templates:createSkill.success'),
                    hideOverlay: true,
                    timeout: 2500
                })
            } else {
                closeActiveOverlay()
            }
            await requestSingleSearchTermRevalidation(props.searchTerm.id)
        } catch (payload) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            createNotification({
                type: t('error:labels.error'),
                description: t('error:response.assignSkillUnknown')
            })
        }
    }

    const getSkillSetDropdownItems = (
        template: TemplateDetails,
        skillSetId: string
    ) => {
        if (!template || !template.childSkillSets || !skillSetId) {
            return []
        }

        return (
            template.childSkillSets
                .reduce(
                    (
                        skillSets: Array<SkillSetDetails>,
                        skillSet: SkillSetDetails
                    ) =>
                        [...skillSets, skillSet].concat(
                            skillSet.childSkillSets
                        ),
                    []
                )
                .find((skillSet) => skillSet.id === skillSetId)
                ?.skills.reduce(
                    (
                        dropdownItems: Array<SelectInputValue>,
                        skill: SkillDetails
                    ) => [
                        ...dropdownItems,
                        { label: skill.name, value: skill.id }
                    ],
                    []
                ) || []
        )
    }

    const initialValues: AssignSkillDialogFormData = {
        template: selectedTemplate
            ? { label: selectedTemplate.name, value: selectedTemplate.id }
            : null,
        skillSet: null,
        skill: null,
        addAnother: lastSkill.addAnother
    }

    const validationSchema = Yup.object().shape({
        template: Yup.object()
            .nullable()
            .required(t('error:validation.fieldRequired')),
        skillSet: Yup.object()
            .nullable()
            .required(t('error:validation.fieldRequired')),
        skill: Yup.object()
            .nullable()
            .required(t('error:validation.fieldRequired'))
    })

    const formik: FormikProps<AssignSkillDialogFormData> = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
        validateOnChange: true,
        enableReinitialize: true
    })

    const templateError = FormikHelpers.getErrorMessage(formik, 'template')

    const skillSetError = FormikHelpers.getErrorMessage(formik, 'skillSet')

    const skillError = FormikHelpers.getErrorMessage(formik, 'skill')

    useEffect(() => {
        if (selectedTemplateId) {
            setLastSkill({
                node: null,
                addAnother: formik.values.addAnother
            })

            formik.resetForm()
            setSkillList([])
            setSelectedSkillSetId(null)
            FormikHelpers.setDirtyForm(
                formik,
                'template',
                formDirty,
                toggleFormToDirty
            )(
                getTemplateDropdownItems(templateList).find(
                    (template) => template.value === selectedTemplateId
                )
            )
            setSelectedTemplate(
                templateList.find(
                    (template) => template.id === selectedTemplateId
                )
            )
        }
        // TODO: Check why adding the necessary dependencies puts this component
        // in an infinite loop
        // eslint-disable-next-line
    }, [selectedTemplateId])

    useEffect(() => {
        if (selectedTemplate) {
            setSkillSetList(getSkillDropdownItems(selectedTemplate))
        }
    }, [selectedTemplate])

    useEffect(() => {
        if (selectedSkillSetId && selectedTemplateId) {
            FormikHelpers.setDirtyForm(
                formik,
                'skillSet',
                formDirty,
                toggleFormToDirty
            )(
                getSkillDropdownItems(selectedTemplate).find(
                    (skillSet) => skillSet.value === selectedSkillSetId
                )
            )

            formik.setFieldValue('skill', null)

            setSkillList(
                getSkillSetDropdownItems(selectedTemplate, selectedSkillSetId)
            )
        }

        // TODO: Check why adding the necessary dependencies puts this component
        // in an infinite loop
        // eslint-disable-next-line
    }, [selectedSkillSetId])

    return (
        <FormikContext.Provider value={formik}>
            <Dialog
                title={t('searchTerms:addSkill.title')}
                subtitle=""
                closeDialog={tryCloseDirtyOverlay}
                showCloseIcon
            >
                <Form className="dialog__form">
                    <div className="dialog__scrollArea">
                        <LabeledSelect
                            label={t(
                                'searchTerms:addSkill.labels.templateLabel'
                            )}
                            name="template"
                            options={
                                getTemplateDropdownItems(templateList) || []
                            }
                            value={formik.values.template}
                            isClearable={false}
                            placeholder={t(
                                'searchTerms:addSkill.selectTemplatePlaceholder'
                            )}
                            hasError={!!templateError}
                            message={templateError}
                            onChange={(template: SelectInputValue) => {
                                setSelectedTemplateId(template.value)
                            }}
                            tabIndex={0}
                        />

                        <LabeledSelect
                            label={t(
                                'searchTerms:addSkill.labels.skillSetLabel'
                            )}
                            name="skillSet"
                            options={skillSetList}
                            value={formik.values.skillSet}
                            isClearable={false}
                            disabled={!selectedTemplateId}
                            placeholder={t('templates:labels.selectSkillSet', {
                                templateName:
                                    formik.values.template?.label ?? ''
                            })}
                            hasError={!!skillSetError}
                            message={skillSetError}
                            onChange={(skillSet: SelectInputValue) => {
                                setSelectedSkillSetId(skillSet.value)
                            }}
                            formatOptionLabel={formatSkillSelector}
                            tabIndex={0}
                        />
                        <LabeledSelect
                            label={t('searchTerms:addSkill.labels.skillLabel')}
                            name="skill"
                            options={skillList || []}
                            value={formik.values.skill}
                            isClearable={false}
                            disabled={!selectedSkillSetId}
                            placeholder={t(
                                'searchTerms:addSkill.selectSkillPlaceholder',
                                {
                                    skillSetName:
                                        formik.values.skillSet?.label ?? ''
                                }
                            )}
                            hasError={!!skillError}
                            message={skillError}
                            onChange={FormikHelpers.setDirtyForm(
                                formik,
                                'skill',
                                formDirty,
                                toggleFormToDirty
                            )}
                            tabIndex={0}
                        />
                    </div>

                    <div className="dialog__actions">
                        <div className="dialog__actionContainer">
                            <div className="dialog__toggleSwitch">
                                <ToggleSwitch
                                    selected={formik.values.addAnother}
                                    label={t('searchTerms:labels.addAnother')}
                                    onChange={(value) =>
                                        formik.setFieldValue(
                                            'addAnother',
                                            value
                                        )
                                    }
                                />
                            </div>
                            <div className="dialog__buttonContainer">
                                <Button
                                    title={t('common:labels.cancel')}
                                    onClick={closeActiveOverlay}
                                    formButton
                                />

                                <Button
                                    title={t('common:labels.add')}
                                    type="submit"
                                    loading={formik.isSubmitting}
                                    primary
                                    formButton
                                />
                            </div>
                        </div>
                    </div>
                </Form>
            </Dialog>
        </FormikContext.Provider>
    )
}

export default AssignSkillDialog
