import { MatrixSkill, MatrixSkillSet } from '@app/matrix'
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'
import { makeStyles } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'

export interface MatrixSkillSetSubtitleProps {
    skillSet: MatrixSkillSet
}

const numberOfFilledSkills: (matrixSkills: Array<MatrixSkill>) => number = (
    skills
) => skills.filter((skill) => skill.rating > 0).length

const useNumberOfMatrixSkillsStyles = makeStyles((theme) => ({
    completedLabel: {
        display: 'grid',
        gridAutoFlow: 'column',
        gap: theme.spacing(0.5),
        justifyContent: 'start',
        alignItems: 'center'
    }
}))

export const MatrixSkillSetSubtitle = (props: MatrixSkillSetSubtitleProps) => {
    const { t } = useTranslation('people')
    const classes = useNumberOfMatrixSkillsStyles()

    const skillSum = props.skillSet.childSkillSets.reduce(
        (sum, childSkillSet) => sum + childSkillSet.skills.length,
        props.skillSet.skills.length
    )

    const filledSkillSum = props.skillSet.childSkillSets.reduce(
        (sum, childSkillSets) =>
            sum + numberOfFilledSkills(childSkillSets.skills),
        numberOfFilledSkills(props.skillSet.skills)
    )

    const completed = skillSum === filledSkillSum

    const skillString = completed
        ? t('labels.completed')
        : t('labels.skill', {
              context: skillSum !== 0 ? 'notEmpty' : 'empty',
              filled: filledSkillSum,
              count: skillSum
          })

    return skillSum >= 1 ? (
        <span className={classes.completedLabel}>
            {completed && (
                <CheckCircleIcon
                    label="Checkmark in a filled circle"
                    primaryColor="#00cc69"
                    size="small"
                />
            )}

            <span>{skillString}</span>
        </span>
    ) : (
        <span>{t('templates:labels.skillSet_empty', { count: 0 })}</span>
    )
}
