import InfoCard from 'components/card/InfoCard'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export const CreateNewPasswordErrorCard = () => {
    const { t } = useTranslation(['common'])

    const cardAction = (
        <Link className="colored" to="/login">
            {t('card.createNewPasswordError.actionLabel')}
        </Link>
    )

    return (
        <InfoCard
            title={t('card.createNewPasswordError.title')}
            description={t('card.createNewPasswordError.description')}
            cardAction={cardAction}
        />
    )
}
