import { SelectInputValue } from '@app/common'
import { ShareSearchTermDialogFormData } from '@app/form'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import LabeledSelect from 'components/input/labeledSelect/LabeledSelect'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import FormikHelpers from 'helpers/formikHelpers'
import { getPeopleEmailAddressDropdownItems } from 'helpers/peopleHelpers'
import {
    useFetchUserList,
    useRequestSearchTermShare
} from 'helpers/searchTermHelpers'
import { useGlobal } from 'hooks/useGlobal'
import { TFunction } from 'i18next'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

export interface Props {
    searchTermId: string
    searchTermName: string
}

const initialValues: ShareSearchTermDialogFormData = {
    users: []
}

const validationSchema = (t: TFunction) =>
    Yup.object().shape({
        users: Yup.array()
            .of(
                Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string()
                })
            )
            .nullable()
            .required(t('error:validation.fieldRequired'))
    })

const ShareSearchTermDialog: React.FC<Props> = ({
    searchTermId,
    searchTermName
}) => {
    const {
        formDirty,
        toggleFormToDirty,
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const { t } = useTranslation(['common', 'searchTerms', 'error'])

    const { peopleList: allPeopleList, error } = useFetchUserList(searchTermId)

    if (error && error.data.status !== 403) {
        createNotification({
            description: t('error:response.peopleListLoadUnknown'),
            type: 'Error'
        })
    }

    const getUserDropdownOptions = () => {
        return getPeopleEmailAddressDropdownItems(allPeopleList)
    }

    const requestSearchTermShare = useRequestSearchTermShare()
    const onSubmit = async (
        values: ShareSearchTermDialogFormData,
        formikActions: FormikActions<ShareSearchTermDialogFormData>
    ) => {
        const { error: shareError } = await requestSearchTermShare({
            id: searchTermId,
            sharedWithList: values.users.map(
                (selectInputValue) => selectInputValue.value
            )
        })

        if (shareError) {
            // TODO: introduce logging
            formikActions.setSubmitting(false)
            createNotification({
                type: t('error:labels.error'),
                description: t('error:response.shareSearchTermUnknown', {
                    searchTermName
                })
            })
        } else {
            closeActiveOverlay()
            createNotification({
                type: t('common:labels:success'),
                description: t('searchTerms:shareSearchTerm.success'),
                hideOverlay: true,
                timeout: 4000
            })
        }
    }

    return (
        <Dialog
            title={t('searchTerms:shareSearchTerm.title', {
                name: searchTermName
            })}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
        >
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema(t)}
                onSubmit={onSubmit}
            >
                {(formikProps) => {
                    const usersError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'users'
                    )

                    return (
                        <Form className="dialog__form">
                            <LabeledSelect
                                name="users"
                                value={formikProps.values.users}
                                label={t('searchTerms:labels.users')}
                                options={getUserDropdownOptions()}
                                multiSelect
                                message={usersError}
                                hasError={!!usersError}
                                onChange={(values: Array<SelectInputValue>) =>
                                    FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'users',
                                        formDirty,
                                        toggleFormToDirty
                                    )(values)
                                }
                                tabIndex={0}
                            />

                            <div className="dialog__actions">
                                <Button
                                    title={t('common:labels.cancel')}
                                    onClick={closeActiveOverlay}
                                    formButton
                                />

                                <Button
                                    title={t('common:labels.share')}
                                    type="submit"
                                    loading={formikProps.isSubmitting}
                                    primary
                                    formButton
                                />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export default ShareSearchTermDialog
