import React from 'react'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import EmailIcon from '@atlaskit/icon/glyph/email'
import MobileIcon from '@atlaskit/icon/glyph/mobile'
import PersonIcon from '@atlaskit/icon/glyph/person-circle'
import { useTranslation } from 'react-i18next'
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel'
import {
    StyledGroupInfoPopover,
    useGroupInfoPopoverStyles
} from './GroupInfoPopover.styles'
import ExitButton from '../button/exit/ExitButton'
import { IconLabel } from '../atoms/IconLabel'
import { useFetchGroupById } from '../../helpers/groupHelpers'
import Button from '../button/Button'

const useStyles = makeStyles((theme) =>
    createStyles({
        typography: {
            padding: theme.spacing(2)
        }
    })
)

interface GroupInfoPopoverProps {
    groupId: string
}

const GroupInfoPopover = (props: GroupInfoPopoverProps) => {
    const classes = useGroupInfoPopoverStyles()
    const { t } = useTranslation(['common', 'groups'])
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )

    const { group, status } = useFetchGroupById(props.groupId)

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'group-info-popover' : undefined

    return (
        <>
            {group && (
                <div>
                    <Button
                        variant="primary"
                        startIcon={<EditorPanelIcon label="" />}
                        aria-describedby={id}
                        onClick={handleClick}
                    >
                        {t('groups:labels.aboutThisGroup')}
                    </Button>
                    <StyledGroupInfoPopover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                    >
                        <div>
                            <div className={classes.popoverHeader}>
                                <span className={classes.popoverHeaderText}>
                                    {group.name}
                                </span>
                                <ExitButton
                                    size="small"
                                    onClick={handleClose}
                                />
                            </div>
                            <IconLabel icon={<EmailIcon label="" />}>
                                {t('common:labels.emailAddress')}
                            </IconLabel>
                            <a
                                className="groupsContent__info groupsContent__info--link"
                                href={`mailto:${group.email}`}
                            >
                                {group.email}
                            </a>
                            {group.phoneNumber && (
                                <>
                                    <IconLabel icon={<MobileIcon label="" />}>
                                        {t('common:labels.phoneNumber')}
                                    </IconLabel>
                                    <a
                                        className="groupsContent__info groupsContent__info--link"
                                        href={`tel:${group.phoneNumber}`}
                                    >
                                        {group.phoneNumber}
                                    </a>
                                </>
                            )}

                            <IconLabel icon={<PersonIcon label="" />}>
                                {t('common:labels.member', {
                                    count: group.membersCount
                                })}
                            </IconLabel>
                            <span className="groupsContent__info">
                                {group.membersCount}
                            </span>
                        </div>
                    </StyledGroupInfoPopover>
                </div>
            )}
        </>
    )
}

export default GroupInfoPopover
