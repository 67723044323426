import { GroupDetails } from '@app/group'
import React from 'react'
import ReactPlaceholder from 'react-placeholder'

export interface GroupsHeaderProps {
    group: GroupDetails
    status: string
    error: Error
}

export const GroupsHeaderContent: React.FC<GroupsHeaderProps> = (
    props: GroupsHeaderProps
) => {
    const { group, status, error } = props

    if (status === 'idle') {
        return null
    }

    if (status === 'pending') {
        return (
            <ReactPlaceholder
                className="placeholder"
                showLoadingAnimation
                type="textRow"
                rows={1}
                ready={status !== 'pending'}
            >
                <></>
            </ReactPlaceholder>
        )
    }

    if (status === 'rejected') {
        throw error
    }

    return (
        <div className="worksheetContentBrowser__headerTitle text-ellipsis">
            {group && <span>{group.name}</span>}
        </div>
    )
}

export const GroupsHeader: React.FC<GroupsHeaderProps> = (
    props: GroupsHeaderProps
) => {
    const { group } = props

    return group ? (
        <div className="worksheetContentBrowser__header">
            <GroupsHeaderContent {...props} />
        </div>
    ) : null
}

export default GroupsHeader
