import classNames from 'classnames'
import ActivityIndicator from 'components/activityIndicator/ActivityIndicator'
import React, { useCallback, useEffect } from 'react'
import { executeWithPreventDefault } from 'utils/commonUtils'

export interface OwnProps {
    title: string
    loading?: boolean
    primary?: boolean
    triggerEnter?: boolean
    formButton?: boolean
    smallButton?: boolean
    icon?: string
    destructive?: boolean
    type?: 'button' | 'submit' | 'reset'
    onClick?: () => void
}

export type ButtonProps = OwnProps & React.HTMLProps<HTMLButtonElement>

export const Button: React.FC<ButtonProps> = (props: ButtonProps) => {
    const {
        className,
        primary,
        formButton,
        smallButton,
        destructive,
        loading,
        triggerEnter,
        ...rest
    } = props

    const handleKeyDown = useCallback(
        (event: KeyboardEvent) => {
            if (
                !props.loading &&
                props.onClick &&
                event.key === 'Enter' &&
                // these do not belong here - change my mind
                document.activeElement &&
                document.activeElement.tagName !== 'TEXTAREA'
            ) {
                executeWithPreventDefault(event)(props.onClick)
            }
        },
        [props.loading, props.onClick]
    )

    useEffect(() => {
        return () => {
            if (triggerEnter) {
                document.removeEventListener('keydown', handleKeyDown)
            }
        }
    }, [handleKeyDown, triggerEnter])

    useEffect(() => {
        if (triggerEnter) {
            document.addEventListener('keydown', handleKeyDown)
        }
    }, [handleKeyDown, triggerEnter])

    const buttonContent = loading ? <ActivityIndicator /> : props.title

    const imageContent = props.icon ? <img src={props.icon} alt="Button" /> : ''

    return (
        <button
            type="button"
            {...rest}
            className={classNames(
                className,
                primary ? 'primaryButton' : 'defaultButton',
                formButton && 'formButton',
                smallButton && 'smallButton',
                { destructive },
                { disabled: rest.disabled }
            )}
            disabled={loading || rest.disabled}
        >
            {imageContent}
            {buttonContent}
        </button>
    )
}

export default Button
