import InfoCard from 'components/card/InfoCard'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const TokenErrorCard = (props: WithTranslation) => {
    const cardAction = (
        <Link className="colored" to="/login">
            {props.t('card.tokenError.actionLabel')}
        </Link>
    )

    return (
        <InfoCard
            title={props.t('card.tokenError.title')}
            description={props.t('card.tokenError.description')}
            cardAction={cardAction}
        />
    )
}

export default withTranslation('common')(TokenErrorCard)
