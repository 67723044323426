import { Optional } from '@app/common'
import { ErrorMessage } from 'components/atoms'
import { useFetchCurrentUser } from 'helpers/accountHelpers'
import React, { useEffect } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import {
    isSearchTermSeenByCurrentUser,
    requestAllSearchTermRevalidation,
    useFetchSearchTermById,
    useRequestSearchTermSeen
} from '../../../helpers/searchTermHelpers'
import SearchTermsContent from './SearchTermsContent'
import SearchTermsHeader from './SearchTermsHeader'

export interface SearchTermContentBrowserProps {
    searchTermId?: Optional<string>
}

export const SearchTermsContentBrowser: React.FC<SearchTermContentBrowserProps> = ({
    searchTermId
}) => {
    const { searchTerm, status, error } = useFetchSearchTermById(searchTermId)

    const { currentUser } = useFetchCurrentUser()

    const requestSearchTermSeen = useRequestSearchTermSeen()

    useEffect(() => {
        async function setSearchTermSeen() {
            await requestSearchTermSeen(searchTerm.id)
            requestAllSearchTermRevalidation()
        }

        if (!isSearchTermSeenByCurrentUser(searchTerm, currentUser, true)) {
            setSearchTermSeen()
        }
    }, [searchTerm, currentUser, requestSearchTermSeen])

    return (
        <div className="worksheetContentBrowser__content">
            <ErrorBoundary
                fallbackRender={({ error: boundaryError }) => (
                    <ErrorMessage>{boundaryError.message}</ErrorMessage>
                )}
            >
                <SearchTermsHeader searchTerm={searchTerm} status={status} />

                <SearchTermsContent
                    searchTerm={searchTerm}
                    status={status}
                    error={error}
                />
            </ErrorBoundary>
        </div>
    )
}

export default SearchTermsContentBrowser
