import { SelectInputValue } from '@app/common'
import classNames from 'classnames'
import withMaybe from 'hoc/withMaybe'
import React, { ReactNode } from 'react'
import CreatableSelect from 'react-select/creatable'

interface MessageLabelProps {
    message: string
    hasError: boolean
}

const renderMessageLabel = (props: MessageLabelProps) => (
    <span
        className={classNames('creatableSelect__message', {
            error: props.hasError
        })}
    >
        {props.message}
    </span>
)

interface CreateableSelectProps {
    label: string
    name: string
    options: Array<SelectInputValue>
    value: Array<SelectInputValue>
    isClearable?: boolean
    onChange: (values: Array<SelectInputValue>) => void
    onCreate?: (value: string) => void
    message?: string
    hasError?: boolean
    multiSelect?: boolean
    placeholder?: string
    createLabel?: (value: string) => ReactNode
    tabIndex?: number
}

const CreateableSelect = (props: CreateableSelectProps) => {
    const MessageLabel = withMaybe<MessageLabelProps>(!!props.message)(
        renderMessageLabel
    )

    return (
        <div className="creatableSelect">
            <label className="creatableSelect__label" htmlFor={props.name}>
                {props.label}
            </label>

            <div className="creatableSelect__wrapper">
                <CreatableSelect
                    id={props.name}
                    name={props.name}
                    classNamePrefix="creatableSelect__input"
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 })
                    }}
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null
                    }}
                    isClearable={props.isClearable}
                    label={props.label}
                    onChange={props.onChange}
                    onCreateOption={props.onCreate}
                    options={props.options}
                    value={props.value}
                    isMulti={props.multiSelect}
                    tabIndex={props.tabIndex.toString()}
                    placeholder={props.placeholder}
                    menuPortalTarget={document.body}
                    formatCreateLabel={props.createLabel}
                />
            </div>

            <MessageLabel hasError={props.hasError} message={props.message} />
        </div>
    )
}

export default CreateableSelect
