import { ThemeProvider } from '@material-ui/core/styles'
import { AppModeProvider } from 'contexts/AppModeContext'
import { AuthenticationProvider } from 'contexts/AuthenticationContext'
import { ClientProvider } from 'contexts/ClientContext'
import { GlobalProvider } from 'contexts/GlobalContext'
import theme from 'material/theme'
import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.scss'
import * as serviceWorker from './serviceWorker'
import i18n from './translations/i18n'

ReactDOM.render(
    <BrowserRouter>
        <I18nextProvider i18n={i18n}>
            <ThemeProvider theme={theme}>
                <GlobalProvider>
                    <AppModeProvider>
                        <AuthenticationProvider>
                            <ClientProvider>
                                <App />
                            </ClientProvider>
                        </AuthenticationProvider>
                    </AppModeProvider>
                </GlobalProvider>
            </ThemeProvider>
        </I18nextProvider>
    </BrowserRouter>,
    document.getElementById('root')
)

serviceWorker.register({
    // eslint-disable-next-line
    onSuccess: () => console.info('[Info] Successfully started service worker.')
})
