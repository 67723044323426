import { RoleSelectInputValue, SelectInputValue } from '@app/common'
import { EditUserDialogFormData } from '@app/form'
import { PersonCreate, PersonDetails, PersonUpdate } from '@app/person'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import LabeledInputField from 'components/input/labeledInputField/LabeledInputField'
import LabeledSelect from 'components/input/labeledSelect/LabeledSelect'
import LevelSelector from 'components/input/levelSelector/LevelSelector'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import FormikHelpers from 'helpers/formikHelpers'
import { useFetchAllGroups } from 'helpers/groupHelpers'
import {
    requestAllPeopleRevalidation,
    requestSinglePersonRevalidation,
    useRequestPersonInvitation,
    useRequestPersonUpdate
} from 'helpers/peopleHelpers'
import { getRoleOptions, isUser } from 'helpers/roleHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import * as Yup from 'yup'
import { ROLE_USER } from '../../../constants'

interface OwnProps {
    person?: PersonDetails
}

export type Props = OwnProps & WithTranslation

const EditUserDialog = (props: Props) => {
    const { t, person } = props

    const {
        formDirty,
        toggleFormToDirty,
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const roleOptions: Array<RoleSelectInputValue> = getRoleOptions(t)

    const { groupList, error, status } = useFetchAllGroups()

    if (status === 'rejected' && error.status !== 403) {
        createNotification({
            description: t('error:response.groupListLoadUnknown'),
            type: 'Error'
        })
    }

    const dialogType = t(
        person ? 'people:editUser.title' : 'people:inviteUser.title'
    )
    const dialogSubtitle = t(person ? '' : 'people:inviteUser.subtitle')
    const submitTitle = t(
        person ? 'common:labels.save' : 'common:labels.invite'
    )

    const groupOptions = groupList.map((group) => ({
        label: group.name,
        value: group.id
    }))

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('error:validation.fieldRequired')),
        email: Yup.string()
            .email(t('error:validation.emailInvalid'))
            .required(t('error:validation.fieldRequired')),
        roles: Yup.array()
            .of(
                Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string()
                })
            )
            .nullable()
            .required(t('error:validation.fieldRequired')),
        rank: Yup.number(),
        groups: Yup.array()
            .of(
                Yup.object().shape({
                    label: Yup.string(),
                    value: Yup.string()
                })
            )
            .nullable()
            .required(t('error:validation:fieldRequired'))
    })

    const initialValues = () => {
        let groups = []

        if (person) {
            if (person.groups.length > 0) {
                groups = person.groups.map((group) => ({
                    label:
                        groupList?.find(
                            (groupListItem) => groupListItem.id === group.id
                        )?.name ?? '',
                    value: group.id
                }))
            }

            return {
                name: person.name,
                email: person.email,
                roles: person.roles.map((role) => ({
                    label: t(`roles.${role}`),
                    value: role
                })),
                rank: person.rank,
                groups
            }
        }

        return {
            name: '',
            email: '',
            roles: [roleOptions[0]],
            rank: 1,
            groups
        }
    }

    const requestPersonUpdate = useRequestPersonUpdate()
    const requestPersonInvitation = useRequestPersonInvitation()
    const onSubmit = async (
        values: EditUserDialogFormData,
        formikActions: FormikActions<EditUserDialogFormData>
    ) => {
        try {
            const personCreate: PersonCreate = {
                name: values.name,
                email: values.email,
                roles: values.roles.map((role) => role.value),
                rank: values.rank,
                groups: values.groups
                    .map((group) => {
                        const selectedGroup = groupList.find(
                            (groupListItem) => groupListItem.id === group.value
                        )
                        if (selectedGroup) {
                            return {
                                id: selectedGroup.id,
                                name: selectedGroup.name
                            }
                        }

                        return null
                    })
                    .filter(Boolean)
            }

            if (!personCreate.roles.includes(ROLE_USER)) {
                personCreate.rank = 5
            }

            if (person) {
                const personUpdate: PersonUpdate = {
                    id: person.id,
                    ...personCreate
                }

                await requestPersonUpdate(personUpdate)
            } else {
                await requestPersonInvitation(personCreate)
            }

            if (person) {
                await requestSinglePersonRevalidation(props.person.id)
            }

            await requestAllPeopleRevalidation()
            closeActiveOverlay()
        } catch (action) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            const { message } = action.error.response.data

            if (
                message === 'registration.already.exists' ||
                message === 'user.already.registered'
            ) {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.emailAlreadyRegistered')
                })
            } else if (action.type === 'LOAD_PEOPLE_LIST_FAIL') {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.peopleListUpdateUnknown')
                })
            } else {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.editUserUnknown', {
                        username: values.name
                    })
                })
            }
        }
    }

    return (
        <Dialog
            title={dialogType}
            subtitle={dialogSubtitle}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
        >
            <Formik
                initialValues={initialValues()}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnChange
                enableReinitialize
            >
                {(formikProps) => {
                    const nameError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'name'
                    )

                    const emailError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'email'
                    )

                    const rolesError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'roles'
                    )

                    const groupsError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'groups'
                    )

                    return (
                        <Form className="dialog__form">
                            <div className="dialog__scrollArea dialog__scrollArea--smallBottomMargin">
                                <LabeledInputField
                                    label={t('people:labels.fullName')}
                                    name="name"
                                    onChange={FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'name',
                                        formDirty,
                                        toggleFormToDirty
                                    )}
                                    value={formikProps.values.name}
                                    message={nameError}
                                    hasError={!!nameError}
                                    tabIndex={0}
                                    autoFocus
                                />

                                <LabeledInputField
                                    label={t('people:labels.email')}
                                    name="email"
                                    type="email"
                                    onChange={FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'email',
                                        formDirty,
                                        toggleFormToDirty
                                    )}
                                    value={formikProps.values.email}
                                    message={emailError}
                                    hasError={!!emailError}
                                    tabIndex={0}
                                />

                                <LabeledSelect
                                    label={t('people:labels.roles')}
                                    name="roles"
                                    options={roleOptions}
                                    value={formikProps.values.roles}
                                    isClearable={false}
                                    onChange={(
                                        values: Array<RoleSelectInputValue>
                                    ) =>
                                        FormikHelpers.setDirtyForm(
                                            formikProps,
                                            'roles',
                                            formDirty,
                                            toggleFormToDirty
                                        )(values)
                                    }
                                    message={rolesError}
                                    hasError={!!rolesError}
                                    multiSelect
                                    tabIndex={0}
                                />

                                {isUser(formikProps.values.roles) && (
                                    <LevelSelector
                                        label={t('people:labels.level')}
                                        name="level"
                                        value={formikProps.values.rank}
                                        onClick={FormikHelpers.setDirtyForm(
                                            formikProps,
                                            'rank',
                                            formDirty,
                                            toggleFormToDirty
                                        )}
                                    />
                                )}

                                <LabeledSelect
                                    label={t('people:labels:groups')}
                                    name="groups"
                                    options={groupOptions}
                                    value={formikProps.values.groups}
                                    isClearable={false}
                                    onChange={(
                                        values: Array<SelectInputValue>
                                    ) =>
                                        FormikHelpers.setDirtyForm(
                                            formikProps,
                                            'groups',
                                            formDirty,
                                            toggleFormToDirty
                                        )(values)
                                    }
                                    message={groupsError}
                                    hasError={!!groupsError}
                                    multiSelect
                                    tabIndex={0}
                                />
                            </div>

                            <div className="dialog__actions">
                                <Button
                                    title={t('common:labels.cancel')}
                                    onClick={closeActiveOverlay}
                                    formButton
                                />

                                <Button
                                    title={submitTitle}
                                    loading={formikProps.isSubmitting}
                                    type="submit"
                                    primary
                                    formButton
                                />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export default compose<Props, OwnProps>(
    withTranslation(['common', 'people', 'error'])
)(EditUserDialog)
