import { SelectInputValue } from '@app/common'
import { CreateGroupDialogFormData } from '@app/form'
import Dialog from 'components/dialog/base/Dialog'
import GroupForm from 'components/groupForm/GroupForm'
import { FormikHelpers as FormikActions } from 'formik'
import {
    requestAllGroupsRevalidation,
    useRequestGroupCreate
} from 'helpers/groupHelpers'
import {
    getPeopleEmailAddressDropdownItems,
    useFetchAllPeople
} from 'helpers/peopleHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const CreateGroupDialog = () => {
    const { t } = useTranslation(['common', 'groups', 'error'])

    const {
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const [inviteAdmin, setInviteAdmin] = useState('')

    const initialValues: CreateGroupDialogFormData = {
        name: '',
        email: '',
        phoneNumber: '',
        administrator: null,
        fullName: '',
        roles: [],
        level: 1
    }

    const { peopleList, error } = useFetchAllPeople()

    if (error && error.data.status !== 403) {
        createNotification({
            description: t('error:response.peopleListLoadUnknown'),
            type: 'Error'
        })
    }

    const getAdministratorDropdownOptions = () => {
        const peopleDropdownList = getPeopleEmailAddressDropdownItems(
            peopleList
        )
        if (
            inviteAdmin.trim() !== '' &&
            !peopleDropdownList.find((person) => person.label === inviteAdmin)
        ) {
            const invitableAdmin: SelectInputValue = {
                label: `${inviteAdmin} (${t('common:labels.invite')})`,
                value: inviteAdmin
            }

            return [invitableAdmin].concat(peopleDropdownList)
        }

        return peopleDropdownList
    }

    const requestGroupCreate = useRequestGroupCreate()
    const onSubmit = async (
        values: CreateGroupDialogFormData,
        formikActions: FormikActions<CreateGroupDialogFormData>
    ) => {
        const { error: groupError, status } = await requestGroupCreate({
            name: values.name,
            email: values.email,
            phoneNumber: values.phoneNumber,
            groupAdmin: !inviteAdmin
                ? {
                      email: values.administrator.label
                  }
                : {
                      email: inviteAdmin,
                      name: values.fullName,
                      rank: values.level,
                      roles: values.roles.map((role) => role.value)
                  }
        })

        if (status === 'resolved') {
            await requestAllGroupsRevalidation()
            closeActiveOverlay()
        } else if (status === 'rejected') {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            if (groupError.data === 'group.already.exists') {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.duplicateGroupName')
                })
            } else {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.createGroupUnknown', {
                        groupName: values.name
                    })
                })
            }
        }
    }

    return (
        <Dialog
            title={t('groups:createGroup.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
        >
            <GroupForm
                initialValues={initialValues}
                getAdministratorDropdownOptions={getAdministratorDropdownOptions()}
                onSubmit={onSubmit}
                inviteAdmin={inviteAdmin}
                setInviteAdmin={setInviteAdmin}
            />
        </Dialog>
    )
}

export default CreateGroupDialog
