import { useClient } from 'hooks/useClient'
import { SearchBarOption } from '@app/common'
import { tryMutation } from './apiHelpers'

const SEARCH_BASE_URL = '/api/search'

function useRequestFilteredSearchBarList() {
    const { client } = useClient()

    return (filterInput: string) =>
        tryMutation(async () => {
            const response = await client.get<Array<SearchBarOption>>(
                `${SEARCH_BASE_URL}?searchPattern=${filterInput}`
            )
            return response.data
        })
}

export { useRequestFilteredSearchBarList }
