import { Optional } from '@app/common'
import { ErrorMessage } from 'components/atoms'
import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useFetchGroupById } from '../../../helpers/groupHelpers'
import { GroupsContent } from './GroupsContent'
import { GroupsHeader } from './GroupsHeader'

interface OwnProps {
    groupId?: Optional<string>
}

export const GroupsContentBrowser: React.FC<OwnProps> = ({ groupId }) => {
    const singleGroupResponse = useFetchGroupById(groupId)
    return (
        <div className="worksheetContentBrowser__content">
            <ErrorBoundary
                fallbackRender={({ error }) => (
                    <ErrorMessage>{error.message}</ErrorMessage>
                )}
            >
                <GroupsHeader {...singleGroupResponse} />

                <GroupsContent {...singleGroupResponse} />
            </ErrorBoundary>
        </div>
    )
}

export default GroupsContentBrowser
