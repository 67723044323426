import { ListItemRender, RouteParams } from '@app/common'
import { DropdownItem } from '@atlaskit/dropdown-menu'
import LabeledButton from 'components/button/labeled/LabeledButton'
import Worksheet from 'components/worksheet/Worksheet'
import { useFetchAllTemplates } from 'helpers/templateHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { match, Route, Switch } from 'react-router-dom'
import { openDialogWrapper } from 'utils/commonUtils'
import TemplateContentBrowser from './TemplateContentBrowser'

interface Props extends WithTranslation {
    match: match<RouteParams>
}

const TemplateWorksheet = (props: Props) => {
    const { openDialog } = useGlobal()

    const { templateList, status } = useFetchAllTemplates()

    const primaryActionButton = (
        <LabeledButton
            title={props.t('templates:createTemplate.title')}
            onClick={() =>
                openDialogWrapper(null, { type: 'CREATE_TEMPLATE' }, openDialog)
            }
        />
    )

    const listItems: Array<ListItemRender> = (templateList ?? []).map(
        (template) => {
            const linkedTemplate =
                template &&
                template.linkedTemplateId &&
                (templateList ?? []).find(
                    (templateListItem) =>
                        template &&
                        templateListItem.id === template.linkedTemplateId
                )

            return {
                id: template.id,
                title: template.name,
                secondaryTitle: linkedTemplate
                    ? props.t(
                          'templates:contentBrowser.linkedTemplate.linkedTemplateName',
                          {
                              linkedTemplateName: linkedTemplate.name
                          }
                      )
                    : '',
                labels: template.labels.map((label) => label.name),
                dropdownMenuContent: (
                    <>
                        <DropdownItem
                            id="edit"
                            onClick={(event) =>
                                openDialogWrapper(
                                    event,
                                    {
                                        type: 'EDIT_TEMPLATE',
                                        payload: template
                                    },
                                    openDialog
                                )
                            }
                        >
                            {props.t('common:labels.edit')}
                        </DropdownItem>

                        <DropdownItem
                            id="delete"
                            onClick={(event) =>
                                openDialogWrapper(
                                    event,
                                    {
                                        type: 'DELETE_TEMPLATE',
                                        payload: template
                                    },
                                    openDialog
                                )
                            }
                        >
                            {props.t('common:labels.delete')}
                        </DropdownItem>
                    </>
                )
            }
        }
    )

    return (
        <Worksheet
            match={props.match}
            listLoading={status === 'pending'}
            listError={status === 'rejected'}
            listItems={listItems}
            primaryActionButton={primaryActionButton}
            type="templates"
        >
            <Switch>
                <Route
                    exact
                    path={`${props.match.path}/:id`}
                    render={(routerProps) => (
                        <TemplateContentBrowser
                            {...routerProps}
                            key={routerProps.match.params.id}
                            openDialog={openDialog}
                        />
                    )}
                />

                <Route
                    exact
                    path={props.match.path}
                    render={(routerProps) => (
                        <TemplateContentBrowser
                            {...routerProps}
                            openDialog={openDialog}
                        />
                    )}
                />
            </Switch>
        </Worksheet>
    )
}

export default withTranslation(['templates', 'common'])(TemplateWorksheet)
