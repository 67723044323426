const searchTerms = {
    title: 'Search Terms',
    labels: {
        name: 'Name',
        nameDescription: 'Must be unique',
        type: 'Type',
        createSearch: 'Create search term',
        addAnother: 'Add another',
        users: 'Users',
        share: 'Share search term'
    },
    createSearchTerm: {
        title: 'Create search term'
    },
    editSearchTerm: {
        title: 'Edit search term'
    },
    types: {
        TYPE_GLOBAL: 'Global search term',
        TYPE_PERSONALIZED: 'Personal search term',
        TYPE_SHARED: 'Shared by {{ownerName}}'
    },
    contentBrowser: {
        emptyState: {
            content: {
                title: 'Create search terms',
                subtitle:
                    'You can pre-assemble search terms which will be useful when searching for specific skills.',
                action: 'Create search term'
            }
        },
        emptySearchTerm: {
            forAdmin: {
                content: {
                    title: 'Add new skills',
                    subtitle:
                        'Add skills to this search term to make searching for people easier.',
                    action: 'Add skill'
                }
            },
            forSales: {
                content: {
                    title: 'Global search term',
                    subtitle:
                        'Unfortunately there is no skill assigned to this search term. You will see all the assigned skills once an admin adds them.'
                }
            }
        },
        error: {
            notFound: {
                title: 'Search term not found',
                subtitle:
                    "You've tried to check the details of a search term that doesn't exist."
            }
        }
    },
    deleteSearchTerm: {
        title: 'Delete search term',
        question:
            'Are you sure you want to remove <0>{{searchTermName}}</0> search term? You will not be able to restore the search term unless you create it again from scratch.',
        action: 'Enter the search term’s name to delete'
    },
    shareSearchTerm: {
        title: 'Share {{name}}',
        success: 'Search term shared successfully.'
    },
    addSkill: {
        title: 'Add skill',
        labels: {
            templateLabel: 'Template',
            skillSetLabel: 'Skill set',
            skillLabel: 'Skill'
        },
        selectTemplatePlaceholder: 'Select a template',
        selectSkillPlaceholder:
            'Select a skill of selected {{skillSetName}} skill set'
    },
    unAssignSkillSet: {
        title: 'Unassign skill set',
        question:
            'Are you sure you want to unassign {{skillSetName}} skill set from the search term? All of its included skills will be unassigned too.'
    },
    unAssignSkill: {
        title: 'Unassign skill',
        question:
            'Are you sure you want to unassign {{skillName}} skill from the search term?'
    }
}

export default searchTerms
