import { ErrorData, FetchResponse } from '@app/api'
import { ComponentStatus } from '@app/common'
import useSWR from 'swr'

const PING_URL = '/api/ping'

function determineComponentStatus<D = any, E = any>(
    data: D,
    error: E
): ComponentStatus {
    if (error) return 'rejected'
    if (!data) return 'pending'
    if (!error && data !== undefined) return 'resolved'
    return 'idle'
}

function useCheckServices() {
    const { error } = useSWR(PING_URL, {
        onErrorRetry: (_error, _key, _config, revalidate, { retryCount }) => {
            // Retry after a minute
            setTimeout(() => {
                revalidate({ retryCount })
            }, 60000)
        }
    })

    return {
        data: null,
        error,
        status: determineComponentStatus(null, error)
    }
}

async function tryMutation<T>(
    apiCall: () => Promise<T>
): Promise<FetchResponse<T>> {
    try {
        const data = await apiCall()

        return {
            data,
            error: null,
            status: determineComponentStatus(data, null)
        }
    } catch (error) {
        const customError: ErrorData = {
            data: error.data,
            status: error.status
        }

        return {
            data: null,
            error: customError,
            status: determineComponentStatus(null, customError)
        }
    }
}

export { useCheckServices, determineComponentStatus, tryMutation }
