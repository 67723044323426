import React from 'react'

const ActivityIndicator = () => (
    <div className="activityIndicator" role="progressbar">
        <div className="activityIndicator__bounce activityIndicator__bounce--longDelay" />
        <div className="activityIndicator__bounce activityIndicator__bounce--shortDelay" />
        <div className="activityIndicator__bounce" />
    </div>
)

export default ActivityIndicator
