import { SelectInputValue } from '@app/common'
import { EditGroupDialogFormData } from '@app/form'
import { GroupUpdate } from '@app/group'
import Dialog from 'components/dialog/base/Dialog'
import GroupForm from 'components/groupForm/GroupForm'
import { FormikHelpers as FormikActions } from 'formik'
import {
    requestAllGroupsRevalidation,
    requestSingleGroupRevalidation,
    useRequestGroupUpdate
} from 'helpers/groupHelpers'
import {
    getPeopleEmailAddressDropdownItems,
    useFetchAllPeople
} from 'helpers/peopleHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface OwnProps {
    group: GroupUpdate
}

const EditGroupDialog = (props: OwnProps) => {
    const { t } = useTranslation(['common', 'groups', 'error'])

    const {
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const [inviteAdmin, setInviteAdmin] = useState('')

    const { peopleList, error } = useFetchAllPeople()

    if (error && error?.data.status !== 403) {
        createNotification({
            description: t('error:response.peopleListLoadUnknown'),
            type: 'Error'
        })
    }

    const initialValues = React.useMemo(
        () => ({
            id: props.group.id || '',
            name: props.group.name || '',
            email: props.group.email || '',
            phoneNumber: props.group.phoneNumber || '',
            administrator: getPeopleEmailAddressDropdownItems(peopleList).find(
                (people) => people.value === props.group.groupAdminId
            ),
            fullName: '',
            roles: [],
            level: 1
        }),
        [
            props.group.id,
            props.group.name,
            props.group.email,
            props.group.phoneNumber,
            props.group.groupAdminId,
            peopleList
        ]
    )

    function getAdministratorDropdownOptions() {
        const peopleDropdownList = getPeopleEmailAddressDropdownItems(
            peopleList
        )
        if (
            inviteAdmin.trim() !== '' &&
            !peopleDropdownList.find((person) => person.value === inviteAdmin)
        ) {
            const invitableAdmin: SelectInputValue = {
                label: `${inviteAdmin} (${t('common:labels.invite')})`,
                value: inviteAdmin
            }

            return [invitableAdmin].concat(peopleDropdownList)
        }

        return peopleDropdownList
    }

    const requestGroupUpdate = useRequestGroupUpdate()
    const onSubmit = async (
        values: EditGroupDialogFormData,
        formikActions: FormikActions<EditGroupDialogFormData>
    ) => {
        const { error: groupError, status } = await requestGroupUpdate({
            id: props.group.id,
            name: values.name,
            email: values.email,
            phoneNumber: values.phoneNumber,
            groupAdmin: !inviteAdmin
                ? {
                      email: values.administrator.label
                  }
                : {
                      email: inviteAdmin,
                      name: values.fullName,
                      rank: values.level,
                      roles: values.roles.map((role) => role.value)
                  }
        })

        if (status === 'resolved') {
            await requestAllGroupsRevalidation()
            await requestSingleGroupRevalidation(values.id)
            closeActiveOverlay()
        } else if (status === 'rejected') {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            if (groupError.data === 'group.already.exists') {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.duplicateGroupName')
                })
            } else {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.createGroupUnknown', {
                        groupName: values.name
                    })
                })
            }
        }
    }

    return (
        <Dialog
            title={t('groups:editGroup.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
        >
            <GroupForm
                initialValues={initialValues}
                getAdministratorDropdownOptions={getAdministratorDropdownOptions()}
                onSubmit={onSubmit}
                inviteAdmin={inviteAdmin}
                setInviteAdmin={setInviteAdmin}
            />
        </Dialog>
    )
}

export default EditGroupDialog
