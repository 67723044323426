import { Notification, NotificationConfig } from '@app/common'
import { DialogConfig } from '@app/dialog'
import { LastTemplateData } from '@app/template'
import React, { createContext, useState } from 'react'
import * as v4 from 'uuid/v4'

interface GlobalContextProps {
    formDirty: boolean
    toggleFormToDirty: VoidFunction
    shouldShowConfirmation: boolean
    showConfirmation: VoidFunction
    hideConfirmation: VoidFunction
    activeNotification: Notification
    createNotification: (config: NotificationConfig) => void
    destroyNotification: (notificationId: string) => void
    activeDialog: DialogConfig
    openDialog: (config: DialogConfig) => void
    closeActiveOverlay: VoidFunction
    tryCloseDirtyOverlay: VoidFunction
    redirectUrl: string
    setRedirectUrl: (redirectUrl: string) => void
    lastSkillSet: LastTemplateData
    setLastSkillSet: (lastSkillSet: LastTemplateData) => void
    lastSkill: LastTemplateData
    setLastSkill: (lastSkill: LastTemplateData) => void
}

export const GlobalContext = createContext<GlobalContextProps>(null)

export const GlobalProvider: React.FC = ({ children }) => {
    const [formDirty, setFormDirty] = useState(false)

    function toggleFormToDirty() {
        setFormDirty(true)
    }

    const [shouldShowConfirmation, setShouldShowConfirmation] = useState(false)

    function showConfirmation() {
        setShouldShowConfirmation(true)
    }

    function hideConfirmation() {
        setShouldShowConfirmation(false)
    }

    const [activeNotification, setActiveNotification] = useState<Notification>(
        null
    )

    function createNotification(config: NotificationConfig) {
        const id = v4.default()
        const { activeElement } = document

        // Removing focus from active element on dialog appearance
        if (activeElement && activeElement instanceof HTMLInputElement) {
            activeElement.blur()
        }

        const notification: Notification = { id, ...config }
        setActiveNotification(notification)
    }

    function destroyNotification(notificationId: string) {
        if (activeNotification && activeNotification.id === notificationId) {
            setActiveNotification(null)
        }
    }

    const [activeDialog, setActiveDialog] = useState<DialogConfig>(null)

    function openDialog(config: DialogConfig) {
        setActiveDialog(config)
    }

    function closeActiveOverlay() {
        if (activeNotification) {
            setActiveNotification(null)
        } else if (activeDialog) {
            setActiveDialog(null)
            setFormDirty(false)
            setShouldShowConfirmation(false)
        }
    }

    const tryCloseDirtyOverlay = () =>
        formDirty && !shouldShowConfirmation
            ? showConfirmation()
            : closeActiveOverlay()

    const [redirectUrl, setRedirectUrl] = useState('')

    const initialLastTemplateData: LastTemplateData = {
        node: {
            parentId: '',
            templateId: ''
        },
        createAnother: false
    }
    const [lastSkillSet, setLastSkillSet] = useState(initialLastTemplateData)
    const [lastSkill, setLastSkill] = useState(initialLastTemplateData)

    return (
        <GlobalContext.Provider
            value={{
                formDirty,
                toggleFormToDirty,
                shouldShowConfirmation,
                showConfirmation,
                hideConfirmation,
                activeNotification,
                createNotification,
                destroyNotification,
                activeDialog,
                openDialog,
                closeActiveOverlay,
                tryCloseDirtyOverlay,
                redirectUrl,
                setRedirectUrl,
                lastSkillSet,
                setLastSkillSet,
                lastSkill,
                setLastSkill
            }}
        >
            {children}
        </GlobalContext.Provider>
    )
}
