import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip'
import TooltipContent from 'components/tooltipContent/TooltipContent'
import React from 'react'

const withTooltip = (className?: string) => (
    Component: React.ComponentType
) => (tooltipProps: Omit<TooltipProps, 'children'>) => (
    <Tooltip {...tooltipProps}>
        <TooltipContent className={className}>
            <Component />
        </TooltipContent>
    </Tooltip>
)

export default withTooltip
