import {
    LeaveFeedbackData,
    MatrixDetails,
    MatrixSkillSet,
    SkillRating
} from '@app/matrix'
import { Roles } from '@app/person'
import { AxiosInstance } from 'axios'
import { useClient } from 'hooks/useClient'
import useSWR, { mutate } from 'swr'
import { determineComponentStatus, tryMutation } from './apiHelpers'
import { isAdmin, isContentAdmin, isMatchingAnyRole } from './roleHelpers'

const MATRIX_BASE_URL = '/api/matrix'

function useFetchSingleMatrix(userId: string, templateId: string) {
    const shouldFetch = !!userId && !!templateId
    const { data, error } = useSWR<MatrixDetails, Error>(
        shouldFetch ? `${MATRIX_BASE_URL}/${userId}/${templateId}` : null
    )

    return {
        matrix: data,
        error,
        status: determineComponentStatus(data, error)
    }
}

function useRequestRateSkill(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (skillRating: SkillRating) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).put<MatrixDetails>(
                '/api/skill-rating',
                {
                    userId: skillRating.userId,
                    skillId: skillRating.skillId,
                    rating: skillRating.rating
                }
            )
            return response.data
        })
}

function useRequestMatrixDelete(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (userId: string, templateId: string) =>
        tryMutation(async () => {
            await (customClient ?? client).delete(
                `/api/user/${userId}/templates/${templateId}`
            )
            return {}
        })
}

function useRequestLeaveFeedback(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (leaveFeedbackData: LeaveFeedbackData) =>
        tryMutation(async () => {
            await (customClient ?? client).post(
                `/api/feedback`,
                leaveFeedbackData
            )
            return {}
        })
}

async function requestSingleMatrixRevalidation(
    userId: string,
    templateId: string
) {
    return mutate(`${MATRIX_BASE_URL}/${userId}/${templateId}`)
}

function filterSkillSetsWithoutSkills(skillSet: MatrixSkillSet) {
    if (skillSet.childSkillSets.length === 0 && skillSet.skills.length === 0) {
        return false
    }

    if (skillSet.skills.length > 0) {
        return true
    }

    return (
        skillSet.childSkillSets.filter(filterSkillSetsWithoutSkills).length > 0
    )
}

function mapSkillSetsByExcludingEmptyChildSkillSets(skillSet: MatrixSkillSet) {
    return {
        ...skillSet,
        childSkillSets: skillSet.childSkillSets
            .map(mapSkillSetsByExcludingEmptyChildSkillSets)
            .filter(filterSkillSetsWithoutSkills)
    }
}

/**
 * Generates a templateList list by filtering out items without skill and/or skillSets from the given templateList
 * @param itemList - Skill set list
 * @param currentUserRoles - Current user roles
 */
function filterSkillsByExistence(
    itemList: Array<MatrixSkillSet>,
    currentUserRoles: Roles[]
) {
    const isAdminOrContentAdmin = isMatchingAnyRole(isAdmin, isContentAdmin)
    if (!isAdminOrContentAdmin(currentUserRoles)) {
        return itemList
            .map(mapSkillSetsByExcludingEmptyChildSkillSets)
            .filter(filterSkillSetsWithoutSkills)
    }

    return itemList
}

export {
    useFetchSingleMatrix,
    useRequestRateSkill,
    useRequestMatrixDelete,
    useRequestLeaveFeedback,
    requestSingleMatrixRevalidation,
    filterSkillsByExistence
}
