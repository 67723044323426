import { AuthenticationContext } from 'contexts/AuthenticationContext'
import { useContext } from 'react'

export function useAuthentication() {
    const context = useContext(AuthenticationContext)

    if (!context) {
        throw new Error(
            "'useAuthentication' hook must be used within an 'AuthenticationContext'"
        )
    }

    return context
}
