import withTitle from 'hoc/withTitle'
import React from 'react'
import { match } from 'react-router'
import { compose } from 'recompose'
import { useTranslation } from 'react-i18next'
import PeopleWorksheet from './PeopleWorksheet'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'

interface OwnProps {
    match: match
}

type Props = OwnProps

const People = (props: Props) => {
    const { t } = useTranslation(['people'])

    return (
        <>
            <Breadcrumb>{t('people:title')}</Breadcrumb>

            <PeopleWorksheet match={props.match} />
        </>
    )
}

export default compose<Props, OwnProps>(withTitle('People'))(People)
