import { AppModeContext } from 'contexts/AppModeContext'
import { useContext } from 'react'

export function useAppMode() {
    const context = useContext(AppModeContext)

    if (!context) {
        throw new Error(
            "'useAppMode' hook must be used within an 'AppModeContext'"
        )
    }

    return context
}
