import { TrainingMaterial } from '@app/overview'
import Button from 'components/button/base/Button'
import {
    requestAllOverviewDataRevalidation,
    useRequestTrainingMaterialFinish
} from 'helpers/overviewHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import clsx from 'clsx'
import Dialog from 'components/dialog/base/Dialog'
import { makeStyles } from '@material-ui/core'

const useFinishTrainingMaterialDialogStyles = makeStyles({
    dialogActions: {
        minHeight: 0
    },
    dialog: {
        minHeight: 0
    }
})

export interface FinishTrainingMaterialDialogProps {
    trainingMaterial: TrainingMaterial
}

export const FinishTrainingMaterialDialog: React.FC<FinishTrainingMaterialDialogProps> = ({
    trainingMaterial
}) => {
    const { createNotification, closeActiveOverlay } = useGlobal()

    const [loading, setLoading] = useState(false)

    const classes = useFinishTrainingMaterialDialogStyles()

    const { t } = useTranslation(['common', 'overview', 'error'])

    const requestTrainingMaterialFinish = useRequestTrainingMaterialFinish()
    const onSubmit = async () => {
        setLoading(true)

        const { status } = await requestTrainingMaterialFinish(
            trainingMaterial.id
        )

        if (status === 'resolved') {
            requestAllOverviewDataRevalidation()
            closeActiveOverlay()
        } else if (status === 'rejected') {
            setLoading(false)
            createNotification({
                type: t('error:labels.error'),
                description: t('error:response.finishTrainingMaterialUnknown')
            })
        }
    }

    return (
        <Dialog
            title={t('overview:finishTrainingMaterial.title')}
            closeDialog={closeActiveOverlay}
            showCloseIcon={false}
            className={classes.dialog}
        >
            <>
                <div className="dialog__info">
                    <Trans i18nKey="overview:finishTrainingMaterial.question">
                        <strong>
                            {{ trainingMaterialTitle: trainingMaterial.name }}
                        </strong>
                    </Trans>
                </div>

                <div
                    className={clsx([
                        'dialog__actions dialog__actions--noShadow',
                        classes.dialogActions
                    ])}
                >
                    <Button
                        title={t('common:labels.cancel')}
                        onClick={closeActiveOverlay}
                        formButton
                    />

                    <Button
                        title={t('common:labels.done')}
                        type="submit"
                        onClick={onSubmit}
                        loading={loading}
                        primary
                        formButton
                        autoFocus
                    />
                </div>
            </>
        </Dialog>
    )
}

export default FinishTrainingMaterialDialog
