import { Expectations } from '@app/common'
import LevelSelector from 'components/input/levelSelector/LevelSelector'
import i18next from 'i18next'
import React from 'react'

interface Props {
    expectations: Expectations
    handleChange: (expectationKey: string, expectationValue: number) => void
}

const getExpectationLabel: (expectationKey: string) => string = (key) => {
    switch (key) {
        case 'level1':
            return i18next.t('common:expectations.level1')
        case 'level2':
            return i18next.t('common:expectations.level2')
        case 'level3':
            return i18next.t('common:expectations.level3')
        case 'level4':
            return i18next.t('common:expectations.level4')
        case 'level5':
            return i18next.t('common:expectations.level5')
        default:
            return ''
    }
}

const ExpectationsInput = ({ expectations, handleChange }: Props) => {
    const expectationsData = Object.keys(expectations).map(
        (expectationKey) => ({
            key: expectationKey,
            value: expectations[expectationKey]
        })
    )

    return (
        <div className="expectationsInput">
            <span className="input__label">
                {i18next.t('common:expectations.title')}
            </span>

            {expectationsData.map((expectationData) => (
                <LevelSelector
                    label={getExpectationLabel(expectationData.key)}
                    onClick={(value) =>
                        handleChange(expectationData.key, value)
                    }
                    value={expectationData.value}
                    key={expectationData.key}
                    horizontal
                />
            ))}
        </div>
    )
}

export default ExpectationsInput
