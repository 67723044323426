import Backdrop from '@material-ui/core/Backdrop'
import Modal from '@material-ui/core/Modal'
import React from 'react'

interface ModalProps {
    onClose: (event?: React.MouseEvent) => void
    open?: boolean
    children?: React.ReactElement
    ariaLabel?: string
    ariaDescription?: string
}

const Overlay = (props: ModalProps) => (
    <Modal
        className="overlay"
        aria-labelledby={props.ariaLabel}
        aria-describedby={props.ariaDescription}
        open={props.open}
        onClose={props.onClose}
        BackdropProps={{ timeout: 350 }}
        BackdropComponent={Backdrop}
        closeAfterTransition
    >
        <>{props.children}</>
    </Modal>
)

export default Overlay
