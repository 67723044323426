import { RouteParams, SearchResult } from '@app/common'
import { FilterPopoverFormData } from '@app/form'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import clsx from 'clsx'
import React, { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useTranslation } from 'react-i18next'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { ErrorMessage } from '../../components/atoms/ErrorMessage'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import { FilterPopover } from '../../components/filterPopover/FilterPopover'
import { useFetchSearchResultsById } from '../../helpers/searchHelpers'
import { SearchResultContent } from './contentBrowser/SearchResultContent'
import { StyledChip, useStyles } from './SearchResults.styles'

function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const SearchResults = () => {
    const { t } = useTranslation(['search'])

    const classes = useStyles()

    const query = useQuery()

    const match = useRouteMatch<RouteParams>('/search/:id')

    const name = query.get('name')

    const type = query.get('type')

    const searchResultResponse = useFetchSearchResultsById(
        match.params.id,
        type
    )

    const [groupFilter, setGroupFilter] = useState([])
    const [levelFilter, setLevelFilter] = useState([])

    React.useEffect(() => {
        setGroupFilter([])
        setLevelFilter([])
    }, [searchResultResponse.searchResults])

    const filterOnLevels = (searchResults: Array<SearchResult>) => {
        const mappedLevelFilter = levelFilter.map((filter) => filter.value)

        return searchResults
            .map(
                (result) =>
                    ({
                        ...result,
                        members: result.members.filter((member) =>
                            mappedLevelFilter.includes(member.level.toString())
                        )
                    } as SearchResult)
            )
            .filter((result) => result.members.length > 0)
    }

    const filterOnGroups = (searchResults: Array<SearchResult>) => {
        const mappedGroupFilter = groupFilter.map((filter) => filter.value)

        return searchResults.filter((result) =>
            mappedGroupFilter.includes(result.group.id)
        )
    }

    const filterSearchResult = (
        searchResults: Array<SearchResult>
    ): Array<SearchResult> => {
        let filteredResults = searchResults
        if (levelFilter && levelFilter.length > 0) {
            filteredResults = [...filterOnLevels(filteredResults)]
        }

        if (groupFilter && groupFilter.length > 0) {
            filteredResults = [...filterOnGroups(filteredResults)]
        }

        return filteredResults
    }

    const filteredSearchResults = filterSearchResult(
        searchResultResponse.searchResults ?? []
    )

    const numOfListItems =
        searchResultResponse.status === 'resolved'
            ? filteredSearchResults.reduce(
                  (counter, group) =>
                      group.members && group.members.length > 0
                          ? counter + group.members.length
                          : counter,

                  0
              )
            : 0

    const onFilter = async (values: FilterPopoverFormData) => {
        setGroupFilter(values.groups || [])
        setLevelFilter(values.levels || [])
    }

    const handleGroupFilterDelete = (id: string) => {
        setGroupFilter(groupFilter.filter((group) => group.value !== id))
    }
    const handleLevelFilterDelete = (id: string) => {
        setLevelFilter(levelFilter.filter((level) => level.value !== id))
    }

    return (
        <>
            <div className="searchResults__header">
                {searchResultResponse.status === 'resolved' && (
                    <Breadcrumb>
                        {groupFilter.length > 0 || levelFilter.length > 0 ? (
                            <>
                                <span className={classes.title}>
                                    {t('search:filteredResultsTitle', {
                                        count: numOfListItems,
                                        target: name
                                    })}
                                </span>

                                <div className={classes.filterContainer}>
                                    {levelFilter.length > 0 &&
                                        levelFilter.map((level) => (
                                            <StyledChip
                                                key={level.value}
                                                deleteIcon={
                                                    <CrossIcon
                                                        label=""
                                                        size="small"
                                                    />
                                                }
                                                label={level.label}
                                                onClick={() =>
                                                    handleLevelFilterDelete(
                                                        level.value
                                                    )
                                                }
                                                onDelete={() =>
                                                    handleLevelFilterDelete(
                                                        level.value
                                                    )
                                                }
                                            />
                                        ))}
                                </div>
                                <div className={classes.filterContainer}>
                                    {groupFilter.length > 0 &&
                                        groupFilter.map((group) => (
                                            <StyledChip
                                                key={group.value}
                                                deleteIcon={
                                                    <CrossIcon
                                                        label=""
                                                        size="small"
                                                    />
                                                }
                                                label={group.label}
                                                onClick={() =>
                                                    handleGroupFilterDelete(
                                                        group.value
                                                    )
                                                }
                                                onDelete={() =>
                                                    handleGroupFilterDelete(
                                                        group.value
                                                    )
                                                }
                                            />
                                        ))}
                                </div>
                            </>
                        ) : (
                            <span className={classes.title}>
                                {t('search:title', {
                                    count: numOfListItems,
                                    target: name
                                })}
                            </span>
                        )}
                    </Breadcrumb>
                )}

                <FilterPopover
                    onFilterSubmit={onFilter}
                    initialValues={{ levels: levelFilter, groups: groupFilter }}
                />
            </div>

            <div
                className={clsx(
                    'worksheet',
                    'searchResults__worksheet',
                    numOfListItems === 0 && 'searchResults__worksheet--expand'
                )}
            >
                <div className="worksheetContentBrowser__content">
                    <ErrorBoundary
                        fallbackRender={({ error }) => (
                            <ErrorMessage>{error.message}</ErrorMessage>
                        )}
                    >
                        <SearchResultContent
                            error={searchResultResponse.error}
                            status={searchResultResponse.status}
                            searchResults={filteredSearchResults}
                        />
                    </ErrorBoundary>
                </div>
            </div>
        </>
    )
}

export default SearchResults
