import { ActiveUserListDialogFormData } from '@app/form'
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'
import ErrorIcon from '@atlaskit/icon/glyph/error'
import Badge from 'components/badge/Badge'
import withFilterListItems from 'hoc/withFilterListItems'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { getMonogram } from 'utils/commonUtils'

interface Props extends ActiveUserListDialogFormData, WithTranslation {
    onClick: (id: string, event: ReactMouseEvent) => void
    withSeparator?: boolean
}

interface EmptyStateProps extends WithTranslation {
    error: boolean
}

interface LabeledBadgeListProps extends Props, EmptyStateProps {
    isEmpty: boolean
}

type ReactMouseEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>

const labeledBadgeListItems = (props: Props) =>
    props.listItems.length > 0 ? (
        <>
            {props.listItems.map((item, index) => (
                <div className="ownerPopover__container" key={item.id}>
                    <span
                        className="ownerPopover__menuItem"
                        onClick={(event: ReactMouseEvent) =>
                            props.onClick(item.id, event)
                        }
                    >
                        <Badge title={getMonogram(item.title)} size="sm" />
                        <span className="ownerPopover__menuItemPeopleName">
                            {item.title}
                        </span>
                    </span>
                    {props.listItems.length - 1 !== index &&
                        props.withSeparator && (
                            <div className="ownerPopover__separator" />
                        )}
                </div>
            ))}
        </>
    ) : (
        <div className="ownerPopover__emptyState">
            {props.t('common:popover.owner.emptyState.noResult')}
        </div>
    )

const renderEmptyState = (props: EmptyStateProps) => (
    <>
        {!props.error ? (
            <>
                <CheckCircleIcon label="" size="large" primaryColor="#4ecca3" />
                <span className="ownerPopover__errorText">
                    {props.t('common:popover.owner.emptyState.people')}
                </span>
            </>
        ) : (
            <>
                <ErrorIcon label="" size="xlarge" primaryColor="#eb5757" />
                <span>
                    {props.t('error:response.unassignedPeopleListLoadUnknown')}
                </span>
            </>
        )}
    </>
)

const LabeledBadgeList = ({
    isEmpty,
    error,
    ...props
}: LabeledBadgeListProps) =>
    !isEmpty
        ? withFilterListItems<Props>(labeledBadgeListItems)(props)
        : renderEmptyState({ error, ...props })

export default withTranslation('common')(LabeledBadgeList)
