import { Expectations, ExpectationValue } from '@app/common'
import { CreateSkillDialogFormData } from '@app/form'
import { TemplateDetails } from '@app/template'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import LabeledInputField from 'components/input/labeledInputField/LabeledInputField'
import LabeledSelect from 'components/input/labeledSelect/LabeledSelect'
import RichTextEditor from 'components/input/richTextEditor/RichTextEditor'
import ToggleSwitch from 'components/input/toggleSwitch/ToggleSwitch'
import {
    Form,
    Formik,
    FormikHelpers as FormikActions,
    FormikProps
} from 'formik'
import FormikHelpers from 'helpers/formikHelpers'
import { useRequestSkillCreate } from 'helpers/skillHelpers'
import {
    formatSkillSelector,
    getSkillDropdownItems,
    requestSingleTemplateRevalidation
} from 'helpers/templateHelpers'
import { useGlobal } from 'hooks/useGlobal'
import ExpectationsInput from 'pages/templates/ExpectationsInput'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import * as Yup from 'yup'

interface OwnProps {
    template: TemplateDetails
}

export type CreateSkillDialogProps = OwnProps & WithTranslation

export interface CreateSkillDialogState {
    expectations: Expectations
}

const CreateSkillDialog: React.FC<CreateSkillDialogProps> = (
    props: CreateSkillDialogProps
) => {
    const { t } = props

    const {
        formDirty,
        toggleFormToDirty,
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay,
        lastSkill,
        setLastSkill
    } = useGlobal()

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('error:validation.fieldRequired')),
        parentSkillSet: Yup.object()
            .nullable()
            .required(t('error:validation.noSkillSetSelected'))
    })

    const initialValues: CreateSkillDialogFormData = {
        name: '',
        description: '',
        parentSkillSet:
            lastSkill.node.templateId === props.template.id
                ? getSkillDropdownItems(props.template).filter(
                      (lastSkillItem) =>
                          lastSkillItem.value === lastSkill.node.parentId
                  )[0]
                : null,
        expectations: {
            level1: 1,
            level2: 1,
            level3: 1,
            level4: 1,
            level5: 1
        },
        createAnother: lastSkill.createAnother
    }

    const handleExpectationChange = (
        formikProps: FormikProps<CreateSkillDialogFormData>
    ) => (key: string, value: ExpectationValue) => {
        FormikHelpers.setDirtyForm(
            formikProps,
            'expectations',
            formDirty,
            toggleFormToDirty
        )({
            ...formikProps.values.expectations,
            [key]: value
        })
    }

    const requestSkillCreate = useRequestSkillCreate()
    const onSubmit = async (
        values: CreateSkillDialogFormData,
        formikActions: FormikActions<CreateSkillDialogFormData>
    ) => {
        try {
            const { data: template } = await requestSkillCreate({
                name: values.name,
                description: values.description,
                parentSkillSetId: values.parentSkillSet.value,
                expectations: values.expectations
            })

            setLastSkill({
                node: {
                    parentId: values.parentSkillSet.value,
                    templateId: template.id
                },
                createAnother: values.createAnother
            })

            await requestSingleTemplateRevalidation(template.id)

            if (values.createAnother) {
                formikActions.resetForm()
                createNotification({
                    type: t('common:labels.success'),
                    description: t('templates:createSkill.success'),
                    hideOverlay: true,
                    timeout: 2500
                })
            } else {
                closeActiveOverlay()
            }
        } catch (payload) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            createNotification({
                type: t('error:labels.error'),
                description: t('error:response.createSkillUnknown', {
                    skillName: values.name
                })
            })
        }
    }

    return (
        <Dialog
            title={t('templates:createSkill.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
            animationWithMargin
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
                validateOnChange
            >
                {(formikProps) => {
                    const nameError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'name'
                    )

                    const parentSkillSetError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'parentSkillSet'
                    )

                    return (
                        <Form className="dialog__form">
                            <div className="dialog__scrollArea modern-scrollbar">
                                <LabeledInputField
                                    label={t('templates:labels.name')}
                                    name="name"
                                    onChange={FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'name',
                                        formDirty,
                                        toggleFormToDirty
                                    )}
                                    value={formikProps.values.name}
                                    message={nameError}
                                    hasError={!!nameError}
                                    tabIndex={0}
                                    autoFocus
                                />

                                <LabeledSelect
                                    label={t('templates:labels.parent')}
                                    name="parent"
                                    options={getSkillDropdownItems(
                                        props.template
                                    )}
                                    value={formikProps.values.parentSkillSet}
                                    isClearable={false}
                                    placeholder={t(
                                        'templates:labels.selectSkillSet',
                                        {
                                            templateName: props.template.name
                                        }
                                    )}
                                    onChange={FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'parentSkillSet',
                                        formDirty,
                                        toggleFormToDirty
                                    )}
                                    hasError={!!parentSkillSetError}
                                    message={parentSkillSetError}
                                    formatOptionLabel={formatSkillSelector}
                                    tabIndex={0}
                                />

                                <RichTextEditor
                                    label={t(
                                        'templates:labels.descriptionOptional'
                                    )}
                                    name="description"
                                    onChange={FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'description',
                                        formDirty,
                                        toggleFormToDirty
                                    )}
                                    value={formikProps.values.description}
                                    placeholder={t(
                                        'templates:labels.descriptionSkillPlaceholder'
                                    )}
                                    bounds=".richTextEditor"
                                />

                                <ExpectationsInput
                                    expectations={
                                        formikProps.values.expectations
                                    }
                                    handleChange={handleExpectationChange(
                                        formikProps
                                    )}
                                />
                            </div>

                            <div className="dialog__actions">
                                <div className="dialog__actionContainer">
                                    <div className="dialog__toggleSwitch">
                                        <ToggleSwitch
                                            selected={
                                                formikProps.values.createAnother
                                            }
                                            label={t(
                                                'templates:labels.createAnother'
                                            )}
                                            onChange={(value) =>
                                                formikProps.setFieldValue(
                                                    'createAnother',
                                                    value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="dialog__buttonContainer">
                                        <Button
                                            title={t('common:labels.cancel')}
                                            onClick={closeActiveOverlay}
                                            formButton
                                        />

                                        <Button
                                            title={t('common:labels.create')}
                                            type="submit"
                                            loading={formikProps.isSubmitting}
                                            primary
                                            formButton
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export default compose<CreateSkillDialogProps, OwnProps>(
    withTranslation(['common', 'templates', 'error'])
)(CreateSkillDialog)
