import { ComponentStatus, PlaceholderData, RouteParams } from '@app/common'
import { SearchTermDetails } from '@app/searchTerm'
import { TemplateDetails } from '@app/template'
import AddItemIcon from '@atlaskit/icon/glyph/add-item'
import CopyIcon from '@atlaskit/icon/glyph/copy'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import Placeholder from 'components/placeholder/Placeholder'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlaceholder from 'react-placeholder'
import { match } from 'react-router-dom'
import { isAdmin, isMatchingAnyRole } from '../../../helpers/roleHelpers'
import { useAuthentication } from '../../../hooks/useAuthentication'
import SearchTermSkillSetListItem from '../listItem/SearchTermSkillSetListItem'

export interface SearchTermsContentProps {
    status: ComponentStatus
    searchTerm: SearchTermDetails
    error: Error
    match?: match<RouteParams>
}

export const SearchTermsContent: React.FC<SearchTermsContentProps> = (
    props: SearchTermsContentProps
) => {
    const { searchTerm, status, error } = props
    const { t } = useTranslation(['common', 'searchTerms'])
    const { openDialog } = useGlobal()

    const { getCurrentUserRoles } = useAuthentication()
    const currentUserRoles = getCurrentUserRoles()

    const isCurrentUserAnAdmin = React.useMemo(
        () => isMatchingAnyRole(isAdmin)(currentUserRoles),
        [currentUserRoles]
    )

    const emptySearchTermDataForAdmin: PlaceholderData = {
        icon: <AddItemIcon label="" size="xlarge" />,
        title: t(
            'searchTerms:contentBrowser.emptySearchTerm.forAdmin.content.title'
        ),
        subtitle: t(
            'searchTerms:contentBrowser.emptySearchTerm.forAdmin.content.subtitle'
        ),
        action: () =>
            openDialog({
                type: 'ASSIGN_SKILL',
                payload: searchTerm
            }),
        actionText: t(
            'searchTerms:contentBrowser.emptySearchTerm.forAdmin.content.action'
        )
    }
    const emptySearchTermDataForSales = {
        icon: <AddItemIcon label="" size="xlarge" />,
        title: t(
            'searchTerms:contentBrowser.emptySearchTerm.forSales.content.title'
        ),
        subtitle: t(
            'searchTerms:contentBrowser.emptySearchTerm.forSales.content.subtitle'
        )
    }

    const renderContentBrowserList = (currentTemplate: TemplateDetails) =>
        currentTemplate.childSkillSets
            .sort((a, b) => a.position - b.position)
            .map((skillSet) => (
                <SearchTermSkillSetListItem
                    key={skillSet.id}
                    skillSet={skillSet}
                    openDialog={openDialog}
                    template={currentTemplate}
                    searchTerm={searchTerm}
                />
            ))

    if (status === 'idle') {
        return (
            <div className="worksheetContentBrowser__emptyState">
                <Placeholder
                    icon={<CopyIcon label="" size="xlarge" />}
                    title={t(
                        'searchTerms:contentBrowser.emptyState.content.title'
                    )}
                    subtitle={t(
                        'searchTerms:contentBrowser.emptyState.content.subtitle'
                    )}
                    actionText={t(
                        'searchTerms:contentBrowser.emptyState.content.action'
                    )}
                />
            </div>
        )
    }

    if (status === 'pending') {
        return (
            <div role="progressbar">
                <ReactPlaceholder
                    className="contentBrowserListItem__reactPlaceholder"
                    showLoadingAnimation
                    type="text"
                    rows={18}
                    ready={false}
                >
                    <></>
                </ReactPlaceholder>
            </div>
        )
    }

    if (status === 'rejected' && error && !searchTerm) {
        return (
            <div className="worksheetContentBrowser__emptyState">
                <Placeholder
                    icon={<CrossIcon label="" size="xlarge" />}
                    title={t('searchTerms:contentBrowser.error.notFound.title')}
                    subtitle={t(
                        'searchTerms:contentBrowser.error.notFound.subtitle'
                    )}
                />
            </div>
        )
    }

    if (status === 'rejected' && error) {
        throw error
    } else if (status === 'resolved' && searchTerm) {
        if (searchTerm.templates && searchTerm.templates.length > 0) {
            return (
                <ReactPlaceholder
                    className="contentBrowserListItem__reactPlaceholder"
                    showLoadingAnimation
                    type="text"
                    rows={18}
                    ready={status === 'resolved'}
                >
                    <div className="worksheetContentBrowser__scrollArea">
                        <div className="worksheetContentBrowser__innerContent modern-scrollbar">
                            {searchTerm.templates.map((template) => {
                                return (
                                    <React.Fragment key={template.id}>
                                        <div className="worksheetContentBrowser__title">
                                            {template.name}
                                        </div>
                                        {renderContentBrowserList(template)}
                                    </React.Fragment>
                                )
                            })}
                        </div>
                    </div>
                </ReactPlaceholder>
            )
        }

        if (
            (searchTerm.type === 'GLOBAL' && isCurrentUserAnAdmin) ||
            searchTerm.type === 'PERSONALIZED'
        ) {
            return (
                <div className="worksheetContentBrowser__emptyState">
                    <Placeholder
                        action={emptySearchTermDataForAdmin.action}
                        actionText={emptySearchTermDataForAdmin.actionText}
                        icon={emptySearchTermDataForAdmin.icon}
                        title={emptySearchTermDataForAdmin.title}
                        subtitle={emptySearchTermDataForAdmin.subtitle}
                    />
                </div>
            )
        }

        return (
            <div className="worksheetContentBrowser__emptyState">
                <Placeholder
                    icon={emptySearchTermDataForSales.icon}
                    title={emptySearchTermDataForSales.title}
                    subtitle={emptySearchTermDataForSales.subtitle}
                />
            </div>
        )
    }

    return null
}

export default SearchTermsContent
