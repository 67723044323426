const groups = {
    title: 'Groups',
    labels: {
        createGroup: 'Create group',
        members: '{{count}} member',
        members_plural: '{{count}} members',
        noMembers: 'No members',
        name: 'Name',
        email: 'Email address',
        phoneNumber: 'Phone number (Optional)',
        administrator: 'Group administrator',
        selectAdministrator: 'Select or invite someone',
        inviteAdminDescription:
            'This person will receive an email notification about this action',
        aboutThisGroup: 'About this group'
    },
    createGroup: {
        title: 'Create group'
    },
    deleteGroup: {
        title: 'Delete group',
        question:
            'Are you sure you want to remove <0>{{groupName}}</0> group? You will not be able to restore the group unless you create it again from scratch. The users in the group will not be deleted.',
        action: 'Enter the group’s name to delete'
    },
    editGroup: {
        title: 'Edit group'
    },
    contentBrowser: {
        emptyState: {
            content: {
                title: 'Create or edit groups',
                subtitle:
                    'Manage user groups to keep the members of different organizations in the right place.',
                action: 'Add a new group'
            }
        },
        error: {
            notFound: {
                title: 'Group not found',
                subtitle:
                    "You've tried to check the details of a group that doesn't exist."
            }
        }
    }
}

export default groups
