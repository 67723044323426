import { DropdownItem } from '@atlaskit/dropdown-menu'
import MaterialPopover from '@material-ui/core/Popover'
import classNames from 'classnames'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import Badge from 'components/badge/Badge'
import { getMonogram } from 'utils/commonUtils'

interface ProfilePopoverProps extends WithTranslation {
    onChangePasswordClick: () => void
    onLogoutClick: () => void
    title?: string
}

type ReactMouseEvent = React.MouseEvent<HTMLButtonElement, MouseEvent>

const ProfilePopover = (props: ProfilePopoverProps) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        undefined
    )

    const onClick = (event: ReactMouseEvent) => {
        event.stopPropagation()

        setAnchorEl(event.currentTarget)
    }

    const onClose = (event: ReactMouseEvent) => {
        event.stopPropagation()

        setAnchorEl(undefined)
    }

    const onChangePasswordClick = (event: ReactMouseEvent) => {
        onClose(event)
        props.onChangePasswordClick()
    }

    const onLogoutClick = (event: ReactMouseEvent) => {
        onClose(event)
        props.onLogoutClick()
    }

    const open = Boolean(anchorEl)
    const id = open ? 'profilePopover' : undefined

    return (
        <div className={classNames('profilePopover', { open })}>
            <Badge
                title={getMonogram(props.title)}
                size="md"
                onClick={onClick}
            />

            <MaterialPopover
                className="profilePopover__body"
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClick={(event) => event.stopPropagation()}
            >
                <span className="profilePopover__title">{props.title}</span>

                <div className="profilePopover__menu">
                    <DropdownItem
                        className="profilePopover__menuItem"
                        onClick={onChangePasswordClick}
                    >
                        {props.t('labels.changePassword')}
                    </DropdownItem>

                    <DropdownItem
                        className="profilePopover__menuItem"
                        onClick={onLogoutClick}
                    >
                        {props.t('labels.logout')}
                    </DropdownItem>
                </div>
            </MaterialPopover>
        </div>
    )
}

export default withTranslation('common')(ProfilePopover)
