import { DialogConfig } from '@app/dialog'
import { SearchTermDetails, UnassignDialogType } from '@app/searchTerm'
import { SkillSetDetails, TemplateDetails } from '@app/template'
import ContentBrowserListItem from 'components/contentBrowserListItem/ContentBrowserListItem'
import SortableList from 'components/listItem/sortable/SortableList'
import { TemplateSkillSetSubtitle } from 'components/subtitle'
import { orderArrayListByPosition } from 'helpers/templateHelpers'
import React from 'react'
import { merge } from 'utils/commonUtils'
import ListItemActionMenu from '../../templates/ListItemActionMenu'
import SearchTermSkillListItem from './SearchTermSkillListItem'

interface Props {
    openDialog: (config: DialogConfig) => void
    skillSet: SkillSetDetails
    disableAdditionalContent?: boolean
    template: TemplateDetails
    searchTerm?: SearchTermDetails
}

const SearchTermSkillSetListItem = ({
    skillSet,
    openDialog,
    disableAdditionalContent,
    template,
    searchTerm
}: Props) => {
    const [currentTemplate, setTemplate] = React.useState(template)

    React.useEffect(() => {
        if (template) {
            setTemplate(template)
        }
    }, [template])

    const unAssignSkillSet: (
        event: React.MouseEvent<HTMLSpanElement, MouseEvent>
    ) => void = (event) => {
        event.stopPropagation()
        openDialog({
            type: 'UN_ASSIGN_SKILL_SET',
            payload: {
                searchTermId: searchTerm.id,
                templateId: template.id,
                skillSet,
                type: UnassignDialogType.SkillSet
            }
        })
    }

    return (
        <ContentBrowserListItem
            data={skillSet}
            additionalContent={
                !disableAdditionalContent && (
                    <ListItemActionMenu unAssignAction={unAssignSkillSet} />
                )
            }
            showAdditionalContentOnlyOnHover
            small={false}
            skillSetSubtitle={<TemplateSkillSetSubtitle skillSet={skillSet} />}
        >
            <SortableList
                useDragHandle
                lockAxis="y"
                template={template}
                list={
                    skillSet
                        ? orderArrayListByPosition(
                              merge(skillSet.childSkillSets, skillSet.skills)
                          ).map((skill) =>
                              skill.ownerUserIds ? (
                                  <SearchTermSkillSetListItem
                                      key={skill.id}
                                      skillSet={skill}
                                      openDialog={openDialog}
                                      disableAdditionalContent={
                                          disableAdditionalContent
                                      }
                                      template={currentTemplate ?? template}
                                      searchTerm={searchTerm}
                                  />
                              ) : skill.expectations ? (
                                  <SearchTermSkillListItem
                                      key={skill.id}
                                      openDialog={openDialog}
                                      skill={skill}
                                      skillSet={skillSet}
                                      ownerUserIds={skillSet.ownerUserIds}
                                      disableAdditionalContent={
                                          disableAdditionalContent
                                      }
                                      template={currentTemplate ?? template}
                                      searchTerm={searchTerm}
                                  />
                              ) : null
                          )
                        : null
                }
            />
        </ContentBrowserListItem>
    )
}

export default SearchTermSkillSetListItem
