import { SkillSetDetails } from '@app/template'
import React from 'react'
import { useTranslation } from 'react-i18next'

export interface TemplateSkillSetSubtitleProps {
    skillSet: SkillSetDetails
}

export const TemplateSkillSetSubtitle = (
    props: TemplateSkillSetSubtitleProps
) => {
    const { t } = useTranslation('templates')

    const skillSum = props.skillSet.childSkillSets.reduce(
        (sum, childSkillSet) => sum + childSkillSet.skills.length,
        props.skillSet.skills.length
    )

    const skillSetString =
        props.skillSet.childSkillSets.length > 0
            ? t('labels.skillSet', {
                  context: 'notEmpty',
                  count: props.skillSet.childSkillSets.length
              })
            : ''

    const skillString = t('labels.skill', {
        context: skillSum !== 0 ? 'notEmpty' : 'empty',
        count: skillSum
    })

    return props.skillSet.childSkillSets.length > 0 || skillSum > 0 ? (
        <span>{`${skillSetString} ${skillString}`}</span>
    ) : (
        <span>{t('templates:labels.skillSet_empty', { count: 0 })}</span>
    )
}
