import { Chip, makeStyles, withStyles } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme: Theme) => ({
    filterContainer: {
        width: '100%',
        display: 'flex',
        margin: '8px 0'
    },
    title: {
        marginBottom: 16,
        minWidth: 300,
        display: 'block'
    },
    closeIcon: {
        marginRight: 7
    }
}))

export const StyledChip = withStyles((theme: Theme) => ({
    root: {
        marginRight: 12,
        background: '#D4DADE',
        borderRadius: 5,
        paddingRight: 7,
        textTransform: 'none',
        '&> span': {
            color: '#1A1A1A'
        }
    }
}))(Chip)
