import React from 'react'

export interface IconLabelProps {
    icon?: React.ReactNode
}

export const IconLabel: React.FC<IconLabelProps> = ({ icon, children }) => (
    <div className="iconLabel">
        {icon && (
            <span className="iconLabel__icon" role="img">
                {icon}
            </span>
        )}

        <span className="iconLabel__textContent">{children}</span>
    </div>
)

export default IconLabel
