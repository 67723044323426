import { PersonDetailsWithMatrices } from '@app/person'
import { useGlobal } from 'hooks/useGlobal'
import React, { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { useLocation } from 'react-router-dom'

interface AuthRequiredContainerProps extends RouteComponentProps {
    isMaintenance: boolean
    isLoggedIn: boolean
    currentUser: PersonDetailsWithMatrices
}

const AuthRequiredContainer: React.FC<AuthRequiredContainerProps> = (props) => {
    const { setRedirectUrl } = useGlobal()
    const { pathname, search } = useLocation()

    useEffect(() => {
        const { history, isLoggedIn } = props

        if (
            !isLoggedIn &&
            pathname !== '/login' &&
            pathname !== '/maintenance' &&
            !pathname.startsWith('/reset-password') &&
            !pathname.startsWith('/activate/')
        ) {
            setRedirectUrl(pathname.concat(search))
            history.push('/login')
        }
    }, [
        props.history,
        props.location,
        props.isLoggedIn,
        props,
        setRedirectUrl,
        pathname,
        search
    ])

    return !props.isMaintenance && props.isLoggedIn && props.currentUser ? (
        <>{props.children}</>
    ) : null
}

export default withRouter(AuthRequiredContainer)
