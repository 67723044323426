import LabeledButton from 'components/button/labeled/LabeledButton'
import Worksheet from 'components/worksheet/Worksheet'
import { useFetchAllPeople } from 'helpers/peopleHelpers'
import { useAuthentication } from 'hooks/useAuthentication'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { match, Route, Switch } from 'react-router-dom'
import { openDialogWrapper } from 'utils/commonUtils'
import { useFetchCurrentUser } from '../../helpers/accountHelpers'
import {
    groupedPeopleToListItems,
    groupPeople
} from '../../mappers/peopleMappers'
import PeopleContentBrowser from './PeopleContentBrowser'

interface Props {
    match: match
}

const PeopleWorksheet = (props: Props) => {
    const { t } = useTranslation(['people'])

    const { isCurrentUserAdmin } = useAuthentication()

    const { currentUser } = useFetchCurrentUser()

    const { peopleList, status } = useFetchAllPeople()

    const { openDialog } = useGlobal()

    const primaryActionButton = isCurrentUserAdmin() && (
        <LabeledButton
            title={t('people:inviteUser.title')}
            onClick={() =>
                openDialogWrapper(null, { type: 'INVITE_USER' }, openDialog)
            }
        />
    )

    const listItems = peopleList
        ? groupedPeopleToListItems(
              groupPeople(peopleList),
              openDialog,
              currentUser,
              isCurrentUserAdmin
          )
        : []

    return (
        <Worksheet
            match={props.match}
            listItems={listItems}
            listError={status === 'rejected'}
            listLoading={status === 'pending'}
            primaryActionButton={primaryActionButton}
            type="people"
        >
            <Switch>
                <Route
                    exact
                    path={`${props.match.path}/:id`}
                    render={(routerProps) => (
                        <PeopleContentBrowser
                            {...routerProps}
                            key={routerProps.match.params.id}
                            openDialog={openDialog}
                        />
                    )}
                />

                <Route
                    exact
                    path={props.match.path}
                    render={(routerProps) => (
                        <PeopleContentBrowser
                            {...routerProps}
                            openDialog={openDialog}
                        />
                    )}
                />
            </Switch>
        </Worksheet>
    )
}

export default PeopleWorksheet
