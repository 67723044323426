/**
 * Defines styles required for making a very long text finished in a single line
 * if it would not fit the container otherwise.
 *
 * Currently there is a similar implementation in common.scss file, but we
 * should convert that approach to a JSS oriented one.
 */
export const textEllipsis: Record<string, string> = {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
}
