import React from 'react'

/**
 * Renders one of the components based on the provided condition.
 *
 * @param condition - Condition
 * @param FirstComponent - Renders when condition gets evaluated to true
 * @param SecondComponent - Renders when condition gets evaluated to false
 */
const withCondition = <T extends any, U extends any>(
    condition: boolean,
    FirstComponent: React.ComponentType<T>,
    SecondComponent: React.ComponentType<U>
) => (props: any) =>
    condition ? <FirstComponent {...props} /> : <SecondComponent {...props} />

export default withCondition
