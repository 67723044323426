import AuthRequiredContainer from 'components/AuthRequiredContainer'
import DialogRenderer from 'components/dialog/DialogRenderer'
import Header from 'components/header/Header'
import MenuBar from 'components/menuBar/MenuBar'
import Notification from 'components/notification/Notification'
import Overlay from 'components/overlay/Overlay'
import { useFetchCurrentUser } from 'helpers/accountHelpers'
import withAppContext from 'hoc/withAppContext'
import { useAppMode } from 'hooks/useAppMode'
import { useAuthentication } from 'hooks/useAuthentication'
import { useGlobal } from 'hooks/useGlobal'
import AccessForbidden from 'pages/accessForbidden/AccessForbidden'
import Activate from 'pages/activate/Activate'
import Groups from 'pages/groups/Groups'
import Login from 'pages/login/Login'
import Maintenance from 'pages/maintenance/Maintenance'
import Matrices from 'pages/matrices/Matrices'
import NotFound from 'pages/notFound/NotFound'
import Overview from 'pages/overview/Overview'
import People from 'pages/people/People'
import Templates from 'pages/templates/Templates'
import React, { useEffect, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { RouteComponentProps, useHistory, withRouter } from 'react-router'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ErrorMessage } from './components/atoms'
import { isAdmin, isMatchingAnyRole, isSales } from './helpers/roleHelpers'
import PersonDetailsPage from './pages/personDetails/PersonDetails'
import SearchResults from './pages/searchResults/SearchResults'
import SearchTerms from './pages/searchTerms/SearchTerms'
import ResetPassword from './pages/resetPassword/ResetPassword'
import { CreateNewPassword } from './pages/createPassword/CreateNewPassword'

type AppProps = RouteComponentProps

const App = (props: AppProps) => {
    const [isLoggedIn, setIsLoggedIn] = useState(false)

    const [mode] = useAppMode()

    const history = useHistory()

    const {
        loggedIn,
        logOutCurrentUser,
        getCurrentUserRoles
    } = useAuthentication()

    const { redirectUrl } = useGlobal()

    const { currentUser, revalidateCurrentUser } = useFetchCurrentUser()

    const currentUserRoles = getCurrentUserRoles()

    useEffect(() => {
        const isLoggingOut = isLoggedIn && !loggedIn
        const isLoggingIn = !isLoggedIn && loggedIn

        if (mode === 'MAINTENANCE') {
            history.push('/maintenance')
        } else if (mode === 'FORBIDDEN') {
            history.push('/access-forbidden')
        } else if (isLoggingIn) {
            if (redirectUrl) {
                history.push(redirectUrl)
            }
        } else if (isLoggingOut) {
            history.push('/login')
        }

        if (loggedIn) {
            revalidateCurrentUser()
        }

        setIsLoggedIn(loggedIn)
    }, [
        isLoggedIn,
        revalidateCurrentUser,
        loggedIn,
        mode,
        redirectUrl,
        history
    ])

    const {
        activeNotification,
        createNotification,
        destroyNotification
    } = useGlobal()

    const defaultPath = '/overview'

    return (
        <div>
            <ErrorBoundary
                onError={(error) =>
                    createNotification({
                        description: error?.message || '',
                        type: 'Error'
                    })
                }
                fallbackRender={({ error }) => (
                    <ErrorMessage>{error.message}</ErrorMessage>
                )}
            >
                <DialogRenderer />
            </ErrorBoundary>

            {activeNotification && activeNotification.hideOverlay ? (
                <Notification
                    notification={activeNotification}
                    destroyNotification={destroyNotification}
                />
            ) : (
                <Overlay
                    open={!!activeNotification}
                    onClose={() => destroyNotification(activeNotification.id)}
                >
                    <Notification
                        notification={activeNotification}
                        destroyNotification={destroyNotification}
                    />
                </Overlay>
            )}

            <Route
                exact
                path="/login"
                render={() =>
                    loggedIn ? <Redirect to={defaultPath} /> : <Login />
                }
            />

            <Route
                exact
                path="/activate/:activationKey"
                render={() =>
                    loggedIn ? (
                        <Redirect to={defaultPath} />
                    ) : (
                        <Activate {...props} />
                    )
                }
            />

            <Route
                exact
                path="/maintenance"
                render={() =>
                    mode === 'MAINTENANCE' ? (
                        <Maintenance />
                    ) : loggedIn ? (
                        <Redirect to={defaultPath} />
                    ) : (
                        <Redirect to="/login" />
                    )
                }
            />

            <Route
                exact
                path="/reset-password"
                render={() =>
                    loggedIn ? (
                        <Redirect to={defaultPath} />
                    ) : (
                        <ResetPassword {...props} />
                    )
                }
            />

            <Route
                exact
                path="/reset-password/:resetPasswordKey"
                render={() =>
                    loggedIn ? (
                        <Redirect to={defaultPath} />
                    ) : (
                        <CreateNewPassword />
                    )
                }
            />

            <AuthRequiredContainer
                isMaintenance={mode === 'MAINTENANCE'}
                isLoggedIn={loggedIn}
                currentUser={currentUser}
            >
                <main className="appContent">
                    <div className="appContent__navigation">
                        <MenuBar />
                    </div>

                    <div className="appContent__contentPanel">
                        <Header
                            history={props.history}
                            currentUser={currentUser}
                            onLogout={logOutCurrentUser}
                        />

                        <div className="pageArea">
                            <Switch>
                                <Route
                                    path="/overview"
                                    render={withAppContext({
                                        page: 'overview'
                                    })(Overview)}
                                />

                                <Route
                                    path="/templates"
                                    render={withAppContext({
                                        page: 'templates'
                                    })(Templates)}
                                />

                                <Route
                                    exact
                                    path="/people/:id/details"
                                    render={
                                        isMatchingAnyRole(
                                            isAdmin,
                                            isSales
                                        )(currentUserRoles)
                                            ? withAppContext({
                                                  page: 'Person Details'
                                              })(PersonDetailsPage)
                                            : () => (
                                                  <Redirect to={defaultPath} />
                                              )
                                    }
                                />

                                <Route
                                    path="/people"
                                    render={withAppContext({
                                        page: 'people'
                                    })(People)}
                                />

                                <Route
                                    path="/groups"
                                    render={withAppContext({
                                        page: 'groups'
                                    })(Groups)}
                                />

                                <Route
                                    path="/search-terms"
                                    render={
                                        isMatchingAnyRole(
                                            isAdmin,
                                            isSales
                                        )(currentUserRoles)
                                            ? withAppContext({
                                                  page: 'search-terms'
                                              })(SearchTerms)
                                            : () => (
                                                  <Redirect to={defaultPath} />
                                              )
                                    }
                                />

                                <Route
                                    exact
                                    path="/search/:id"
                                    render={
                                        isMatchingAnyRole(
                                            isAdmin,
                                            isSales
                                        )(currentUserRoles)
                                            ? withAppContext({
                                                  page: 'search'
                                              })(SearchResults)
                                            : () => (
                                                  <Redirect to={defaultPath} />
                                              )
                                    }
                                />

                                <Route
                                    path="/matrices"
                                    render={withAppContext({
                                        page: 'matrices'
                                    })(Matrices)}
                                />

                                <Route
                                    path="/access-forbidden"
                                    component={AccessForbidden}
                                />

                                <Route
                                    exact
                                    path="/"
                                    render={() => <Redirect to={defaultPath} />}
                                />

                                <Route component={NotFound} />
                            </Switch>
                        </div>
                    </div>
                </main>
            </AuthRequiredContainer>
        </div>
    )
}

export default withRouter(App)
