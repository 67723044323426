import {
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles
} from '@material-ui/core'
import LevelSelector from 'components/input/levelSelector/LevelSelector'
import { pxToRem } from 'material/theme'
import React from 'react'
import { useHistory } from 'react-router'
import { textEllipsis } from 'styles/commonStyles'
import Badge from '../../components/badge/Badge'
import { getMonogram } from '../../utils/commonUtils'

export interface SearchResultListItemProps {
    name: string
    level: number
    id: string
}

const useSearchResultListItemStyles = makeStyles((theme) => ({
    primaryTitle: {
        color: theme.palette.text.primary,
        fontWeight: 600,
        fontSize: pxToRem(18),
        marginLeft: theme.spacing(1.5),
        ...textEllipsis
    }
}))

export const SearchResultListItem: React.FC<SearchResultListItemProps> = ({
    name,
    level,
    id
}) => {
    const history = useHistory()
    const classes = useSearchResultListItemStyles()

    function openPersonDetails() {
        history.push(`/people/${id}/details`)
    }

    return (
        <ListItem button onClick={openPersonDetails}>
            <ListItemAvatar>
                <Badge title={getMonogram(name)} size="md" />
            </ListItemAvatar>

            <ListItemText
                primary={name}
                primaryTypographyProps={{ className: classes.primaryTitle }}
            />

            <ListItemSecondaryAction>
                <LevelSelector value={level} disabled />
            </ListItemSecondaryAction>
        </ListItem>
    )
}

export default SearchResultListItem
