const overview = {
    title: 'Overview',
    statistics: {
        overallFillingRate: 'Overall filling rate',
        assignedMatrices: 'Assigned matrices',
        matricesMeetingExpectations: 'Matrices meeting expectations'
    },
    recommendation: {
        title: 'Training materials',
        recommendationBase:
            'Recommended based on your skill level in <0>{{matrixName}}</0>',
        noRecommendations: {
            newMatrices: 'Check out new matrices',
            checkMatrices: 'Check out your matrices'
        },
        filledAllMatrices:
            'You have mastered all the skills in your assigned matrices.',
        hasNotMatrices:
            'You have not assigned any matrices to your profile yet. Assign a new matrix and dive into a new technology.',
        doesNotFillAllMatrices:
            'Complete the matrices to get training material recommendations.',
        doesNotFillAnyMatrices:
            'Your overall filling rate is at zero. Start filling matrices to get training material recommendations.'
    },
    finishTrainingMaterial: {
        title: 'Finish training material',
        question: 'Have you finished <0>{{trainingMaterialTitle}}</0>?'
    }
}

export default overview
