import { SelectInputValue } from '@app/common'
import { FilterPopoverFormData } from '@app/form'
import FilterIcon from '@atlaskit/icon/glyph/filter'
import { useTheme } from '@material-ui/core'
import { Button as BaseButton } from 'components/button/base/Button'
import { Form, Formik } from 'formik'
import { useFetchAllGroups } from 'helpers/groupHelpers'
import React from 'react'
import { useTranslation } from 'react-i18next'
import FormikHelpers from '../../helpers/formikHelpers'
import { useGlobal } from '../../hooks/useGlobal'
import { Button } from '../button/Button'
import ExitButton from '../button/exit/ExitButton'
import LabeledSelect from '../input/labeledSelect/LabeledSelect'
import {
    StyledFilterPopover,
    useFilterPopoverStyles
} from './FilterPopover.styles'

export interface FilterPopoverProps {
    disabled?: boolean
    onFilterSubmit: (values: FilterPopoverFormData) => Promise<void>
    initialValues: FilterPopoverFormData
}

export const FilterPopover: React.FC<FilterPopoverProps> = ({
    disabled,
    onFilterSubmit,
    initialValues
}) => {
    const theme = useTheme()
    const { t } = useTranslation(['search', 'common'])
    const classes = useFilterPopoverStyles()

    const { formDirty, toggleFormToDirty, createNotification } = useGlobal()

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        null
    )

    const { groupList, error, status } = useFetchAllGroups()

    if (status === 'rejected' && error.status !== 403) {
        createNotification({
            description: t('error:response.groupListLoadUnknown'),
            type: 'Error'
        })
    }

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    const id = open ? 'filter-popover' : undefined

    const levelOptions: Array<SelectInputValue> = [
        { label: t('search:labels.levelOptions.firstLevel'), value: '1' },
        { label: t('search:labels.levelOptions.secondLevel'), value: '2' },
        { label: t('search:labels.levelOptions.thirdLevel'), value: '3' },
        { label: t('search:labels.levelOptions.fourthLevel'), value: '4' },
        { label: t('search:labels.levelOptions.fifthLevel'), value: '5' }
    ]

    const groupOptions: Array<SelectInputValue> = groupList.map((group) => ({
        label: group.name,
        value: group.id
    }))

    const onSubmit = async (values: FilterPopoverFormData) => {
        await onFilterSubmit(values)
        handleClose()
    }

    return (
        <div>
            <Button
                startIcon={
                    <FilterIcon
                        label=""
                        size="medium"
                        primaryColor={
                            open
                                ? theme.palette.common.white
                                : theme.palette.text.primary
                        }
                    />
                }
                variant={open ? 'primary' : 'secondary'}
                className={classes.filterPopoverButton}
                aria-describedby={id}
                onClick={handleClick}
                disabled={disabled}
            >
                {t('search:labels.filter')}
            </Button>
            <StyledFilterPopover
                marginThreshold={40}
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
            >
                <div className={classes.filterPopoverHeader}>
                    <span className={classes.filterPopoverHeaderText}>
                        {t('search:labels.selectFilters')}
                    </span>
                    <ExitButton size="small" onClick={handleClose} />
                </div>
                <Formik initialValues={initialValues} onSubmit={onSubmit}>
                    {(formikProps) => {
                        return (
                            <Form className="dialog__form">
                                <div className="dialog__scrollArea dialog__scrollArea--smallBottomMargin">
                                    <LabeledSelect
                                        label={t('search:labels:levels')}
                                        name="levels"
                                        options={levelOptions}
                                        value={formikProps.values.levels}
                                        isClearable={false}
                                        onChange={(
                                            values: Array<SelectInputValue>
                                        ) =>
                                            FormikHelpers.setDirtyForm(
                                                formikProps,
                                                'levels',
                                                formDirty,
                                                toggleFormToDirty
                                            )(values)
                                        }
                                        multiSelect
                                        tabIndex={0}
                                    />
                                    <LabeledSelect
                                        label={t('search:labels:groups')}
                                        name="groups"
                                        options={groupOptions}
                                        value={formikProps.values.groups}
                                        isClearable={false}
                                        onChange={(
                                            values: Array<SelectInputValue>
                                        ) =>
                                            FormikHelpers.setDirtyForm(
                                                formikProps,
                                                'groups',
                                                formDirty,
                                                toggleFormToDirty
                                            )(values)
                                        }
                                        multiSelect
                                        tabIndex={0}
                                    />
                                </div>
                                <div className={classes.formActions}>
                                    <BaseButton
                                        title={t('common:labels.save')}
                                        loading={formikProps.isSubmitting}
                                        type="submit"
                                        primary
                                        formButton
                                    />
                                </div>
                            </Form>
                        )
                    }}
                </Formik>
            </StyledFilterPopover>
        </div>
    )
}

export default FilterPopover
