import { TrainingMaterial } from '@app/overview'
import CheckCircleIcon from '@atlaskit/icon/glyph/check-circle'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { Trans } from 'react-i18next'

export interface Props {
    trainingMaterialId: string
    trainingMaterialTitle: string
    trainingMaterialUrl: string
    matrixName: string
}

const RecommendationCard = (props: Props) => {
    const {
        trainingMaterialId,
        trainingMaterialTitle,
        trainingMaterialUrl,
        matrixName
    } = props

    const { openDialog } = useGlobal()

    const handleMarkCompleted = () => {
        openDialog({
            type: 'FINISH_TRAINING_MATERIAL',
            payload: {
                id: trainingMaterialId,
                name: trainingMaterialTitle
            } as TrainingMaterial
        })
    }

    return (
        <div className="recommendationCard">
            <div>
                <a
                    className="colored"
                    href={trainingMaterialUrl}
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className="recommendationCard__title">
                        {trainingMaterialTitle}
                    </div>
                </a>
                <div className="recommendationCard__description">
                    <Trans i18nKey="overview:recommendation.recommendationBase">
                        <strong>{{ matrixName }}</strong>
                    </Trans>
                </div>
            </div>
            <div
                className="recommendationCard__primaryAction"
                onClick={handleMarkCompleted}
            >
                <CheckCircleIcon label="" primaryColor="" />
            </div>
        </div>
    )
}

export default RecommendationCard
