import classNames from 'classnames'
import InfoCard from 'components/card/InfoCard'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'

interface Props extends WithTranslation {
    cardAction: React.ReactNode
    hasError: boolean
}

const MaintenanceCard = (props: Props) => (
    <InfoCard
        title={props.t('card.maintenance.title')}
        description={props.t('card.maintenance.description')}
        className={classNames({ shake: props.hasError })}
        cardAction={props.cardAction}
    />
)

export default withTranslation('common')(MaintenanceCard)
