import { SearchResult } from '@app/common'
import { Divider, List, ListSubheader, makeStyles } from '@material-ui/core'
import { pxToRem } from 'material/theme'
import React from 'react'
import { SearchResultListItem } from './SearchResultListItem'

export interface SearchResultSectionProps {
    searchResult: SearchResult
}

const useSearchResultSectionStyles = makeStyles((theme) => ({
    subheader: {
        display: 'flex',
        width: '100%',
        padding: theme.spacing(2),
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        textTransform: 'uppercase',
        lineHeight: 'inherit',
        color: theme.palette.text.disabled,
        fontWeight: 700,
        fontSize: pxToRem(14)
    }
}))

export const SearchResultSection: React.FC<SearchResultSectionProps> = ({
    searchResult
}) => {
    const classes = useSearchResultSectionStyles()

    return searchResult.members ? (
        <List
            subheader={
                <ListSubheader className={classes.subheader}>
                    {searchResult.group.name}
                </ListSubheader>
            }
            aria-label="search results"
        >
            {searchResult.members.map((member, index) => (
                <React.Fragment key={member.id}>
                    <SearchResultListItem
                        name={member.name}
                        level={member.level}
                        id={member.id}
                    />

                    {index !== searchResult.members.length - 1 && <Divider />}
                </React.Fragment>
            ))}
        </List>
    ) : null
}

export default SearchResultSection
