import MaterialPopover from '@material-ui/core/Popover'
import classNames from 'classnames'
import Button from 'components/button/base/Button'
import Overlay from 'components/overlay/Overlay'
import React from 'react'
import FocusLock from 'react-focus-lock'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'recompose'

interface OwnProps {
    onClose: () => void
    onConfirmClose?: () => void
    title?: string
    content?: string
    footerText?: string
}

type Props = OwnProps & WithTranslation

const DirtyConfirmDialog = (props: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<
        null | Element | ((element: Element) => Element)
    >(document.getElementById('root'))

    const [showOverlay, setOverlay] = React.useState<boolean>(true)

    const onPopoverClose = () => {
        setAnchorEl(undefined)
        props.onConfirmClose()
        setOverlay(false)
    }

    return (
        <Overlay open={showOverlay} onClose={null}>
            <MaterialPopover
                className={classNames('confirmDialog__body')}
                id="confirmDialog"
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'center'
                }}
                onClose={onPopoverClose}
            >
                <FocusLock>
                    <div className="confirmDialog__title">{props.title}</div>
                    <div className="confirmDialog__content">
                        {props.content}
                    </div>

                    <div className="confirmDialog__footer">
                        {props.footerText}
                    </div>

                    <div className="confirmDialog__actions">
                        <Button
                            title={props.t('common:labels.cancel')}
                            onClick={onPopoverClose}
                            smallButton
                        />

                        <Button
                            title={props.t('common:labels.leave')}
                            onClick={props.onClose}
                            primary
                            smallButton
                            destructive
                            autoFocus
                        />
                    </div>
                </FocusLock>
            </MaterialPopover>
        </Overlay>
    )
}

export default compose<Props, OwnProps>(withTranslation('common'))(
    DirtyConfirmDialog
)
