import { makeStyles, Popover, withStyles } from '@material-ui/core'
import { pxToRem } from '../../material/theme'

export const StyledGroupInfoPopover = withStyles((theme) => ({
    paper: {
        width: 340,
        padding: 16,
        backgroundColor: theme.palette.common.white,
        borderRadius: 15
    }
}))(Popover)

export const useGroupInfoPopoverStyles = makeStyles((theme) => ({
    popoverHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        height: 40
    },
    popoverHeaderText: {
        fontWeight: 600,
        fontSize: pxToRem(18)
    }
}))
