import common from './common.en'
import error from './error.en'
import groups from './groups.en'
import login from './login'
import matrices from './matrices.en'
import overview from './overview.en'
import people from './people.en'
import templates from './templates.en'
import searchTerms from './searchTerms.en'
import search from './search.en'

export default {
    common,
    error,
    groups,
    login,
    matrices,
    overview,
    people,
    templates,
    searchTerms,
    search
}
