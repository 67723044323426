import { RouteParams } from '@app/common'
import AssignTemplateDialog from 'components/dialog/assignTemplate/AssignTemplateDialog'
import CancelInvitationDialog from 'components/dialog/cancelInvitation/CancelInvitationDialog'
import ChangePasswordDialog from 'components/dialog/changePassword/ChangePasswordDialog'
import ConfirmationDialog from 'components/dialog/confirmation/ConfirmationDialog'
import ConvertSkillDialog from 'components/dialog/convertSkill/ConvertSkillDialog'
import CopySkillDialog from 'components/dialog/copySkill/CopySkillDialog'
import CopySkillSetDialog from 'components/dialog/copySkillSet/CopySkillSetDialog'
import CreateGroupDialog from 'components/dialog/createGroup/CreateGroupDialog'
import CreateSkillDialog from 'components/dialog/createSkill/CreateSkillDialog'
import CreateSkillSetDialog from 'components/dialog/createSkillSet/CreateSkillSetDialog'
import CreateTemplateDialog from 'components/dialog/createTemplate/CreateTemplateDialog'
import DeleteSkillDialog from 'components/dialog/deleteSkill/DeleteSkillDialog'
import DeleteSkillSetDialog from 'components/dialog/deleteSkillSet/DeleteSkillSetDialog'
import DeleteTemplateDialog from 'components/dialog/deleteTemplate/DeleteTemplateDialog'
import DeleteUserDialog from 'components/dialog/deleteUser/DeleteUserDialog'
import DescriptionDialog from 'components/dialog/description/DescriptionDialog'
import EditGroupDialog from 'components/dialog/editGroup/EditGroupDialog'
import EditSkillDialog from 'components/dialog/editSkill/EditSkillDialog'
import EditSkillSetDialog from 'components/dialog/editSkillSet/EditSkillSetDialog'
import EditTemplateDialog from 'components/dialog/editTemplate/EditTemplateDialog'
import EditUserDialog from 'components/dialog/editUser/EditUserDialog'
import LinkTemplateDialog from 'components/dialog/linkTemplate/LinkTemplateDialog'
import RemoveMatrixDialog from 'components/dialog/removeMatrix/RemoveMatrixDialog'
import ResendInvitationDialog from 'components/dialog/resendInvitation/ResendInvitationDialog'
import UnlinkTemplateDialog from 'components/dialog/unlinkTemplate/UnlinkTemplateDialog'
import Overlay from 'components/overlay/Overlay'
import { useGlobal } from 'hooks/useGlobal'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { matchPath, useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import CreateSearchTermDialog from './createSearchTerm/CreateSearchTermDialog'
import DeleteGroupDialog from './deleteGroup/DeleteGroupDialog'
import DeleteSearchTermDialog from './deleteSearchTerm/DeleteSearchTermDialog'
import EditSearchTermDialog from './editSearchTerm/EditSearchTermDialog'
import FinishTrainingMaterialDialog from './finishTrainingMaterial/FinishTrainingMaterialDialog'
import LeaveFeedbackDialog from './leaveFeedback/LeaveFeedbackDialog'
import ShareSearchTermDialog from './shareSearchTerm/ShareSearchTermDialog'
import AssignSkillDialog from './assignSkill/AssignSkillDialog'
import UnassignDialog from './unassign/UnassignDialog'

const DialogRenderer = () => {
    const { t } = useTranslation(['common'])

    const location = useLocation()

    const history = useHistory()

    const {
        formDirty,
        shouldShowConfirmation,
        hideConfirmation,
        activeDialog,
        closeActiveOverlay
    } = useGlobal()

    const redirectOnMatch: (path: string) => void = (pathId) => {
        const match = matchPath<RouteParams>(location.pathname, {
            path: '/:type/:id',
            exact: false,
            strict: false
        })

        if (match && match.params.id === pathId) {
            history.push(`/${match.params.type}`)
        }
    }

    const onUnload = React.useCallback(
        (event: BeforeUnloadEvent) => {
            if (formDirty && !shouldShowConfirmation) {
                event.preventDefault()
                // eslint-disable-next-line
                event.returnValue = ''

                return ''
            }

            return ''
        },
        [formDirty, shouldShowConfirmation]
    )

    useEffect(() => {
        window.addEventListener('beforeunload', onUnload)

        return () => {
            window.removeEventListener('beforeunload', onUnload)
        }
    }, [onUnload])

    const renderDialog = () => {
        switch (activeDialog.type) {
            case 'FINISH_TRAINING_MATERIAL':
                return (
                    <FinishTrainingMaterialDialog
                        trainingMaterial={activeDialog.payload}
                    />
                )
            case 'ASSIGN_TEMPLATE':
                return (
                    <AssignTemplateDialog
                        userId={activeDialog.payload.userId}
                    />
                )
            case 'CANCEL_INVITATION':
                return <CancelInvitationDialog user={activeDialog.payload} />
            case 'CONVERT_SKILL':
                return (
                    <ConvertSkillDialog
                        skill={activeDialog.payload.currentSkill}
                    />
                )
            case 'CREATE_SKILL':
                return <CreateSkillDialog template={activeDialog.payload} />
            case 'CREATE_SKILL_SET':
                return <CreateSkillSetDialog template={activeDialog.payload} />
            case 'CREATE_TEMPLATE':
                return <CreateTemplateDialog />
            case 'DELETE_SKILL':
                return (
                    <DeleteSkillDialog
                        skill={activeDialog.payload.currentSkill}
                    />
                )
            case 'DELETE_SKILL_SET':
                return <DeleteSkillSetDialog skillSet={activeDialog.payload} />
            case 'DELETE_TEMPLATE':
                return (
                    <DeleteTemplateDialog
                        redirectOnMatch={(templateId: string) =>
                            redirectOnMatch(templateId)
                        }
                        template={activeDialog.payload}
                    />
                )
            case 'EDIT_SKILL':
                return (
                    <EditSkillDialog
                        skill={activeDialog.payload.skill}
                        parentSkillSetId={activeDialog.payload.parentSkillSetId}
                        template={activeDialog.payload.template}
                    />
                )
            case 'COPY_SKILL':
                return (
                    <CopySkillDialog
                        skill={activeDialog.payload.skill}
                        parentSkillSetId={activeDialog.payload.parentSkillSetId}
                        template={activeDialog.payload.template}
                    />
                )
            case 'EDIT_SKILL_SET':
                return (
                    <EditSkillSetDialog
                        skillSet={activeDialog.payload?.skillSet}
                        parentId={activeDialog.payload?.parentId}
                        template={activeDialog.payload?.template}
                    />
                )

            case 'COPY_SKILL_SET':
                return (
                    <CopySkillSetDialog
                        skillSet={activeDialog.payload.skillSet}
                        parentId={activeDialog.payload.parentId}
                        template={activeDialog.payload.template}
                    />
                )
            case 'EDIT_TEMPLATE':
                return <EditTemplateDialog template={activeDialog.payload} />
            case 'EDIT_GROUP':
                return <EditGroupDialog group={activeDialog.payload} />
            case 'INVITE_USER':
                return <EditUserDialog />
            case 'EDIT_USER':
                return <EditUserDialog person={activeDialog.payload} />
            case 'CHANGE_PASSWORD':
                return <ChangePasswordDialog person={activeDialog.payload} />
            case 'DELETE_USER':
                return (
                    <DeleteUserDialog
                        redirectOnMatch={(userId: string) =>
                            redirectOnMatch(userId)
                        }
                        user={activeDialog.payload}
                    />
                )
            case 'REMOVE_MATRIX':
                return (
                    <RemoveMatrixDialog
                        matrixData={activeDialog.payload}
                        redirectOnMatch={(templateId: string) =>
                            redirectOnMatch(templateId)
                        }
                    />
                )
            case 'LEAVE_FEEDBACK':
                return (
                    <LeaveFeedbackDialog
                        feedbackTargetId={activeDialog.payload.feedbackTargetId}
                        feedbackTargetType={
                            activeDialog.payload.feedbackTargetType
                        }
                        feedbackTargetName={
                            activeDialog.payload.feedbackTargetName
                        }
                    />
                )
            case 'RESEND_INVITATION':
                return <ResendInvitationDialog user={activeDialog.payload} />
            case 'LINK_TEMPLATE':
                return (
                    <LinkTemplateDialog
                        templateId={activeDialog.payload.templateId}
                    />
                )

            case 'UNLINK_TEMPLATE': {
                return (
                    <UnlinkTemplateDialog templateData={activeDialog.payload} />
                )
            }

            case 'DESCRIPTION':
                return (
                    <DescriptionDialog
                        entity={activeDialog.payload.data}
                        currentPage={activeDialog.payload.currentPage}
                        owners={activeDialog.payload.owners}
                    />
                )

            case 'CREATE_GROUP':
                return <CreateGroupDialog />

            case 'DELETE_GROUP':
                return (
                    <DeleteGroupDialog
                        redirectOnMatch={(groupId: string) =>
                            redirectOnMatch(groupId)
                        }
                        group={activeDialog.payload}
                    />
                )

            case 'CREATE_SEARCH_TERM':
                return <CreateSearchTermDialog />

            case 'EDIT_SEARCH_TERM':
                return (
                    <EditSearchTermDialog searchTerm={activeDialog.payload} />
                )

            case 'DELETE_SEARCH_TERM':
                return (
                    <DeleteSearchTermDialog
                        redirectOnMatch={redirectOnMatch}
                        searchTerm={activeDialog.payload}
                    />
                )

            case 'ASSIGN_SKILL':
                return <AssignSkillDialog searchTerm={activeDialog.payload} />

            case 'UN_ASSIGN_SKILL_SET':
                return (
                    <UnassignDialog
                        searchTermId={activeDialog.payload.searchTermId}
                        skillSet={activeDialog.payload.skillSet}
                        templateId={activeDialog.payload.templateId}
                        type={activeDialog.payload.type}
                    />
                )

            case 'UN_ASSIGN_SKILL':
                return (
                    <UnassignDialog
                        searchTermId={activeDialog.payload.searchTermId}
                        skill={activeDialog.payload.skill}
                        templateId={activeDialog.payload.templateId}
                        skillSet={activeDialog.payload.skillSet}
                        type={activeDialog.payload.type}
                    />
                )

            case 'SHARE_SEARCH_TERM':
                return (
                    <ShareSearchTermDialog
                        searchTermId={activeDialog.payload.searchTermId}
                        searchTermName={activeDialog.payload.searchTermName}
                    />
                )

            default:
                throw new Error(`Unsupported dialog type: ${activeDialog.type}`)
        }
    }

    const { tryCloseDirtyOverlay } = useGlobal()

    return activeDialog ? (
        <>
            <Overlay open={!!activeDialog} onClose={tryCloseDirtyOverlay}>
                {renderDialog()}
            </Overlay>

            {shouldShowConfirmation ? (
                <ConfirmationDialog
                    onClose={closeActiveOverlay}
                    onConfirmClose={hideConfirmation}
                    title={t('common:popover.confirmation.title')}
                    content={t('common:popover.confirmation.content')}
                    footerText={t('common:popover.confirmation.footerText')}
                />
            ) : null}
        </>
    ) : null
}

export default DialogRenderer
