import { SelectableListItem } from '@app/common'
import { AssignTemplateDialogFormData, OnboardingFormData } from '@app/form'
import { CircularProgress, makeStyles } from '@material-ui/core'
import clsx from 'clsx'
import {
    Form,
    Formik,
    FormikHelpers as FormikActions,
    FormikProps
} from 'formik'
import React, { lazy, Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import { useFetchCurrentUser } from '../../helpers/accountHelpers'
import {
    orderTemplateListByName,
    requestAllUserTemplatesRevalidation,
    useFetchUnassignedUserTemplates,
    useRequestTemplatesAssign
} from '../../helpers/templateHelpers'
import Button from '../button/base/Button'
import { StepIndicator } from './StepIndicator'

const OnboardingStart = lazy(() => import('./OnboardingStart'))
const OnboardingProfile = lazy(() => import('./OnboardingProfile'))
const OnboardingFinish = lazy(() => import('./OnboardingFinish'))

export interface OnboardingProps {
    onChange: VoidFunction
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        position: 'relative',
        minWidth: 1000,
        backgroundColor: 'white',
        borderRadius: 15,
        boxShadow: '0 5px 15px rgba(0, 0, 0, 0.08)',
        flex: '1 1 auto',
        alignItems: 'center',
        justifyContent: 'center'
    },
    form: {
        display: 'grid',
        width: '100%',
        height: '100%',
        maxWidth: 555,
        rowGap: theme.spacing(12.5),
        justifyItems: 'center'
    },
    denseForm: {
        rowGap: 0
    },
    actions: {
        display: 'flex',
        alignSelf: 'flex-end',
        flexDirection: 'column',
        minHeight: 100
    },
    actionButton: {
        maxWidth: 125,
        margin: theme.spacing(0, 'auto', 1.25)
    },
    activityIndicator: {
        marginTop: theme.spacing(8)
    }
}))

export const Onboarding: React.FC<OnboardingProps> = ({ onChange }) => {
    const [step, setStep] = React.useState(0)

    const { t } = useTranslation(['common', 'matrices'])
    const { currentUser } = useFetchCurrentUser()
    const requestTemplatesAssign = useRequestTemplatesAssign()
    const classes = useStyles()

    const validationSchema = Yup.object().shape({
        templates: Yup.array().test(
            'Check matrix is selected',
            t('error:validation.noMatrixSelected'),
            (templates: Array<SelectableListItem>) =>
                step === 0 || templates.some((template) => template.selected)
        )
    })

    const onSubmit = async (
        values: AssignTemplateDialogFormData,
        formikActions: FormikActions<AssignTemplateDialogFormData>
    ) => {
        if (step === 2) {
            const selectedTemplateIds: Array<string> = values.templates
                .filter((template) => template.selected)
                .map((template) => template.id)

            try {
                if (currentUser.id && selectedTemplateIds) {
                    await requestTemplatesAssign(
                        currentUser.id,
                        selectedTemplateIds
                    )
                }

                await requestAllUserTemplatesRevalidation(currentUser.id)
                onChange()
            } catch (payload) {
                // TODO: Introduce logging
                formikActions.setSubmitting(true)
            }
            formikActions.setSubmitting(false)
        } else {
            if (
                step === 1 &&
                values.templates.every((template) => !template.selected)
            ) {
                return
            }
            setStep(step + 1)
        }
    }

    const {
        unassignedUserTemplateList,
        status
    } = useFetchUnassignedUserTemplates(currentUser.id)

    const initialValues = React.useMemo<OnboardingFormData>(
        () => ({
            searchKey: '',
            templates: orderTemplateListByName(unassignedUserTemplateList).map(
                (template) => {
                    const linkedTemplate = unassignedUserTemplateList.find(
                        (element) => element.id === template.linkedTemplateId
                    )

                    return {
                        id: template.id,
                        title: template.name,
                        secondaryTitle: linkedTemplate
                            ? t(
                                  'matrices:assignTemplate.labels.linkedTemplate',
                                  { linkedTemplateName: linkedTemplate.name }
                              )
                            : null,
                        selected: false,
                        labels: template.labels.map((label) => label.name)
                    }
                }
            )
        }),
        [t, unassignedUserTemplateList]
    )

    return (
        <div className={classes.container}>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={onSubmit}
                enableReinitialize
            >
                {(formikProps: FormikProps<AssignTemplateDialogFormData>) => {
                    return (
                        <Form
                            className={clsx(
                                classes.form,
                                step === 1 && classes.denseForm
                            )}
                        >
                            <Suspense
                                fallback={
                                    <CircularProgress
                                        className={classes.activityIndicator}
                                    />
                                }
                            >
                                {step === 0 && <OnboardingStart />}
                                {step === 1 && (
                                    <OnboardingProfile
                                        formikProps={formikProps}
                                        status={status}
                                    />
                                )}

                                {step === 2 && <OnboardingFinish />}
                            </Suspense>

                            <div className={classes.actions}>
                                <Button
                                    className={classes.actionButton}
                                    title={
                                        step === 2
                                            ? t('matrices:labels.start')
                                            : t('matrices:labels.next')
                                    }
                                    type="submit"
                                    primary
                                    loading={formikProps.isSubmitting}
                                    formButton
                                    autoFocus
                                />
                                <StepIndicator
                                    step={step}
                                    setStep={(i) => {
                                        if (
                                            i === 2 &&
                                            formikProps.values.templates.every(
                                                (template) => !template.selected
                                            )
                                        ) {
                                            return
                                        }

                                        setStep(i)
                                    }}
                                    numberOfSteps={3}
                                />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </div>
    )
}

export default Onboarding
