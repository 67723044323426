import { UnlinkTemplateData } from '@app/template'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import {
    requestAllTemplatesRevalidation,
    requestSingleTemplateRevalidation,
    useRequestTemplateUnlink
} from 'helpers/templateHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { Trans, WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'recompose'

interface OwnProps {
    templateData: UnlinkTemplateData
}

type Props = OwnProps & WithTranslation

const UnlinkTemplateDialog: React.FC<Props> = (props: Props) => {
    const { t, templateData } = props

    const {
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const initialValues = {}

    const requestTemplateUnlink = useRequestTemplateUnlink()
    const onSubmit = async (
        values: unknown,
        formikActions: FormikActions<unknown>
    ) => {
        try {
            await requestTemplateUnlink(templateData.baseTemplate.id)
            await requestAllTemplatesRevalidation()
            await requestSingleTemplateRevalidation(
                templateData.baseTemplate.id
            )
            closeActiveOverlay()
        } catch (payload) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            createNotification({
                type: props.t('error:labels.error'),
                description: props.t('error:response.unlinkTemplateUnknown')
            })
        }
    }

    return (
        <Dialog
            title={t('templates:unlinkTemplate.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon={false}
        >
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(formikProps) => (
                    <Form className="dialog__form dialog__form--confirm">
                        <div className="dialog__info">
                            <Trans i18nKey="templates:unlinkTemplate.question">
                                <strong>
                                    {{
                                        linkedTemplateName:
                                            templateData.linkedTemplateName
                                    }}
                                </strong>

                                <strong>
                                    {{
                                        baseTemplateName:
                                            templateData.baseTemplate.name
                                    }}
                                </strong>
                            </Trans>
                        </div>

                        <div className="dialog__actions dialog__actions--noShadow">
                            <Button
                                title={t('common:labels.cancel')}
                                onClick={closeActiveOverlay}
                                formButton
                            />

                            <Button
                                title={t('common:labels.unlink')}
                                type="submit"
                                loading={formikProps.isSubmitting}
                                destructive
                                primary
                                formButton
                                autoFocus
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </Dialog>
    )
}

export default compose<Props, OwnProps>(
    withTranslation(['common', 'templates', 'error'])
)(UnlinkTemplateDialog)
