import { SelectInputValue } from '@app/common'
import { CopySkillSetDialogFormData } from '@app/form'
import { SkillSetDetails, TemplateDetails } from '@app/template'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import LabeledSelect from 'components/input/labeledSelect/LabeledSelect'
import {
    Form,
    FormikContext,
    FormikHelpers as FormikActions,
    FormikProps,
    useFormik
} from 'formik'
import FormikHelpers from 'helpers/formikHelpers'
import { useRequestSkillSetCopy } from 'helpers/skillHelpers'
import {
    getSkillSetDropdownItems,
    getTemplateDropdownItems,
    requestSingleTemplateRevalidation,
    useFetchAllTemplates,
    useFetchTemplateById
} from 'helpers/templateHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React, { useEffect, useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'recompose'

interface OwnProps {
    skillSet: SkillSetDetails
    parentId: string
    template: TemplateDetails
}

type Props = OwnProps & WithTranslation

const CopySkillSetDialog = (props: Props) => {
    const [selectedTemplateId, setSelectedTemplateId] = useState(
        props.template.id
    )

    const { t, skillSet, parentId } = props

    const {
        formDirty,
        toggleFormToDirty,
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const { templateList } = useFetchAllTemplates()

    const { template: selectedTemplate, status } = useFetchTemplateById(
        selectedTemplateId
    )

    if (status === 'rejected') {
        createNotification({
            type: props.t('error:labels.error'),
            description: props.t('error:response.templateLoadUnknown')
        })
    }

    const getSkillSetListBasedOnSelectedTemplate = React.useCallback(
        (template: TemplateDetails) => {
            if (
                skillSet.childSkillSets &&
                skillSet.childSkillSets.length !== 0
            ) {
                return [
                    {
                        label: t('templates:labels.parentPlaceholderEdit', {
                            templateName: template?.name ?? ''
                        }),
                        value: template?.id ?? ''
                    }
                ]
            }
            return [
                {
                    label: t('templates:labels.parentPlaceholderEdit', {
                        templateName: template?.name ?? ''
                    }),
                    value: template?.id ?? ''
                },
                ...getSkillSetDropdownItems(template).filter(
                    (skillSetInput) => skillSetInput.value !== skillSet.id
                )
            ]
        },
        [skillSet.childSkillSets, skillSet.id, t]
    )

    const initialValues: CopySkillSetDialogFormData = {
        name: skillSet.name,
        description: skillSet.description,
        parent: getSkillSetListBasedOnSelectedTemplate(selectedTemplate).find(
            (skillSetBasedOnSelectedTemplate) =>
                skillSetBasedOnSelectedTemplate.value === parentId
        ),
        template: getTemplateDropdownItems(templateList).find(
            (template) => template.value === selectedTemplate?.id
        ),
        ownerUserIds: skillSet.ownerUserIds
    }

    const requestSkillSetCopy = useRequestSkillSetCopy()
    const onSubmit = async (
        values: CopySkillSetDialogFormData,
        formikActions: FormikActions<CopySkillSetDialogFormData>
    ) => {
        try {
            const { data: template } = await requestSkillSetCopy(skillSet.id, {
                newTemplateId: values.template.value,
                parentId: values.parent.value
            })
            await requestSingleTemplateRevalidation(template.id)

            closeActiveOverlay()
        } catch (payload) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            createNotification({
                type: props.t('error:labels.error'),
                description: props.t('error:response.copySkillSetUnknown', {
                    skillSetName: skillSet.name
                })
            })
        }
    }

    const formik: FormikProps<CopySkillSetDialogFormData> = useFormik({
        initialValues,
        onSubmit,
        validateOnChange: true,
        enableReinitialize: true
    })

    useEffect(() => {
        if (!selectedTemplate) {
            return
        }

        FormikHelpers.setDirtyForm(
            formik,
            'template',
            formDirty,
            toggleFormToDirty
        )(
            getTemplateDropdownItems(templateList).find(
                (template) => template.value === selectedTemplate?.id
            )
        )

        formik.setFieldValue(
            'parent',
            getSkillSetListBasedOnSelectedTemplate(selectedTemplate)[0]
        )

        // TODO: Check why adding the necessary dependencies puts this component
        // in an infinite loop
        // eslint-disable-next-line
    }, [selectedTemplate])

    return (
        <FormikContext.Provider value={formik}>
            <Dialog
                title={t('templates:copySkillSet.title')}
                closeDialog={tryCloseDirtyOverlay}
                showCloseIcon
            >
                <Form className="dialog__form">
                    <div className="dialog__scrollArea">
                        <LabeledSelect
                            label={t('templates:labels.template')}
                            name="template"
                            options={getTemplateDropdownItems(templateList)}
                            value={formik.values.template}
                            isClearable={false}
                            placeholder={t('templates:labels.selectSkillSet', {
                                templateName: props.template.name
                            })}
                            onChange={(template: SelectInputValue) => {
                                setSelectedTemplateId(template.value)
                            }}
                            tabIndex={0}
                        />

                        <LabeledSelect
                            label={t('templates:labels.parent')}
                            name="parent"
                            options={getSkillSetListBasedOnSelectedTemplate(
                                selectedTemplate
                            )}
                            value={formik.values.parent}
                            isClearable={false}
                            placeholder={t('templates:labels.selectSkillSet', {
                                templateName: selectedTemplate?.name ?? ''
                            })}
                            onChange={FormikHelpers.setDirtyForm(
                                formik,
                                'parent',
                                formDirty,
                                toggleFormToDirty
                            )}
                            tabIndex={0}
                        />
                    </div>

                    <div className="dialog__actions dialog__actions--noShadow">
                        <Button
                            title={t('common:labels.cancel')}
                            onClick={closeActiveOverlay}
                            formButton
                        />

                        <Button
                            title={t('common:labels.copy')}
                            type="submit"
                            loading={formik.isSubmitting}
                            primary
                            formButton
                            autoFocus
                        />
                    </div>
                </Form>
            </Dialog>
        </FormikContext.Provider>
    )
}

export default compose<Props, OwnProps>(
    withTranslation(['common', 'templates', 'error'])
)(CopySkillSetDialog)
