const templates = {
    title: 'Templates',
    labels: {
        name: 'Name',
        type: 'Type',
        labels: 'Labels',
        unlink: 'Unlink',
        template: 'Template',
        parent: 'Location',
        parentPlaceholder: 'Default: Root of {{templateName}} template',
        parentPlaceholderEdit: 'Root of {{templateName}} template',
        descriptionOptional: 'Description (Optional)',
        coachesOptional: 'Coaches (Optional)',
        coaches: 'Coaches',
        description: 'Description',
        descriptionSkillPlaceholder:
            '(Add some description and useful links to this skill)',
        descriptionSkillSetPlaceholder:
            '(Add some description and useful links to this skill set)',
        selectSkillSet: 'Select a skill set of {{templateName}} template',
        selectSkillLabel: '{{skillName}} in {{parentName}}',
        resetSkillRatings: 'Reset skill ratings',
        resetSkillRatingsDescription:
            'You can reset this skill in every matrix where it was already rated. Use this feature only if you’ve made significant changes to this item because it will result in a loss of data for everyone who has already given a rating to this skill.',
        createAnother: 'Create another',
        skillSet_notEmpty: '{{count}} skill set and ',
        skillSet_notEmpty_plural: '{{count}} skill sets and ',
        skillSet_empty: '{{count}} skills',
        skill_notEmpty: '{{count}} skill',
        skill_notEmpty_plural: '{{count}} skills',
        skill_empty: '{{count}} skills',
        selectLabel: 'Select or create labels',
        createLabel: '{{newLabel}} (New label)',
        labelsDescription: 'Note: Maximum 3 labels can be added to a template.'
    },

    createSkill: {
        title: 'Create skill',
        success: 'Skill was successfully saved.'
    },

    copySkill: {
        title: 'Copy skill to...',
        success: 'Skill was successfully copied'
    },

    createSkillSet: {
        title: 'Create skill set',
        success: 'Skill set was successfully saved.'
    },

    convertSkill: {
        title: 'Convert skill to skill set',
        success: 'Skill was successfully converted to a skill set.',
        question:
            'Are you sure you want to convert <0>{{skillName}}</0> to a skill set? Users will not be able to give ratings on this item if you continue.'
    },

    copySkillSet: {
        title: 'Copy skill set to...',
        success: 'Skill set was successfully copied'
    },

    createTemplate: {
        title: 'Create template'
    },

    editSkill: {
        title: 'Edit skill'
    },

    editSkillSet: {
        title: 'Edit skill set',
        moveError:
            "This skill set includes at least one skill set, so it can't be moved"
    },

    editTemplate: {
        title: 'Edit template'
    },

    deleteSkill: {
        title: 'Delete skill',
        question:
            'Are you sure you want to remove <0>{{skillName}}</0>? This will affect every matrix where it has been already rated by the users. You will not be able to restore the data unless you create it again from scratch.'
    },

    deleteSkillSet: {
        title: 'Delete skill set',
        question:
            'Are you sure you want to remove <0>{{skillSetName}}</0> and all of its included skill sets and skills? This will affect every matrix where the skills of this skill set were already rated by the users. You will not be able to restore the data unless you create everything again from scratch.'
    },

    deleteTemplate: {
        title: 'Delete template',
        question:
            'Are you sure you want to remove <0>{{templateName}}</0> template and all of its content? This will affect every matrix that uses this template and every other template that is linked with this one. You will not be able to restore the data unless you create everything again from scratch.'
    },

    linkTemplate: {
        title: 'Link template',
        alreadyLinked: 'Currently being linked',
        alreadyLinkedTooltip:
            "You can't link templates to a template that is already linked to something."
    },

    unlinkTemplate: {
        title: 'Unlink template',
        question:
            'Are you sure you want to unlink <0>{{linkedTemplateName}}</0> template and all of its content from <1>{{baseTemplateName}}</1>? This will affect every matrix that uses this combination of templates.'
    },

    contentBrowser: {
        emptyState: {
            content: {
                title: 'Create or edit templates',
                subtitle:
                    'Describe areas of expertise with the help of templates. Matrices are based on these templates.',
                action: 'Add a new template'
            },

            template: {
                title: 'Create skill sets and skills',
                subtitle:
                    'This template is empty right now, start creating new skill sets and skills.'
            }
        },

        idErrorData: {
            title: 'Template not found',
            subtitle:
                "You've tried to open a template which is not available in our database."
        },

        linkedTemplate: {
            linkedTemplateName: ' and {{linkedTemplateName}}',
            emptySkillSetState:
                'This template is currently empty start creating the first skill set by pressing Create skill set button in the top right corner.',
            emptyLinkedTemplate:
                'This linked template is currently empty and can’t be modified here. Open it for editing to fill it with skill sets and skills.'
        },

        navLink: {
            editTemplate: '(Edit template)'
        }
    }
}

export default templates
