import CopyIcon from '@atlaskit/icon/glyph/copy'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import EmailIcon from '@atlaskit/icon/glyph/email'
import MobileIcon from '@atlaskit/icon/glyph/mobile'
import PersonIcon from '@atlaskit/icon/glyph/person-circle'
import { IconLabel } from 'components/atoms'
import Placeholder from 'components/placeholder/Placeholder'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlaceholder from 'react-placeholder'
import { GroupDetails } from '@app/group'

export interface GroupsContentProps {
    group: GroupDetails
    status: string
    error: Error
}

export const GroupsContent: React.FC<GroupsContentProps> = (
    props: GroupsContentProps
) => {
    const { group, status, error } = props
    const { t } = useTranslation(['common', 'groups'])

    if (status === 'idle') {
        return (
            <div className="worksheetContentBrowser__emptyState">
                <Placeholder
                    icon={<CopyIcon label="" size="xlarge" />}
                    title={t('groups:contentBrowser.emptyState.content.title')}
                    subtitle={t(
                        'groups:contentBrowser.emptyState.content.subtitle'
                    )}
                    actionText={t(
                        'groups:contentBrowser.emptyState.content.action'
                    )}
                />
            </div>
        )
    }
    if (status === 'pending') {
        return (
            <div role="progressbar">
                <ReactPlaceholder
                    className="contentBrowserListItem__reactPlaceholder"
                    showLoadingAnimation
                    type="text"
                    rows={18}
                    ready={false}
                >
                    <></>
                </ReactPlaceholder>
            </div>
        )
    }
    if (status === 'rejected' && error && !group) {
        return (
            <div className="worksheetContentBrowser__emptyState">
                <Placeholder
                    icon={<CrossIcon label="" size="xlarge" />}
                    title={t('groups:contentBrowser.error.notFound.title')}
                    subtitle={t(
                        'groups:contentBrowser.error.notFound.subtitle'
                    )}
                />
            </div>
        )
    }
    if (status === 'rejected' && error) {
        throw error
    } else if (status === 'resolved' && group) {
        return (
            <div className="worksheetContentBrowser__scrollArea">
                <div className="worksheetContentBrowser__innerContent modern-scrollbar">
                    <div className="groupsContent__section">
                        <IconLabel icon={<EmailIcon label="" />}>
                            {t('common:labels.emailAddress')}
                        </IconLabel>
                        <a
                            className="groupsContent__info groupsContent__info--link"
                            href={`mailto:${group.email}`}
                        >
                            {group.email}
                        </a>
                        {group.phoneNumber && (
                            <>
                                <IconLabel icon={<MobileIcon label="" />}>
                                    {t('common:labels.phoneNumber')}
                                </IconLabel>
                                <a
                                    className="groupsContent__info groupsContent__info--link"
                                    href={`tel:${group.phoneNumber}`}
                                >
                                    {group.phoneNumber}
                                </a>
                            </>
                        )}

                        <IconLabel icon={<PersonIcon label="" />}>
                            {t('common:labels.member', {
                                count: group.membersCount
                            })}
                        </IconLabel>
                        <span className="groupsContent__info">
                            {group.membersCount}
                        </span>
                    </div>
                </div>
            </div>
        )
    } else {
        return null
    }
}

export default GroupsContent
