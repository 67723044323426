import { ErrorData } from '@app/api'
import {
    PersonCreate,
    PersonDetails,
    PersonDetailsWithMatrices,
    PersonUpdate,
    SimplePerson
} from '@app/person'
import { AxiosInstance } from 'axios'
import { useClient } from 'hooks/useClient'
import useSWR, { mutate } from 'swr'
import { determineComponentStatus, tryMutation } from './apiHelpers'

const PEOPLE_BASE_URL = '/api/user'

/**
 * Generates dropdown list items (name, id pairs) from peopleList content.
 *
 * @param peopleList
 */
function getPeopleEmailAddressDropdownItems(peopleList: Array<SimplePerson>) {
    return (peopleList ?? []).map((person) => ({
        label: person.email,
        value: person.id
    }))
}

function useFetchAllPeople() {
    const { data, error } = useSWR<Array<PersonDetails>>(PEOPLE_BASE_URL)

    return {
        peopleList: data,
        error,
        status: determineComponentStatus(data, error)
    }
}

function useFetchAllActivePeople() {
    const { data, error } = useSWR(`${PEOPLE_BASE_URL}/active`)

    return {
        data,
        error,
        status: determineComponentStatus(data, error)
    }
}

function useFetchPersonById(personId?: string) {
    const { data, error } = useSWR<PersonDetailsWithMatrices, ErrorData>(
        personId ? `${PEOPLE_BASE_URL}/${personId}` : null
    )

    return {
        person: data,
        error,
        status: data ? determineComponentStatus(data, error) : 'idle'
    }
}

function useFetchAnonymousById(personId?: string) {
    const { data, error } = useSWR<PersonDetailsWithMatrices, ErrorData>(
        personId ? `${PEOPLE_BASE_URL}/${personId}/anonymous` : null
    )

    return {
        person: data,
        error,
        status: data ? determineComponentStatus(data, error) : 'idle'
    }
}

function useRequestPersonInvitation(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (personCreate: PersonCreate) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).post<PersonDetails>(
                `${PEOPLE_BASE_URL}/register`,
                personCreate
            )
            return response.data
        })
}

function useRequestPersonInvitationResending(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (personId: string) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).patch<
                PersonDetails
            >(`${PEOPLE_BASE_URL}/${personId}/resend`)
            return response.data
        })
}

function useRequestPersonInvitationCancelling(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (personId: string) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).patch<
                PersonDetails
            >(`${PEOPLE_BASE_URL}/${personId}/cancel`)
            return response.data
        })
}

function useRequestPersonUpdate(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (personUpdate: PersonUpdate) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).put(
                `${PEOPLE_BASE_URL}/${personUpdate.id}`,
                {
                    name: personUpdate.name,
                    email: personUpdate.email,
                    roles: personUpdate.roles,
                    rank: personUpdate.rank,
                    groups: personUpdate.groups
                }
            )
            return response.data
        })
}

function useRequestPersonDeletion(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (personId: string) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).delete(
                `${PEOPLE_BASE_URL}/${personId}`
            )
            return response.data
        })
}

async function requestAllPeopleRevalidation() {
    return mutate(PEOPLE_BASE_URL)
}

async function requestSinglePersonRevalidation(personId: string) {
    return mutate(`${PEOPLE_BASE_URL}/${personId}`)
}

export {
    getPeopleEmailAddressDropdownItems,
    useFetchAllPeople,
    useFetchAllActivePeople,
    useFetchPersonById,
    useRequestPersonInvitation,
    useRequestPersonInvitationResending,
    useRequestPersonInvitationCancelling,
    useRequestPersonUpdate,
    useRequestPersonDeletion,
    requestAllPeopleRevalidation,
    requestSinglePersonRevalidation,
    useFetchAnonymousById
}
