import { SelectInputValue } from '@app/common'
import { CopySkillDialogFormData } from '@app/form'
import { SkillDetails, TemplateDetails } from '@app/template'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import LabeledSelect from 'components/input/labeledSelect/LabeledSelect'
import {
    Form,
    FormikContext,
    FormikHelpers as FormikActions,
    FormikProps,
    useFormik
} from 'formik'
import FormikHelpers from 'helpers/formikHelpers'
import { useRequestSkillCreate } from 'helpers/skillHelpers'
import {
    formatSkillSelector,
    getSkillDropdownItems,
    getTemplateDropdownItems,
    requestSingleTemplateRevalidation,
    useFetchAllTemplates,
    useFetchTemplateById
} from 'helpers/templateHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React, { useEffect, useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import * as Yup from 'yup'

interface OwnProps {
    skill: SkillDetails
    template: TemplateDetails
    parentSkillSetId: string
}

type Props = OwnProps & WithTranslation

const CopySkillDialog = (props: Props) => {
    const [selectedTemplateId, setSelectedTemplateId] = useState<string>(
        props.template.id
    )

    const { t, skill, parentSkillSetId } = props

    const {
        formDirty,
        toggleFormToDirty,
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const { templateList } = useFetchAllTemplates()

    const { template: selectedTemplate, status } = useFetchTemplateById(
        selectedTemplateId
    )

    if (status === 'rejected') {
        createNotification({
            type: t('error:labels.error'),
            description: t('error:response.templateLoadUnknown')
        })
    }

    const getSkillListBasedOnSelectedTemplate = (template: TemplateDetails) => [
        ...getSkillDropdownItems(template).filter(
            (skillItem) => skillItem.value !== props.skill.id
        )
    ]

    const initialValues: CopySkillDialogFormData =
        skill && parentSkillSetId
            ? {
                  name: skill.name,
                  description: skill.description,
                  parentSkillSet: getSkillListBasedOnSelectedTemplate(
                      selectedTemplate
                  ).filter(
                      (skillBasedOnSelectedTemplate) =>
                          skillBasedOnSelectedTemplate.value ===
                          parentSkillSetId
                  )[0],
                  template: getTemplateDropdownItems(templateList).find(
                      (template) => template.value === selectedTemplate?.id
                  ),
                  expectations: skill.expectations,
                  resetRatings: false
              }
            : {
                  name: '',
                  description: '',
                  parentSkillSet: null,
                  expectations: {
                      level1: 1,
                      level2: 1,
                      level3: 1,
                      level4: 1,
                      level5: 1
                  },
                  template: null,
                  resetRatings: false
              }

    const validationSchema = Yup.object().shape({
        parentSkillSet: Yup.object()
            .nullable()
            .required(t('error:validation.noSkillSetSelected'))
    })

    const requestSkillCreate = useRequestSkillCreate()
    const onSubmit = async (
        values: CopySkillDialogFormData,
        formikActions: FormikActions<CopySkillDialogFormData>
    ) => {
        try {
            const { data: template } = await requestSkillCreate({
                name: values.name,
                description: values.description,
                parentSkillSetId: values.parentSkillSet.value,
                expectations: values.expectations
            })
            await requestSingleTemplateRevalidation(template.id)

            closeActiveOverlay()
        } catch (payload) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            createNotification({
                type: t('error:labels.error'),
                description: t('error:response.copySkillUnknown', {
                    skillName: props.skill.name
                })
            })
        }
    }

    const formik: FormikProps<CopySkillDialogFormData> = useFormik({
        initialValues,
        onSubmit,
        validationSchema,
        validateOnChange: true,
        enableReinitialize: true
    })

    useEffect(() => {
        FormikHelpers.setDirtyForm(
            formik,
            'template',
            formDirty,
            toggleFormToDirty
        )(
            getTemplateDropdownItems(templateList).find(
                (template) => template.value === selectedTemplate?.id
            )
        )

        formik.setFieldValue('parentSkillSet', null)

        // TODO: Check why adding the necessary dependencies puts this component
        // in an infinite loop
        // eslint-disable-next-line
    }, [selectedTemplate])

    const parentSkillSetError = FormikHelpers.getErrorMessage(
        formik,
        'parentSkillSet'
    )

    return (
        <FormikContext.Provider value={formik}>
            <Dialog
                title={t('templates:copySkill.title')}
                closeDialog={tryCloseDirtyOverlay}
                showCloseIcon
            >
                <Form className="dialog__form">
                    <div className="dialog__scrollArea">
                        <LabeledSelect
                            label={t('templates:labels.template')}
                            name="template"
                            options={getTemplateDropdownItems(templateList)}
                            value={formik.values.template}
                            isClearable={false}
                            placeholder={t('templates:labels.selectSkillSet', {
                                templateName: props.template.name
                            })}
                            onChange={(template: SelectInputValue) => {
                                setSelectedTemplateId(template.value)
                            }}
                            tabIndex={0}
                        />

                        <LabeledSelect
                            label={t('templates:labels.parent')}
                            name="parent"
                            options={getSkillListBasedOnSelectedTemplate(
                                selectedTemplate
                            )}
                            value={formik.values.parentSkillSet}
                            isClearable={false}
                            placeholder={t('templates:labels.selectSkillSet', {
                                templateName: selectedTemplate?.name ?? ''
                            })}
                            onChange={FormikHelpers.setDirtyForm(
                                formik,
                                'parentSkillSet',
                                formDirty,
                                toggleFormToDirty
                            )}
                            hasError={!!parentSkillSetError}
                            message={parentSkillSetError}
                            formatOptionLabel={formatSkillSelector}
                            tabIndex={0}
                        />
                    </div>

                    <div className="dialog__actions dialog__actions--noShadow">
                        <Button
                            title={t('common:labels.cancel')}
                            onClick={closeActiveOverlay}
                            formButton
                        />

                        <Button
                            title={t('common:labels.copy')}
                            type="submit"
                            loading={formik.isSubmitting}
                            primary
                            formButton
                            autoFocus
                        />
                    </div>
                </Form>
            </Dialog>
        </FormikContext.Provider>
    )
}

export default compose<Props, OwnProps>(
    withTranslation(['common', 'templates', 'error'])
)(CopySkillDialog)
