import { RemoveMatrixData } from '@app/matrix'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import { useRequestMatrixDelete } from 'helpers/matrixHelpers'
import { requestAllUserTemplatesRevalidation } from 'helpers/templateHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { Trans, WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'recompose'

interface OwnProps {
    matrixData: RemoveMatrixData
    redirectOnMatch: (redirectUrl: string) => void
}

type Props = OwnProps & WithTranslation

const RemoveMatrixDialog = (props: Props) => {
    const initialValues = {}

    const { t } = props

    const {
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const requestMatrixDelete = useRequestMatrixDelete()
    const onSubmit = async (
        values: unknown,
        formikActions: FormikActions<unknown>
    ) => {
        const { matrixData } = props

        try {
            await requestMatrixDelete(matrixData.userId, matrixData.templateId)

            await requestAllUserTemplatesRevalidation(matrixData.userId)
            props.redirectOnMatch(matrixData.templateId)
            closeActiveOverlay()
        } catch (payload) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            createNotification({
                type: props.t('error:labels.error'),
                description: props.t('error:response.removeMatrixUnknown', {
                    matrixName: matrixData.templateName
                })
            })
        }
    }

    return (
        <Dialog
            title={t('matrices:removeMatrix.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon={false}
        >
            <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {(formikProps) => {
                    return (
                        <Form className="dialog__form dialog__form--confirm">
                            <div className="dialog__info">
                                <Trans i18nKey="matrices:removeMatrix.question">
                                    <strong>
                                        {{
                                            matrixName:
                                                props.matrixData.templateName
                                        }}
                                    </strong>
                                </Trans>
                            </div>

                            <div className="dialog__actions dialog__actions--noShadow">
                                <Button
                                    title={t('common:labels.cancel')}
                                    onClick={closeActiveOverlay}
                                    formButton
                                />

                                <Button
                                    title={t('common:labels.remove')}
                                    type="submit"
                                    loading={formikProps.isSubmitting}
                                    destructive
                                    primary
                                    formButton
                                    autoFocus
                                />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export default compose<Props, OwnProps>(
    withTranslation(['common', 'matrices', 'error'])
)(RemoveMatrixDialog)
