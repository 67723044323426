import { ExpectationValue, SelectInputValue } from '@app/common'
import { EditSkillDialogFormData } from '@app/form'
import { SkillDetails, TemplateDetails } from '@app/template'
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import LabeledInputField from 'components/input/labeledInputField/LabeledInputField'
import LabeledSelect from 'components/input/labeledSelect/LabeledSelect'
import RichTextEditor from 'components/input/richTextEditor/RichTextEditor'
import ToggleSwitch from 'components/input/toggleSwitch/ToggleSwitch'
import {
    Form,
    Formik,
    FormikHelpers as FormikActions,
    FormikProps
} from 'formik'
import FormikHelpers from 'helpers/formikHelpers'
import { useRequestSkillUpdate } from 'helpers/skillHelpers'
import {
    formatSkillSelector,
    getSkillDropdownItems,
    getTemplateDropdownItems,
    requestSingleTemplateRevalidation,
    useFetchAllTemplates
} from 'helpers/templateHelpers'
import withTooltip from 'hoc/withTooltip'
import { useGlobal } from 'hooks/useGlobal'
import ExpectationsInput from 'pages/templates/ExpectationsInput'
import React, { useState } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import * as Yup from 'yup'

interface OwnProps {
    skill: SkillDetails
    parentSkillSetId: string
    template: TemplateDetails
}

type Props = OwnProps & WithTranslation

const EditSkillDialog: React.FC<Props> = (props: Props) => {
    const [selectedTemplate, setSelectedTemplate] = useState<TemplateDetails>(
        null
    )

    const { templateList } = useFetchAllTemplates()

    React.useEffect(() => {
        if (templateList) {
            setSelectedTemplate(
                templateList.find(
                    (template) => template.id === props.template.id
                )
            )
        }
    }, [props.template.id, templateList])

    const { t, skill, parentSkillSetId } = props

    const {
        formDirty,
        toggleFormToDirty,
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const IconWithTooltip = withTooltip('tooltipIcon')(() => (
        <EditorPanelIcon label="" primaryColor="#1471eb" />
    ))

    const handleExpectationChange = (
        formikProps: FormikProps<EditSkillDialogFormData>
    ) => (key: string, value: ExpectationValue) => {
        FormikHelpers.setDirtyForm(
            formikProps,
            'expectations',
            formDirty,
            toggleFormToDirty
        )({
            ...formikProps.values.expectations,
            [key]: value
        })
    }

    const getSkillListBasedOnSelectedTemplate = (template: TemplateDetails) => [
        {
            label: t('templates:labels.parentPlaceholderEdit', {
                templateName: template?.name ?? ''
            }),
            value: template?.id ?? '',
            parentLabel: ''
        },
        ...getSkillDropdownItems(template).filter(
            (skillItem) => skillItem.value !== props.skill.id
        )
    ]

    const initialValues: EditSkillDialogFormData =
        skill && parentSkillSetId
            ? {
                  name: skill.name,
                  description: skill.description,
                  parentSkillSet: getSkillListBasedOnSelectedTemplate(
                      selectedTemplate
                  ).find(
                      (skillBasedOnSelectedTemplate) =>
                          skillBasedOnSelectedTemplate.value ===
                          parentSkillSetId
                  ),
                  template: getTemplateDropdownItems(templateList).find(
                      (template) => template.value === selectedTemplate?.id
                  ),
                  expectations: skill.expectations,
                  resetRatings: false
              }
            : {
                  name: '',
                  description: '',
                  parentSkillSet: null,
                  expectations: {
                      level1: 1,
                      level2: 1,
                      level3: 1,
                      level4: 1,
                      level5: 1
                  },
                  template: null,
                  resetRatings: false
              }

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('error:validation.fieldRequired')),
        parentSkillSet: Yup.object()
            .nullable()
            .required(t('error:validation.noSkillSetSelected'))
    })

    const requestSkillUpdate = useRequestSkillUpdate()
    const onSubmit = async (
        values: EditSkillDialogFormData,
        formikActions: FormikActions<EditSkillDialogFormData>
    ) => {
        try {
            const { data: template } = await requestSkillUpdate({
                id: props.skill.id,
                name: values.name,
                description: values.description,
                parentSkillSetId: values.parentSkillSet.value,
                templateId: values.template.value,
                expectations: values.expectations,
                resetRatings: values.resetRatings
            })
            await requestSingleTemplateRevalidation(template.id)

            closeActiveOverlay()
        } catch (payload) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            createNotification({
                type: t('error:labels.error'),
                description: t('error:response.editSkillUnknown', {
                    skillName: props.skill.name
                })
            })
        }
    }

    return (
        <Dialog
            title={t('templates:editSkill.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                enableReinitialize
                validateOnChange
            >
                {(formikProps) => {
                    const nameError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'name'
                    )

                    const parentSkillSetError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'parentSkillSet'
                    )
                    return (
                        <Form className="dialog__form">
                            <div className="dialog__scrollArea modern-scrollbar">
                                <LabeledInputField
                                    label={t('templates:labels.name')}
                                    name="name"
                                    onChange={FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'name',
                                        formDirty,
                                        toggleFormToDirty
                                    )}
                                    value={formikProps.values.name}
                                    message={nameError}
                                    hasError={!!nameError}
                                    tabIndex={0}
                                    autoFocus
                                />

                                <LabeledSelect
                                    label={t('templates:labels.template')}
                                    name="template"
                                    options={getTemplateDropdownItems(
                                        templateList
                                    )}
                                    value={formikProps.values.template}
                                    isClearable={false}
                                    placeholder={t(
                                        'templates:labels.selectSkillSet',
                                        {
                                            templateName: props.template.name
                                        }
                                    )}
                                    onChange={(
                                        selectedTemplateItem: SelectInputValue
                                    ) => {
                                        FormikHelpers.setDirtyForm(
                                            formikProps,
                                            'template',
                                            formDirty,
                                            toggleFormToDirty
                                        )(selectedTemplateItem)

                                        formikProps.setFieldValue(
                                            'parentSkillSet',
                                            getSkillListBasedOnSelectedTemplate(
                                                templateList.find(
                                                    (template) =>
                                                        template.id ===
                                                        selectedTemplateItem.value
                                                )
                                            )[0]
                                        )
                                    }}
                                    tabIndex={0}
                                />

                                <LabeledSelect
                                    label={t('templates:labels.parent')}
                                    name="parent"
                                    options={getSkillListBasedOnSelectedTemplate(
                                        selectedTemplate
                                    )}
                                    value={formikProps.values.parentSkillSet}
                                    isClearable={false}
                                    placeholder={t(
                                        'templates:labels.selectSkillSet',
                                        {
                                            templateName:
                                                selectedTemplate?.name ?? ''
                                        }
                                    )}
                                    onChange={FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'parentSkillSet',
                                        formDirty,
                                        toggleFormToDirty
                                    )}
                                    hasError={!!parentSkillSetError}
                                    message={parentSkillSetError}
                                    formatOptionLabel={formatSkillSelector}
                                    tabIndex={0}
                                />

                                <RichTextEditor
                                    label={t(
                                        'templates:labels.descriptionOptional'
                                    )}
                                    name="description"
                                    onChange={FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'description',
                                        formDirty,
                                        toggleFormToDirty
                                    )}
                                    value={formikProps.values.description}
                                    placeholder={t(
                                        'templates:labels.descriptionSkillPlaceholder'
                                    )}
                                    bounds=".richTextEditor"
                                />

                                <ExpectationsInput
                                    expectations={
                                        formikProps.values.expectations
                                    }
                                    handleChange={handleExpectationChange(
                                        formikProps
                                    )}
                                />
                            </div>

                            <div className="dialog__actions">
                                <div className="dialog__actionContainer">
                                    <div className="dialog__toggleSwitch">
                                        <ToggleSwitch
                                            selected={
                                                formikProps.values.resetRatings
                                            }
                                            label={t(
                                                'templates:labels.resetSkillRatings'
                                            )}
                                            onChange={FormikHelpers.setDirtyForm(
                                                formikProps,
                                                'resetRatings',
                                                formDirty,
                                                toggleFormToDirty
                                            )}
                                        />

                                        <div className="tooltip">
                                            <IconWithTooltip
                                                title={t(
                                                    'templates:labels.resetSkillRatingsDescription'
                                                )}
                                                enterDelay={350}
                                                leaveDelay={150}
                                                placement="top"
                                            />
                                        </div>
                                    </div>

                                    <div className="dialog__buttonContainer">
                                        <Button
                                            title={t('common:labels.cancel')}
                                            onClick={tryCloseDirtyOverlay}
                                            formButton
                                        />

                                        <Button
                                            title={t('common:labels.save')}
                                            type="submit"
                                            loading={formikProps.isSubmitting}
                                            primary
                                            formButton
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export default compose<Props, OwnProps>(
    withTranslation(['common', 'templates', 'error'])
)(EditSkillDialog)
