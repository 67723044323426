import {
    CreateSearchTermDialogFormData,
    EditSearchTermDialogFormData
} from '@app/form'
import { TypeSelectInputValue } from '@app/searchTerm'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import FormikHelpers from 'helpers/formikHelpers'
import { isAdmin, isMatchingAnyRole } from 'helpers/roleHelpers'
import { getTypeOptions } from 'helpers/searchTermHelpers'
import { useAuthentication } from 'hooks/useAuthentication'
import { useGlobal } from 'hooks/useGlobal'
import { Button } from 'components/button/base/Button'
import LabeledInputField from 'components/input/labeledInputField/LabeledInputField'
import LabeledSelect from 'components/input/labeledSelect/LabeledSelect'

export type SearchTermFormData =
    | CreateSearchTermDialogFormData
    | EditSearchTermDialogFormData

export interface SearchTermFormProps {
    initialValues: SearchTermFormData
    onSubmit: (
        values: SearchTermFormData,
        formikActions: FormikActions<SearchTermFormData>
    ) => Promise<void>
}

export const SearchTermForm: React.FC<SearchTermFormProps> = ({
    initialValues,
    onSubmit
}) => {
    const { t } = useTranslation(['common', 'error', 'searchTerms'])
    const typeOptions: Array<TypeSelectInputValue> = getTypeOptions(t)
    const { formDirty, toggleFormToDirty, closeActiveOverlay } = useGlobal()
    const { getCurrentUserRoles } = useAuthentication()
    const currentUserRoles = getCurrentUserRoles()

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .required(t('error:validation.fieldRequired'))
            .max(30, t('error:validation.maxThirtyCharacters')),
        type: Yup.object()
            .shape({
                label: Yup.string(),
                value: Yup.string()
            })
            .nullable()
            .required(t('error:validation.fieldRequired'))
    })

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            validateOnChange
            enableReinitialize
        >
            {(formikProps) => {
                const nameError = FormikHelpers.getErrorMessage(
                    formikProps,
                    'name'
                )

                const typeError = FormikHelpers.getErrorMessage(
                    formikProps,
                    'type'
                )

                return (
                    <Form className="dialog__form">
                        <div className="dialog__scrollArea">
                            <LabeledInputField
                                label={t('searchTerms:labels.name')}
                                name="name"
                                onChange={FormikHelpers.setDirtyForm(
                                    formikProps,
                                    'name',
                                    formDirty,
                                    toggleFormToDirty
                                )}
                                value={formikProps.values.name}
                                message={
                                    nameError.length > 0
                                        ? nameError
                                        : t(
                                              'searchTerms:labels.nameDescription'
                                          )
                                }
                                hasError={!!nameError}
                                tabIndex={0}
                                autoFocus
                            />
                            {isMatchingAnyRole(isAdmin)(currentUserRoles) && (
                                <LabeledSelect
                                    label={t('searchTerms:labels.type')}
                                    name="type"
                                    message={typeError}
                                    messageVariant="INFO"
                                    options={typeOptions}
                                    value={formikProps.values.type}
                                    isClearable={false}
                                    hasError={!!typeError}
                                    placeholder={t('searchTerms:labels.type')}
                                    onChange={(
                                        values: Array<TypeSelectInputValue>
                                    ) =>
                                        FormikHelpers.setDirtyForm(
                                            formikProps,
                                            'type',
                                            formDirty,
                                            toggleFormToDirty
                                        )(values)
                                    }
                                    tabIndex={0}
                                />
                            )}
                        </div>

                        <div className="dialog__actions">
                            <Button
                                title={t('common:labels.cancel')}
                                onClick={closeActiveOverlay}
                                formButton
                            />

                            <Button
                                title={t('common:labels.save')}
                                type="submit"
                                loading={formikProps.isSubmitting}
                                primary
                                formButton
                            />
                        </div>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default SearchTermForm
