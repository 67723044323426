import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import englishLocalization from 'translations/en'

const dev = process.env.NODE_ENV === 'development'

i18n
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        preload: ['en'],
        lng: 'en',
        debug: dev,
        initImmediate: true,

        ns: ['login'],

        resources: {
            en: englishLocalization
        },

        interpolation: {
            escapeValue: false
        },

        // special options for react-i18next
        // learn more: https://react.i18next.com/components/i18next-instance
        react: {
            wait: true,
            useSuspense: false
        }
    })

export default i18n
