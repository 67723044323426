import { ErrorData } from '@app/api'
import {
    Positionable,
    SelectInputValue,
    SkillSelectInputValue
} from '@app/common'
import {
    SimpleLabel,
    SkillSetDetails,
    TemplateCreate,
    TemplateData,
    TemplateDetails,
    TemplateUpdate,
    UpdateTemplateDataOrder
} from '@app/template'
import { AxiosInstance } from 'axios'
import { useClient } from 'hooks/useClient'
import i18next from 'i18next'
import React, { useMemo } from 'react'
import { FormatOptionLabelMeta } from 'react-select/src/Select'
import useSWR, { mutate } from 'swr'
import { determineComponentStatus, tryMutation } from './apiHelpers'

// TODO: Add unit tests for these helper functions because they are
// hard to understand as is

/**
 * Generates dropdown list items (name, id pairs) from template's content.
 *
 * @param template - Template details
 */
export const getSkillSetDropdownItems = (template: TemplateDetails) => {
    if (!template || !template.childSkillSets) {
        return []
    }

    return template.childSkillSets.reduce(
        (dropdownItems: Array<SelectInputValue>, skillSet: SkillSetDetails) =>
            [
                ...dropdownItems,
                { label: skillSet.name, value: skillSet.id }
            ].concat(
                skillSet.childSkillSets
                    .filter((child) => !child.childSkillSets)
                    .map((childSkillSet) => {
                        return {
                            label: `- ${childSkillSet.name}`,
                            value: childSkillSet.id,
                            parentLabel: skillSet.name
                        }
                    })
            ),
        []
    )
}

export const getSkillDropdownItems = (template: TemplateDetails) => {
    if (!template || !template.childSkillSets) {
        return []
    }

    return template.childSkillSets.reduce(
        (
            dropdownItems: Array<SkillSelectInputValue>,
            skillSet: SkillSetDetails
        ) =>
            [
                ...dropdownItems,
                { label: skillSet.name, value: skillSet.id, parentLabel: '' }
            ].concat(
                skillSet.childSkillSets
                    .filter((child) => !!child.childSkillSets)
                    .map((childSkillSet) => {
                        return {
                            label: `- ${childSkillSet.name}`,
                            value: childSkillSet.id,
                            parentLabel: skillSet.name
                        }
                    })
            ),
        []
    )
}

/**
 * Generates dropdown list items (name, id pairs) from templateList content.
 *
 * @param templateList
 */
export const getTemplateDropdownItems = (
    templateList: Array<TemplateDetails>
) =>
    templateList
        ? templateList.map((template) => ({
              label: template.name,
              value: template.id
          }))
        : []

/**
 * Generates an alphabetic ordered templateList list from the given templateList
 * @param templateList
 */
export const orderTemplateListByName = (templateList: Array<TemplateData>) =>
    templateList
        ? templateList.sort((a, b) => a.name.localeCompare(b.name))
        : []

/**
 * Generates an alphabetic ordered templateList list from the given templateList
 * @param templateList
 */
export const orderArrayListByPosition = <T extends Positionable>(
    itemList: Array<T>
) => (itemList ? itemList.sort((a, b) => a.position - b.position) : [])

/**
 * Formatting the selected value text based on if it has parentLabel
 */
export const formatSkillSelector = (
    option: SkillSelectInputValue,
    labelMeta: FormatOptionLabelMeta<SkillSelectInputValue>
) => {
    if (labelMeta.context === 'value') {
        let skillOption = { ...option }

        if (skillOption.label.charAt(0) === '-') {
            skillOption = { ...skillOption, label: skillOption.label.substr(1) }
        }

        return (
            <span>
                {skillOption.parentLabel
                    ? i18next.t('templates:labels.selectSkillLabel', {
                          skillName: skillOption.label,
                          parentName: skillOption.parentLabel
                      })
                    : skillOption.label}
            </span>
        )
    }

    if (labelMeta.context === 'menu') {
        return <span>{option.label}</span>
    }

    return null
}

/**
 * Generates two separated array with skillSetOrderList, and skillOrderList from the given array
 * @param skillList
 */

export const getTemplateItemsToReorder = (skillList: Array<any>) =>
    skillList
        ? {
              skillOrderDtoList: skillList
                  .map(
                      (item, index) =>
                          item.props.skill && {
                              id: item.props.skill.id,
                              position: index + 1
                          }
                  )
                  .filter((items) => items),
              skillSetOrderDtoList: skillList
                  .map(
                      (item, index) =>
                          item.props.skillSet && {
                              id: item.props.skillSet.id,
                              position: index + 1
                          }
                  )
                  .filter((items) => items)
          }
        : {
              skillOrderDtoList: [],
              skillSetOrderDtoList: []
          }

const TEMPLATE_BASE_URL = '/api/template'
const LABEL_BASE_URL = '/api/label'

function useFetchAllTemplates() {
    const { data, error } = useSWR<Array<TemplateDetails>, Error>(
        TEMPLATE_BASE_URL
    )

    return {
        templateList: data,
        error,
        status: determineComponentStatus(data, error)
    }
}

function useFetchAllUserTemplates(userId: string) {
    const { data, error } = useSWR<Array<TemplateData>, Error>(
        userId ? `${TEMPLATE_BASE_URL}/user/${userId}` : null
    )

    return {
        userTemplateList: data,
        error,
        status: determineComponentStatus(data, error)
    }
}

function useFetchTemplateById(templateId?: string) {
    const { data, error } = useSWR<TemplateDetails, ErrorData>(
        templateId ? `${TEMPLATE_BASE_URL}/${templateId}` : null
    )

    return {
        template: useMemo(() => data, [data]),
        error,
        status: determineComponentStatus(data, error)
    }
}

function useFetchUnassignedUserTemplates(userId: string) {
    const { data, error } = useSWR<Array<TemplateData>, Error>(
        `${TEMPLATE_BASE_URL}/unassigned/user/${userId}`
    )

    return {
        unassignedUserTemplateList: data,
        error,
        status: determineComponentStatus(data, error)
    }
}

function useFetchAllLabels() {
    const { data, error } = useSWR<Array<SimpleLabel>, Error>(LABEL_BASE_URL)

    return {
        labelList: data,
        error,
        status: determineComponentStatus(data, error)
    }
}

function useRequestTemplateCreate(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (templateCreate: TemplateCreate) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).post<
                TemplateDetails
            >(TEMPLATE_BASE_URL, templateCreate)
            return response.data
        })
}

function useRequestTemplateUpdate(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (templateUpdate: TemplateUpdate) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).put<
                TemplateDetails
            >(`${TEMPLATE_BASE_URL}/${templateUpdate.id}`, {
                name: templateUpdate.name,
                labels: templateUpdate.labels
            })
            return response.data
        })
}

function useRequestTemplateDelete(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (templateId: string) =>
        tryMutation(async () => {
            await (customClient ?? client).delete(
                `${TEMPLATE_BASE_URL}/${templateId}`
            )
            return {}
        })
}

function useRequestTemplateLink(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (templateId: string, linkedTemplateId: string) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).put<
                TemplateDetails
            >(`${TEMPLATE_BASE_URL}/${templateId}/link-template`, {
                templateId: linkedTemplateId
            })
            return response.data
        })
}

function useRequestTemplateUnlink(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (templateId: string) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).put<
                TemplateDetails
            >(`${TEMPLATE_BASE_URL}/${templateId}/unlink-template`)
            return response.data
        })
}

function useRequestTemplatesAssign(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (userId: string, templateIds: Array<string>) =>
        tryMutation(async () => {
            await (customClient ?? client).patch(
                `/api/user/${userId}/templates`,
                {
                    templates: templateIds
                }
            )
            return {}
        })
}

function useRequestTemplateItemsReorder(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (
        templateId: string,
        updateTemplateItemsOrder: UpdateTemplateDataOrder
    ) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).post(
                `${TEMPLATE_BASE_URL}/order/${templateId}`,
                updateTemplateItemsOrder
            )
            return response.data
        })
}

async function requestAllTemplatesRevalidation() {
    return mutate(TEMPLATE_BASE_URL)
}

async function requestAllUserTemplatesRevalidation(userId: string) {
    return mutate(`${TEMPLATE_BASE_URL}/user/${userId}`)
}

async function requestSingleTemplateRevalidation(templateId: string) {
    return mutate(`${TEMPLATE_BASE_URL}/${templateId}`)
}

async function requestAllLabelsRevalidation() {
    return mutate(LABEL_BASE_URL)
}

export {
    useFetchAllTemplates,
    useFetchAllUserTemplates,
    useFetchTemplateById,
    useFetchUnassignedUserTemplates,
    useFetchAllLabels,
    useRequestTemplateCreate,
    useRequestTemplateUpdate,
    useRequestTemplateDelete,
    useRequestTemplateLink,
    useRequestTemplateUnlink,
    useRequestTemplatesAssign,
    useRequestTemplateItemsReorder,
    requestAllTemplatesRevalidation,
    requestAllUserTemplatesRevalidation,
    requestSingleTemplateRevalidation,
    requestAllLabelsRevalidation
}
