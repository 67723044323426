import { ListItemRender, SelectorListItem } from '@app/common'
import HipchatChevronDownIcon from '@atlaskit/icon/glyph/hipchat/chevron-down'
import classNames from 'classnames'
import ExitButton from 'components/button/exit/ExitButton'
import withMaybe from 'hoc/withMaybe'
import React, { useState } from 'react'

interface DropdownSelectorProps {
    listItems: Array<ListItemRender | SelectorListItem>
    selectAction: (id: string) => void
    activeListItemId?: string
}

interface DropdownMenuProps extends DropdownSelectorProps {
    closeAction: () => void
}

interface State {
    dropdownActive: boolean
}

const ListItems = (props: DropdownSelectorProps) => (
    <>
        {props.listItems.map((item) => (
            <div
                key={item.id}
                className={classNames('dropdownSelector__listItem', {
                    active: item.id === props.activeListItemId
                })}
                onClick={() => props.selectAction(item.id)}
            >
                <span className="dropdownSelector__listItemText text-ellipsis">
                    {item.title}
                </span>
            </div>
        ))}
    </>
)

const renderChevron = () => <HipchatChevronDownIcon label="" />

const renderMenu = (props: DropdownMenuProps) => (
    <div className="dropdownSelector__menu animation-slide-down-in animation-slow">
        <div className="dropdownSelector__header">
            <div className="dropdownSelector__title">Select matrix</div>

            <ExitButton size="small" onClick={props.closeAction} />
        </div>

        <ListItems
            listItems={props.listItems}
            activeListItemId={props.activeListItemId}
            selectAction={props.selectAction}
        />
    </div>
)

const DropdownSelector = (props: DropdownSelectorProps) => {
    const [state, setState] = useState<State>({
        dropdownActive: false
    })

    const openMenu = () => {
        if (props.listItems.length) {
            setState({ dropdownActive: true })
        }
    }

    const closeMenu = () => {
        setState({ dropdownActive: false })
    }

    const selectAction = (id: string) => {
        closeMenu()
        props.selectAction(id)
    }

    const Chevron = withMaybe(props.listItems.length > 0)(renderChevron)

    const DropdownSelectorMenu = withMaybe<DropdownMenuProps>(
        state.dropdownActive
    )(renderMenu)

    const activeListItem = props.listItems.find(
        (item) => item.id === props.activeListItemId
    )

    return (
        <>
            <div
                className={classNames('dropdownSelector__button', {
                    empty: !props.listItems.length
                })}
                onClick={openMenu}
            >
                <span className="dropdownSelector__buttonTitle text-ellipsis">
                    {activeListItem
                        ? activeListItem.title
                        : 'No matrices found'}
                </span>

                <Chevron />
            </div>

            <DropdownSelectorMenu
                closeAction={closeMenu}
                listItems={props.listItems}
                activeListItemId={activeListItem && activeListItem.id}
                selectAction={selectAction}
            />
        </>
    )
}

export default DropdownSelector
