import { MatrixSkillSet } from '@app/matrix'
import ContentBrowserListItem from 'components/contentBrowserListItem/ContentBrowserListItem'
import { MatrixSkillSetSubtitle } from 'components/subtitle/matrixSkillSetSubtitle/MatrixSkillSetSubtitle'
import { orderArrayListByPosition } from 'helpers/templateHelpers'
import React from 'react'
import { merge } from 'utils/commonUtils'
import PeopleSkillListItem from './PeopleSkillListItem'

interface PeopleSkillSetListItemProps {
    skillSet: MatrixSkillSet
}

const PeopleSkillSetListItem = ({ skillSet }: PeopleSkillSetListItemProps) => {
    // TODO: check why skillSet.ownerUserIds gets undefined value
    return (
        <ContentBrowserListItem
            data={skillSet}
            owners={skillSet.owners}
            additionalContent={null}
            showAdditionalContentOnlyOnHover={false}
            small={false}
            skillSetSubtitle={<MatrixSkillSetSubtitle skillSet={skillSet} />}
            showTriggerButtonLabel
        >
            {skillSet
                ? orderArrayListByPosition(
                      merge(skillSet.childSkillSets, skillSet.skills)
                  ).map((listItem) =>
                      listItem.skillSetId ? (
                          <PeopleSkillSetListItem
                              key={listItem.skillSetId}
                              skillSet={listItem}
                          />
                      ) : listItem.skillId ? (
                          <PeopleSkillListItem
                              key={listItem.skillId}
                              ownerUserIds={skillSet.ownerUserIds}
                              skill={listItem}
                          />
                      ) : null
                  )
                : null}
        </ContentBrowserListItem>
    )
}

export default PeopleSkillSetListItem
