import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import { Popover, withStyles } from '@material-ui/core'

export const useMatrixPopoverStyles = makeStyles((theme: Theme) =>
    createStyles({
        matrixPopoverButton: {
            borderColor: theme.palette.text.primary,
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 4,
            color: theme.palette.text.primary,
            minWidth: 'auto',
            padding: theme.spacing(1, 1.5)
        },
        listItem: {
            height: 40,
            padding: theme.spacing(1, 1.875),
            transition: 'background-color .35s ease',
            color: theme.palette.text.primary,
            cursor: 'pointer',
            '&.active': {
                color: theme.palette.primary.main,
                fontWeight: 600
            },
            '&:hover': {
                backgroundColor: theme.palette.action.hover
            }
        },
        listItemText: {
            display: 'block'
        }
    })
)

export const StyledMatrixPopover = withStyles((theme) => ({
    paper: {
        minWidth: 340,
        borderRadius: 15,
        padding: theme.spacing(2, 0)
    }
}))(Popover)
