import { MatrixSkill, MatrixSkillSet } from '@app/matrix'
import { SkillDetails, SkillSetDetails } from '@app/template'
import ChevronRightLargeIcon from '@atlaskit/icon/glyph/chevron-right-large'
import EditorPanelIcon from '@atlaskit/icon/glyph/editor/panel'
import MaterialPopover, { PopoverOrigin } from '@material-ui/core/Popover'
import classNames, { Value } from 'classnames'
import { AppContext } from 'contexts/AppContext'
import { useGlobal } from 'hooks/useGlobal'
import React, { useContext } from 'react'
import stringToHtml from 'react-html-parser'
import { useTranslation, WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import { SimplePerson } from '@app/person'

interface OwnProps {
    className?: Value
    anchorOrigin?: PopoverOrigin
    transformOrigin?: PopoverOrigin
    children?: React.ReactNode
    data: MatrixSkill | MatrixSkillSet | SkillDetails | SkillSetDetails
    owners: Array<SimplePerson>
    showTriggerButtonLabel: boolean
}

type Props = OwnProps & WithTranslation

const DescriptionPopover = (props: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
        undefined
    )

    const { t } = useTranslation(['common'])

    const { page } = useContext(AppContext)

    const { openDialog } = useGlobal()

    const onClick = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.stopPropagation()

        setAnchorEl(event.currentTarget)
    }

    const onClose = (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.stopPropagation()

        setAnchorEl(undefined)
    }

    const openPopover = async (
        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        event.persist()
        openDialog({
            type: 'DESCRIPTION',
            payload: {
                data: props.data,
                currentPage: page,
                owners: props.owners
            }
        })

        onClose(event)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'descriptionPopover' : undefined

    // Remove HTML from description string
    const normalizedDescription = props.data.description.replace(
        /<\/?("[^"]*"|'[^']*'|[^>])*(>|$)/g,
        ''
    )

    // Convert HTML in description string to actual HTML elements
    const formattedDescription = stringToHtml(
        props.data.description || props.t('popover.description.emptyState')
    )

    const numberOfRows = formattedDescription
        .map((element) => (element.props ? element.props.children : undefined))
        .filter((children) => children)
        .reduce((childCount, children) => childCount + children.length, 0)

    const shouldDisplaySeeMore =
        numberOfRows > 5 || normalizedDescription.length > 300

    return (
        <div
            className={classNames('descriptionPopover', props.className, {
                open
            })}
        >
            <button
                className="contentBrowserListItem__trigger"
                type="button"
                onClick={onClick}
            >
                <EditorPanelIcon label="" />
                {props.showTriggerButtonLabel && (
                    <div>{t('common:labels.info')}</div>
                )}
            </button>

            <MaterialPopover
                className="descriptionPopover__body"
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={onClose}
                anchorOrigin={
                    props.anchorOrigin || {
                        vertical: 'bottom',
                        horizontal: 'left'
                    }
                }
                transformOrigin={
                    props.transformOrigin || {
                        vertical: 'top',
                        horizontal: 'left'
                    }
                }
                onClick={(event) => event.stopPropagation()}
            >
                {props.data.name && (
                    <span className="descriptionPopover__title">
                        {props.t('popover.description.title')}
                    </span>
                )}

                <div
                    className={classNames('descriptionPopover__description', {
                        'descriptionPopover__description--emptyState': !props
                            .data.description
                    })}
                >
                    {formattedDescription}

                    {shouldDisplaySeeMore && (
                        <div className="descriptionPopover__buttonContainer">
                            <button
                                className="descriptionPopover__seeMore"
                                type="button"
                                onClick={openPopover}
                            >
                                {props.t('popover.description.seeMore')}

                                <ChevronRightLargeIcon label="" size="small" />
                            </button>
                        </div>
                    )}
                </div>

                {props.children}
            </MaterialPopover>
        </div>
    )
}

export default compose<Props, OwnProps>(withTranslation('common'))(
    DescriptionPopover
)
