import { SelectableListItem } from '@app/common'
import { AssignTemplateDialogFormData } from '@app/form'
import classNames from 'classnames'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import ListFilterBar from 'components/listFilter/ListFilterBar'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import FormikHelpers from 'helpers/formikHelpers'
import {
    orderTemplateListByName,
    requestAllUserTemplatesRevalidation,
    useFetchUnassignedUserTemplates,
    useRequestTemplatesAssign
} from 'helpers/templateHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import ReactPlaceholder from 'react-placeholder'
import * as Yup from 'yup'
import UnassignedTemplateList from './UnassignedTemplateList'

interface AssignTemplateDialogProps {
    userId: string
}

const AssignTemplateDialog = (props: AssignTemplateDialogProps) => {
    const {
        unassignedUserTemplateList,
        status
    } = useFetchUnassignedUserTemplates(props.userId)

    const { t } = useTranslation(['common', 'matrices'])

    const [
        numberOfSelectedTemplates,
        setNumberOfSelectedTemplates
    ] = React.useState(0)

    const {
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const initialValues: AssignTemplateDialogFormData = React.useMemo(
        () => ({
            searchKey: '',
            templates: orderTemplateListByName(unassignedUserTemplateList).map(
                (template) => {
                    const linkedTemplate = unassignedUserTemplateList.find(
                        (element) => element.id === template.linkedTemplateId
                    )

                    return {
                        id: template.id,
                        title: template.name,
                        secondaryTitle: linkedTemplate
                            ? t(
                                  'matrices:assignTemplate.labels.linkedTemplate',
                                  {
                                      linkedTemplateName: linkedTemplate.name
                                  }
                              )
                            : null,
                        selected: false,
                        labels: template.labels.map((label) => label.name)
                    }
                }
            )
        }),
        [t, unassignedUserTemplateList]
    )

    const validationSchema = Yup.object().shape({
        templates: Yup.array().test(
            'Check template is selected',
            t('error:validation.noTemplatesSelected'),
            (templates: Array<SelectableListItem>) =>
                templates.filter((template) => template.selected).length > 0
        )
    })

    function handleTemplateListChange(templates: Array<SelectableListItem>) {
        return (id: string) => {
            const templatesWithUpdatedSelection = templates.map((template) =>
                template.id === id
                    ? { ...template, selected: !template.selected }
                    : template
            )

            setNumberOfSelectedTemplates(
                templatesWithUpdatedSelection.filter(
                    (template) => template.selected
                ).length
            )

            return templatesWithUpdatedSelection
        }
    }

    const requestTemplatesAssign = useRequestTemplatesAssign()
    const onSubmit = async (
        values: AssignTemplateDialogFormData,
        formikActions: FormikActions<AssignTemplateDialogFormData>
    ) => {
        const selectedTemplateIds: Array<string> = values.templates
            .filter((template) => template.selected)
            .map((template) => template.id)

        try {
            if (props.userId && selectedTemplateIds) {
                await requestTemplatesAssign(props.userId, selectedTemplateIds)
            }

            await requestAllUserTemplatesRevalidation(props.userId)
            closeActiveOverlay()
        } catch (payload) {
            // TODO: Introduce logging
            formikActions.setSubmitting(true)

            createNotification({
                type: t('error:labels.error'),
                description: t('error:response.assignTemplateUnknown')
            })
        }
    }

    return (
        <Dialog
            title={t('matrices:assignTemplate.title')}
            subtitle={t('matrices:assignTemplate.subtitle')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
        >
            <Formik
                initialValues={initialValues}
                onSubmit={onSubmit}
                validationSchema={validationSchema}
                enableReinitialize
            >
                {(formikProps) => {
                    const templateError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'templates'
                    )

                    return (
                        <Form className="dialog__form withoutMargin">
                            <div className="dialog__scrollArea">
                                <ListFilterBar
                                    className="dialog__search"
                                    isDialog
                                    padding="vertical"
                                    handleChange={formikProps.handleChange(
                                        'searchKey'
                                    )}
                                    placeholder={t(
                                        'common:worksheet.search.templates'
                                    )}
                                    searchKey={formikProps.values.searchKey}
                                    autoFocus
                                />

                                <div className="dialog__listContainer modern-scrollbar">
                                    <ReactPlaceholder
                                        className="dialog__reactPlaceholder"
                                        showLoadingAnimation
                                        type="text"
                                        rows={6}
                                        ready={status === 'resolved'}
                                    >
                                        <UnassignedTemplateList
                                            formikProps={formikProps}
                                            error={status === 'rejected'}
                                            searchKey={
                                                formikProps.values.searchKey
                                            }
                                            handleTemplateListChange={handleTemplateListChange(
                                                formikProps.values.templates
                                            )}
                                            listItems={
                                                formikProps.values.templates
                                            }
                                            isEmpty={
                                                formikProps.values.templates
                                                    .length === 0
                                            }
                                        />
                                    </ReactPlaceholder>
                                </div>

                                <div className="dialog__informationWrapper">
                                    <span
                                        className={classNames(
                                            'labeledInputField__message',
                                            templateError &&
                                                'labeledInputField__message--error'
                                        )}
                                    >
                                        {numberOfSelectedTemplates > 0 ? (
                                            <div>
                                                {t(
                                                    'matrices:assignTemplate.selectedTemplates',
                                                    {
                                                        count: numberOfSelectedTemplates
                                                    }
                                                )}
                                            </div>
                                        ) : null}

                                        {templateError}
                                    </span>
                                </div>
                            </div>

                            <div className="dialog__actions dialog__actions--noShadow">
                                <Button
                                    title={t('common:labels.cancel')}
                                    onClick={closeActiveOverlay}
                                    formButton
                                />

                                <Button
                                    title={t('common:labels.assign')}
                                    loading={formikProps.isSubmitting}
                                    disabled={
                                        formikProps.values.templates.length ===
                                        0
                                    }
                                    type="submit"
                                    primary
                                    formButton
                                />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export default AssignTemplateDialog
