const matrices = {
    title: 'Matrices',
    labels: {
        createMatrix: 'Create matrix',
        leaveFeedback: 'Leave feedback',
        selectFeedbackType: 'Select a feedback',
        freeTextFeedbackOptional: 'Free text feedback (Optional)',
        freeTextFeedbackPlaceholder:
            '(Feel free to express your thoughts here)',
        freeTextFeedbackDescription:
            'This field is optional unless you do not select anything from the previous section or you select the "Other" option.',
        notMatricesFound: 'No matrices found',
        next: 'Next',
        start: 'Start'
    },

    feedbackType: {
        reportAnIssue: 'Report an issue',
        notRelevant: 'Not relevant',
        incompleteDescription: 'Incomplete description',
        other: 'Other'
    },

    assignTemplate: {
        title: 'Assign template',
        subtitle: 'Linked templates will appear separately as well',
        actionText: 'Assign a template',
        selectedTemplates: '{{count}} template selected',
        selectedTemplates_plural: '{{count}} templates selected',
        labels: {
            linkedTemplate: 'and {{linkedTemplateName}}',
            emptyState: `Templates can't be listed here either because you assigned all of them
                to your profile or the administrators didn't create any templates yet.`
        }
    },
    onboarding: {
        firstStep: {
            title: 'Get the most out of your career',
            description:
                'Let us help you find the most appropriate skills with really useful training materials that match your professional profile.'
        },
        secondStep: {
            title: 'Create your profile by filling matrices',
            description:
                'Select at least one of the available matrices below to start creating your own profile.'
        },
        thirdStep: {
            title: 'Your journey begins now',
            description:
                'You are about to experience how our matrices can contribute to your career development by providing useful guidelines and smart suggestions.'
        }
    },
    removeMatrix: {
        title: 'Remove matrix',
        question:
            'Are you sure you want to remove <0>{{matrixName}}</0> matrix and all of its content from your profile? You will not be able to restore the data unless you create everything again from scratch.',
        error:
            "You can't remove this matrix because it's linked to at least one of your other assigned matrices."
    },

    contentBrowser: {
        emptyState: {
            content: {
                title: 'Create or edit matrices',
                subtitle:
                    'Matrices can be created by assigning templates to your profile.'
            }
        },

        idErrorData: {
            title: 'Matrix not found',
            subtitle:
                "You've tried to open a matrix which is not available in our database."
        },

        currentTemplateContent: {
            emptyState:
                "This template is empty, so we can't display anything at this time."
        },

        linkedTemplateContent: {
            emptyState:
                "This linked template is empty, so we can't display anything at this time."
        },

        linkedMatrix: ' and {{linkedMatrixName}}'
    },

    leaveFeedback: {
        success:
            'Thank you for your feedback! Our team will get in touch with you soon.'
    }
}

export default matrices
