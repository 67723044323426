import { PlaceholderData, RouteParams } from '@app/common'
import { DialogConfig } from '@app/dialog'
import AddItemIcon from '@atlaskit/icon/glyph/add-item'
import CrossIcon from '@atlaskit/icon/glyph/cross'
import LinkFilledIcon from '@atlaskit/icon/glyph/link-filled'
import LabeledButton, {
    LabeledButtonProps
} from 'components/button/labeled/LabeledButton'
import SortableList from 'components/listItem/sortable/SortableList'
import Placeholder from 'components/placeholder/Placeholder'
import {
    getTemplateItemsToReorder,
    orderArrayListByPosition,
    useFetchTemplateById,
    useRequestTemplateItemsReorder
} from 'helpers/templateHelpers'
import withInactive from 'hoc/withInactive'
import withMaybe from 'hoc/withMaybe'
import withTooltip from 'hoc/withTooltip'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import ReactPlaceholder from 'react-placeholder'
import 'react-placeholder/lib/reactPlaceholder.css'
import { match as Match } from 'react-router-dom'
import {
    isAdmin,
    isContentAdmin,
    isMatchingAnyRole
} from '../../helpers/roleHelpers'
import { useAuthentication } from '../../hooks/useAuthentication'
import TemplateSkillSetListItem from './TemplateSkillSetListItem'

interface Props extends WithTranslation {
    openDialog?: (config: DialogConfig) => void
    match?: Match<RouteParams>
}

const TemplateContentBrowser: React.FC<Props> = (props: Props) => {
    const { t, match } = props

    const shouldDisplayEmptyState = !match.params || !match.params.id

    const { template: currentTemplate, error, status } = useFetchTemplateById(
        match.params.id
    )

    const { template: linkedTemplate } = useFetchTemplateById(
        currentTemplate?.linkedTemplateId
    )

    const shouldDisplayIdError = !!error && error.status === 404

    const emptyStateData: PlaceholderData = {
        icon: <AddItemIcon label="" size="xlarge" />,
        title: t('templates:contentBrowser.emptyState.content.title'),
        subtitle: t('templates:contentBrowser.emptyState.content.subtitle'),
        action: () => props.openDialog({ type: 'CREATE_TEMPLATE' }),
        actionText: t('templates:contentBrowser.emptyState.content.action')
    }

    const emptyTemplateStateData: PlaceholderData = {
        icon: <AddItemIcon label="" size="xlarge" />,
        title: t('templates:contentBrowser.emptyState.template.title'),
        subtitle: t('templates:contentBrowser.emptyState.template.subtitle')
    }

    const idErrorData: PlaceholderData = {
        icon: <CrossIcon label="" size="xlarge" />,
        title: t('templates:contentBrowser.idErrorData.title'),
        subtitle: t('templates:contentBrowser.idErrorData.subtitle')
    }

    const isTemplateBeingLinked = () => {
        return currentTemplate && currentTemplate.linked
    }

    const { getCurrentUserRoles } = useAuthentication()
    const currentUserRoles = getCurrentUserRoles()

    const isCurrentUserAnAdmin = React.useMemo(
        () => isMatchingAnyRole(isAdmin, isContentAdmin)(currentUserRoles),
        [currentUserRoles]
    )

    const linkedSkillSetList = () => {
        return linkedTemplate && linkedTemplate.childSkillSets.length
            ? orderArrayListByPosition(linkedTemplate.childSkillSets).map(
                  (skillSet) => {
                      return (
                          <TemplateSkillSetListItem
                              key={skillSet.id}
                              skillSet={skillSet}
                              template={
                                  isCurrentUserAnAdmin
                                      ? linkedTemplate
                                      : currentTemplate
                              }
                              openDialog={props.openDialog}
                              disableAdditionalContent={!isCurrentUserAnAdmin}
                          />
                      )
                  }
              )
            : undefined
    }

    const requestTemplateItemsReorder = useRequestTemplateItemsReorder()
    const reorderTemplateItems = (templateItems: Array<any>) => {
        if (templateItems && currentTemplate) {
            requestTemplateItemsReorder(
                currentTemplate.id,
                getTemplateItemsToReorder(templateItems)
            )
        }
    }

    const skillSetList = () => {
        return currentTemplate &&
            currentTemplate.childSkillSets &&
            currentTemplate.childSkillSets.length ? (
            <SortableList
                useDragHandle
                lockAxis="y"
                onSortEnd={reorderTemplateItems}
                template={currentTemplate}
                list={orderArrayListByPosition(
                    currentTemplate.childSkillSets
                ).map((skillSet) => (
                    <TemplateSkillSetListItem
                        key={skillSet.id}
                        skillSet={skillSet}
                        reorderTemplateItems={reorderTemplateItems}
                        template={currentTemplate}
                        openDialog={props.openDialog}
                    />
                ))}
            />
        ) : undefined
    }

    const renderContentBrowserTitle = ({ title }: { title: string }) => (
        <div className="worksheetContentBrowser__title">{title}</div>
    )

    const renderLinkTemplateButton = ({
        onClick,
        icon,
        inactive,
        title
    }: LabeledButtonProps) => (
        <LabeledButton
            onClick={onClick}
            icon={icon}
            inactive={inactive}
            title={inactive ? t('templates:linkTemplate.alreadyLinked') : title}
        />
    )

    const RenderedLinkButton = withInactive(isTemplateBeingLinked())(
        renderLinkTemplateButton
    )

    const LinkTemplateButton = () => (
        <RenderedLinkButton
            title={t('templates:linkTemplate.title')}
            icon={<LinkFilledIcon label="" />}
            onClick={() =>
                currentTemplate &&
                props.openDialog({
                    type: 'LINK_TEMPLATE',
                    payload: { templateId: currentTemplate.id }
                })
            }
        />
    )

    const LinkButtonWithTooltip = isTemplateBeingLinked()
        ? withTooltip()(LinkTemplateButton)
        : LinkTemplateButton

    const linkTemplateButton = linkedTemplate ? (
        <LabeledButton
            title={`${t('templates:labels.unlink')} ${
                linkedTemplate.name.length < 15
                    ? linkedTemplate.name
                    : t('templates:labels.template')
            }`}
            icon={<LinkFilledIcon label="" />}
            onClick={() =>
                currentTemplate &&
                linkedTemplate &&
                props.openDialog({
                    type: 'UNLINK_TEMPLATE',
                    payload: {
                        baseTemplate: currentTemplate,
                        linkedTemplateName: linkedTemplate.name
                    }
                })
            }
        />
    ) : (
        <LinkButtonWithTooltip
            title={t('templates:linkTemplate.alreadyLinkedTooltip')}
            enterDelay={350}
            leaveDelay={150}
            placement="bottom"
            PopperProps={{ style: { textAlign: 'center' } }}
        />
    )

    const TemplateTitle = withMaybe<{ title: string }>(
        !!currentTemplate && !!linkedTemplate
    )(renderContentBrowserTitle)

    const LinkedTemplateTitle = withMaybe<{ title: string }>(!!linkedTemplate)(
        renderContentBrowserTitle
    )

    const render = () => {
        if (shouldDisplayEmptyState) {
            return (
                <div className="worksheetContentBrowser__emptyState">
                    <Placeholder
                        action={emptyStateData.action}
                        actionText={emptyStateData.actionText}
                        icon={emptyStateData.icon}
                        title={emptyStateData.title}
                        subtitle={emptyStateData.subtitle}
                    />
                </div>
            )
        }

        if (shouldDisplayIdError) {
            return (
                <div className="worksheetContentBrowser__emptyState">
                    <Placeholder
                        icon={idErrorData.icon}
                        title={idErrorData.title}
                        subtitle={idErrorData.subtitle}
                    />
                </div>
            )
        }

        return (
            <>
                <div className="worksheetContentBrowser__header">
                    <ReactPlaceholder
                        className="placeholder"
                        showLoadingAnimation
                        type="textRow"
                        rows={1}
                        ready={status === 'resolved'}
                    >
                        <div className="worksheetContentBrowser__headerTitle text-ellipsis">
                            <span>
                                {currentTemplate && currentTemplate.name}
                            </span>

                            <span>
                                {linkedTemplate
                                    ? t(
                                          'templates:contentBrowser.linkedTemplate.linkedTemplateName',
                                          {
                                              linkedTemplateName:
                                                  linkedTemplate.name
                                          }
                                      )
                                    : ''}
                            </span>
                        </div>
                    </ReactPlaceholder>

                    <ReactPlaceholder
                        className="placeholder"
                        showLoadingAnimation
                        type="text"
                        rows={1}
                        ready={status === 'resolved'}
                    >
                        <div className="worksheetContentBrowser__actions">
                            {linkTemplateButton}

                            <LabeledButton
                                title={t('templates:createSkillSet.title')}
                                onClick={() =>
                                    currentTemplate &&
                                    props.openDialog({
                                        type: 'CREATE_SKILL_SET',
                                        payload: currentTemplate
                                    })
                                }
                            />

                            {skillSetList() && (
                                <LabeledButton
                                    title={t('templates:createSkill.title')}
                                    onClick={() =>
                                        currentTemplate &&
                                        props.openDialog({
                                            type: 'CREATE_SKILL',
                                            payload: currentTemplate
                                        })
                                    }
                                />
                            )}
                        </div>
                    </ReactPlaceholder>
                </div>

                {currentTemplate && !skillSetList() && !linkedTemplate ? (
                    <div className="worksheetContentBrowser__emptyState">
                        <Placeholder
                            icon={emptyTemplateStateData.icon}
                            title={emptyTemplateStateData.title}
                            subtitle={emptyTemplateStateData.subtitle}
                        />
                    </div>
                ) : (
                    <ReactPlaceholder
                        className="contentBrowserListItem__reactPlaceholder"
                        showLoadingAnimation
                        type="text"
                        rows={18}
                        ready={status === 'resolved'}
                    >
                        <div className="worksheetContentBrowser__scrollArea">
                            <div className="worksheetContentBrowser__innerContent modern-scrollbar">
                                <TemplateTitle
                                    title={
                                        currentTemplate && currentTemplate.name
                                    }
                                />

                                {skillSetList() ? (
                                    skillSetList()
                                ) : (
                                    <div className="worksheetContentBrowser__emptyList">
                                        {t(
                                            'templates:contentBrowser.linkedTemplate.emptySkillSetState'
                                        )}
                                    </div>
                                )}

                                {linkedTemplate ? (
                                    <>
                                        <LinkedTemplateTitle
                                            title={
                                                linkedTemplate &&
                                                linkedTemplate.name
                                            }
                                        />

                                        {linkedSkillSetList() ? (
                                            <div className="worksheetContentBrowser__section">
                                                {linkedSkillSetList()}
                                            </div>
                                        ) : (
                                            <div className="worksheetContentBrowser__emptyList">
                                                {t(
                                                    'templates:contentBrowser.linkedTemplate.emptyLinkedTemplate'
                                                )}
                                            </div>
                                        )}
                                    </>
                                ) : null}
                            </div>
                        </div>
                    </ReactPlaceholder>
                )}
            </>
        )
    }

    return <div className="worksheetContentBrowser__content">{render()}</div>
}

export default withTranslation(['templates', 'common'])(TemplateContentBrowser)
