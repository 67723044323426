import { Nullable } from '@app/common'
import { DialogConfig } from '@app/dialog'

/**
 * Removes all diacritics from specified text and returns the transformed value.
 */
export const removeDiacritics: (text?: string) => string = (text) => {
    return text ? text.normalize('NFD').replace(/[\u0300-\u036f]/g, '') : ''
}

/**
 * Parses a JWT token and returns its content.
 *
 * @param token - JWT token to parse
 */
export const getParsedJWT = (token?: string) => {
    try {
        return token
            ? JSON.parse(atob(decodeURIComponent(token.split('.')[1])))
            : undefined
    } catch (error) {
        // TODO: Introduce logging
        return undefined
    }
}

/**
 * Merges specified arrays.
 *
 * @param x - Array 1
 * @param y - Array 2
 */
export const merge: (x: Array<any>, y: Array<any>) => Array<any> = (x, y) =>
    x.concat(y)

/**
 * Returns the capitalized format of the specified text.
 *
 * @param text - Text to capitalize
 */
export const getCapitalizedText = (text?: string): string =>
    text ? `${text.charAt(0).toUpperCase()}${text.substr(1).toLowerCase()}` : ''

/**
 * Calls the function after preventing the provided event's default interaction.
 *
 * @param event - Event
 */
export const executeWithPreventDefault = (
    event: KeyboardEvent | MouseEvent
) => (callableFunction: () => void) => {
    event.preventDefault()
    callableFunction()
}

/**
 * Opens the specified dialog after preventing the keyboard or mouse event's default behaviour.
 *
 * @param event - Keyboard or mouse event
 * @param config - Dialog configuration
 * @param openAction - Dialog open action
 */
export const openDialogWrapper = (
    event: Nullable<KeyboardEvent | MouseEvent>,
    config: DialogConfig,
    openAction: (config: DialogConfig) => void
) =>
    event
        ? executeWithPreventDefault(event)(() => openAction(config))
        : openAction(config)

/**
 * Converts the specified name to a monogram using the first letters of the first two words.
 *
 * @param name - Name
 */
export const getMonogram = (name: string) =>
    name
        .split(' ')
        .reduce(
            (monogram, partialName) =>
                monogram.length < 2
                    ? `${monogram}${partialName.charAt(0)}`
                    : monogram,
            ''
        )
        .toUpperCase()

export const PHONE_NUMBER_REG_EXP = /^[+][0-9]{11}$/
