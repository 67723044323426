import { DeleteGroupDialogFormData } from '@app/form'
import { GroupDetails } from '@app/group'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import LabeledInputField from 'components/input/labeledInputField/LabeledInputField'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import FormikHelpers from 'helpers/formikHelpers'
import {
    requestAllGroupsRevalidation,
    useRequestGroupDelete
} from 'helpers/groupHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import * as Yup from 'yup'

interface DeleteGroupDialogProps {
    redirectOnMatch: (redirectUrl: string) => void
    group: GroupDetails
}

const DeleteGroupDialog = (props: DeleteGroupDialogProps) => {
    const { t } = useTranslation(['common', 'groups', 'error'])

    const {
        formDirty,
        toggleFormToDirty,
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const initialValues: DeleteGroupDialogFormData = {
        name: ''
    }

    const validationSchema = Yup.object().shape({
        name: Yup.string()
            .oneOf(
                [props.group.name],
                t('error:validation.groupNameShouldMatch', {
                    groupName: props.group.name
                })
            )
            .required(t('error:validation.fieldRequired'))
    })

    const requestGroupDelete = useRequestGroupDelete()
    const onSubmit = async (
        values: DeleteGroupDialogFormData,
        formikActions: FormikActions<DeleteGroupDialogFormData>
    ) => {
        const { group } = props

        const { status } = await requestGroupDelete(group.id)

        if (status === 'resolved') {
            await requestAllGroupsRevalidation()
            props.redirectOnMatch(group.id)
            closeActiveOverlay()
        } else if (status === 'rejected') {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            createNotification({
                type: t('error:labels.error'),
                description: t('error:response.deleteGroupUnknown', {
                    groupName: group.name
                })
            })
        }
    }

    return (
        <Dialog
            title={t('groups:deleteGroup.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon={false}
        >
            <div className="dialog__info">
                <Trans i18nKey="groups:deleteGroup.question">
                    <strong>
                        {{
                            groupName: props.group.name
                        }}
                    </strong>
                </Trans>
            </div>

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
                validateOnChange
            >
                {(formikProps) => {
                    const nameError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'name'
                    )

                    return (
                        <Form className="dialog__form dialog__form--confirm margin-top-lg">
                            <LabeledInputField
                                name="name"
                                label={t('groups:deleteGroup.action')}
                                onChange={FormikHelpers.setDirtyForm(
                                    formikProps,
                                    'name',
                                    formDirty,
                                    toggleFormToDirty
                                )}
                                placeholder={props.group.name}
                                value={formikProps.values.name}
                                message={nameError}
                                hasError={!!nameError}
                                tabIndex={0}
                                autoFocus
                            />

                            <div className="dialog__actions dialog__actions--noShadow">
                                <Button
                                    title={t('common:labels.cancel')}
                                    onClick={closeActiveOverlay}
                                    formButton
                                />

                                <Button
                                    title={t('common:labels.delete')}
                                    type="submit"
                                    loading={formikProps.isSubmitting}
                                    destructive
                                    primary
                                    formButton
                                    autoFocus
                                />
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export default DeleteGroupDialog
