import { MatrixSkill } from '@app/matrix'
import { SimplePerson } from '@app/person'
import CommentIcon from '@atlaskit/icon/glyph/comment'
import ContentBrowserListItem from 'components/contentBrowserListItem/ContentBrowserListItem'
import LevelSelector from 'components/input/levelSelector/LevelSelector'
import {
    requestSingleMatrixRevalidation,
    useRequestRateSkill
} from 'helpers/matrixHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
    skill: MatrixSkill
    userId: string
    owners: Array<SimplePerson>
}

interface State {
    loading: boolean
    rating: number
}

const MatrixSkillListItem = (props: Props) => {
    const [state, setState] = useState<State>({
        loading: false,
        rating: props.skill.rating
    })

    const { openDialog } = useGlobal()

    const { t } = useTranslation(['matrices'])
    const { skill, owners } = props

    const onLeaveFeedbackClick = (event) => {
        event.stopPropagation()

        openDialog({
            type: 'LEAVE_FEEDBACK',
            payload: {
                feedbackTargetId: skill.skillId,
                feedbackTargetType: 'SKILL',
                feedbackTargetName: skill.name
            }
        })
    }

    const leaveFeedbackButton = (
        <button
            className="contentBrowserListItem__trigger"
            type="button"
            onClick={onLeaveFeedbackClick}
        >
            <CommentIcon label="" size="small" />
            <div>{t('matrices:labels.leaveFeedback')}</div>
        </button>
    )

    const requestRateSkill = useRequestRateSkill()
    const rateSkill = async (rate: number) => {
        setState({ loading: true, rating: rate })

        const { data } = await requestRateSkill({
            userId: props.userId,
            skillId: props.skill.skillId,
            rating: rate
        })

        setState({ ...state, loading: false })

        requestSingleMatrixRevalidation(props.userId, data.templateId)
    }

    const listItemActionMenu = (
        <LevelSelector
            value={
                state.rating !== props.skill.rating
                    ? props.skill.rating
                    : state.rating
            }
            onClick={(value: number) => rateSkill(value)}
            isLoading={state.loading}
        />
    )

    return (
        <ContentBrowserListItem
            additionalContent={listItemActionMenu}
            data={skill}
            owners={owners}
            showAdditionalContentOnlyOnHover={false}
            showTriggerButtonLabel
            additionalTriggerButton={leaveFeedbackButton}
            small
        />
    )
}

export default MatrixSkillListItem
