import Eye from '@atlaskit/icon/glyph/watch'
import FilledEye from '@atlaskit/icon/glyph/watch-filled'
import classNames from 'classnames'
import LabeledInputField, {
    LabeledInputFieldProps
} from 'components/input/labeledInputField/LabeledInputField'
import React, { useState } from 'react'

export type SecureInputFieldProps = LabeledInputFieldProps

const SecureInputField = (props: SecureInputFieldProps) => {
    const [shouldReveal, setShouldReveal] = useState(false)

    return (
        <LabeledInputField
            {...props}
            className={classNames({ secure: !shouldReveal })}
            type={shouldReveal ? 'text' : 'password'}
            onIconClick={() => setShouldReveal(!shouldReveal)}
            icon={shouldReveal ? FilledEye : Eye}
        />
    )
}

export default SecureInputField
