import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

export interface TrainingMaterialsRecommendationMessageProps {
    assignedMatrices: number
    matricesMeetingExpectations: number
    overallFillingRate: number
    link: ReactNode
}

export const TrainingMaterialsRecommendationMessage = (
    props: TrainingMaterialsRecommendationMessageProps
) => {
    const { t } = useTranslation('overview')

    if (props.assignedMatrices === 0) {
        return (
            <>
                <p className="recommendationSubtitle--margin">
                    {t('overview:recommendation.hasNotMatrices')}
                </p>
                {props.link}
            </>
        )
    }

    if (
        props.assignedMatrices > 0 &&
        props.assignedMatrices === props.matricesMeetingExpectations
    ) {
        return (
            <>
                <p className="recommendationSubtitle--margin">
                    {t('overview:recommendation.filledAllMatrices')}
                </p>
                {props.link}
            </>
        )
    }

    if (props.overallFillingRate > 0) {
        return (
            <>
                <p className="recommendationSubtitle--margin">
                    {t('overview:recommendation.doesNotFillAllMatrices')}
                </p>
                {props.link}
            </>
        )
    }

    return (
        <>
            <p className="recommendationSubtitle--margin">
                {t('overview:recommendation.doesNotFillAnyMatrices')}
            </p>
            {props.link}
        </>
    )
}
