import { RouteParams } from '@app/common'
import withTitle from 'hoc/withTitle'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { match, RouteComponentProps, withRouter } from 'react-router'
import { compose } from 'recompose'
import Breadcrumb from '../../components/breadcrumb/Breadcrumb'
import Onboarding from '../../components/onboarding/Onboarding'
import { useFetchCurrentUser } from '../../helpers/accountHelpers'
import MatrixWorksheet from './MatrixWorksheet'

interface OwnProps {
    match: match<RouteParams>
}

type Props = OwnProps & RouteComponentProps

const Matrices = (props: Props) => {
    const { t } = useTranslation(['matrices'])
    const { currentUser } = useFetchCurrentUser()
    const [onboarding, setOnboarding] = React.useState(currentUser.onboarding)

    return (
        <>
            <Breadcrumb>{t('matrices:title')}</Breadcrumb>

            {onboarding ? (
                <Onboarding onChange={() => setOnboarding(false)} />
            ) : (
                <MatrixWorksheet match={props.match} />
            )}
        </>
    )
}

export default compose<Props, OwnProps>(
    withTitle('Matrices'),
    withRouter
)(Matrices)
