import { ErrorData } from '@app/api'
import { OverviewData, TrainingMaterial } from '@app/overview'
import { AxiosInstance } from 'axios'
import { useClient } from 'hooks/useClient'
import useSWR, { mutate } from 'swr'
import { determineComponentStatus, tryMutation } from './apiHelpers'

const OVERVIEW_BASE_URL = '/api/overview'

function useFetchAllOverviewData() {
    const { data, error } = useSWR<OverviewData, ErrorData>(OVERVIEW_BASE_URL)

    return {
        overviewData: data,
        error,
        status: determineComponentStatus(data, error)
    }
}

function useFetchAllTrainingMaterialData() {
    const { data, error } = useSWR<Array<TrainingMaterial>, ErrorData>(
        `${OVERVIEW_BASE_URL}/trainingMaterial`
    )

    return {
        trainingMaterialData: data,
        error,
        status: determineComponentStatus(data, error)
    }
}

function useRequestTrainingMaterialFinish(customClient?: AxiosInstance) {
    const { client } = useClient()

    return (trainingMaterialId: string) =>
        tryMutation(async () => {
            const response = await (customClient ?? client).post(
                `${OVERVIEW_BASE_URL}/trainingMaterial/${trainingMaterialId}`
            )
            return response.data
        })
}

async function requestAllOverviewDataRevalidation() {
    return mutate(OVERVIEW_BASE_URL)
}

export {
    useFetchAllOverviewData,
    useRequestTrainingMaterialFinish,
    requestAllOverviewDataRevalidation,
    useFetchAllTrainingMaterialData
}
