import { SelectInputValue } from '@app/common'
import { CreateSkillSetDialogFormData } from '@app/form'
import { TemplateDetails } from '@app/template'
import OwnersBadgeList from 'components/badge/ownerBadgeList/OwnersBadgeList'
import Button from 'components/button/base/Button'
import Dialog from 'components/dialog/base/Dialog'
import LabeledInputField from 'components/input/labeledInputField/LabeledInputField'
import LabeledSelect from 'components/input/labeledSelect/LabeledSelect'
import RichTextEditor from 'components/input/richTextEditor/RichTextEditor'
import ToggleSwitch from 'components/input/toggleSwitch/ToggleSwitch'
import { Form, Formik, FormikHelpers as FormikActions } from 'formik'
import { useFetchCurrentUser } from 'helpers/accountHelpers'
import FormikHelpers from 'helpers/formikHelpers'
import { useFetchAllActivePeople } from 'helpers/peopleHelpers'
import { useRequestSkillSetCreate } from 'helpers/skillHelpers'
import {
    getSkillSetDropdownItems,
    requestSingleTemplateRevalidation
} from 'helpers/templateHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { compose } from 'recompose'
import * as Yup from 'yup'

interface OwnProps {
    template: TemplateDetails
}

type CreateSkillSetDialogProps = OwnProps & WithTranslation

const CreateSkillSetDialog = (props: CreateSkillSetDialogProps) => {
    const { t, template } = props

    const {
        formDirty,
        toggleFormToDirty,
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const { currentUser } = useFetchCurrentUser()

    const { lastSkillSet, setLastSkillSet } = useGlobal()

    const { data: activePeopleList, status } = useFetchAllActivePeople()

    const validationSchema = Yup.object().shape({
        name: Yup.string().required(t('error:validation.fieldRequired'))
    })

    const dropdownOptions: Array<SelectInputValue> = [
        {
            label: t('templates:labels.parentPlaceholder', {
                templateName: template.name
            }),
            value: template.id
        },
        ...getSkillSetDropdownItems(template)
    ]

    const initialValues: CreateSkillSetDialogFormData = {
        name: '',
        description: '',
        parent:
            lastSkillSet.node.templateId === props.template.id
                ? dropdownOptions.filter(
                      (skillSet) =>
                          skillSet.value === lastSkillSet.node.parentId
                  )[0]
                : dropdownOptions.filter(
                      (skillSet) => skillSet.value === props.template.id
                  )[0],
        ownerUserIds: [currentUser.id],
        createAnother: lastSkillSet.createAnother
    }

    const requestSkillSetCreate = useRequestSkillSetCreate()
    const onSubmit = async (
        values: CreateSkillSetDialogFormData,
        formikActions: FormikActions<CreateSkillSetDialogFormData>
    ) => {
        try {
            await requestSkillSetCreate({
                name: values.name,
                description: values.description
                    .replace(/^<p><br><\/p>/g, '')
                    .replace(/<p><br><\/p>$/g, ''),
                parentId: values.parent.value,
                ownerUserIds: values.ownerUserIds
            })

            setLastSkillSet({
                node: {
                    parentId: values.parent.value,
                    templateId: props.template.id
                },
                createAnother: values.createAnother
            })

            await requestSingleTemplateRevalidation(template.id)

            if (values.createAnother) {
                formikActions.resetForm()
                createNotification({
                    type: t('common:labels.success'),
                    description: t('templates:createSkillSet.success'),
                    hideOverlay: true,
                    timeout: 2500
                })
            } else {
                closeActiveOverlay()
            }
        } catch (payload) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            createNotification({
                type: t('error:labels.error'),
                description: t('error:response.createSkillSetUnknown', {
                    skillSetName: values.name
                })
            })
        }
    }

    return (
        <Dialog
            title={t('templates:createSkillSet.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
            animationWithMargin
        >
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={onSubmit}
                validateOnChange
            >
                {(formikProps) => {
                    const nameError = FormikHelpers.getErrorMessage(
                        formikProps,
                        'name'
                    )

                    return (
                        <Form className="dialog__form">
                            <div className="dialog__scrollArea modern-scrollbar">
                                <LabeledInputField
                                    label={t('templates:labels.name')}
                                    name="name"
                                    onChange={FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'name',
                                        formDirty,
                                        toggleFormToDirty
                                    )}
                                    value={formikProps.values.name}
                                    message={nameError}
                                    hasError={!!nameError}
                                    tabIndex={0}
                                    autoFocus
                                />

                                <LabeledSelect
                                    label={t('templates:labels.parent')}
                                    name="parent"
                                    options={dropdownOptions}
                                    value={formikProps.values.parent}
                                    isClearable={false}
                                    placeholder={props.t(
                                        'templates:labels.selectSkillSet',
                                        {
                                            templateName: props.template.name
                                        }
                                    )}
                                    onChange={FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'parent',
                                        formDirty,
                                        toggleFormToDirty
                                    )}
                                    tabIndex={0}
                                />

                                <RichTextEditor
                                    label={t(
                                        'templates:labels.descriptionOptional'
                                    )}
                                    name="description"
                                    onChange={FormikHelpers.setDirtyForm(
                                        formikProps,
                                        'description',
                                        formDirty,
                                        toggleFormToDirty
                                    )}
                                    value={formikProps.values.description}
                                    placeholder={t(
                                        'templates:labels.descriptionSkillSetPlaceholder'
                                    )}
                                    bounds=".richTextEditor"
                                    tabIndex={0}
                                />

                                <OwnersBadgeList
                                    label={t(
                                        'templates:labels.coachesOptional'
                                    )}
                                    activePeopleList={(
                                        activePeopleList ?? []
                                    ).map((people) => {
                                        return {
                                            id: people.id,
                                            title: people.name,
                                            subtitle: ''
                                        }
                                    })}
                                    formikProps={formikProps}
                                    setFormDirty={toggleFormToDirty}
                                    isFormDirty={formDirty}
                                    hasError={status === 'rejected'}
                                />
                            </div>

                            <div className="dialog__actions">
                                <div className="dialog__actionContainer">
                                    <div className="dialog__toggleSwitch">
                                        <ToggleSwitch
                                            selected={
                                                formikProps.values.createAnother
                                            }
                                            label={t(
                                                'templates:labels.createAnother'
                                            )}
                                            onChange={(value) =>
                                                formikProps.setFieldValue(
                                                    'createAnother',
                                                    value
                                                )
                                            }
                                        />
                                    </div>
                                    <div className="dialog__buttonContainer">
                                        <Button
                                            title={t('common:labels.cancel')}
                                            onClick={closeActiveOverlay}
                                            formButton
                                        />

                                        <Button
                                            title={t('common:labels.create')}
                                            type="submit"
                                            loading={formikProps.isSubmitting}
                                            primary
                                            formButton
                                        />
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )
                }}
            </Formik>
        </Dialog>
    )
}

export default compose<CreateSkillSetDialogProps, OwnProps>(
    withTranslation(['common', 'templates', 'error'])
)(CreateSkillSetDialog)
