import {
    RoleSelectInputValue,
    SelectInputValue,
    SkillSelectInputValue
} from '@app/common'
import classNames from 'classnames'
import withMaybe from 'hoc/withMaybe'
import React from 'react'
import Select from 'react-select'
import { FormatOptionLabelMeta } from 'react-select/src/Select'
import { InputActionMeta } from 'react-select/src/types'

type MessageLabelVariantType = 'WARNING' | 'ERROR' | 'INFO'

interface MessageLabelProps {
    message: string
    variant: MessageLabelVariantType
    hasError: boolean
}

const renderMessageLabel = (props: MessageLabelProps) => (
    <span
        className={classNames(
            'labeledSelect__message',
            props.variant
                ? {
                      error: props.hasError || props.variant === 'ERROR',
                      info: !props.hasError && props.variant === 'INFO',
                      warning: !props.hasError && props.variant === 'WARNING'
                  }
                : {
                      error: props.hasError,
                      warning: !props.hasError
                  }
        )}
    >
        {props.message}
    </span>
)

interface LabeledSelectProps {
    label: string
    name: string
    onChange: (
        values:
            | Array<RoleSelectInputValue>
            | Array<SelectInputValue>
            | SelectInputValue
    ) => void
    onInputChange?: (newValue: string, meta: InputActionMeta) => void
    value:
        | Array<RoleSelectInputValue>
        | Array<SelectInputValue>
        | SelectInputValue
        | SkillSelectInputValue
    options:
        | Array<SelectInputValue>
        | Array<RoleSelectInputValue>
        | Array<SkillSelectInputValue>
    message?: string
    messageVariant?: MessageLabelVariantType
    hasError?: boolean
    isClearable?: boolean
    tabIndex?: number
    placeholder?: string
    multiSelect?: boolean
    disabled?: boolean
    formatOptionLabel?: (
        option: SelectInputValue,
        labelMeta: FormatOptionLabelMeta<SelectInputValue>
    ) => React.ReactNode
}

const LabeledSelect = (props: LabeledSelectProps) => {
    const MessageLabel = withMaybe<MessageLabelProps>(!!props.message)(
        renderMessageLabel
    )

    return (
        <div className="labeledSelect">
            <label className="labeledSelect__label" htmlFor={props.name}>
                {props.label}
            </label>

            <div className="labeledSelect__wrapper" aria-label={props.name}>
                <Select
                    id={props.name}
                    name={props.name}
                    formatOptionLabel={props.formatOptionLabel}
                    classNamePrefix="labeledSelect__input"
                    styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 })
                    }}
                    onChange={props.onChange}
                    onInputChange={props.onInputChange}
                    options={props.options}
                    value={props.value}
                    placeholder={props.placeholder}
                    isClearable={props.isClearable}
                    isDisabled={props.disabled}
                    tabIndex={props.tabIndex.toString()}
                    isMulti={props.multiSelect}
                    menuPortalTarget={document.body}
                />
            </div>

            <MessageLabel
                hasError={props.hasError}
                variant={props.messageVariant}
                message={props.message}
            />
        </div>
    )
}

export default LabeledSelect
