import { MatrixSkillSet } from '@app/matrix'
import CommentIcon from '@atlaskit/icon/glyph/comment'
import ContentBrowserListItem from 'components/contentBrowserListItem/ContentBrowserListItem'
import { MatrixSkillSetSubtitle } from 'components/subtitle/matrixSkillSetSubtitle/MatrixSkillSetSubtitle'
import { orderArrayListByPosition } from 'helpers/templateHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { merge } from 'utils/commonUtils'
import MatrixSkillListItem from './MatrixSkillListItem'

// TODO REFACTOR
export interface MatrixSkillSetListItemProps {
    skillSet: MatrixSkillSet
    userId: string
}

export const MatrixSkillSetListItem: React.FC<MatrixSkillSetListItemProps> = ({
    skillSet,
    userId
}: MatrixSkillSetListItemProps) => {
    const { openDialog } = useGlobal()

    const { t } = useTranslation(['matrices'])

    const onLeaveFeedbackClick = (event) => {
        event.stopPropagation()

        openDialog({
            type: 'LEAVE_FEEDBACK',
            payload: {
                feedbackTargetId: skillSet.skillSetId,
                feedbackTargetType: 'SKILL_SET',
                feedbackTargetName: skillSet.name
            }
        })
    }

    const leaveFeedbackButton = (
        <button
            className="contentBrowserListItem__trigger"
            type="button"
            onClick={onLeaveFeedbackClick}
        >
            <CommentIcon label="" size="small" />
            <div>{t('matrices:labels.leaveFeedback')}</div>
        </button>
    )

    return (
        <ContentBrowserListItem
            data={skillSet}
            additionalContent={null}
            showAdditionalContentOnlyOnHover={false}
            showTriggerButtonLabel
            additionalTriggerButton={leaveFeedbackButton}
            small={false}
            owners={skillSet.owners}
            skillSetSubtitle={<MatrixSkillSetSubtitle skillSet={skillSet} />}
        >
            {skillSet
                ? orderArrayListByPosition(
                      merge(skillSet.childSkillSets, skillSet.skills)
                  ).map((listItem) =>
                      listItem.skillSetId ? (
                          <MatrixSkillSetListItem
                              key={listItem.skillSetId}
                              skillSet={listItem}
                              userId={userId}
                          />
                      ) : listItem.skillId ? (
                          <MatrixSkillListItem
                              key={listItem.skillId}
                              skill={listItem}
                              userId={userId}
                              owners={skillSet.owners}
                          />
                      ) : null
                  )
                : null}
        </ContentBrowserListItem>
    )
}

export default MatrixSkillSetListItem
