const search = {
    title: '{{count}} result for "{{target}}"',
    filteredResultsTitle: '{{count}} results for selected filters',
    title_plural: '{{count}} results for "{{target}}"',
    labels: {
        loading: 'Loading...',
        noOptions: 'No options',
        search: 'Search...',
        searchBar: 'Search bar',
        filter: 'Filter',
        levels: 'Levels',
        groups: 'Groups',
        selectFilters: 'Select filters',
        levelOptions: {
            firstLevel: 'Level 1',
            secondLevel: 'Level 2',
            thirdLevel: 'Level 3',
            fourthLevel: 'Level 4',
            fifthLevel: 'Level 5'
        }
    },
    contentBrowser: {
        emptyState: {
            content: {
                title: 'No search results',
                subtitle:
                    'Please choose a different search criteria, because no matches were found for your selection.'
            }
        }
    }
}

export default search
