import { ListItemRender } from '@app/common'
import {
    DropdownItemGroup,
    DropdownMenuStateless
} from '@atlaskit/dropdown-menu'
import MoreIcon from '@atlaskit/icon/glyph/more'
import React, { useEffect, useState } from 'react'

export interface ListItemProps {
    listItem: ListItemRender
    withSeparator: boolean
}

export interface ListItemState {
    showActionMenu: boolean
    isDropdownOpen: boolean
}

const ListItem: React.FC<ListItemProps> = (props: ListItemProps) => {
    const [state, setState] = useState({
        showActionMenu: false,
        isDropdownOpen: false
    })

    useEffect(() => {
        if (
            !state.isDropdownOpen &&
            state.showActionMenu &&
            state.isDropdownOpen
        ) {
            setState({ ...state, showActionMenu: false })
        }
    }, [state])

    const hideActionMenu = () => {
        if (!state.isDropdownOpen) {
            setState({ ...state, showActionMenu: false })
        }
    }

    const showActionMenu = () => {
        if (!state.showActionMenu && !props.listItem.disableActionMenu) {
            setState({ ...state, showActionMenu: true })
        }
    }

    const onOpenChange = (attrs: any) =>
        setState({ ...state, isDropdownOpen: attrs.isOpen })

    return (
        <div className="listItem__container">
            <div
                className="listItem"
                onMouseMove={showActionMenu}
                onMouseLeave={hideActionMenu}
            >
                <div className="listItem__text">
                    <div className="listItem__title">
                        <span className="listItem__primaryTitle text-ellipsis text--normal-wrap">
                            {props.listItem.title}
                        </span>
                        {props.listItem.badge}
                    </div>

                    {props.listItem.secondaryTitle && (
                        <span className="listItem__title text-ellipsis">
                            {props.listItem.secondaryTitle}
                        </span>
                    )}

                    {props.listItem.subtitle && (
                        <span className="listItem__subtitle text-ellipsis">
                            {props.listItem.subtitle}
                        </span>
                    )}

                    {props.listItem.labels && (
                        <div className="listItem__labels">
                            {props.listItem.labels.map((label) => (
                                <span
                                    key={label}
                                    className="listItem__labels__label"
                                >
                                    {label}
                                </span>
                            ))}
                        </div>
                    )}
                </div>

                {state.showActionMenu && (
                    <DropdownMenuStateless
                        isOpen={state.isDropdownOpen}
                        onOpenChange={onOpenChange}
                        trigger={
                            <div
                                className="listItem__action"
                                onClick={(e) => e.preventDefault()}
                            >
                                <MoreIcon label="" />
                            </div>
                        }
                        triggerType="default"
                        position="top left"
                        isMenuFixed
                    >
                        <DropdownItemGroup id="actions">
                            {props.listItem.dropdownMenuContent}
                        </DropdownItemGroup>
                    </DropdownMenuStateless>
                )}
            </div>

            {props.withSeparator && <span className="listItem__separator" />}
        </div>
    )
}

export default ListItem
