import { Typography } from '@material-ui/core'
import React from 'react'

export const ErrorMessage: React.FC = (props) => (
    <Typography className="error" role="alert">
        {props.children}
    </Typography>
)

export default ErrorMessage
