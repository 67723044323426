import Placeholder from 'components/placeholder/Placeholder'
import { History } from 'history'
import withTitle from 'hoc/withTitle'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { compose } from 'recompose'

type Props = RouteComponentProps & WithTranslation

const redirect = (history: History) => () => history.push('/')

const AccessForbidden = (props: Props) => (
    <div className="errorPage__contentBrowser">
        <Placeholder
            className="placeholder--errorPage"
            title={props.t('notFound.title')}
            subtitle={props.t('notFound.subtitle')}
            actionText={props.t('labels.goBackToHome')}
            action={redirect(props.history)}
        />
    </div>
)

export default compose<Props, unknown>(
    withRouter,
    withTitle('Not Found'),
    withTranslation('common')
)(AccessForbidden)
