import { CreateTemplateDialogFormData } from '@app/form'
import Dialog from 'components/dialog/base/Dialog'
import TemplateForm from 'components/templateForm/TemplateForm'
import { FormikHelpers as FormikActions } from 'formik'
import {
    requestAllLabelsRevalidation,
    requestAllTemplatesRevalidation,
    useFetchAllLabels,
    useRequestTemplateCreate
} from 'helpers/templateHelpers'
import { useGlobal } from 'hooks/useGlobal'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

const CreateTemplateDialog = () => {
    const { t } = useTranslation(['common', 'groups', 'error', 'templates'])

    const {
        createNotification,
        closeActiveOverlay,
        tryCloseDirtyOverlay
    } = useGlobal()

    const [labelOptions, setLabelOptions] = React.useState([])

    const { labelList } = useFetchAllLabels()

    const initialValues: CreateTemplateDialogFormData = {
        name: '',
        labels: []
    }

    useEffect(() => {
        setLabelOptions(
            (labelList ?? []).map((label) => ({
                label: label.name,
                value: label.id
            }))
        )
    }, [labelList, setLabelOptions])

    const requestTemplateCreate = useRequestTemplateCreate()
    const onSubmit = async (
        values: CreateTemplateDialogFormData,
        formikActions: FormikActions<CreateTemplateDialogFormData>
    ) => {
        try {
            await requestTemplateCreate({
                name: values.name,
                labels: values.labels.map((item) => ({
                    id: item.value === item.label ? null : item.value,
                    name: item.label,
                    linkedTemplateLabel: false
                }))
            })

            await requestAllTemplatesRevalidation()
            await requestAllLabelsRevalidation()
            closeActiveOverlay()
        } catch (payload) {
            // TODO: Introduce logging
            formikActions.setSubmitting(false)

            const { message } = payload.error.response.data

            if (message === 'name.already.exists') {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.duplicateTemplateName')
                })
            } else {
                createNotification({
                    type: t('error:labels.error'),
                    description: t('error:response.createTemplateUnknown', {
                        templateName: values.name
                    })
                })
            }
        }
    }

    return (
        <Dialog
            title={t('templates:createTemplate.title')}
            closeDialog={tryCloseDirtyOverlay}
            showCloseIcon
        >
            <TemplateForm
                initialValues={initialValues}
                onSubmit={onSubmit}
                labelOptions={labelOptions}
                setLabelOptions={setLabelOptions}
            />
        </Dialog>
    )
}

export default CreateTemplateDialog
